import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import Button from '../Widget/Button'

import firebaseClient from '../../utils/FirebaseClient';

class VerifyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            processing: false,
            message: '',
            errorMessage: ''
        }

        this.verifyEmail = this.verifyEmail.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { user } = this.props;

        if (user && !user.emailVerified && prevProps.user !== user) {
            this.setState({
                show: true
            }, () => {
                firebaseClient.verifyEmail();
            })
        } else if (!user && prevProps.user !== user) {
            this.setState({
                show: false
            });
        }
    }

    verifyEmail() {
        this.setState({
            processing: true,
            message: '',
            errorMessage: ''
        }, () => {
            firebaseClient.verifyEmail().then(() => {
                this.setState({
                    processing: false,
                    message: "Verification email sent again"
                })
            }).catch((error) => {
                this.setState({
                    processing: false,
                    errorMessage: "Some error occurred"
                });
            });
        });
    }

    logout() {
        firebaseClient.logOut();
    }

    render() {
        const { show, processing, message, errorMessage } = this.state;

        return (
            <div className={classNames('verify-wrapper', { 'show': show })}>
                <div className='verify-div'>
                    <p className='verify-text'>
                    We have sent a verification email to your specified email address. Please check your inbox for the email and click on the verification link to complete login.
                    <br />
                    (If the verification email has not arrived after 2 minutes, please check your spam folder)
                    </p>
                    <Button
                        type="default"
                        className="btn-send-again"
                        disabled={processing}
                        onClick={this.verifyEmail}
                    >
                        Send again
                    </Button>
                    <Button
                        type="default"
                        className="btn-send-again"
                        disabled={processing}
                        onClick={this.logout}
                    >
                        Logout
                    </Button>
                    {message ? <p className='again-text'>{message}</p> : null}
                    {errorMessage ? <p className='error-text'>{errorMessage}</p> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user
    };
};

const VerifyAccountContainer = connect(
    mapStateToProps
)(VerifyAccount);

export default VerifyAccountContainer;