export const UPDATE_BANNED_LIST_ITEM_ACTION = 'UPDATE_BANNED_LIST_ITEM_ACTION';
export const UPDATE_BANNED_LIST_ACTION = 'UPDATE_BANNED_LIST_ACTION';
export const CLEAR_BANNED_LIST_ACTION = 'CLEAR_BANNED_LIST_ACTION';

export const updateListItem = (item) => {
    return {
        type: UPDATE_BANNED_LIST_ITEM_ACTION,
        payload: item
    };
}

export const updateList = (data) => {
    return {
        type: UPDATE_BANNED_LIST_ACTION,
        payload: data
    };
}

export const clearList = () => {
    return {
        type: CLEAR_BANNED_LIST_ACTION
    };
}