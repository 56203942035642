import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Hangup from "../../../../resources/assets/Exit.svg";
import * as roomActions from "../../../actions/room_actions";

class HangUpButtonBottomBar extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { showMenu, hideMenu, exitModal } = this.props;

    if (prevProps.exitModal !== exitModal) {
      if (exitModal) {
        showMenu();
      } else {
        hideMenu();
      }
    }
  }

  render() {
    const { exitModal, showExitModal } = this.props;

    return (
      <li className="hangup-bottom-bar">
        <a
          data-tip
          data-for="leave"
          title='Exit Stream'
          onClick={() => showExitModal(!exitModal, false)}
        >
          <img src={Hangup} />
        </a>
      </li>
    );
  }
}

HangUpButtonBottomBar.propTypes = {
  leave: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    exitModal: state.room.exitModal
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showExitModal: (exitModal, continueModal) => {
      dispatch(roomActions.showExitModal(exitModal, continueModal));
    }
  };
};

const HangUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HangUpButtonBottomBar);

export default HangUpContainer;
