import * as appActions from "../actions/app_actions";
var store = null;

class AppClient {
    constructor() {
    }

    setStore(pstore) {
        store = pstore;
    }

    disableScroll(value) {
        if (value) {
            // document.body.style.overflow = "hidden";
            document.body.classList.add("no-scroll")
        } else {
            // document.body.style.overflow = "auto"
            document.body.classList.remove("no-scroll")
        }
    }

    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            let state = getState();
            let res = next(action);
            switch (action.type) {
                case appActions.APP_STATE_ACTION: {
                    break;
                }
            }
            return res;
        };
    }
}

export default new AppClient();