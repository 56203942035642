import React, { Component } from 'react';
import classNames from "classnames";

class Banner extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            children,
            type
        } = this.props;

        return (
            <div className={classNames('banner-container', type)}>
                {children}
            </div>
        )
    }
}

export default Banner;