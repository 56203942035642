import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { push as nativePush } from 'connected-react-router';
import fanoutClient from '../../utils/FanoutClient';
import deviceManager from '../../utils/DeviceManager';
import PreConfiguration from './Conferencing/PreConfiguration';
import { Defines } from '../../utils/FanoutDefines';
import { Device } from "mediasoup-client";
import * as MediasoupClient from "./Fanout/mediasoup-client.js";
import * as roomActions from "../../actions/room_actions";
import * as chatActions from "../../actions/chat_actions";
import * as appActions from "../../actions/app_actions";
import * as devicesActions from "../../actions/devices_actions";
import {
    isIOS,
    setAnonymousWatcher,
    getUserImg
} from '../../utils/text';
import { v1 as uuidv1 } from 'uuid';
import canAutoPlay from 'can-autoplay';
import CallManager from "../../utils/CallManager";
import StreamManager from "../../utils/StreamManager";
import AudioMixer from "../../utils/AudioMixer";
import ConferenceRoom from "./Conferencing/Conference/ConferenceRoom";
import Loader from "../Widget/Loader";
import AudienceView from "./Voxeet/AudienceView";

const initialState = {
    videos: [],
    activeSpeakersList: [],
    visibleSpeakersList: [],
    showBackground: false,
    background: null,
    videoBackground: null,
    activeSpeakers: false,
    screenShare: false,
    videoPresentation: false,
    videoDuration: 0,
    videoURL: null,
    videoPlaying: false,
    videoTS: 0,
    layout: 'no-crop',
    labels: 'default',
    banner: null,
    watermark: null,
    showWatermark: true,
    isMuted: false,
    videoEnabled: true,
    noTicketModal: false,
    displayAttendeesList: false,
    attendeesSettingsOpened: false,
    isLoading: false,
    loadingMessage: '',
    selfScreenShare: false,
    selfVideoPresentation: false,
    canPlay: false,
    showPlayButton: true,
    speakerUserId: null,
    activeSpeakersKeys: [],
    visibleSpeakersKeys: [],
    smallAWBanner: false,
    bigAWBanner: false,
    awExitTime: 0
}

class StreamingConference extends Component {

    #CallManager = null;
    #StreamManager = null;
    #AudioMixer = null;

    constructor(props) {
        super(props);
        this.state = { ...initialState };
        this.sessionEnded = false;

        this.sessionId = uuidv1();

        this.audioConsumer = null;
        this.videoConsumer = null;
        this.remoteMediaStream = null;
        this.recvTransport = null;
        this.remoteVideoId = null;
        this.remoteAudioId = null;
        this.dataProducer = null;
        this.audioProducer = null;
        this.videoProducer = null;
        this.dataConsumers = new Map();
        this.initialDataProducers = null;
        this.dataProducerCallback = null;
        this.dataProducerErrback = null;

        this.previousFanoutState = Defines.Fanout.Status.Starting;

        this.MediasoupDevice = new MediasoupClient.Device();
        this.sendMessage = this.sendMessage.bind(this);
        this.handleFanoutStateChanged = this.handleFanoutStateChanged.bind(this);
        this.handleChatMessage = this.handleChatMessage.bind(this);
        this.getCallState = this.getCallState.bind(this);
        this.endConference = this.endConference.bind(this);

        this.toggleList = this.toggleList.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);
        this.backToForm = this.backToForm.bind(this);
        this.setupRoom = this.setupRoom.bind(this);
        this.cleanupRoom = this.cleanupRoom.bind(this);
        this.rejoinRoom = this.rejoinRoom.bind(this);
        this.reconnectRoom = this.reconnectRoom.bind(this);
        this.reloadPage = this.reloadPage.bind(this);
        this.enableRemoteVideo = this.enableRemoteVideo.bind(this);
        this.reconnectMediasoup = this.reconnectMediasoup.bind(this);
        this.handleShowLogIn = this.handleShowLogIn.bind(this);
        this.handleRoomWarn = this.handleRoomWarn.bind(this);
        this.onShowActiveSpeakers = this.onShowActiveSpeakers.bind(this);
        this.onVideoAdded = this.onVideoAdded.bind(this);
        this.onVideoRemoved = this.onVideoRemoved.bind(this);
        this.onShowBackground = this.onShowBackground.bind(this);
        this.onVideoPresentation = this.onVideoPresentation.bind(this);
        this.onUpdateProducerVideo = this.onUpdateProducerVideo.bind(this);
        this.onOrientationChanged = this.onOrientationChanged.bind(this);
        this.onChangeLayout = this.onChangeLayout.bind(this);
        this.getActiveSpeaker = this.getActiveSpeaker.bind(this);
        this.onActiveSpeaker = this.onActiveSpeaker.bind(this);
        this.handleMuteRemoteVideosForMe = this.handleMuteRemoteVideosForMe.bind(this)
        this.handleJoinedConference = this.handleJoinedConference.bind(this);
        this.handleJoinedRoom = this.handleJoinedRoom.bind(this);
        this.toggleScreenShare = this.toggleScreenShare.bind(this);
        this.handleScreenShareStatus = this.handleScreenShareStatus.bind(this);
        this.toggleVideoPresentation = this.toggleVideoPresentation.bind(this);
        this.getTilesNumber = this.getTilesNumber.bind(this);
        this.getTilesVideo = this.getTilesVideo.bind(this)
        this.getTilesVideoWithoutActiveSpeaker = this.getTilesVideoWithoutActiveSpeaker.bind(this);

        // TODO change this after to get rid of hardcoding and instead request ip from server
        fanoutClient.on('fanoutStateChanged', this.handleFanoutStateChanged);
        fanoutClient.on('chatMessage', this.handleChatMessage);
        fanoutClient.on('enableRemoteVideo', this.enableRemoteVideo);
        fanoutClient.on('roomWarn', this.handleRoomWarn);

        this.remoteVideoEnabled = true;
    }

    getTilesNumber(type) {
        if (!type)
            return 0;

        let { activeSpeakersList, visibleSpeakersList, screenShare, layout } = this.state;
        const { streaming, isListener } = this.props;

        let videos = screenShare || isListener ? this.state.videos : layout && layout === 'speaker' ? activeSpeakersList : visibleSpeakersList;

        if (videos && videos.length) {
            if (screenShare) {
                videos = videos.filter((item) => item.srcType && item.srcType === 'screenshare');
            }

            if (streaming) {
                videos = videos.filter((item) => ((!item.role) || (item.role && item.role !== 'moderator')));
            }

            switch (type) {
                case 'horizontal':
                    return videos.filter(item => item.stream && item.type && item.type === 'horizontal').length;
                case 'vertical':
                    return videos.filter(item => item.stream && item.type && item.type === 'vertical').length;
                default:
                    return videos.filter(item => item.stream).length;
            }
        } else return 0;
    }

    getTilesVideo(type) {
        if (!type)
            return [];

        let { activeSpeakersList, visibleSpeakersList, screenShare, layout } = this.state;
        const { streaming, isListener } = this.props;

        let videos = screenShare || isListener ? this.state.videos : layout && layout === 'speaker' ? activeSpeakersList : visibleSpeakersList;

        if (videos && videos.length) {
            if (screenShare) {
                videos = videos.filter((item) => item.srcType && item.srcType === 'screenshare');
            }

            if (streaming) {
                videos = videos.filter((item) => ((!item.role) || (item.role && item.role !== 'moderator')));
            }

            switch (type) {
                case 'horizontal':
                    return videos.filter(item => item.stream && item.type && item.type === 'horizontal');
                case 'vertical':
                    return videos.filter(item => item.stream && item.type && item.type === 'vertical');
                default:
                    return videos.filter(item => item.stream);
            }
        } else return [];
    }

    getActiveSpeaker() {
        let { activeSpeakersList, visibleSpeakersList, screenShare, layout } = this.state;
        const { streaming, isListener } = this.props;

        let videos = screenShare || isListener ? this.state.videos : layout && layout === 'speaker' ? activeSpeakersList : visibleSpeakersList;

        if (videos && videos.length) {
            if (screenShare) {
                videos = videos.filter((item) => item.srcType && item.srcType !== 'screenshare');
            }

            if (streaming) {
                videos = videos.filter((item) => ((!item.role) || (item.role && item.role !== 'moderator')));
            }

            let retVal = null, filterVideos = videos.filter(item => item.stream) || [];

            if (filterVideos && filterVideos.length) {
                retVal = filterVideos.find((item, i) => item.speaking);
            }

            if (!retVal && filterVideos && filterVideos.length) {
                retVal = filterVideos[0];
            }

            return retVal;
        } else
            return null;
    }

    onActiveSpeaker(data) {
        console.log('onActiveSpeaker', data);
        let { videos } = this.state;
        const { payload } = data || {};
        const { user_id, activeSpeakers, visibleSpeakers } = payload || {};

        let videosArray = [], activeSpeakersArray = [], visibleSpeakersArray = [];

        this.setState({
            speakerUserId: user_id ? user_id : null,
            activeSpeakersKeys: activeSpeakers && activeSpeakers.length ? activeSpeakers : [],
            visibleSpeakersKeys: visibleSpeakers && visibleSpeakers.length ? visibleSpeakers : []
        }, () => {
            if (videos && videos.length && user_id) {
                videos.map((item, i) => {
                    if (item && item.srcType && item.srcType === 'camera') {
                        if (item && item.uid && item.uid === user_id) {
                            item.speaking = true;
                            item.previousSpeaker = false;
                        } else if (item && item.speaking) {
                            item.speaking = item.uid && item.uid === user_id ? true : false;
                            item.previousSpeaker = item.uid && item.uid === user_id ? false : true;
                        } else {
                            item.speaking = false;
                            item.previousSpeaker = false;
                        }
                    }

                    return item;
                });

                videosArray = videos;
            } else {
                if (videos && videos.length && ((videos.length === 1) || (!this.checkIfActiveSpeaker(videos) && !this.checkIfPrevActiveSpeaker(videos)))) {
                    videos[0].speaking = true;
                    videos[0].previousSpeaker = false;
                } else if (videos && videos.length && this.checkIfPrevActiveSpeaker(videos)) {
                    videos.map((item) => {
                        if (item && item.srcType && item.srcType === 'camera') {
                            if (item.previousSpeaker) {
                                item.speaking = true;
                                item.previousSpeaker = false;
                            } else {
                                item.speaking = false;
                                item.previousSpeaker = false;
                            }
                        }

                        return item;
                    });
                }

                videosArray = videos;
            }

            if (videosArray && videosArray.length && activeSpeakers && activeSpeakers.length) {
                videosArray.forEach((item) => {
                    if (activeSpeakers.indexOf(item.producerId) !== -1) {
                        activeSpeakersArray[activeSpeakers.indexOf(item.producerId)] = item;
                    }
                });
            }

            if (videosArray && videosArray.length && visibleSpeakers && visibleSpeakers.length) {
                videosArray.forEach((item) => {
                    if (visibleSpeakers.indexOf(item.producerId) !== -1) {
                        visibleSpeakersArray[visibleSpeakers.indexOf(item.producerId)] = item;
                    }
                });
            }

            this.setState({
                videos: videosArray,
                activeSpeakersList: activeSpeakersArray && activeSpeakersArray.length ? activeSpeakersArray : videosArray,
                visibleSpeakersList: visibleSpeakersArray && visibleSpeakersArray.length ? visibleSpeakersArray : videosArray,
            });
        });
    }

    checkIfActiveSpeaker(videos) {
        let retVal = false;

        if (videos && videos.length) {
            videos.map((item) => {
                if (item && item.speaking) {
                    retVal = true;
                }
                return item;
            })
        }

        return retVal;
    }

    checkIfPrevActiveSpeaker(videos) {
        let retVal = false;

        if (videos && videos.length) {
            videos.map((item) => {
                if (item && item.previousSpeaker) {
                    retVal = true;
                }
                return item;
            })
        }

        return retVal;
    }

    setAndFilterSpeakers(videos, type) {
        const { speakerUserId, activeSpeakersKeys, visibleSpeakersKeys } = this.state;
        let activeSpeakersArray = [], visibleSpeakersArray = [];

        if (videos && videos.length && speakerUserId) {
            videos.map((item, i) => {
                if (item && item.srcType && item.srcType === 'camera') {
                    if (item && item.uid && item.uid === speakerUserId) {
                        item.speaking = true;
                        item.previousSpeaker = false;
                    } else if (item && item.speaking) {
                        item.speaking = item.uid && item.uid === speakerUserId ? true : false;
                        item.previousSpeaker = item.uid && item.uid === speakerUserId ? false : true;
                    } else {
                        item.speaking = false;
                        item.previousSpeaker = false;
                    }
                }

                return item;
            });
        } else if (!speakerUserId) {
            if (videos && videos.length && ((videos.length === 1) || (!this.checkIfActiveSpeaker(videos) && !this.checkIfPrevActiveSpeaker(videos)))) {
                videos[0].speaking = true;
                videos[0].previousSpeaker = false;
            } else if (videos && videos.length && this.checkIfPrevActiveSpeaker(videos)) {
                videos.map((item) => {
                    if (item && item.srcType && item.srcType === 'camera') {
                        if (item.previousSpeaker) {
                            item.speaking = true;
                            item.previousSpeaker = false;
                        } else {
                            item.speaking = false;
                            item.previousSpeaker = false;
                        }
                    }

                    return item;
                });
            }
        }

        switch (type) {
            case 'active': {
                if (videos && videos.length && activeSpeakersKeys && activeSpeakersKeys.length) {
                    videos.forEach((item) => {
                        if (activeSpeakersKeys.indexOf(item.producerId) !== -1) {
                            activeSpeakersArray[activeSpeakersKeys.indexOf(item.producerId)] = item;
                        }
                    });
                }
                return activeSpeakersArray;
            }
            case 'visible': {
                if (videos && videos.length && visibleSpeakersKeys && visibleSpeakersKeys.length) {
                    videos.forEach((item) => {
                        if (visibleSpeakersKeys.indexOf(item.producerId) !== -1) {
                            visibleSpeakersArray[visibleSpeakersKeys.indexOf(item.producerId)] = item;
                        }
                    });
                }
                return visibleSpeakersArray;
            }
            default: {
                return videos;
            }
        }
    }

    getTilesVideoWithoutActiveSpeaker() {
        const { activeSpeakersList, visibleSpeakersList, screenShare, layout } = this.state;
        const { streaming, isListener } = this.props;

        let videos = screenShare || isListener ? this.state.videos : layout && layout === 'speaker' ? activeSpeakersList : visibleSpeakersList;

        if (videos && videos.length) {
            let retVal = [], filterVideos = videos.filter(item => item.stream) || [];

            if (screenShare) {
                filterVideos = filterVideos.filter((item) => item.srcType && item.srcType === 'screenshare');
            }

            if (streaming) {
                filterVideos = filterVideos.filter((item) => ((!item.role) || (item.role && item.role !== 'moderator')));
            }

            if (filterVideos && filterVideos.length) {
                retVal = filterVideos.filter((item, i) => !item.speaking);

                if (retVal && retVal.length && retVal.length === filterVideos.length) {
                    retVal = retVal.slice(1);
                }
            }

            return retVal;
        } else
            return [];
    }

    async setupRoom() {

        this.sessionEnded = false;

        this.sessionId = uuidv1();

        this.audioConsumer = null;
        this.videoConsumer = null;
        this.remoteMediaStream = null;
        this.recvTransport = null;
        this.remoteVideoId = null;
        this.remoteAudioId = null;
        this.dataProducer = null;
        this.dataConsumers = new Map();
        this.initialDataProducers = null;
        this.dataProducerCallback = null;
        this.dataProducerErrback = null;
        this.consumerData = {};

        this.previousFanoutState = Defines.Fanout.Status.Starting;

        this.MediasoupDevice = new Device();
        this.setState({ ...initialState });
    }

    handleJoinedConference(eventId) {
        const { isAdmin } = this.props;

        if (eventId) {
            fanoutClient.sendChatConnect(this.getCallState());
            fanoutClient.updateSignallingServer('chat-history', {}, this.getCallState());

            if (isAdmin) {
                fanoutClient.updateSignallingServer('knock-requests', {}, this.getCallState());
            }
        }
    }

    handleJoinedRoom(eventId) {
        const { isAnonymousGuest } = this.props;

        if (eventId) {
            fanoutClient.sendChatConnect(this.getCallState());
            fanoutClient.updateSignallingServer('chat-history', {}, this.getCallState());
        }

        if (isAnonymousGuest) {
            fanoutClient.sendKnockRequest(this.getCallState(), { isAnonymousGuest: true });
        }
    }

    stopAllStreams() {
        this.#CallManager.stopAllStreams();
        deviceManager.stopAllStreams();
    }

    async handleJoin() {
        const { eventId, name, uid, username, eventItem, audioInputDevice, videoInputDevice, joinRoom, isListener } = this.props;

        let params = {
            name, uid, username,
            eventId: eventId || eventItem.id,
            audioInputDevice: audioInputDevice,
            videoInputDevice: videoInputDevice,
            remoteVideoEnabled: true,
            callParams: eventItem.callParams
        };

        let autoPlayResult = await this.handleCanAutoPlay();

        if (!autoPlayResult)
            return;

        if (!isListener) {
            this.setAudioDevice();
            this.#CallManager.joinConference(params).then(this.handleJoinedConference);
        } else {
            this.#CallManager.joinRoom(params).then(this.handleJoinedRoom);
        }

        if (joinRoom)
            joinRoom();

        console.log("StreamingConference handleJoin called, sending join-conference request sessionId:", params, this.props);
    }

    async endConference() {
        const { pushToHome } = this.props;

        let ended = await this.#CallManager.endConference();
        if (ended) {
            await deviceManager.leaveConference();
            fanoutClient.cleanTheRoom();
            pushToHome?.();
        } else {
            // TODO: Add some warning and retry button
            console.error('endConference()  Could not end the conference');
        }
    }

    async toggleScreenShare(type) {
        console.log('toggleScreenShare called');
        const { selfScreenShare, selfVideoPresentation } = this.state;
        const { eventItem } = this.props;

        if (selfScreenShare || (type && type === 'screenshare')) {
            if (!selfScreenShare) {
                let mediaStream = null;
                try {
                    mediaStream = await navigator.mediaDevices.getDisplayMedia({
                        video: {
                            cursor: "always"
                        },
                        selfBrowserSurface: "exclude",
                        audio: false
                    });
                    console.log('toggleScreenShare stream', mediaStream);
                    await this.#CallManager.createScreenshareProducers(mediaStream, this.handleScreenShareStatus);
                } catch (ex) {
                    console.log("Error occurred", ex);
                }
            } else {
                try {
                    await this.#CallManager.stopAllScreenshareProducers().then(this.handleScreenShareStatus);
                } catch (ex) {
                    console.log("Error occurred", ex);
                }
            }
        } else if (selfVideoPresentation) {
            try {
                await this.#CallManager.stopVideo({ eventId: eventItem.id }).then(() => {
                    this.setState({
                        selfVideoPresentation: false,
                        videoPresentation: false,
                        videoURL: '',
                        videoPlaying: false,
                        videoTS: 0,
                        videoDuration: 0
                    });
                });
            } catch (ex) {
                console.log("Error occurred", ex);
            }
        }
    }

    handleScreenShareStatus(screenShareStatus) {
        this.setState({
            selfScreenShare: screenShareStatus
        })
    }

    async toggleVideoPresentation(url) {
        console.log('toggleVideoPresentation called');
        const { selfVideoPresentation, videoPresentation } = this.state;
        const { eventItem } = this.props;

        if (!selfVideoPresentation) {
            try {
                if (videoPresentation) {
                    this.setState({
                        videoPresentation: false
                    })
                }
                await this.#CallManager.startVideo({ url, eventId: eventItem.id }).then((res) => {
                    if (res) {
                        this.setState({
                            selfVideoPresentation: true,
                            videoPresentation: true,
                            videoURL: res.url,
                            videoPlaying: true,
                            videoTS: 0,
                            videoDuration: 0
                        });
                    }
                });
            } catch (ex) {
                console.log("Error occurred", ex);
            }
        } else {
            try {
                await this.#CallManager.stopVideo({ eventId: eventItem.id }).then(() => {
                    this.setState({
                        selfVideoPresentation: false,
                        videoPresentation: false,
                        videoURL: '',
                        videoPlaying: false,
                        videoTS: 0,
                        videoDuration: 0
                    });
                });
            } catch (ex) {
                console.log("Error occurred", ex);
            }
        }
    }

    /**
     * @param params
     * @return {Promise<void>}
     */
    async onShowActiveSpeakers(params) {
        console.log('onShowActiveSpeakers', params);
        const { streams } = params;

        this.setState({
            videos: this.setAndFilterSpeakers(streams),
            activeSpeakersList: this.setAndFilterSpeakers(streams, 'active'),
            visibleSpeakersList: this.setAndFilterSpeakers(streams, 'visible'),
            activeSpeakers: true,
            screenShare: streams.filter((item) => item.srcType && item.srcType === 'screenshare').length ? true : false
        });
    }

    /**
    * @param params
    * @return {Promise<void>}
    */
    async onVideoAdded(video) {
        console.log('onVideoAdded', video);

        let newVideos = [...this.state.videos, video && video.stream && video.stream.id ? video : null];

        this.setState({
            videos: this.setAndFilterSpeakers(newVideos),
            activeSpeakersList: this.setAndFilterSpeakers(newVideos, 'active'),
            visibleSpeakersList: this.setAndFilterSpeakers(newVideos, 'visible'),
            activeSpeakers: true,
            screenShare: newVideos.filter((item) => item.srcType && item.srcType === 'screenshare').length ? true : false
        });
    }

    /**
    * @param params
    * @return {Promise<void>}
    */
    async onVideoRemoved(video) {
        console.log('onVideoRemoved', video);

        let { videos } = this.state;

        if (video && video.producerId) {
            videos = videos.filter((item) => item.producerId && item.producerId !== video.producerId);
        }

        this.setState({
            videos: this.setAndFilterSpeakers(videos),
            activeSpeakersList: this.setAndFilterSpeakers(videos, 'active'),
            visibleSpeakersList: this.setAndFilterSpeakers(videos, 'visible'),
            activeSpeakers: true,
            screenShare: videos.filter((item) => item.srcType && item.srcType === 'screenshare').length ? true : false
        });
    }

    /**
     * TODO: Show background
     * @param params
     * @return {Promise<void>}
     */
    async onShowBackground() {
        console.log('onShowBackground');
        this.setState({
            showBackground: true
        });
    }

    /**
     * @param params
     * @return {Promise<void>}
     */
    onVideoPresentation(params) {
        console.log('onVideoPresentation', params);
        const { selfVideoPresentation } = this.state;

        const { request, payload } = params || {};
        let { eventId, timestamp, url, time } = payload || {};

        console.log("VideoUpdate Event:", request, eventId, timestamp, url);
        switch (request) {
            case Defines.DolbyEvents.VideoPresentation.Started:
            case Defines.DolbyEvents.VideoPresentation.Sought: {
                if (time) {
                    // Add offset if user gets status right after joining the call
                    let delta = Date.now() - time;
                    console.log('Found offset for video update', delta);
                    timestamp += delta;
                }
                this.setState({
                    videoPresentation: selfVideoPresentation ? false : true,
                    videoURL: url,
                    videoPlaying: true,
                    videoTS: timestamp,
                    videoDuration: parseInt(timestamp ? timestamp / 1000 : 0) || 0,
                    selfVideoPresentation: false
                }, () => {
                    if (selfVideoPresentation)
                        this.setState({
                            videoPresentation: true
                        });
                });
            }
                break;
            case Defines.DolbyEvents.VideoPresentation.Paused:
                this.setState({
                    videoPresentation: selfVideoPresentation ? false : true,
                    videoURL: url,
                    videoPlaying: false,
                    videoTS: timestamp,
                    videoDuration: parseInt(timestamp ? timestamp / 1000 : 0) || 0,
                    selfVideoPresentation: false
                }, () => {
                    if (selfVideoPresentation)
                        this.setState({
                            videoPresentation: true
                        });
                });
                break;
            case Defines.DolbyEvents.VideoPresentation.Played:
                this.setState({
                    videoPresentation: selfVideoPresentation ? false : true,
                    videoURL: url,
                    videoPlaying: true,
                    videoTS: timestamp,
                    videoDuration: parseInt(timestamp ? timestamp / 1000 : 0) || 0,
                    selfVideoPresentation: false
                }, () => {
                    if (selfVideoPresentation)
                        this.setState({
                            videoPresentation: true
                        });
                });
                break;
            case Defines.DolbyEvents.VideoPresentation.Stopped:
                this.setState({
                    videoPresentation: false,
                    videoURL: '',
                    videoPlaying: false,
                    videoTS: 0,
                    videoDuration: 0,
                    selfVideoPresentation: false
                });
                break;
            default:
                console.log('Video_Update default', params);
                break;
        }
    }

    /**
     * @param params
     * @return {Promise<void>}
     */
    async onUpdateProducerVideo(params) {
        return new Promise((resolve) => {
            try {
                const { producerId } = params || {};
                let { videos } = this.state;
                console.log('onUpdateProducerVideo', params);
                videos.forEach(async (speaker, index) => {
                    if (producerId && speaker && speaker.producerId && speaker.producerId === producerId) {
                        videos[index] = Object.assign(videos[index], params);
                        console.log('Track %i update', index, videos[index]);
                    }
                });
                this.setState({
                    videos: this.setAndFilterSpeakers(videos),
                    activeSpeakersList: this.setAndFilterSpeakers(videos, 'active'),
                    visibleSpeakersList: this.setAndFilterSpeakers(videos, 'visible')
                }, resolve)
            } catch (e) {
                console.error('onUpdateProducerVideo Error: %s', e.message, e);
                resolve();
            }
        });
    }

    getDefaultBanner(banners, banner, speakerBanner) {
        console.log('getDefaultBanner', banners, banner, speakerBanner)
        let found = null;

        if (banners && banners.length) {
            if (banner) {
                found = banners.find(b => b.id === banner)
            } else {
                found = banners.find(b => b.default)
            }

            if (!found) {
                found = banners[0];
            }
        } else if (!found && speakerBanner) {
            found = {
                img: speakerBanner,
                default: true,
                id: (Math.random() + 1).toString(36).substring(2),
                link: null
            }
        }

        return found;
    }

    async onOrientationChanged(params) {
        return new Promise((resolve) => {
            try {
                const { streamId, orientation } = params || {};
                let { videos } = this.state;
                console.log('onOrientationChanged');
                videos.forEach(async (speaker, index) => {
                    if (orientation && streamId && speaker && speaker.stream && speaker.stream.id && speaker.stream.id === streamId) {
                        videos[index].type = orientation;
                        console.log('Track %i update', index, videos[index]);
                    }
                });
                this.setState({
                    videos: this.setAndFilterSpeakers(videos),
                    activeSpeakersList: this.setAndFilterSpeakers(videos, 'active'),
                    visibleSpeakersList: this.setAndFilterSpeakers(videos, 'visible')
                }, resolve)
            } catch (e) {
                console.error('LayoutManager::onOrientationChanged Error: %s', e.message, e);
                resolve();
            }
        });
    }

    /**
     * TODO: change layout
     * @param payload
     * @return {Promise<void>}
     */
    async onChangeLayout(data) {
        console.log('onChangeLayout', data);
        const { payload } = data
        const { speakerBanner, banners, background, videoBackground, labels, watermark } = payload.layoutParams || {};
        const { isListener } = this.props;

        if (isListener) return;

        this.setState({
            layout: payload.layout || '',
            banner: this.getDefaultBanner(banners, payload.banner || '', speakerBanner),
            background: background,
            videoBackground: videoBackground,
            labels: labels,
            watermark: watermark
        });
    }

    async componentDidMount() {
        const { streaming, displayMessage, eventItem, isAnonymous, isAnonymousGuest, isListener, role, isOwner } = this.props;

        this.#CallManager = CallManager.get({ eventId: eventItem.id });
        this.#CallManager.on('rejoin', this.rejoinRoom);
        this.#CallManager.on('reconnect', this.reconnectRoom);
        this.#StreamManager = new StreamManager({ callManager: this.#CallManager });
        this.#StreamManager.on('showActiveSpeakers', this.onShowActiveSpeakers);
        this.#StreamManager.on('onVideoAdded', this.onVideoAdded);
        this.#StreamManager.on('onVideoRemoved', this.onVideoRemoved);
        this.#StreamManager.on('showBackground', this.onShowBackground);
        this.#StreamManager.on('videoPresentation', this.onVideoPresentation);
        this.#StreamManager.on('updateProducerVideo', this.onUpdateProducerVideo);
        this.#StreamManager.on('orientationChanged', this.onOrientationChanged);
        this.#StreamManager.on('changeLayout', this.onChangeLayout);
        this.#StreamManager.on('activeSpeaker', this.onActiveSpeaker);
        this.#AudioMixer = AudioMixer.get({ eventId: eventItem.id, callManager: this.#CallManager });

        if (!streaming && displayMessage && isListener) {
            displayMessage('The event will begin soon', null, 'top');
        }

        this.setupRoom();

        if (isAnonymous || isAnonymousGuest) {
            this.setState({
                smallAWBanner: true
            });
        }

        if (isListener) {
            this.handleJoin();
        }

        // if (eventItem && role) {
        //     fanoutClient.checkOverrunsTimeout(eventItem, role, isOwner);
        // }
    }

    async handleCanAutoPlay() {
        let APVideo = await canAutoPlay.video({ inline: true, muted: true }).then(({ result }) => result);
        let APAudio = await canAutoPlay.audio().then(({ result }) => result);

        console.log('handleCanAutoPlay result', APVideo, APAudio);

        if (APVideo && APAudio) {
            this.setState({
                canPlay: true,
                showPlayButton: false
            });
            return true;
        } else {
            this.setState({
                canPlay: false,
                showPlayButton: true
            });
            return false;
        }
    }

    handleMuteAudio(shouldMute) {
        this.#CallManager.muteAudio(shouldMute).then(() => {
            this.setState({ isMuted: shouldMute });
        }).catch(e => {
            console.error('Could not set mute audio to %s', shouldMute);
        });
    }

    handleMuteVideo(shouldMute) {
        this.#CallManager.muteVideo(shouldMute).then(() => {
            this.setState({ videoEnabled: !shouldMute });
        }).catch(e => {
            console.error('Could not set mute video to %s', shouldMute);
        });
    }

    handleMuteRemoteVideosForMe(shouldMute) {
        this.#CallManager.muteRemoteVideosForMe(shouldMute).then(() => {
            const { setLowBandwidthMode } = this.props;

            if (setLowBandwidthMode) {
                setLowBandwidthMode(shouldMute);
            }
        }).catch(e => {
            console.error('Could not set mute remote videos to %s', shouldMute);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            eventItem,
            orientation,
            streaming,
            displayMessage,
            hideMessage,
            roomMessage,
            isAnonymous,
            isAnonymousGuest,
            user,
            isListener,
            conferenceEnded,
            role,
            isOwner
        } = this.props;

        if (prevProps.streaming !== streaming) {
            if (streaming && hideMessage && roomMessage && roomMessage.displayRoomMessage) {
                hideMessage();
            } else if (!streaming && streaming !== null && displayMessage && isListener && !conferenceEnded) {
                displayMessage('Please wait', null, 'top');
            }
        }

        if ((prevProps.isAnonymous !== isAnonymous) || (prevProps.isAnonymousGuest !== isAnonymousGuest)) {
            if (isAnonymous || isAnonymousGuest) {
                this.setState({
                    smallAWBanner: true
                });
            }

            if (!streaming && displayMessage && isListener) {
                displayMessage('The event will begin soon', null, 'top');
            }
        }

        // if (eventItem && role && ((eventItem !== prevProps.eventItem) || (role !== prevProps.role))) {
        //     fanoutClient.checkOverrunsTimeout(eventItem, role, isOwner);
        // }
    }

    setAudioDevice() {
        const { audioOutputDevice } = this.props;

        if (audioOutputDevice && audioOutputDevice.deviceId && this.#AudioMixer) {
            this.#AudioMixer.sinkId = deviceManager.getDefaultOutputAudioDeviceId(audioOutputDevice.deviceId);
        }
    }

    /**
     * Keep latest and previous fanout state
     * @param state
     */
    handleFanoutStateChanged(state) {
        try {
            console.log("handleFanoutStateChange", state);

            // check if this.sessionClosed or whatever the flag is if not then set reconnecting
            if (state !== Defines.Fanout.Status.Running &&
                this.previousFanoutState === Defines.Fanout.Status.Running && !this.sessionEnded)
                console.warn("Reconnecting");

            if (this.previousFanoutState != state)
                this.previousFanoutState = state;
        } catch (err) {
            console.error(err);
        }
    }

    getCallState() {
        const { eventId, name, uid, username } = this.props;

        if (eventId) {
            console.log('getCallState', {
                fanoutId: process.env.fanoutId,
                username: username ? username : (name ? name : 'Unknown'),
                uid: uid ? uid : null,
                routerId: this.routerId,
                sessionId: this.sessionId,
                conferenceAlias: eventId,
            });
            return {
                fanoutId: process.env.fanoutId,
                username: username ? username : (name ? name : 'Unknown'),
                uid: uid ? uid : null,
                routerId: this.routerId,
                sessionId: this.sessionId,
                conferenceAlias: eventId,
            }
        } else {
            return null;
        }
    }

    handleChatMessage(data) {
        const { addMessage } = this.props;
        const dataObj = (data.payload);
        const { title } = dataObj;
        console.log('Got chat message');

        if (title && title === Defines.Fanout.DataChannel.Chat && addMessage) {
            console.log('Handling chat message');
            addMessage(dataObj);
        }
    }

    /*
     * @brief: Shut down all the active mediasoup entities
     * @param: type - indicates whether shutting down for incoming/outgoing data/media
     */
    async destroyMediasoup(type) {
        console.log("destroyMediasoup", type);
        if (!type)
            return;

        if (type & Defines.Fanout.Signalling.Transport.Recv) {
            if (this.recvTransport && !this.recvTransport.closed) {
                try {
                    await this.recvTransport.close();
                } catch (e) {
                }
                this.recvTransport = null;
            }
        }
        if (type & Defines.Fanout.Signalling.Transport.Send) {
            if (this.sendTransport && !this.sendTransport.closed) {
                try {
                    await this.sendTransport.close();
                } catch (e) {
                }
                this.sendTransport = null;
            }
        }
    };

    /*
     * @brief: Destroy the session, which entails all mediasoup entities, removing ref and
     *         getting rid of incoming table in fbrtdb
     */
    destroySession() {
        if (this.remoteMediaStream && this.remoteMediaStream.getTracks) {
            console.log("StreamingConference stopping tracks");
            for (let track of this.remoteMediaStream.getTracks()) {
                try {
                    track.stop();
                } catch (e) {
                    // NOP
                }
            }
        }
        this.remoteMediaStream = null;
    };

    sendMessage(message) {
        const { username, name, uid } = this.props;

        if (message) {
            const chat = {
                title: Defines.Fanout.DataChannel.Chat,
                content: message,
                time: Date.now(),
                type: "text",
                name: username ? username : (name ? name : 'Unknown'),
                ownerId: uid ? uid : '',
                avatarUrl: null
            };
            fanoutClient.sendChatMessage(JSON.stringify(chat), this.getCallState());
        }
    }

    async stop() {
        console.log("StreamingConference stop called");
        await this.destroyMediasoup(Defines.Fanout.Signalling.Transport.Send | Defines.Fanout.Signalling.Transport.Recv);
        this.destroySession();
    };

    componentWillUnmount() {
        const { eventItem } = this.props;
        AudioMixer.delete(eventItem.id);
        this.#CallManager.removeListener('rejoin', this.rejoinRoom);
        this.#CallManager.removeListener('reconnect', this.reconnectRoom);
        this.#StreamManager.removeListener('showActiveSpeakers', this.onShowActiveSpeakers);
        this.#StreamManager.removeListener('onVideoAdded', this.onVideoAdded);
        this.#StreamManager.removeListener('onVideoRemoved', this.onVideoRemoved);
        this.#StreamManager.removeListener('showBackground', this.onShowBackground);
        this.#StreamManager.removeListener('videoPresentation', this.onVideoPresentation);
        this.#StreamManager.removeListener('updateProducerVideo', this.onUpdateProducerVideo);
        this.#StreamManager.removeListener('orientationChanged', this.onOrientationChanged);
        this.#StreamManager.removeListener('changeLayout', this.onChangeLayout);
        this.#StreamManager.removeListener('activeSpeaker', this.onActiveSpeaker);
        this.#StreamManager.destroy();
        this.#CallManager.destroy();

        fanoutClient.removeListener('fanoutStateChanged', this.handleFanoutStateChanged);
        fanoutClient.removeListener('chatMessage', this.handleChatMessage);
        fanoutClient.removeListener('enableRemoteVideo', this.enableRemoteVideo);
        fanoutClient.removeListener('roomWarn', this.handleRoomWarn);

        clearInterval(this.statsInterval);

        if (this.rejoinTimeout) {
            clearTimeout(this.rejoinTimeout);
            this.rejoinTimeout = null;
        }

        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }

        if (this.vanityTimeout) {
            clearTimeout(this.vanityTimeout);
            this.vanityTimeout = null;
        }

        if (this.streamingTimeout) {
            clearTimeout(this.streamingTimeout);
            this.streamingTimeout = null;
        }
        console.log("StreamingConference ComponentWillUnmount called");

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = null;
        }

        const { hideMessage } = this.props;

        if (hideMessage)
            hideMessage();

        this.cleanupRoom();
        this.stopAllStreams();
    }

    async cleanupRoom() {
        const { clearMessages } = this.props;

        clearInterval(this.statsInterval);

        if (this.rejoinTimeout) {
            clearTimeout(this.rejoinTimeout);
            this.rejoinTimeout = null;
        }

        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }

        if (this.vanityTimeout) {
            clearTimeout(this.vanityTimeout);
            this.vanityTimeout = null;
        }

        if (this.streamingTimeout) {
            clearTimeout(this.streamingTimeout);
            this.streamingTimeout = null;
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        if (!this.sessionEnded) {
            this.sessionEnded = true;
            await this.stop();
        } else
            console.log("Mediasoup Device Already Destroyed");

        if (clearMessages)
            clearMessages();
    }

    async reconnectMediasoup() {
        this.stop();

        this.sessionEnded = false;

        this.sessionId = uuidv1();

        this.audioConsumer = null;
        this.videoConsumer = null;
        this.remoteMediaStream = null;
        this.recvTransport = null;
        this.remoteVideoId = null;
        this.remoteAudioId = null;
        this.dataProducer = null;
        this.dataConsumers = new Map();
        this.initialDataProducers = null;
        this.dataProducerCallback = null;
        this.dataProducerErrback = null;
        this.consumerData = {};

        this.previousFanoutState = Defines.Fanout.Status.Starting;

        const { eventItem } = this.props;

        fanoutClient.updateSignallingServer('join-conference', this.getCallState(), this.getCallState()).then((res) => {
        });
        console.log("StreamingConference reconnectMediasoup called, sending join-conference request sessionId:", this.sessionId, this.props);
    }

    async rejoinRoom() {
        console.log('--- About to rejoin ----------')
        this.setState({
            isLoading: true,
            loadingMessage: 'Rejoining'
        });
        if (this.rejoinTimeout) {
            clearTimeout(this.rejoinTimeout);
        }
        this.rejoinTimeout = setTimeout(() => {
            this.setState({
                isLoading: false,
                loadingMessage: ''
            }, () => {
                this.handleJoin();
            })
        }, 2000);
        console.log('--- rejoin ----------')
    }

    async reconnectRoom() {
        console.log('--- About to reconnect ----------')
        this.setState({
            isLoading: true,
            loadingMessage: 'Reconnecting'
        });
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
        }
        this.reconnectTimeout = setTimeout(() => {
            this.cleanupOldConference();
            this.setState({
                isLoading: false,
                loadingMessage: ''
            }, () => {
                const { joinUser, role, user } = this.props;

                if (joinUser) {
                    joinUser(
                        true,
                        user && user.username ? user.username : null,
                        null,
                        null,
                        null,
                        getUserImg(user),
                        user && user.email ? user.email : null,
                        false,
                        role,
                        null
                    );
                }
            })
        }, 2000);
        console.log('--- reconnect ----------')
    }

    cleanupOldConference() {
        const { cleanupDevices } = this.props;

        if (cleanupDevices) {
            cleanupDevices();
        }

        this.setupRoom();
        fanoutClient.cleanTheRoomReconnect();
    }

    async enableRemoteVideo(shouldEnable) {
        console.log(`--- About to ${shouldEnable ? 'enable' : 'disable'} video`);
        if (shouldEnable) {
            this.remoteVideoEnabled = true;
            this.reconnectMediasoup();
        } else {
            this.remoteVideoEnabled = false;
            this.reconnectMediasoup();
        }
    }

    handleRoomWarn(data) {
        try {
            console.log("handleRoomWarn response received from server", data);
            const { smallAWBanner, bigAWBanner, awExitTime } = this.state;
            const { eventItem, isAnonymous, isAnonymousGuest, defaults } = this.props;

            if (data && data.status && data.status === Defines.Response.Unauthorized && smallAWBanner && !bigAWBanner && !awExitTime && !isAnonymousGuest) {
                this.setState({
                    smallAWBanner: false,
                    bigAWBanner: true,
                    awExitTime: defaults && defaults.anonymousWatchersCounterTime && parseInt(defaults.anonymousWatchersCounterTime) ? parseInt(defaults.anonymousWatchersCounterTime) : 60
                }, () => {
                    if (isAnonymous && eventItem.id) {
                        setAnonymousWatcher(eventItem.id);
                    }
                });
            }
        } catch (error) {
            console.error("Failed handleRoomWarn", error, data);
        }
    }

    handlePeerClosedResponse() {
        if (!this.sessionEnded) {
            this.sessionEnded = true;
            this.destroyMediasoup(Defines.Fanout.Signalling.Transport.Send | Defines.Fanout.Signalling.Transport.Recv);
            this.destroySession();
        }
    };

    toggleList() {
        const { displayAttendeesList } = this.state;

        this.setState({
            displayAttendeesList: !displayAttendeesList,
            attendeesSettingsOpened: false
        });
    }

    toggleSettings() {
        const { attendeesSettingsOpened } = this.state;

        this.setState({
            displayAttendeesList: false,
            attendeesSettingsOpened: !attendeesSettingsOpened
        });
    }

    backToForm() {
        const { handleExit, knocks } = this.props;

        if (Object.keys(knocks).length) {
            let callState = this.getCallState();
            Object.keys(knocks).map((key) => {
                if (knocks[key] && callState && knocks[key].uid === callState.uid) {
                    fanoutClient.sendKnockRevoked(knocks[key].rid, callState);
                }
                return key;
            });
        }

        if (handleExit) {
            handleExit(true);
        }
    }

    pushToVanityPage() {
        const { eventItem, pushToVanity, eventId } = this.props;
        if (eventItem && eventItem.id && eventItem.type) {
            pushToVanity(`/${eventItem.type}/${eventId}`)
        }
    }

    checkHorizontalScaling(width, height) {
        if (width > ((height / 9) * 16)) {
            return true;
        } else return false;
    }

    handleShowLogIn() {
        const { showLogIn, logIn } = this.props;

        if (showLogIn) {
            showLogIn(!logIn);
        }
    }

    reloadPage() {
        const { displayMessage } = this.props;

        if (displayMessage) {
            displayMessage('Leaving the event, now returning to event page', 5000);
        }

        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    render() {
        const { isMuted, videoEnabled, isLoading, loadingMessage, canPlay, showPlayButton } = this.state;
        const { preConfiguration, isListener, user, role, eventItem, eventId, username, name, userPhoto, isAdmin } = this.props;

        console.log('rerendering...', this.state, this.props);
        console.log('videos...', this.state.videos);

        return (
            <div className='conference-wrapper voxeet-wrapper'>
                {isLoading ?
                    <Loader
                        text={loadingMessage}
                        dots={loadingMessage ? true : false}
                    />
                    :
                    preConfiguration && !isListener ?
                        <PreConfiguration
                            handleJoin={() => this.handleJoin()}
                        />
                        : !canPlay ?
                            showPlayButton ?
                                <Loader
                                    clickFunction={() => this.handleJoin()}
                                    text="Click to play"
                                    play
                                />
                                :
                                <Loader
                                    text='Connecting stream'
                                />
                            :
                            <Fragment>
                                <ConferenceRoom
                                    {...this.state}
                                    {...this.props}
                                    getTilesNumber={this.getTilesNumber}
                                    getTilesVideo={this.getTilesVideo}
                                    getTilesVideoWithoutActiveSpeaker={this.getTilesVideoWithoutActiveSpeaker}
                                    getActiveSpeaker={this.getActiveSpeaker}
                                    sendMessage={this.sendMessage}
                                    getCallState={this.getCallState}
                                    toggleScreenShare={this.toggleScreenShare}
                                    toggleVideoPresentation={this.toggleVideoPresentation}
                                    muteRemoteVideosForMe={this.handleMuteRemoteVideosForMe}
                                    handleShowLogIn={this.handleShowLogIn}
                                    toggleList={this.toggleList}
                                    toggleSettings={this.toggleSettings}
                                    endConference={this.endConference}
                                    handleMuteAudio={() => this.handleMuteAudio(!isMuted)}
                                    handleMuteVideo={() => this.handleMuteVideo(videoEnabled)}
                                    changeLayout={(val) => this.setState({ layout: val })}
                                    handleJoin={() => this.handleJoin()}
                                    handleAWBanners={() => this.setState({ smallAWBanner: false, bigAWBanner: false })}
                                    reloadPage={this.reloadPage}
                                    onOrientationChanged={this.onOrientationChanged}
                                />
                                <AudienceView
                                    user={user}
                                    role={role}
                                    eventItem={eventItem}
                                    eventId={eventId}
                                    username={username}
                                    name={name}
                                    userPhoto={userPhoto}
                                />
                            </Fragment>
                }
            </div>
        );
    }
}

StreamingConference.defaultProps = {
    conferenceName: "conference_name",
    userName: "Guest " + Math.floor(Math.random() * 100 + 1),
};

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        preConfiguration: state.devices.preConfiguration,
        defaults: state.firebase.defaults,
        orientation: state.app.orientation,
        logIn: state.app.logIn,
        knocks: state.knocks,
        streaming: state.room.streaming,
        roomMessage: state.room.roomMessage,
        mobile: state.app.user_agent_info.platform.type === 'mobile',
        audioInputDevice: state.devices.audioInputDevice,
        audioOutputDevice: state.devices.audioOutputDevice,
        videoInputDevice: state.devices.videoInputDevice,
        conferenceEnded: (state.room.conference && (state.room.conference === 'ended_for_audience' || state.room.conference === 'ended')) || (state.room.status && (state.room.status === 'ended_for_audience' || state.room.status === 'ended'))
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addMessage: (data) => {
            dispatch(chatActions.addMessage(data));
        },
        pushToHome: () => {
            dispatch(nativePush('/'));
            dispatch(devicesActions.exitRoom());
        },
        cleanupDevices: () => {
            dispatch(devicesActions.exitRoom());
        },
        pushToVanity: (path) => {
            dispatch(nativePush(path));
        },
        clearMessages: () => {
            dispatch(chatActions.clearMessages());
        },
        displayMessage: (message, timer, type) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer, type: type }));
        },
        hideMessage: () => {
            dispatch(roomActions.hideMessage());
        },
        setLowBandwidthMode: (value) => {
            dispatch(roomActions.setLowBandwidthMode(value));
        },
        showLogIn: (value) => {
            dispatch(appActions.showLogIn(value));
        },
        joinRoom: () => {
            dispatch(devicesActions.joinRoom({ preConfiguration: false }));
        }
    };
};

const StreamingConferenceContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamingConference);

export default StreamingConferenceContainer;
