export const PAYMENT_CREATE_INTENTS_ACTION = 'PAYMENT_CREATE_INTENTS_ACTION';
export const PAYMENT_FAILED_INTENTS_ACTION = 'PAYMENT_FAILED_INTENTS_ACTION';
export const PAYMENT_SUCCESS_INTENTS_ACTION = 'PAYMENT_SUCCESS_INTENTS_ACTION';
export const PAYMENT_PROCESSING_CONFIRM_PAYMENT_ACTION = 'PAYMENT_PROCESSING_CONFIRM_PAYMENT_ACTION';
export const PAYMENT_FAILED_CONFIRM_PAYMENT_ACTION = 'PAYMENT_FAILED_CONFIRM_PAYMENT_ACTION';
export const PAYMENT_SUCCESS_CONFIRM_PAYMENT_ACTION = 'PAYMENT_SUCCESS_CONFIRM_PAYMENT_ACTION';
export const PAYMENT_SET_CLIENT_SECRET_ACTION = 'PAYMENT_SET_CLIENT_SECRET_ACTION';
export const PAYMENT_RESET_INTENTS_ACTION = 'PAYMENT_RESET_INTENTS_ACTION';

export const PAYMENT_STATE_READY = 'PAYMENT_STATE_READY';
export const PAYMENT_STATE_PROCESSING = 'PAYMENT_STATE_PROCESSING';
export const PAYMENT_STATE_SUCCESS = 'PAYMENT_STATE_SUCCESS';
// export const PAYMENT_STATE_ = 'PAYMENT_STATE_';
export const PAYMENT_STATE_ERROR = 'PAYMENT_STATE_ERROR';

export const createPaymentIntents = (data) => {
    return {
        type: PAYMENT_CREATE_INTENTS_ACTION,
        payload: {
            ...data,
            state: PAYMENT_STATE_READY
        }
    };
}

export const setClientSecret = (clientSecret) => {
    return {
        type: PAYMENT_SET_CLIENT_SECRET_ACTION,
        payload: {
            clientSecret: clientSecret,
        }
    };
}

export const successPaymentIntents = (data) => {
    return {
        type: PAYMENT_SUCCESS_INTENTS_ACTION,
        payload: {
            state: PAYMENT_STATE_SUCCESS,
        }
    };
}

export const failedPaymentIntents = (error) => {
    return {
        type: PAYMENT_FAILED_INTENTS_ACTION,
        payload: {
            error,
            state: PAYMENT_STATE_ERROR
        }
    };
}

export const processingConfirmPayment = () => {
    return {
        type: PAYMENT_PROCESSING_CONFIRM_PAYMENT_ACTION,
        payload: {
            state: PAYMENT_STATE_PROCESSING,
        }
    };
}

export const successConfirmPayment = () => {
    return {
        type: PAYMENT_SUCCESS_CONFIRM_PAYMENT_ACTION,
        payload: {
            state: PAYMENT_STATE_SUCCESS,
        }
    };
}

export const failedConfirmPayment = (error) => {
    return {
        type: PAYMENT_FAILED_CONFIRM_PAYMENT_ACTION,
        payload: {
            error,
            state: PAYMENT_STATE_ERROR
        }
    };
}

export const resetPaymentIntents = () => {
    return {
        type: PAYMENT_RESET_INTENTS_ACTION,
        payload: {
            clientSecret: null,
            error: null,
            state: PAYMENT_STATE_READY
        }
    };
}