import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from 'react-router-dom';
import Cards from './Widget/Cards';
import ComponentHeader from './Widget/ComponentHeader';
import Button from './Widget/Button';
import {
    getQueryVariable,
    stringToBoolean,
    validShowBrowsePastEvent
} from "../utils/text";
import path from '../../resources/assets/path.svg';

class Browse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '',
            nav: 'all',
            userId: null,
            alias: null,
            featured: false
        }

        this.handleChangeInput = this.handleChangeInput.bind(this);
    }

    componentDidMount() {
        let showButton = getQueryVariable('b');
        let userId = getQueryVariable('u');
        let alias = getQueryVariable('a');
        let featured = getQueryVariable('f');
        let input = getQueryVariable('q');

        if (showButton) {
            this.setState({
                nav: showButton,
                userId: userId,
                alias: alias,
                featured: stringToBoolean(featured),
                input: input || ''
            }, () => {
                this.handleScrollToId(showButton);
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;

        let showButton = getQueryVariable('b');
        let userId = getQueryVariable('u');
        let alias = getQueryVariable('a');
        let featured = getQueryVariable('f');

        if (showButton && location && location.search && location.search !== prevProps.location.search) {
            this.setState({
                nav: showButton,
                userId: userId,
                alias: alias,
                featured: stringToBoolean(featured)
            }, () => {
                this.handleScrollToId(showButton);
            });
        }
    }

    handleScrollToId(id) {
        if (id) {
            let el = document.getElementById(id);

            if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
            }
        }
    }

    handleChangeInput(e) {
        const { nav, userId, alias, featured } = this.state;
        const { history } = this.props;

        const value = e.target.value;

        this.setState({
            input: value
        }, () => {
            history.push({
                pathname: '/browse',
                search: `?b=${nav}${featured ? '&f=1' : ''}${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${value ? `&q=${encodeURIComponent(value)}` : ''}`
            });
        });
    }

    filterBrowse(filterArray, type) {
        const { input, userId, featured, nav } = this.state;
        const { events, user } = this.props;

        let array = filterArray;

        if (featured) {
            array = events.featured
        }

        if (!array || (array && !array.length))
            return [];

        let inputValue = input.toLowerCase(), newArray = [];

        if (inputValue) {
            array.map((item) => {
                if (item && item.visibility && item.visibility === "public" && ((newArray.length < 50) || (nav && nav === 'all' && newArray.length < 100)) &&
                    ((item.title && item.title.toLowerCase().indexOf(inputValue) !== -1) || (item.cat && item.cat.toLowerCase().indexOf(inputValue) !== -1) || (item.email && item.email.toLowerCase().indexOf(inputValue) !== -1) || (item.author && item.author.toLowerCase().indexOf(inputValue) !== -1)) &&
                    (!userId || userId && item.uid && item.uid === userId)) {
                    newArray.push(item);
                    return item;
                }
            });
        } else {
            array.map((item) => {
                if (item && item.visibility && item.visibility === "public" && ((newArray.length < 50) || (nav && nav === 'all' && newArray.length < 100)) &&
                    (!userId || userId && item.uid && item.uid === userId)) {
                    newArray.push(item);
                    return item;
                }
            });
        }

        switch (type) {
            case 'upcoming':
                return newArray.filter((item) => {
                    let now = Date.now(), period = (item && item.estDuration ? item.estDuration : 60) * 60 * 1000;

                    if (item && item.status && ((item.status !== 'ended' && item.startDate + period > now) || (item.status === 'live' || item.status === 'started'))) {
                        return item;
                    }
                });
            case 'past':
                return newArray.filter((item) => {
                    let now = Date.now(), period = (item && item.estDuration ? item.estDuration : 60) * 60 * 1000;

                    if (item && item.status && !(item.status === 'live' || item.status === 'started') && ((item.status !== 'ended' && item.startDate + period < now) || (validShowBrowsePastEvent(item, user) || (item.type && item.type === 'stream')))) {
                        return item;
                    }
                });
            default:
                return newArray;
        }
    }

    checkBrowseLength(nav) {
        const { userId, featured } = this.state;
        const { events, videos } = this.props;

        let allItems = [];

        if (events && events.upcoming && videos) {
            allItems = events.upcoming.concat(videos)
        }

        let navValue = nav;

        if (navValue && navValue === 'food') {
            navValue = 'food&drink';
        }

        let filterArray = navValue && (navValue !== 'all' && navValue !== 'upcoming-streams' && navValue !== 'live') && allItems && allItems.length ? allItems.filter(item => item.cat && item.cat.toLowerCase() == navValue && navValue.toLowerCase()) : null;

        let array = filterArray;

        if (featured) {
            array = events.featured
        }

        if (!array || (array && !array.length))
            return false;

        let newArray = [];


        if (array && array.length) {
            array.map((item) => {
                if (item && item.visibility && item.visibility === "public" && newArray.length < 50 &&
                    (!userId || userId && item.uid && item.uid === userId)) {
                    newArray.push(item);
                    return item;
                }
            });
        }

        return newArray && newArray.length;
    }

    renderTitle(type) {
        const { nav, alias, featured } = this.state;

        switch (nav) {
            // case 'all':
            //     return `all${alias ? ` - ${alias}` : ''}${featured ? ` - Popular on the platform` : ''}`;
            case 'upcoming-streams':
                return `upcoming streams${alias ? ` - ${alias}` : ''}`;
            case 'live':
                return `live${alias ? ` - ${alias}` : ''}`;
            default:
                switch (type) {
                    case 'upcoming':
                        return `upcoming streams${alias ? ` - ${alias}` : ''}`;
                    case 'past':
                        return `past streams${alias ? ` - ${alias}` : ''}`;
                    default:
                        return "";
                }
        }
    }

    renderContent(type) {
        const { nav } = this.state;
        const { events, videos } = this.props;

        let allItems = [];

        if (events && events.upcoming && videos) {
            allItems = events.upcoming.concat(videos)
        }

        let navValue = nav;

        if (navValue && navValue === 'food') {
            navValue = 'food&drink';
        }

        let cat_items = navValue && (navValue !== 'all' && navValue !== 'upcoming-streams' && navValue !== 'live') && allItems && allItems.length ? allItems.filter(item => item.cat && item.cat.toLowerCase() == navValue && navValue.toLowerCase()) : null;

        switch (navValue) {
            case 'all':
                return this.filterBrowse(allItems, type);
            case 'upcoming-streams':
                return this.filterBrowse(events.upcoming);
            case 'live':
                let liveEvents = events && events.upcoming && events.upcoming.length ? events.upcoming.filter(item => item.status && (item.status === 'live' || item.status === 'started')) : null;
                return this.filterBrowse(liveEvents);
            default:
                switch (type) {
                    case 'upcoming':
                        return this.filterBrowse(cat_items, type);
                    case 'past':
                        return this.filterBrowse(cat_items, type);
                    default:
                        return this.filterBrowse(allItems);
                }
        }
    }

    render() {
        const { input, nav, featured, userId, alias } = this.state;
        const { categories } = this.props;

        return (
            <div>
                <ComponentHeader
                    text="browse events"
                />
                <div className='hosts-search-wrapper'>
                    <div className='hosts-search'>
                        <img src={path} className='path' loading='lazy' />
                        <input
                            type="text"
                            className="search-input"
                            placeholder='What event are you looking for?'
                            value={input}
                            onChange={this.handleChangeInput}
                        />
                    </div>
                </div>
                <div className='profile-div-wrapper'>
                    <div className='profile-nav-wrapper browse-nav-wrapper'>
                        <div className={classNames('profile-nav', { 'hidden': featured })}>
                            <Link id='all' to={`/browse?b=all${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${input ? `&q=${input}` : ''}`}>
                                <Button type='border-white' className={classNames('btn-profile-nav', { 'active': nav === 'all' })}>all</Button>
                            </Link>
                            <Link id='upcoming-streams' to={`/browse?b=upcoming-streams${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${input ? `&q=${input}` : ''}`}>
                                <Button type='border-white' className={classNames('btn-profile-nav', { 'active': nav === 'upcoming-streams' })}>upcoming streams</Button>
                            </Link>
                            <Link id='live' to={`/browse?b=live${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${input ? `&q=${input}` : ''}`}>
                                <Button type='border-white' className={classNames('btn-profile-nav', { 'active': nav === 'live' })}>live</Button>
                            </Link>
                            {categories && categories.length ?
                                categories.map((item, index) => {
                                    let id = item && item.id && item.id.toLowerCase ? item.id.toLowerCase().trim() : null;
                                    id = id && id === 'food&drink' ? 'food' : id;
                                    if (this.checkBrowseLength(id)) {
                                        return (
                                            <Link key={index} id={id} to={`/browse?b=${id}${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${input ? `&q=${input}` : ''}`}>
                                                <Button type='border-white' className={classNames('btn-profile-nav', { 'active': nav === id })}>{item.title}</Button>
                                            </Link>
                                        );
                                    }
                                })
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                <Cards
                    title={this.renderTitle('upcoming')}
                    videos={this.renderContent('upcoming')}
                    grid={true}
                    emptyText={nav && nav === 'live' ? "No live shows right now" : ""}
                />
                {nav && nav !== 'upcoming-streams' && nav !== 'live' ?
                    <Cards
                        title={this.renderTitle('past')}
                        videos={this.renderContent('past')}
                        grid={true}
                    />
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        events: state.firebase.events,
        videos: state.firebase.videos,
        categories: state.firebase.categories
    };
};

const BrowseContainer = connect(
    mapStateToProps,
    null
)(Browse);

export default BrowseContainer;
