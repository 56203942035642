import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Button from '../../Widget/Button';
import ClickOutside from '../../Widget/ClickOutside';
import fanoutClient from '../../../utils/FanoutClient';
import * as roomActions from "../../../actions/room_actions";

class CopyGuestLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            processing: false,
            error: '',
            tooltipText: ""
        }

        this.handleCloseForm = this.handleCloseForm.bind(this);
        this.handleGetGuestLink = this.handleGetGuestLink.bind(this);
        this.handleCopyGuestLink = this.handleCopyGuestLink.bind(this);
        this.buttonRef = React.createRef();
    }

    componentWillUnmount() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
    }

    componentDidMount() {
        this.handleGetGuestLink();
    }

    handleGetGuestLink() {
        const { guestLink } = this.props;

        if (guestLink) {
            return;
        }

        fanoutClient.getMeInvitationLink().then((link) => {
            const { setGuestLink } = this.props;
            if (link && setGuestLink) {
                setGuestLink(link);
            }
        }).catch((e) => {
            console.error('ERROR', e.message);
            this.setState({
                error: "Error copying link",
                processing: false
            });
        });
    }

    handleCopyGuestLink(ref) {
        const { guestLink } = this.props;

        this.setState({
            processing: true,
            error: ''
        }, () => {
            if (guestLink) {
                let onCopyOk = () => {
                    console.log('Copy link success', guestLink, ref);
                    if (ref) {
                        this.setState({
                            tooltipText: "Copied!"
                        }, () => {
                            ReactTooltip.show(ref);
                            this.setState({
                                processing: false
                            }, () => {
                                this.closeTimeout = setTimeout(() => {
                                    this.handleCloseForm();
                                }, 5000);
                            });
                            setTimeout(() => {
                                ReactTooltip.hide(ref);
                            }, 2000);
                        });
                    }
                }
                let onCopyNOk = (e) => {
                    console.error(`Could not write link [${guestLink}] to clipboard: ${e.message}`)
                    if (ref) {
                        this.setState({
                            tooltipText: "Could not copy"
                        }, () => {
                            ReactTooltip.show(ref);
                            this.setState({
                                processing: false
                            });
                            setTimeout(() => {
                                ReactTooltip.hide(ref);
                            }, 2000);
                        });
                    }
                }

                navigator.clipboard.writeText(guestLink)
                    .then(onCopyOk)
                    .catch(onCopyNOk);
            }
        });
    }

    handleCloseForm() {
        const { processing } = this.state;
        const { showCopyGuestForm } = this.props;

        if (showCopyGuestForm && !processing) {
            showCopyGuestForm(false);
            this.setState({ error: '' });
        }
    }

    render() {
        const { processing, error, tooltipText } = this.state;
        const { guestLink } = this.props;

        return (
            <ClickOutside
                className='guest-form-wrapper'
                clickOutside={this.handleCloseForm}
            >
                <p className='guest-form-title'>Guest Link</p>
                <p className='guest-form-text'>Send this link only to people that you want to participate in the live show.</p>
                {error && <p className='guest-form-error'>{error}</p>}
                <div className='guest-buttons'>
                    <div
                        data-tip
                        ref={ref => this.firstTooltip = ref}
                        data-for="guest-form-button"
                        className='button-wrapper'
                    >
                        <Button
                            type="default"
                            className='guest-form-button'
                            onClick={() => this.handleCopyGuestLink(this.firstTooltip)}
                            disabled={processing || !guestLink}
                            loading={processing}
                        >
                            Copy guest link
                        </Button>
                        <ReactTooltip
                            id="guest-form-button"
                            isCapture={true}
                            className=""
                            place="top"
                            type="dark"
                            effect="solid"
                            event="none"
                            eventOff="click"
                            delayHide={2000}
                        >
                            {tooltipText}
                        </ReactTooltip>
                    </div>
                    <div
                        className='button-wrapper'
                    >
                        <Button
                            type="border"
                            className='guest-form-button'
                            onClick={this.handleCloseForm}
                            disabled={processing}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showCopyGuestForm: (value) => {
            dispatch(roomActions.showCopyGuest(value));
        }
    };
};

const CopyGuestLinkContainer = connect(
    null,
    mapDispatchToProps
)(CopyGuestLink);

export default CopyGuestLinkContainer;
