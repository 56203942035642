const NFNStrings = {
    appName: "No Filter",
    fullName: "No Filter Network",
    title: "NoFilter.net"

}

const NFLAAStrings = {
    appName: "NFL Alumni Media",
    fullName: "NFL Alumni Media",
    title: "NFL Alumni Media"
}

export const strings = process.env.build && process.env.build === 'nfl-aa' ? NFLAAStrings : NFNStrings;