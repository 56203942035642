import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import { getOrientation, isIOS } from '../../../../utils/text';
import loadingGif from "../../../../../resources/assets/loading.jpg";
import volumeUpIcon from "../../../../../resources/assets/volume-up.svg";
import userPlaceholder from "../../../../../resources/assets/user-placeholder.png";

class VideoBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            trackMuted: false,
            runAnimation: false
        }

        this.videoRef = React.createRef();
        this.onLoadedMetadata = this.onLoadedMetadata.bind(this);
        this.onResize = this.onResize.bind(this);
        this.handleTrackEvent = this.handleTrackEvent.bind(this);
    }

    componentDidMount() {
        const { source, loading, onOrientationChanged, track, disableTrackHandler, lowBandwidthMode, disableLBM } = this.props;

        if (this.videoRef && this.videoRef.current) {
            if (source && (typeof source === 'string' || source instanceof String)) {
                this.videoRef.current.src = source;
            } else if (source) {
                this.videoRef.current.srcObject = source;
            }

            if (loading && (!lowBandwidthMode || disableLBM)) {
                this.setState({ loading: true });
            }

            if (loading || onOrientationChanged) {
                this.videoRef.current.addEventListener('loadedmetadata', this.onLoadedMetadata);
            }

            if (onOrientationChanged) {
                this.videoRef.current.addEventListener('resize', this.onResize);
            }
        }

        if (track && !disableTrackHandler) {
            if (track.paused || !track.enabled) {
                this.setState({
                    trackMuted: true,
                    loading: false
                });
            } else {
                this.setState({
                    trackMuted: false
                });
            }

            track.addEventListener('mute', this.handleTrackEvent);
            track.addEventListener('paused', this.handleTrackEvent);
            track.addEventListener('unmute', this.handleTrackEvent);
            track.addEventListener('enabled', this.handleTrackEvent);
        }
    }

    componentDidUpdate(prevProps) {
        const { source, loading, track, disableTrackHandler } = this.props;

        if (prevProps.source !== source) {
            if (this.videoRef && this.videoRef.current) {
                if (source && (typeof source === 'string' || source instanceof String)) {
                    this.videoRef.current.src = source;
                } else if (source) {
                    this.videoRef.current.srcObject = source;
                }

                if (track && !disableTrackHandler) {
                    prevProps.track.removeEventListener('mute', this.handleTrackEvent);
                    prevProps.track.removeEventListener('paused', this.handleTrackEvent);
                    prevProps.track.removeEventListener('unmute', this.handleTrackEvent);
                    prevProps.track.removeEventListener('enabled', this.handleTrackEvent);

                    if (track.paused || !track.enabled) {
                        this.setState({
                            trackMuted: true
                        });
                    } else {
                        this.setState({
                            trackMuted: false
                        });
                    }

                    track.addEventListener('mute', this.handleTrackEvent);
                    track.addEventListener('paused', this.handleTrackEvent);
                    track.addEventListener('unmute', this.handleTrackEvent);
                    track.addEventListener('enabled', this.handleTrackEvent);
                } else if (track) {
                    prevProps.track.removeEventListener('mute', this.handleTrackEvent);
                    prevProps.track.removeEventListener('paused', this.handleTrackEvent);
                    prevProps.track.removeEventListener('unmute', this.handleTrackEvent);
                    prevProps.track.removeEventListener('enabled', this.handleTrackEvent);

                    this.setState({
                        trackMuted: false
                    });
                }

                this.videoRef.current.load();
                this.setState({
                    runAnimation: true,
                    loading: loading
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            runAnimation: false
                        });
                    }, 1000);
                });
            }
        }

        if (!disableTrackHandler && prevProps.track !== track) {
            if (track.paused || !track.enabled) {
                this.setState({
                    trackMuted: true,
                    loading: false
                });
            } else {
                this.setState({
                    trackMuted: false
                });
            }
        }
    }

    handleTrackEvent(event) {
        console.log('handleTrackEvent', event, this.props.track.id);
        if (event && event.type) {
            switch (event.type) {
                case 'mute':
                    // this.setState({
                    //     trackMuted: true,
                    //     loading: false
                    // });
                    console.warn(`It shouldn't be there handleTrackEvent:mute`);
                    break;
                case 'enabled':
                    this.setState({
                        trackMuted: event.currentTarget && event.currentTarget.enable ? false : true
                    });
                    break;
                case 'paused':
                    if (event.detail && event.detail.paused) {
                        this.setState({
                            trackMuted: true,
                            loading: false
                        });
                    } else if (isIOS() && process.env.platform && process.env.platform === 'mobile' && event.detail && !event.detail.paused) {
                        this.setState({
                            trackMuted: false,
                            loading: false
                        });
                    }
                    break;
                case 'unmute':
                    this.setState({ trackMuted: false });
                    break;
                default:
                    console.log('default case handleTrackEvent', event.type);
                    break;
            }
        }
    }

    onLoadedMetadata(e) {
        const { loading, name, onOrientationChanged, orientation, source, lowBandwidthMode } = this.props;

        if (loading) {
            this.setState({ loading: false });
        }

        console.log('loadedmetadata %s, %sx%s', name, e.target.videoWidth, e.target.videoHeight);

        if (onOrientationChanged && orientation != getOrientation(e.target.videoWidth, e.target.videoHeight) && e.target.videoWidth > 10 && e.target.videoHeight > 10)
            onOrientationChanged({
                streamId: source && source.id ? source.id : null,
                orientation: lowBandwidthMode ? 'horizontal' : getOrientation(e.target.videoWidth, e.target.videoHeight)
            });
    }

    onResize(e) {
        const { name, onOrientationChanged, orientation, source, lowBandwidthMode } = this.props;

        console.log('resized %s, %sx%s', name, e.target.videoWidth, e.target.videoHeight);
        if (onOrientationChanged && orientation != getOrientation(e.target.videoWidth, e.target.videoHeight) && e.target.videoWidth > 10 && e.target.videoHeight > 10)
            onOrientationChanged({
                streamId: source && source.id ? source.id : null,
                orientation: lowBandwidthMode ? 'horizontal' : getOrientation(e.target.videoWidth, e.target.videoHeight)
            });
    }

    componentWillUnmount() {
        const { loading, onOrientationChanged, track, disableTrackHandler } = this.props;

        if (this.videoRef && this.videoRef.current) {
            if (loading || onOrientationChanged) {
                this.videoRef.current.removeEventListener('loadedmetadata', this.onLoadedMetadata);
            }

            if (onOrientationChanged) {
                this.videoRef.current.removeEventListener('resize', this.onResize);
            }
        }

        if (track && !disableTrackHandler) {
            track.removeEventListener('mute', this.handleTrackEvent);
            track.removeEventListener('paused', this.handleTrackEvent);
            track.removeEventListener('unmute', this.handleTrackEvent);
            track.removeEventListener('enabled', this.handleTrackEvent);
        }
    }

    render() {
        const { loading, runAnimation, trackMuted } = this.state;
        const { className, name, autoPlay, muted, loop, labels, speaking, orientation, picture, lowBandwidthMode, disableLBM } = this.props;

        return (
            <div className={classNames(`video-box ${className ? className : ''}`, { 'animation': runAnimation }, { 'avatar-box': ((lowBandwidthMode && !disableLBM) || trackMuted) })}>
                <video
                    ref={this.videoRef}
                    autoPlay={autoPlay}
                    muted={muted}
                    loop={loop}
                    playsInline
                    orientation={orientation}
                    onContextMenu={e => e.preventDefault()}
                    className={((lowBandwidthMode && !disableLBM) || trackMuted) ? 'video-off' : 'video-on'}
                />
                {((lowBandwidthMode && !disableLBM) || trackMuted) ?
                    <img
                        className='video-avatar'
                        src={picture ? picture : userPlaceholder}
                        alt=''
                        loading='lazy'
                    />
                    :
                    null
                }
                {loading && ((!lowBandwidthMode || disableLBM) || (!trackMuted)) ?
                    <img src={loadingGif} className='video-loader' />
                    : null
                }
                {name ?
                    <p className={classNames(`video-name ${labels}`, { 'avatar': ((lowBandwidthMode && !disableLBM) || trackMuted) })}>
                        <span>
                            {name}
                            {speaking ? <img src={volumeUpIcon} loading='lazy' alt='' className='volume-up' /> : null}
                        </span>
                    </p>
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lowBandwidthMode: state.room.lowBandwidthMode
    };
};

const VideoBoxContainer = connect(
    mapStateToProps
)(VideoBox);

export default VideoBoxContainer;