export const MYSTUFF_MY_TICKETS_ACTION = 'MYSTUFF_MY_TICKETS_ACTION';
export const MYSTUFF_MY_STUFF_ACTION = 'MYSTUFF_MY_STUFF_ACTION';
export const MYSTUFF_ADD_PROCESSING_TICKET = 'MYSTUFF_ADD_PROCESSING_TICKET';




export const setMyTickets = (value) => {
    let theTickets = {};
    theTickets = value && value.data ? value.data.reduce((obj, item) => {
        return {
            ...obj,
            [item[(item.type === 'hosting' || item.type === 'subscription') ? 'authorId' : 'eventId']]: item,
        };
    }, theTickets) : {};
    // console.log('my tickets', theTickets);
    return {
        type: MYSTUFF_MY_TICKETS_ACTION,
        payload: {
            tickets: theTickets,
        }
    };
}

export const addProcessingTicket = (value) => {
    console.log("add processing ticket", value)
    return {
        type: MYSTUFF_ADD_PROCESSING_TICKET,
        payload: {
            ticket: value,
        }
    }
}

export const setMyStuff = (mystuff) => {
    return {
        type: MYSTUFF_MY_STUFF_ACTION,
        payload: mystuff
    };
}
