import React, { Component } from 'react';

class Button extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            type,
            id,
            onClick,
            onMouseDown,
            onMouseUp,
            className,
            disabled,
            loading,
            children
        } = this.props;

        let btnType = type ? type : 'default'

        return (
            <button
                id={id}
                className={`btn btn-${btnType} ${loading ? 'btn-loading' : ''} ${className}`}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                disabled={disabled}
            >
                {loading ?
                    <div className="dots-loader">
                        <div className="dot-loader dot1" />
                        <div className="dot-loader dot2" />
                        <div className="dot-loader dot3" />
                    </div>
                    :
                    children
                }
            </button>
        )
    }
}

export default Button;