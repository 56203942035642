export const BROADCAST_STREAMING_ACTION = 'BROADCAST_STREAMING_ACTION';
export const BROADCAST_DISABLE_STREAMING_BTN_ACTION = 'BROADCAST_DISABLE_STREAMING_BTN_ACTION';
export const BROADCAST_SHOW_TIP_ACTION = 'BROADCAST_SHOW_TIP_ACTION';
export const BROADCAST_INVITE_INPUT_ACTION = 'BROADCAST_INVITE_INPUT_ACTION';
export const BROADCAST_LIVE_INPUT_ACTION = 'BROADCAST_LIVE_INPUT_ACTION';
export const BROADCAST_KNOCK_ACTION = 'BROADCAST_KNOCK_ACTION';
export const BROADCAST_SET_MESSAGE_ACTION = 'BROADCAST_SET_MESSAGE_ACTION';
export const BROADCAST_SET_ACTIVE_MODERATOR_ACTION = 'BROADCAST_SET_ACTIVE_MODERATOR_ACTION';
export const BROADCAST_SET_FANOUT_ID = 'BROADCAST_SET_FANOUT_ID';

export const broadcastStreaming = (value) => {
    return {
        type: BROADCAST_STREAMING_ACTION,
        payload: {
            streaming: value
        }
    };
}

export const disableStreamingBtn = (value) => {
    return {
        type: BROADCAST_DISABLE_STREAMING_BTN_ACTION,
        payload: {
            disabledStreamingBtn: value
        }
    };
}

export const showTipMenu = (value) => {
    return {
        type: BROADCAST_SHOW_TIP_ACTION,
        payload: {
            showTip: value
        }
    };
}

export const broadcastInviteInput = (value) => {
    return {
        type: BROADCAST_INVITE_INPUT_ACTION,
        payload: {
            inviteInput: value
        }
    };
}

export const broadcastLiveInput = (value) => {
    return {
        type: BROADCAST_LIVE_INPUT_ACTION,
        payload: {
            liveInput: value
        }
    };
}

export const broadcastKnock = (value) => {
    return {
        type: BROADCAST_KNOCK_ACTION,
        payload: {
            knock: value
        }
    };
}

export const setBroadcastMessage = (value) => {
    return {
        type: BROADCAST_SET_MESSAGE_ACTION,
        payload: {
            broadcastMessage: value,
        }
    };
}

export const setActiveModerator = (value) => {
    return {
        type: BROADCAST_SET_ACTIVE_MODERATOR_ACTION,
        payload: {
            activeModerator: value,
        }
    };
}

export const setFanoutId = (value) => {
    return {
        type: BROADCAST_SET_FANOUT_ID,
        payload: {
            fanoutId: value,
        }
    };
}