import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import * as roomActions from "../../actions/room_actions";

class RoomMessage extends Component {
  constructor(props) {
    super(props);
    this.linkClicked = this.linkClicked.bind(this);
  }

  componentDidUpdate() {
    const { hideMessage } = this.props;
    const { timer, displayRoomMessage } = this.props.roomMessage;

    if (displayRoomMessage && timer) {
      setTimeout(() => {
        if (hideMessage) {
          hideMessage();
        }
      }, timer);
    }
  }

  linkClicked() {
    const { hideMessage } = this.props;

    if (hideMessage) {
      hideMessage();
    }
  }

  render() {
    const {
      message,
      displayRoomMessage,
      type
    } = this.props.roomMessage;
    return (
      <div
        className={classNames(
          { 'room-message': displayRoomMessage },
          { 'room-message-fadeout': !displayRoomMessage },
          { 'room-message-error': type && type === 'error' },
          { 'room-message-notification': type && type === 'notification' },
          { 'room-message-top': type && type === 'top' }
        )}
      >
        {message}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomMessage: state.room.roomMessage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideMessage: () => {
      dispatch(roomActions.hideMessage());
    }
  };
};

const RoomMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomMessage);

export default RoomMessageContainer;