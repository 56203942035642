export const UPDATE_HOSTS_LIST_ACTION = 'UPDATE_HOSTS_LIST_ACTION';
export const CLEAR_HOSTS_LIST_ACTION = 'CLEAR_HOSTS_LIST_ACTION';

export const updateList = (data) => {
    return {
        type: UPDATE_HOSTS_LIST_ACTION,
        payload: data
    };
}

export const clearList = () => {
    return {
        type: CLEAR_HOSTS_LIST_ACTION
    };
}