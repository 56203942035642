import {
    JOIN_ROOM_ACTION,
    EXIT_ROOM_ACTION,
    SET_DEVICES_DATA_ACTION,
    SET_DEVICES_ACTION,
    SET_VIDEO_ENABLED_ACTION,
    SET_AUDIO_ENABLED_ACTION,
    SET_AUDIO_INPUT_DEVICE_ACTION,
    SET_AUDIO_OUTPUT_DEVICE_ACTION,
    SET_VIDEO_INPUT_DEVICE_ACTION
} from '../actions/devices_actions';

const initialState = {
    preConfiguration: true,
    loading: true,
    lockJoin: false,
    audioInputDevice: null,
    audioOutputDevice: null,
    videoInputDevice: null,
    audioInputDevices: [],
    audioOutputDevices: [],
    videoInputDevices: [],
    userVideoStream: null,
    userAudioStream: null,
    error: null,
    videoEnabled: true,
    audioEnabled: true
};

const devices = (state = initialState, action) => {
    switch (action.type) {
        case JOIN_ROOM_ACTION:
        case SET_DEVICES_DATA_ACTION:
        case SET_DEVICES_ACTION:
        case SET_VIDEO_ENABLED_ACTION:
        case SET_AUDIO_ENABLED_ACTION:
        case SET_AUDIO_INPUT_DEVICE_ACTION:
        case SET_AUDIO_OUTPUT_DEVICE_ACTION:
        case SET_VIDEO_INPUT_DEVICE_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, data);
        }
        case EXIT_ROOM_ACTION: {
            return Object.assign({}, initialState);
        }
        default:
            return state;
    }
};

export default devices;