let _learnq = typeof window !== "undefined" ? (window._learnq || []) : [];


class KlaviyoClient {
    constructor(store) {
        this._store = store;
        this.configured = false;
        this.loaded = false;
        this.load = this.load.bind(this)
        this.identify = this.identify.bind(this)
    }

    setStore(store) {
        this._store = store;
    }

    load(callback) {
        console.log('klaviyo call load');
        if(!process.env.klaviyo_client_id) {
            this.configured = false;
            console.warn('Klaviyo nor configured');
            return Promise.resolve();
        }
        this.configured = true;

        const existingScript = document.getElementById('klaviyo-js');

        if (!existingScript) {
            // Return promise
            return new Promise((resolve, reject)=>{
                // Create script element with src for current client
                const script = document.createElement('script');
                script.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.klaviyo_client_id}`;
                script.id = 'klaviyo-js';
                document.body.appendChild(script);
                script.onload = () => {
                    this.loaded = true;
                    _learnq = window._learnq;
                    console.log('Klaviyo API loaded');
                    if (callback) {
                        console.log('About to call Klaviyo onLoad callback')
                        return resolve(callback());
                    }
                    return resolve();
                };
                script.onerror = () => {
                    this.loaded = false;
                    console.error('Could not load Klaviyo API');
                    return reject();
                }
            });

        } else {
            if (callback) {
                console.log('About to call Klaviyo onLoad callback')
                return Promise.resolve(callback());
            }
            this.loaded = true;
            return Promise.resolve();
        }
        // if (existingScript && callback) callback();
    };

    identify(user) {
        console.log('klaviyo call identify', user, this.configured);
        if(!this.configured || !_learnq)
            return;
        const { uid, displayName, email, photoURL } = user;
        _learnq.push(['identify', {
            '$email' : email,
            '$image': photoURL,
            'uid': uid,
            'displayName' : displayName
        }]);
    }

    trackTickets(user, ticket) {
        console.log('klaviyo call trackTickets', user, this.configured);
        if(!this.configured || !_learnq)
            return;
        const { uid, displayName, email, photoURL } = user;
        _learnq.push(['track', 'Ticket purchased', {
            'PreviouslyVicePresident' : true,
            'price' : 1801,
            'VicePresidents' : ['Aaron Burr', 'George Clinton']
        }]);
        _learnq.push(['identify', {
            '$email' : email,
            '$image': photoURL,
            'uid': uid,
            'displayName' : displayName
        }]);
    }

    trackCategory(user, category) {
        console.log('klaviyo trackCategory', user, category, this.configured);
        if(!this.configured || !_learnq)
            return;
        const { uid, displayName, email, photoURL } = user||{};
        if(category) {
            const {id, title} = category || {};
            console.log('track Category visited', email, title, id);
            _learnq.push(['track', 'Category visited', {
                // '$email': email || null,
                'title': title,
                'id': id
            }]);
        }
    }
}

export default new KlaviyoClient();
