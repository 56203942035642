import React, { Component } from 'react';
import ClickOutside from './ClickOutside';
import appClient from '../../utils/AppClient';
import closeIcon from '../../../resources/assets/close.svg';

class Preview extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        appClient.disableScroll(true);
    }

    componentWillUnmount() {
        appClient.disableScroll(false);
    }

    render() {
        const { closePreview, previewImage, previewTitle } = this.props;

        return (
            <div className='preview-wrapper'>
                <ClickOutside
                    className='preview-div'
                    clickOutside={closePreview}
                >
                    <p className='preview-title'>{previewTitle ? previewTitle : "Enlarged view"}</p>
                    <img
                        src={closeIcon}
                        alt='x'
                        loading='lazy'
                        className='close'
                        onClick={closePreview}
                    />
                    <img
                        src={previewImage}
                        alt=''
                        className='preview-image'
                    />
                </ClickOutside>
            </div>
        )
    }
}

export default Preview;