import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deviceManager from '../../../utils/DeviceManager';
import Button from '../../Widget/Button';
import Loader from '../../Widget/Loader';
import * as devicesActions from '../../../actions/devices_actions';
import { logo } from '../../../utils/text';

class PreConfiguration extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        deviceManager.checkPermissions()
            .then(() => {
                deviceManager.init()
                    .then(() => {
                        const { videoInputDevice, loading } = this.props;

                        if (videoInputDevice && !loading) {
                            deviceManager.attachDeviceMediaStream(this.video, videoInputDevice);
                        }
                    });
            });
    }

    componentDidUpdate(prevProps) {
        const { videoInputDevice, loading } = this.props;

        if (videoInputDevice && !loading && ((videoInputDevice !== prevProps.videoInputDevice) || (loading !== prevProps.loading))) {
            deviceManager.attachDeviceMediaStream(this.video, videoInputDevice);
        }
    }

    componentWillUnmount() {
        deviceManager.releaseAudioStream().then(() => deviceManager.releaseVideoStream());
    }

    reportError(error) {
        console.error(error);
    }

    render() {
        const { audioInputDevices, audioInputDevice, audioOutputDevices, audioOutputDevice, videoInputDevices, videoInputDevice, audioEnabled, error, loading, lockJoin } = this.props;

        return (
            <Fragment>
                {loading ? (
                    <div className='pre-configuration-wrapper'>
                        <div className='content'>
                            <Loader
                                text='Device check'
                                dots
                                payment
                            />
                        </div>
                    </div>
                ) : (
                    <div className="pre-configuration-wrapper">
                        {error == null ? (
                            <div className="content">
                                {/* <h3>Set up your devices</h3> */}
                                <div className="video">
                                    <video
                                        className="pre-configuration-video"
                                        id="pre-configuration-video"
                                        playsInline
                                        ref={(ref) => (this.video = ref)}
                                        autoPlay
                                        muted
                                        onContextMenu={e => e.preventDefault()}
                                    />
                                </div>
                                <div>
                                    <label className='label-dropdown' htmlFor="videoInput">Camera</label>
                                    <div className="select-dropdown">
                                        <select
                                            name='videoInput'
                                            className='select-video-input-device'
                                            value={videoInputDevice && videoInputDevice.deviceId ? videoInputDevice.deviceId : ''}
                                            onChange={(e) => deviceManager.handleChangeVideoInputDevice(e)}
                                            disabled={false}
                                        >
                                            {videoInputDevices.map((device, i) => (
                                                <option key={i} value={device.deviceId}>
                                                    {device.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <label className='label-dropdown' htmlFor="audioInput">Microphone</label>
                                    <div className="select-dropdown">
                                        <select
                                            name='audioInput'
                                            className='select-audio-input-device'
                                            value={audioInputDevice && audioInputDevice.deviceId ? audioInputDevice.deviceId : ''}
                                            onChange={(e) => deviceManager.handleChangeAudioInputDevice(e)}
                                            disabled={false}
                                        >
                                            {audioInputDevices.map((device, i) => (
                                                <option key={i} value={device.deviceId}>
                                                    {device.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <label className='label-dropdown' htmlFor="audioOutput">Sound Output</label>
                                    <div className="select-dropdown">
                                        <select
                                            name='audioOutput'
                                            className='select-audio-output-device'
                                            value={audioOutputDevice && audioOutputDevice.deviceId ? audioOutputDevice.deviceId : ''}
                                            onChange={(e) => deviceManager.handleChangeAudioOutputDevice(e)}
                                            disabled={false}
                                        >
                                            {audioOutputDevices.map((device, i) => (
                                                <option key={i} value={device.deviceId}>
                                                    {device.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='join-button'>
                                    <Button
                                        type='default'
                                        disabled={lockJoin}
                                        className='btn-join-conference'
                                        onClick={() => this.props.handleJoin()}
                                    >
                                        Join
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="pre-configuration-wrapper">
                                <div className="content">
                                    <img className='logo' src={logo} alt='logo' loading='lazy' />
                                    <div className="info-message">
                                        {error}
                                    </div>
                                    <div className='join-button'>
                                        <Button
                                            type='border'
                                            className='btn-join-conference'
                                            onClick={() => deviceManager.onDeviceChange()}
                                        >
                                            Retry
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        audioInputDevices: state.devices.audioInputDevices,
        audioInputDevice: state.devices.audioInputDevice,
        audioOutputDevices: state.devices.audioOutputDevices,
        audioOutputDevice: state.devices.audioOutputDevice,
        videoInputDevices: state.devices.videoInputDevices,
        videoInputDevice: state.devices.videoInputDevice,
        videoEnabled: state.devices.videoEnabled,
        audioEnabled: state.devices.audioEnabled,
        loading: state.devices.loading,
        lockJoin: state.devices.lockJoin,
        error: state.devices.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAudioInputDevice: (value) => {
            dispatch(devicesActions.setAudioInputDevice(value));
        },
        setAudioOutputDevice: (value) => {
            dispatch(devicesActions.setAudioOutputDevice(value));
        },
        setVideoInputDevice: (value) => {
            dispatch(devicesActions.setVideoInputDevice(value));
        },
        setVideoEnabled: (value) => {
            dispatch(devicesActions.setVideoEnabled(value));
        },
        setAudioEnabled: (value) => {
            dispatch(devicesActions.setAudioEnabled(value));
        },
        joinRoom: (payload) => {
            dispatch(devicesActions.joinRoom(payload));
        },
    };
};

const PreConfigurationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreConfiguration);

export default PreConfigurationContainer;