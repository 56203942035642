import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import ResizableRoster from '../../Voxeet/ResizableRoster';
import Banner from '../../../Widget/Banner';
import Button from '../../../Widget/Button';
import Counter from '../../../Widget/Counter';
import LogIn from '../../../Header/LogIn';
import RoomMessage from "../../../Room/RoomMessage";
import { strings } from '../../../../translations/localizedStrings';
import LayoutView from '../ConferenceLayout/LayoutView';
import ConferenceButtons from "../ConferenceButtons";
import ConferenceSettings from "./ConferenceSettings";
import StreamingCounter from "../../Voxeet/StreamingCounter";
import CustomSettings from "../../Voxeet/CustomSettings";
import SelfView from "./SelfView";
import ExitModal from '../../Voxeet/ExitModal';
import KnockModal from '../../Voxeet/KnockModal';
import loadingGif from "../../../../../resources/assets/loading.jpg";

class ConferenceRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientWidth: 0,
            clientHeight: 0
        }

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps) {
        const { user, orientation } = this.props;

        if (prevProps.user !== user && user) {
            if (this.exitLoginTimeout) {
                clearTimeout(this.exitLoginTimeout);
            }
        }

        if (orientation && orientation !== prevProps.orientation) {
            setTimeout(() => {
                this.handleResize();
            }, 200);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        let wrapper = document.getElementById('conference-attendees');

        if (wrapper) {
            if (this.wrapperTimeout) {
                clearTimeout(this.wrapperTimeout);
            }

            if (wrapper && wrapper.clientWidth && wrapper.clientHeight) {
                this.setState({
                    clientWidth: wrapper.clientWidth,
                    clientHeight: wrapper.clientHeight
                });
            }
        } else {
            this.wrapperTimeout = setTimeout(() => {
                this.handleResize();
            }, 3000);
        }
    }

    render() {
        const {
            clientWidth,
            clientHeight
        } = this.state;
        const {
            videoPresentation,
            screenShare,
            activeSpeakers,
            videoDuration,
            videoPlaying,
            videoTS,
            videoURL,
            showBackground,
            background,
            layout,
            banner,
            labels,
            watermark,
            showWatermark,
            isMuted,
            videoEnabled,
            displayAttendeesList,
            attendeesSettingsOpened,
            rosterOpened,
            smallAWBanner,
            bigAWBanner,
            awExitTime,
            onOrientationChanged,
            role,
            isAdmin,
            isListener,
            conferenceAlias,
            knockId,
            eventItem,
            eventId,
            user,
            isAnonymous,
            isAnonymousGuest,
            logIn,
            defaults,
            getTilesNumber,
            getTilesVideo,
            getTilesVideoWithoutActiveSpeaker,
            getActiveSpeaker,
            getCallState,
            sendMessage,
            muteRemoteVideosForMe,
            handleShowLogIn,
            showLogIn,
            reloadPage,
            handleAWBanners,
            handleMuteAudio,
            handleMuteVideo,
            toggleList,
            toggleSettings,
            endConference,
            changeLayout,
            toggleScreenShare,
            toggleVideoPresentation,
            selfScreenShare,
            selfVideoPresentation,
            streaming,
            username,
            name,
            userPhoto
        } = this.props;

        console.log('ConferenceRoom props', this.props);

        return (
            <div
                className='conference-room'
                id='vxt-widget-container'
            >
                <aside className="conference-video-wrapper vxt-widget-container vxt-widget-opened vxt-widget-fullscreen-on">
                    <div
                        id="conference-attendees"
                        className={
                            classNames("vxt-conference-attendees",
                                { 'buttons': !isListener },
                                { 'green-room': !streaming && !isListener && (!isAnonymous || isAnonymousGuest) }
                            )
                        }
                    >
                        <ResizableRoster
                            attendeesListOpened={displayAttendeesList}
                            eventItem={eventItem}
                            user={user}
                            role={role}
                            isAdmin={isAdmin}
                            sendMessage={sendMessage}
                            getCallState={getCallState}
                            isListener={isListener}
                            isAnonymous={isAnonymous && isListener}
                        />
                        <ExitModal
                            leave={endConference}
                            getCallState={getCallState}
                            eventId={eventId}
                            role={role}
                        />
                        <KnockModal
                            getCallState={getCallState}
                            conferenceAlias={eventId}
                            knockId={knockId}
                        />
                        <RoomMessage />
                        {!isListener ?
                            <ConferenceSettings
                                videoEnabled={videoEnabled}
                                isListener={isListener}
                                attendeesSettingsOpened={attendeesSettingsOpened}
                                conferenceAlias={eventId}
                                isAdmin={isAdmin}
                                customSettings={CustomSettings}
                                eventItem={eventItem}
                                muteRemoteVideosForMe={muteRemoteVideosForMe}
                                changeLayout={changeLayout}
                            />
                            : null
                        }
                        <div
                            className={classNames('sidebar-container',
                                { 'attendees-list-close': !displayAttendeesList },
                                { 'attendees-list-opened': displayAttendeesList }
                            )}
                            id={classNames({ 'roster-closed': rosterOpened })}
                            style={{
                                height: clientWidth * (9 / 16),
                                width: clientHeight * (16 / 9),
                                maxWidth: clientWidth,
                                maxHeight: clientHeight
                            }}
                        >
                            <div
                                className='background'
                                style={{
                                    backgroundImage: showBackground && background ? `url(${background})` : null
                                }}
                            >
                                {!showBackground ?
                                    <div className="call">
                                        <LayoutView
                                            layout={layout}
                                            labels={labels}
                                            banner={banner}
                                            getTilesNumber={getTilesNumber}
                                            getTilesVideo={getTilesVideo}
                                            getTilesVideoWithoutActiveSpeaker={getTilesVideoWithoutActiveSpeaker}
                                            onOrientationChanged={onOrientationChanged}
                                            getActiveSpeaker={getActiveSpeaker}
                                            videoPresentation={videoPresentation}
                                            screenShare={screenShare}
                                            activeSpeakers={activeSpeakers}
                                            videoDuration={videoDuration}
                                            videoURL={videoURL}
                                            videoPlaying={videoPlaying}
                                            videoTS={videoTS}
                                            isVideoPresentation={selfVideoPresentation}
                                            eventItem={eventItem}
                                            isListener={isListener}
                                            showWatermark={showWatermark}
                                            watermark={watermark}
                                            roomWidth={clientWidth}
                                            roomHeight={clientHeight}
                                        />
                                    </div>
                                    : null
                                }
                                {((!getTilesVideo) || (getTilesVideo && !getTilesVideo('all').length)) ? <img src={loadingGif} className='video-loader' /> : null}
                            </div>
                        </div>
                        {!isListener ?
                            <SelfView />
                            : null
                        }
                        {isListener && smallAWBanner ?
                            isAnonymousGuest ?
                                <Banner>
                                    <p className='banner-text-line'>We’ve let the host know you’re waiting and they’ll let you in soon.<br /> Make sure your mic and camera are ready!</p>
                                </Banner>
                                : isAnonymous ? <Banner>
                                    <div className='banner-texts'>
                                        <p className='banner-text button-line bold-text'>Login to be able to Chat, Knock and be part
                                            of the discussion!</p>
                                    </div>
                                    <div className='banner-buttons'>
                                        <Button
                                            className='btn-banner'
                                            type="default"
                                            onClick={handleShowLogIn}
                                        >
                                            Login
                                        </Button>
                                    </div>
                                </Banner>
                                    : null

                            : null
                        }
                        {isListener && bigAWBanner && isAnonymous && !isAnonymousGuest ?
                            <Banner>
                                <div className='banner-texts'>
                                    <p className='banner-text bold-text'>{'To continue watching the show, please login in the next '}
                                        <Counter
                                            seconds={awExitTime}
                                            zeroFunction={() => {
                                                handleShowLogIn();
                                                handleAWBanners();
                                                this.exitLoginTimeout = setTimeout(() => {
                                                    showLogIn(false);
                                                    reloadPage();
                                                }, defaults && defaults.anonymousWatchersLoginTime && parseInt(defaults.anonymousWatchersLoginTime) ? parseInt(defaults.anonymousWatchersLoginTime) * 1000 : 20000);
                                            }}
                                        />
                                        {' seconds.'}</p>
                                    <p className='banner-text sm-text'>{`It’s 100% free to access this show on ${strings.appName}!`}</p>
                                </div>
                                <div className='banner-buttons'>
                                    <Button
                                        className='btn-banner'
                                        type="default"
                                        onClick={handleShowLogIn}
                                    >
                                        Login
                                    </Button>
                                </div>
                            </Banner>
                            : null
                        }
                        {logIn ?
                            <LogIn
                                handleShowLogIn={handleShowLogIn}
                            />
                            : null
                        }
                    </div>
                    <ConferenceButtons
                        isMuted={isMuted}
                        isAdmin={isAdmin}
                        isListener={isListener}
                        handleMuteAudio={handleMuteAudio}
                        role={role}
                        videoEnabled={videoEnabled}
                        handleMuteVideo={handleMuteVideo}
                        getCallState={getCallState}
                        toggleList={toggleList}
                        toggleSettings={toggleSettings}
                        displayAttendeesList={displayAttendeesList}
                        attendeesSettingsOpened={attendeesSettingsOpened}
                        endConference={endConference}
                        eventId={eventId}
                        eventItem={eventItem}
                        conferenceAlias={conferenceAlias}
                        knockId={knockId}
                        toggleScreenShare={toggleScreenShare}
                        toggleVideoPresentation={toggleVideoPresentation}
                        selfScreenShare={selfScreenShare}
                        selfVideoPresentation={selfVideoPresentation}
                        isAnonymous={isAnonymous && isListener}
                        muteRemoteVideosForMe={muteRemoteVideosForMe}
                    />
                </aside>
                <StreamingCounter
                    role={role}
                    user={user}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        streaming: state.room.streaming,
        orientation: state.app.orientation
    };
};

const ConferenceRoomContainer = connect(
    mapStateToProps
)(ConferenceRoom);

export default ConferenceRoomContainer;
