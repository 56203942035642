import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import ClickOutside from '../../Widget/ClickOutside';
import fanoutClient from '../../../utils/FanoutClient';
import * as roomActions from "../../../actions/room_actions";

class KnockModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            knock: false,
            knockRid: null,
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            const { showKnockModal } = this.props;

            if (showKnockModal) {
                showKnockModal(false);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { knock } = this.state;
        const { knocks, getCallState } = this.props;

        if (Object.keys(knocks).length !== Object.keys(prevProps.knocks).length) {
            if (Object.keys(knocks).length) {
                let callState = getCallState();
                Object.keys(knocks).map((key) => {
                    if (knocks[key] && callState && knocks[key].uid === callState.uid && ((!knocks[key].payload) || (knocks[key].payload && !knocks[key].payload.isAnonymousGuest))) {
                        this.setState({
                            knock: true,
                            knockRid: knocks[key].rid
                        });
                    }
                    return key;
                });
            } else if (knock) {
                this.setState({
                    knock: false,
                    knockRid: null
                });
            }
        }
    }

    handleKnock() {
        const { knock, knockRid } = this.state;
        const { getCallState, showKnockModal } = this.props;

        if (!knock && getCallState) {
            fanoutClient.sendKnockRequest(getCallState());

            if (showKnockModal) {
                showKnockModal(false, true);
            }
        } else if (knock && knockRid && getCallState) {
            fanoutClient.sendKnockRevoked(knockRid, getCallState());

            if (showKnockModal) {
                showKnockModal(false);
            }
        }
    }

    render() {
        const { knock } = this.state;
        const { knockModal, showKnockModal } = this.props;

        return (
            <Fragment>
                {knockModal ?
                    <div className='hangup-modal-wrapper'>
                        {knock ?
                            <ClickOutside
                                className='hangup-modal big-modal'
                                clickOutside={() => {
                                    if (showKnockModal) {
                                        showKnockModal(false);
                                    }
                                }}
                            >
                                <div className='hangup-title'>Cancel Knock</div>
                                <div className='hangup-text'>Remove knock request?</div>
                                <button
                                    className='btn hangup-knock-remove'
                                    onClick={this.handleKnock.bind(this)}
                                >
                                    Remove
                                </button>
                                <button
                                    className='btn hangup-cancel-knock'
                                    onClick={() => {
                                        if (showKnockModal) {
                                            showKnockModal(false);
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                            </ClickOutside>
                            :
                            <ClickOutside
                                className='hangup-modal big-modal'
                                clickOutside={() => {
                                    if (showKnockModal) {
                                        showKnockModal(false);
                                    }
                                }}
                            >
                                <div className='hangup-title'>Confirm Knock</div>
                                <div className='hangup-text'>Knocking tells the host that you’d like to join the stream. If the host lets you in, everyone will be able to see you on camera and hear you via your microphone, so be ready!</div>
                                <button
                                    className='btn hangup-knock'
                                    onClick={this.handleKnock.bind(this)}
                                >
                                    Knock
                                </button>
                                <button
                                    className='btn hangup-cancel-knock'
                                    onClick={() => {
                                        if (showKnockModal) {
                                            showKnockModal(false);
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                            </ClickOutside>
                        }
                    </div>
                    : null}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        knocks: state.knocks,
        knockModal: state.room.knockModal
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showKnockModal: (knockModal, knockDisabled = false) => {
            dispatch(roomActions.showKnockModal(knockModal, knockDisabled));
        }
    };
};

const KnockModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(KnockModal);

export default KnockModalContainer;
