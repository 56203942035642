import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from "classnames";
import { push as nativePush } from 'connected-react-router';
import firebaseClient from './../../utils/FirebaseClient';
import { v4 as uuidv4 } from 'uuid';
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState, ContentState } from 'draft-js';
import CurrencyInput from 'react-currency-input-field';
import { Link } from "react-router-dom";
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';
import {
    stringToBoolean,
    validLink,
    signupCampaignPageLink,
    showBecomeAHost
} from '../../utils/text';

const DEFAULT_MONTHLY_PRICE = 4;
const DEFAULT_ANUAL_PRICE = 40;

class EditProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profileData: null,
            look: '',
            image1: '',
            image2: '',
            image3: '',
            selectedImg: 1,
            text1: '',
            text2: '',
            text3: '',
            selectedText: 1,
            first_name: "",
            last_name: "",
            username: "",
            email: "",
            phone_number: "",
            social_handle_1: "",
            social_handle_2: "",
            social_handle_3: "",
            social_handle_4: "",
            website: "",
            biography: "",
            editorState: EditorState.createEmpty(),
            interest1: "",
            interest2: "",
            interest3: "",
            interest4: "",
            interest5: "",
            interest6: "",
            privateAvailable: false,
            subscriptionsAvailable: false,
            "price-month-*": undefined,
            "price-year-*": undefined,
            cancelModal: false,
            privileges: null,
            cancelProcessing: false,
            nav: 'personal-settings',
            instagram: '',
            linkedIn: '',
            twitter: '',
            archiveLink: ''
        }

        this.addFileRef = React.createRef();
        this.addFile2Ref = React.createRef();
        this.handleSwitchSubscription = this.handleSwitchSubscription.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.getUserDetails();
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.state.cancelProcessing) {
            this.setState({
                cancelModal: false
            });
        }
    }

    getUserDetails() {
        const { firebaseInit, user } = this.props;

        if (firebaseInit && user && user.uid) {
            firebaseClient.getUserFS().then((res) => {
                if (res.data) {

                    let privateAvailable = false
                    let subscriptionsAvailable = true

                    if (res.data.interests) {
                        res.data.interests.split("/n").forEach(element => {
                            if (element !== "") privateAvailable = true;
                        })
                    }

                    let biography = res.data.biography ? res.data.biography : '';


                    if (biography && typeof biography !== 'string') {
                        biography = convertFromRaw(res.data.biography);
                        biography = EditorState.createWithContent(biography);
                    } else {
                        biography = EditorState.createWithContent(ContentState.createFromText(biography))
                    }

                    this.setState({
                        profileData: res.data,
                        image1: res.data.profile_picture1 || "",
                        image2: res.data.profile_picture2 || "",
                        image3: res.data.profile_picture3 || "",
                        first_name: res.data.first_name || "",
                        last_name: res.data.last_name || "",
                        username: res.data.username || "",
                        email: res.data.email || "",
                        phone_number: res.data.phone_number || "",
                        biography: res.data.biography || "",
                        editorState: biography || "",
                        social_handle_1: res.data.social_handle_1 || "",
                        social_handle_2: res.data.social_handle_2 || "",
                        social_handle_3: res.data.social_handle_3 || "",
                        social_handle_4: res.data.social_handle_4 || "",
                        website: res.data.website || "",
                        isSaving: false,
                        interest1: res.data.interests ? res.data.interests.split("/n")[0] : "",
                        interest2: res.data.interests ? res.data.interests.split("/n")[1] : "",
                        interest3: res.data.interests ? res.data.interests.split("/n")[2] : "",
                        interest4: res.data.interests ? res.data.interests.split("/n")[3] : "",
                        interest5: res.data.interests ? res.data.interests.split("/n")[4] : "",
                        interest6: res.data.interests ? res.data.interests.split("/n")[5] : "",
                        privateAvailable: privateAvailable,
                        instagram: res.data.instagram || "",
                        linkedIn: res.data.linkedIn || "",
                        twitter: res.data.twitter || "",
                        archiveLink: res.data.archiveLink || ""
                    });
                    if (!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) {
                        // Fetch user products
                        firebaseClient.getUserProducts().then(products => {
                            // console.log('User products', products);
                            let price_user_month = undefined;
                            let update = {
                                subscriptionsAvailable: (products && products.length),
                                products: products
                            };
                            if (products) {
                                products.forEach(product => {
                                    let { prices } = product;
                                    if (prices) {
                                        prices.forEach(price => {
                                            update[`price-${price.interval}-${product.show}`] = parseFloat(price.value);
                                        });
                                    }
                                });
                            }
                            this.setState(update, () => {
                                // console.log('Prices updated', this.state);
                            });
                        });
                        firebaseClient.getUserPrivileges().then((data) => {
                            this.setState({
                                privileges: data,
                            });
                        });
                    }

                }
            });
        } else {
            setTimeout(() => {
                this.getUserDetails()
            }, 500);
        }
    }

    handleChangeLook(value) {
        this.setState({
            look: value
        });
    }

    handleChangeImageURL(event, index) {
        const res = validLink(event.target.value);
        if (res) {
            this.setState({
                [`image${index}`]: event.target.value
            });
        }
    }

    handleClickAddFile(number) {
        if (number && number === 2 && this.addFileRef && this.addFileRef.current && this.addFileRef.current.click) {
            this.addFileRef.current.click();
        } else if (number && number === 1 && this.addFile2Ref && this.addFile2Ref.current && this.addFile2Ref.current.click) {
            this.addFile2Ref.current.click();
        }
    }

    handleImageChange(event, index) {
        const { user } = this.props;
        // console.log("Index", index);
        if (event.target.files && event.target.files[0] && user) {
            let img = event.target.files[0];
            let id = `${user.uid}/${uuidv4()}`;
            firebaseClient.addImageToStorage(img, null, id).then((res) => {
                this.setState({
                    [`image${index}`]: res
                });
            })
        }
    }

    handleChangeText(event, index) {
        this.setState({
            [`text${index}`]: event.target.value
        })
    }

    handleSelectedImage(value) {
        this.setState({
            selectedImg: value
        })
    }

    handleSelectedText(value) {
        this.setState({
            selectedText: value
        })
    }


    handleSwitchSubscription(e) {
        let update = {
            subscriptionsAvailable: e.target.checked,
        };
        if (!e.target.checked) {
            update["price-month-*"] = undefined;
            update["price-year-*"] = undefined;
        } else {
            if (!this.state.products || !this.state.products.length) {
                update["price-month-*"] = DEFAULT_MONTHLY_PRICE;
                update["price-year-*"] = DEFAULT_ANUAL_PRICE;
                update.products = [{
                    scope: 'user',
                    show: '*',
                    prices: [
                        {
                            value: DEFAULT_MONTHLY_PRICE,
                            currency: 'usd',
                            interval: 'month'
                        },
                        {
                            value: DEFAULT_ANUAL_PRICE,
                            currency: 'usd',
                            interval: 'year'
                        }
                    ],
                    title: 'User all shows',
                }];
            } else {
                this.state.products.forEach(product => {
                    let { prices } = product;
                    prices.forEach(price => {
                        update[`price-${price.interval}-${price.show || '*'}`] = price.value;
                    })
                });
            }
        }
        this.setState(update, () => {
            // console.log('updated state', this.state);
        });
    }

    saveChanges() {
        const {
            selectedImg,
            selectedText,
            first_name,
            last_name,
            phone_number,
            username,
            image1,
            image2,
            image3,
            biography,
            editorState,
            social_handle_1,
            social_handle_2,
            social_handle_3,
            social_handle_4,
            website,
            interest1,
            interest2,
            interest3,
            interest4,
            interest5,
            interest6,
            subscriptionsAvailable,
            products,
            instagram,
            linkedIn,
            twitter,
            archiveLink
        } = this.state;
        const { user,
            pushToProfile,
            pushToHome,
            broadcasterUser
        } = this.props;
        let image;

        let interests = interest1 + "/n" + interest2 + "/n" + interest3 + "/n" + interest4 + "/n" + interest5 + "/n" + interest6

        this.setState({ isSaving: true });

        switch (selectedImg) {
            case 1: {
                image = {
                    profile_picture1: image1 || '',
                    profile_picture2: image2 || '',
                    profile_picture3: image3 || '',
                }
            }
                break;
            case 2: {
                image = {
                    profile_picture1: image2 || '',
                    profile_picture2: image1 || '',
                    profile_picture3: image3 || '',
                }
            }
                break;
            case 3: {
                image = {
                    profile_picture1: image3 || '',
                    profile_picture2: image1 || '',
                    profile_picture3: image2 || '',
                }
            }
                break;
        }

        let changes = Object.assign(image,
            {
                first_name,
                last_name,
                phone_number,
                username,
                username_lc: username.toLowerCase(),
                biography,
                biographyText: editorState.getCurrentContent().getPlainText(),
                social_handle_1,
                social_handle_2,
                social_handle_3,
                social_handle_4,
                website,
                interests,
                instagram,
                linkedIn,
                twitter,
                archiveLink
            }
        );
        let nproducts = undefined;
        if (subscriptionsAvailable) {
            // Set collected prices
            products.forEach(product => {
                if (product.scope === 'user' && product.show === '*') {
                    product.prices = [];
                    if (this.state["price-month-*"] !== undefined && this.state["price-month-*"] !== null && !isNaN(this.state["price-month-*"])) {
                        product.prices.push({
                            value: this.state["price-month-*"],
                            currency: 'usd',
                            interval: 'month'
                        })
                    }
                    if (this.state["price-year-*"] !== undefined && this.state["price-year-*"] !== null && !isNaN(this.state["price-year-*"])) {
                        product.prices.push({
                            value: this.state["price-year-*"],
                            currency: 'usd',
                            interval: 'year'
                        })
                    }
                }
            });
            nproducts = products;
        }

        if (user && user.uid) {
            firebaseClient.updateUser(user.uid, changes).then(() => {
                firebaseClient.saveUserProducts(user.uid, nproducts).then((res) => {
                    firebaseClient.updateUserData(user).then(() => {
                        if (pushToProfile && broadcasterUser) {
                            pushToProfile(user.uid)
                        } else if (pushToHome) {
                            pushToHome();
                        }
                    });
                });
            }).catch((error) => {
                console.error('There was an error when calling the Cloud Function', error);
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleCancelSubscription() {

        const { privileges } = this.state;

        this.setState({
            cancelProcessing: true
        })

        firebaseClient.cancelHostingSubscription(privileges.uid).then(() => {
            firebaseClient.getUserPrivileges().then((data) => {
                this.setState({
                    privileges: data,
                    cancelModal: false,
                    cancelProcessing: false
                });
            });
        });
    }

    render() {
        const {
            profileData,
            look,
            selectedImg,
            first_name,
            last_name,
            username,
            email,
            phone_number,
            biography,
            editorState,
            social_handle_1,
            social_handle_2,
            social_handle_3,
            social_handle_4,
            image1,
            image2,
            image3,
            selectedText,
            website,
            text1,
            text2,
            text3,
            isSaving,
            interest1,
            interest2,
            interest3,
            interest4,
            interest5,
            interest6,
            privateAvailable,
            subscriptionsAvailable,
            products,
            cancelModal,
            privileges,
            cancelProcessing,
            nav,
            instagram,
            linkedIn,
            twitter,
            archiveLink
        } = this.state;

        const {
            user,
            broadcasterUser
        } = this.props;

        let avatar = user ?
            (user.profile_picture2 ?
                user.profile_picture2 :
                (user.profile_picture ? user.profile_picture : null)) :
            null;

        let planName = privileges && privileges.plan ? privileges.plan === 'zero_commitment' ? "Zero Commitment" : "Content Creator Package" : "";

        return (
            <div>
                {profileData ?
                    <div className='profile-div-wrapper'>
                        <div className='profile-div-details my-stuff'>
                            <div className='profile-left'>
                                <img src={avatar} className='profile-img' loading='lazy' />
                                <div className='profile-username my-stuff'>
                                    <p>{username ? username : `${first_name} ${last_name}`}</p>
                                </div>
                            </div>
                            <div className='profile-right'>
                                {broadcasterUser ?
                                    <div className='profile-buttons'>
                                        <Link to='/calendar'>
                                            <Button type="default" className='btn-subscribe'>
                                                schedule new stream
                                            </Button>
                                        </Link>
                                        <Link to={`/profile/${user && user.uid ? user.uid : null}`}>
                                            <Button type="border" className='btn-subscribe'>
                                                view my public page
                                            </Button>
                                        </Link>
                                    </div> : null
                                }
                            </div>
                        </div>
                        {/* <div className='profile-nav-wrapper'>
                            <div className='profile-nav'>
                                <Button type='text-white' className='btn-profile-nav' onClick={() => this.setState({ nav: 'personal-settings' })}><span className={classNames('profile-nav-span', { 'active': nav === 'personal-settings' })}>personal settings</span></Button>
                                <Button type='text-white' className='btn-profile-nav' onClick={() => this.setState({ nav: 'payment-information' })}><span className={classNames('profile-nav-span', { 'active': nav === 'payment-information' })}>payment information</span></Button>
                            </div>
                        </div> */}
                        {nav && nav === 'personal-settings' ?
                            <div className={classNames('profile-edit-wrapper', { 'header-taller': process.env.build && process.env.build === 'nfl-aa' })}>
                                <p className='profile-edit-title'>personal settings</p>
                                <div className='image-line'>
                                    <div className='image-text'>Default Profile Picture</div>
                                </div>
                                <div className='profile-edit-picture'>
                                    <img src={image2 === '' && user && user.profile_picture2 ? user.profile_picture2 : image2 === '' && user && user.profile_picture ? user.profile_picture : image2 === '' ? "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" : image2} className='change-avatar-img' loading='lazy' />
                                    <input
                                        ref={this.addFileRef}
                                        className='add-file'
                                        type="file"
                                        accept="image/png,image/jpeg,image/svg+xml"
                                        disabled={isSaving}
                                        onChange={(event) => this.handleImageChange(event, 2)}
                                    />
                                    <Button type='text-white' className='btn-edit-change-img' disabled={isSaving} onClick={() => this.handleClickAddFile(2)}>Change Picture</Button>
                                </div>
                                {broadcasterUser ?
                                    <div className='edit-profile-input-wrapper flex-input'>
                                        <div className='edit-profile-input-line'>
                                            <p>First Name</p>
                                            <input
                                                type="text"
                                                placeholder="First Name"
                                                value={first_name}
                                                disabled={isSaving}
                                                onChange={(e) => this.setState({ first_name: e.target.value })}
                                            />
                                        </div>
                                        <div className='edit-profile-input-line'>
                                            <p>Last Name</p>
                                            <input
                                                type="text"
                                                placeholder="Last Name"
                                                value={last_name}
                                                disabled={isSaving}
                                                onChange={(e) => this.setState({ last_name: e.target.value })}
                                            />
                                        </div>
                                    </div> : null
                                }
                                <div className='edit-profile-input-wrapper flex-input'>
                                    <div className='edit-profile-input-line'>
                                        <p>Email</p>
                                        <input
                                            type="text"
                                            placeholder="Email Address"
                                            value={email}
                                            readOnly
                                            disabled
                                        />
                                    </div>
                                    <div className='edit-profile-input-line'>
                                        <p>Number (+1, followed by 10 digits)</p>
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            value={phone_number}
                                            disabled={isSaving}
                                            onChange={(e) => this.setState({ phone_number: e.target.value })}
                                        />
                                    </div>
                                </div>
                                {broadcasterUser ?
                                    <div>
                                        <div className='image-line'>
                                            <div className='image-text'>Default Event Banner Picture</div>
                                        </div>
                                        <div className='profile-edit-picture'>
                                            <img src={image1 === '' && user && user.profile_picture1 ? user.profile_picture1 : image1 === '' && user && user.profile_picture ? user.profile_picture : image1 === '' ? "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" : image1} className='change-profile-img' loading='lazy' />
                                            <input
                                                ref={this.addFile2Ref}
                                                className='add-file'
                                                type="file"
                                                accept="image/png,image/jpeg,image/svg+xml"
                                                disabled={isSaving}
                                                onChange={(event) => this.handleImageChange(event, 1)}
                                            />
                                            <Button type='text-white' className='btn-edit-change-img' disabled={isSaving} onClick={() => this.handleClickAddFile(1)}>Change Picture</Button>
                                        </div>
                                    </div> : null
                                }
                                <div className='edit-profile-input-wrapper'>
                                    <div className='edit-profile-input-line'>
                                        <p>Username / Alias</p>
                                        <input
                                            type="text"
                                            placeholder="Username / Alias"
                                            value={username}
                                            disabled={isSaving}
                                            onChange={(e) => this.setState({ username: e.target.value })}
                                        />
                                    </div>
                                </div>
                                {broadcasterUser && <div className="edit-profile-input-wrapper">
                                    <div className='edit-profile-input-line'>
                                        <p>Biography</p>
                                        <Editor
                                            toolbar={{
                                                options: ['inline', 'list', 'emoji', 'history'],
                                                inline: {
                                                    inDropdown: false,
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                    options: ['bold', 'italic', 'underline']
                                                },
                                                list: {
                                                    inDropdown: false,
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                    options: ['unordered', 'ordered']
                                                }
                                            }}
                                            editorState={editorState}
                                            onEditorStateChange={(e) => this.setState({ editorState: e })}
                                            onContentStateChange={(e) => this.setState({ biography: e })}
                                            placeholder='Start typing biography'
                                            stripPastedStyles
                                        />
                                    </div>
                                </div>
                                }
                                {broadcasterUser ?
                                    <div className='switch-line-wrapper'>
                                        {(((!process.env.hideSubscription) || (process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription))) && ((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile'))) ?
                                            <div className='switch-line'>
                                                <div className='switch-div'>
                                                    <div className='switch-text'>Monthly Subscription Available</div>
                                                    <label className="switch-control">
                                                        <input
                                                            type="checkbox"
                                                            value={subscriptionsAvailable}
                                                            onChange={this.handleSwitchSubscription}
                                                            checked={subscriptionsAvailable}
                                                            disabled={isSaving}
                                                        />
                                                        <span className="slider round" />
                                                    </label>
                                                </div>
                                            </div> : null
                                        }
                                        <div className='switch-line'>
                                            <div className='switch-div'>
                                                <div className='switch-text'>Private Events Availability</div>
                                                <label className="switch-control">
                                                    <input
                                                        type="checkbox"
                                                        value={privateAvailable}
                                                        onChange={(e) => this.setState({ privateAvailable: e.target.checked })}
                                                        checked={privateAvailable}
                                                        disabled={isSaving}
                                                    />
                                                    <span className="slider round" />
                                                </label>
                                            </div>
                                        </div>
                                    </div> : null
                                }
                                {subscriptionsAvailable && broadcasterUser &&
                                    ((!process.env.hideSubscription) || (process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription))) &&
                                    ((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile')) ?
                                    <div className='edit-profile-input-wrapper'>
                                        <div className='edit-profile-input-line'>
                                            <p>All shows monthly subscription</p>
                                            <CurrencyInput
                                                id="price-month-*"
                                                name="price-month-*"
                                                placeholder="Set price"
                                                prefix={'$'}
                                                defaultValue={undefined}
                                                decimalScale={2}
                                                decimalsLimit={2}
                                                step={1}
                                                min={0}
                                                value={isNaN(this.state["price-month-*"]) ? undefined : this.state["price-month-*"]}
                                                onValueChange={(value) => {
                                                    this.setState({ "price-month-*": parseInt(value) });
                                                }}
                                            />
                                        </div>
                                        {/* <div className='edit-profile-input-line'>
                                                <p>All shows annual subscription</p>
                                                <CurrencyInput
                                                    id="price-year-*"
                                                    name="price-year-*"
                                                    placeholder="Set price"
                                                    prefix={'$'}
                                                    defaultValue={undefined}
                                                    decimalScale={2}
                                                    decimalsLimit={2}
                                                    step={1}
                                                    min={0}
                                                    value={isNaN(this.state["price-year-*"]) ? undefined : this.state["price-year-*"]}
                                                    onValueChange={(value) => {
                                                        this.setState({ "price-year-*": parseFloat(value) }, () => {
                                                            console.log('Prices updated', this.state);
                                                        });
                                                    }}
                                                />
                                            </div> */}
                                    </div> : null
                                }
                                {broadcasterUser && privateAvailable ?
                                    <div className='edit-profile-input-wrapper'>
                                        <div className='edit-profile-input-line'>
                                            <p>Interest 1</p>
                                            <input
                                                type="text"
                                                placeholder="Interest 1"
                                                value={interest1}
                                                onChange={(e) => this.setState({ interest1: e.target.value })}
                                            />
                                        </div>
                                        <div className='edit-profile-input-line'>
                                            <p>Interest 2</p>
                                            <input
                                                type="text"
                                                placeholder="Interest 2"
                                                value={interest2}
                                                onChange={(e) => this.setState({ interest2: e.target.value })}
                                            />
                                        </div>
                                        <div className='edit-profile-input-line'>
                                            <p>Interest 3</p>
                                            <input
                                                type="text"
                                                placeholder="Interest 3"
                                                value={interest3}
                                                onChange={(e) => this.setState({ interest3: e.target.value })}
                                            />
                                        </div>
                                        <div className='edit-profile-input-line'>
                                            <p>Interest 4</p>
                                            <input
                                                type="text"
                                                placeholder="Interest 4"
                                                value={interest4}
                                                onChange={(e) => this.setState({ interest4: e.target.value })}
                                            />
                                        </div>
                                        <div className='edit-profile-input-line'>
                                            <p>Interest 5</p>
                                            <input
                                                type="text"
                                                placeholder="Interest 5"
                                                value={interest5}
                                                onChange={(e) => this.setState({ interest5: e.target.value })}
                                            />
                                        </div>
                                        <div className='edit-profile-input-line'>
                                            <p>Interest 6</p>
                                            <input
                                                type="text"
                                                placeholder="Interest 6"
                                                value={interest6}
                                                onChange={(e) => this.setState({ interest6: e.target.value })}
                                            />
                                        </div>
                                    </div> : null
                                }
                                {broadcasterUser && <div className='edit-profile-input-wrapper'>
                                    <div className='edit-profile-input-line'>
                                        <p>Instagram</p>
                                        <input
                                            type="text"
                                            placeholder="Enter Instagram link"
                                            value={instagram}
                                            disabled={isSaving}
                                            onChange={(e) => this.setState({ instagram: e.target.value })}
                                        />
                                    </div>
                                </div>}
                                {broadcasterUser && <div className='edit-profile-input-wrapper'>
                                    <div className='edit-profile-input-line'>
                                        <p>Twitter</p>
                                        <input
                                            type="text"
                                            placeholder="Enter Twitter link"
                                            value={twitter}
                                            disabled={isSaving}
                                            onChange={(e) => this.setState({ twitter: e.target.value })}
                                        />
                                    </div>
                                </div>}
                                {broadcasterUser && <div className='edit-profile-input-wrapper'>
                                    <div className='edit-profile-input-line'>
                                        <p>LinkedIn</p>
                                        <input
                                            type="text"
                                            placeholder="Enter LinkedIn link"
                                            value={linkedIn}
                                            disabled={isSaving}
                                            onChange={(e) => this.setState({ linkedIn: e.target.value })}
                                        />
                                    </div>
                                </div>}
                                {broadcasterUser && (process.env.build && process.env.build === 'nfl-aa') && <div className='edit-profile-input-wrapper'>
                                    <div className='edit-profile-input-line'>
                                        <p>My Archive</p>
                                        <input
                                            type="text"
                                            placeholder="Enter Archive link"
                                            value={archiveLink}
                                            disabled={isSaving}
                                            onChange={(e) => this.setState({ archiveLink: e.target.value })}
                                        />
                                    </div>
                                </div>}
                                <div className='edit-profile-buttons'>
                                    <Button
                                        type='default'
                                        className="btn-save-profile"
                                        onClick={() => this.saveChanges()}
                                        disabled={isSaving}
                                        loading={isSaving}
                                    >
                                        Save Changes
                                    </Button>
                                    <Button
                                        type='text-white'
                                        className="btn-profile-cancel"
                                        disabled={isSaving}
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                {user && user.broadcaster && privileges && privileges.active && (!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) ?
                                    // <div className="edit-profile-input-wrapper">
                                    //     <p className="edit-subscribe-text title-text">{"You are subscribed to " + planName}</p>
                                    //     {privileges && privileges.status === "canceling" ? <p className="edit-subscribe-text"> Subscription is cancelled and it will end at the subscription period end {new Date(privileges.stop * 1000).toLocaleString()}</p> : null}
                                    // </div>
                                    null
                                    :
                                    showBecomeAHost && user && !user.broadcaster ?
                                        <div className="edit-profile-input-wrapper">
                                            <p className="edit-subscribe-text title-text">Ready to host your own stream?</p>
                                            <a href={signupCampaignPageLink}>
                                                <Button className='btn-edit-host-app'>
                                                    become a host
                                                </Button>
                                            </a>
                                        </div>
                                        :
                                        null}
                            </div>
                            : nav && nav === 'payment-information' ?
                                <div className={classNames('profile-edit-wrapper', { 'header-taller': process.env.build && process.env.build === 'nfl-aa' })}>
                                    <p className='profile-edit-title'>payment information</p>
                                </div>
                                : null
                        }
                    </div>
                    : <Loader />
                }
                {/* {cancelModal ?
                    <div className='vanity-modal-wrapper'>
                        <div ref={this.setWrapperRef} className='vanity-modal-div'>
                            <div className='vanity-modal-title'>Are you sure?</div>
                            <div className='vanity-modal-text'>Subscription will be permanently canceled!</div>
                            <div className='vanity-modal-buttons'>
                                <Button
                                    type='default'
                                    className='btn-modal-accepted'
                                    disabled={cancelProcessing}
                                    onClick={() => this.handleCancelSubscription()}
                                    loading={cancelProcessing}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    type='text-blue'
                                    className='btn-modal-cancel'
                                    disabled={cancelProcessing}
                                    onClick={() => this.setState({
                                        cancelModal: false
                                    })}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                    : null} */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        broadcasterUser: state.firebase.roles && state.firebase.roles.broadcaster ? true : false,
        firebaseInit: state.firebase.init
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logOut: () => {
            // dispatch(firebaseActions.logOut());
        },
        pushToProfile: (uid) => {
            dispatch(nativePush(`/profile/${uid}`));
        },
        pushToHome: () => {
            dispatch(nativePush(`/`));
        }
    };
};

const EditProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProfile);

export default EditProfileContainer;
