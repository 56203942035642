import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import moment from 'moment';
import fanoutClient from "../../../utils/FanoutClient";
import * as roomActions from "../../../actions/room_actions";
import Broadcast from "../../../../resources/assets/Broadcast.svg";
import BroadcastActive from "../../../../resources/assets/Broadcast-Active.svg";
import BroadcastPause from "../../../../resources/assets/Broadcast-Pause.svg";
import BroadcastResume from "../../../../resources/assets/Broadcast-Resume.svg";
import firebaseClient from "../../../utils/FirebaseClient";
import { checkApprovedEntry } from '../../../utils/text';

class BroadcastButtonBottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            disableBtn: false,
            streamingLauncher: false
        }

        this.checkStreamingAvailable = this.checkStreamingAvailable.bind(this);
        this.handleStreamingResponse = this.handleStreamingResponse.bind(this);
        this.endEventForAudience = this.endEventForAudience.bind(this);
        this.startStreaming = this.startStreaming.bind(this);
        this.handleShowBroadcast = this.handleShowBroadcast.bind(this);
        this.handleStreamingGetReady = this.handleStreamingGetReady.bind(this);
        this.handleStreamingAtEase = this.handleStreamingAtEase.bind(this);

        fanoutClient.on('streamingResponse', this.handleStreamingResponse);
        fanoutClient.on('streamingGetReady', this.handleStreamingGetReady);
        fanoutClient.on('streamingAtEase', this.handleStreamingAtEase);
    }

    componentDidMount() {
        this.checkStreamingAvailable();
    }

    componentDidUpdate(prevProps) {
        const { streaming, displayMessage, role, showBroadcast, showMenu, eventItem, conferenceEnded, streamingStatus } = this.props;

        if (prevProps.streaming !== streaming) {
            this.setState({
                check: false
            }, () => {
                this.disableStreamingButton();
                if (streaming) {
                    if (displayMessage) {
                        displayMessage('Your event is being streamed', 2000);
                    }
                    if (showBroadcast && showMenu)
                        showMenu(false);
                } else if (streamingStatus && streamingStatus !== 'ended_for_audience' && streamingStatus !== 'ended') {
                    if (displayMessage) {
                        displayMessage('Your event is no longer being streamed', 1000);
                    }
                    if (role && ((role === 'presenter') || (role === 'moderator')) && showMenu) {
                        showMenu(true);
                    }
                }

                this.setState({ streamingLauncher: false });
            });
        }

        if (eventItem && prevProps.eventItem !== eventItem) {
            this.checkStreamingAvailable();
        }

        if (conferenceEnded && prevProps.conferenceEnded !== conferenceEnded) {
            if (showBroadcast && showMenu) {
                showMenu(false);
            }
        }
    }

    componentWillUnmount() {
        fanoutClient.removeListener('streamingResponse', this.handleStreamingResponse);
        fanoutClient.removeListener('streamingGetReady', this.handleStreamingGetReady);
        fanoutClient.removeListener('streamingAtEase', this.handleStreamingAtEase);

        if (this.streamingTimeout) {
            clearTimeout(this.streamingTimeout);
        }

        if (this.streamingBtnTimeout) {
            clearTimeout(this.streamingBtnTimeout);
        }
    }

    handleStreamingGetReady(data) {
        const { streamingLauncher } = this.state;
        const { getCallState, role, hideModerator } = this.props;

        if (role && role === 'moderator' && hideModerator) {
            hideModerator();
        }

        if (streamingLauncher && data.startAt) {
            let seconds = moment.utc(new Date(data.startAt)).diff(moment.utc(new Date()), 'seconds');
            this.getReadyTimeout = setTimeout(() => {
                this.setState({
                    check: true
                }, () => {
                    fanoutClient.sendStartStreaming(getCallState());
                    let { streamed, eventItem } = this.props;
                    console.log('Should %s record teasers',
                        !streamed && eventItem.visibility == 'public' ? '' : 'not',
                        streamed, eventItem.visibility);
                    if (!streamed && eventItem.visibility == 'public' && role && role !== 'moderator') {
                        // Streaming for the first time => record teasers
                        firebaseClient.recAndUploadTeaser(eventItem.id)
                    }
                });
            }, seconds * 1000);
        }
    }

    handleStreamingAtEase() {
        const { role, prevStateModerator } = this.props;

        if (role && role === 'moderator' && prevStateModerator) {
            prevStateModerator();
        }

        if (this.getReadyTimeout) {
            clearTimeout(this.getReadyTimeout);
        }
    }

    disableStreamingButton() {
        this.setState({
            disableBtn: true
        }, () => {
            this.streamingBtnTimeout = setTimeout(() => {
                this.setState({
                    disableBtn: false
                });
            }, 2000);
        })
    }

    handleStreamingResponse(data) {
        try {
            console.log("handleStreamingResponse response received from server", data);
            if (data && data.result && data.result === 'error') {
                this.setState({
                    streamingLauncher: false,
                    check: false
                });
            }
        }
        catch (error) {
            console.error("Failed handleStreamingResponse", error, data);
        }
    }

    handleShowBroadcast() {
        const { showMenu, showBroadcast } = this.props;
        if (showMenu) {
            showMenu(!showBroadcast);
        }
    }

    checkStreamingAvailable() {
        const { eventItem } = this.props;

        if (eventItem && eventItem.startDate) {
            let diffMinutes = moment(new Date(eventItem.startDate)).subtract(30, 'minutes').diff(moment(new Date()), 'minutes');
            let diffSeconds = moment(new Date(eventItem.startDate)).subtract(30, 'minutes').diff(moment(new Date()), 'seconds');

            if (diffMinutes > 0 || diffSeconds > 0) {
                this.setState({
                    check: true
                }, () => {
                    this.streamingTimeout = setTimeout(() => {
                        this.setState({
                            check: false
                        });
                    }, diffSeconds * 1000);
                });
            }
        }
    }

    endEventForAudience() {
        const { getCallState, showMenu } = this.props;

        if (getCallState) {
            fanoutClient.endEventForAudience(getCallState());
            if (showMenu) {
                showMenu(false)
            }
        }
    }

    startStreaming() {
        const { check } = this.state;
        const { streaming, getCallState, streamingCounter, displayMessage, conferenceEnded, approvedEntry, eventItem } = this.props;

        if(conferenceEnded || check || !checkApprovedEntry(approvedEntry, eventItem)) {
            if (displayMessage) {
                displayMessage('Streaming is not available', 4000, 'notification');
            }
        } else if (streamingCounter) {
            fanoutClient.sendStreamingAtEase();
        } else if (!streaming && getCallState) {
            this.setState({ streamingLauncher: true });
            const delta = 5;
            let startAt = moment.utc().add(delta + 1, 'seconds').valueOf();
            fanoutClient.sendStreamingGetReady(startAt, delta);
        } else if (streaming && getCallState) {
            this.setState({
                check: true
            }, () => {
                fanoutClient.sendStopStreaming(getCallState());
            });
        }
    }

    render() {
        const { runningAnimation, check, disableBtn } = this.state;
        const { streaming, conferenceEnded, showKnocks, showBroadcast, streamingCounter, approvedEntry, eventItem, streamed } = this.props;

        return (
            <li className={classNames("toggle-broadcast", { 'active': streaming }, { 'disable': check || disableBtn || conferenceEnded || !checkApprovedEntry(approvedEntry, eventItem) }, { "menu-animation": runningAnimation })}>
                {showBroadcast &&
                    <div className={classNames("bubble-tip broadcast-menu", { "menuTop": /*showKnocks*/ false }, { "activeKnocks": showKnocks })}>
                        <a
                            className="icon-close"
                            title="Close"
                            onClick={this.handleShowBroadcast}
                        />
                        <p className='text'>End event for audience?</p>
                        <p className='text sm'>(action is not reversible)</p>
                        <div className='menu-flex'>
                            <a
                                className="end-audience"
                                onClick={this.endEventForAudience}
                            >
                                Yes
                            </a>
                            <a
                                className="end-audience"
                                onClick={this.handleShowBroadcast}>
                                No
                            </a>
                        </div>
                        <div className="anchor-popup" />
                    </div>}
                <a
                    data-tip
                    data-for="broadcast"
                    title={streaming ? "Pause stream" : streamingCounter ? "Pause Stream" : streamed ? "Resume Stream" : "Start Stream"}
                    onClick={this.startStreaming}
                >
                    <img src={streaming ? BroadcastActive : streamingCounter ? BroadcastPause : streamed ? BroadcastResume : Broadcast} />
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        streaming: state.room.streaming,
        streamed: state.room.streamed,
        approvedEntry: state.room.approvedEntry,
        streamingCounter: state.room.streamingCounter,
        streamingStatus: state.room.status,
        conferenceEnded: (state.room.conference && (state.room.conference === 'ended_for_audience' || state.room.conference === 'ended')) || (state.room.status && (state.room.status === 'ended_for_audience' || state.room.status === 'ended')),
        showBroadcast: state.room.showBroadcast,
        showKnocks: state.room.showKnocks
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showMenu: (value) => {
            dispatch(roomActions.showBroadcast(value));
        },
        displayMessage: (message, timer, type = null) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer, type: type }));
        }
    };
};

const BroadcastContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BroadcastButtonBottomBar);

export default BroadcastContainer;
