import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from 'classnames';
import ProfileList from "../Header/ProfileList";
import Button from "../Widget/Button";

class LogInBtn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleShowLogIn,
            activeTab,
            auth,
            history
        } = this.props;

        return (
            <div className={classNames('logInBtn', { 'nfl-aa': process.env.build && process.env.build === 'nfl-aa'})}>
                {!auth ?
                    <Button
                        type='text'
                        className='btn-logIn'
                        onClick={() => handleShowLogIn()}
                    >
                        log in
                    </Button>
                    :
                    <ProfileList
                        activeTab={activeTab}
                        history={history}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    };
};

const LogInBtnContainer = connect(
    mapStateToProps,
    null //mapDispatchToProps
)(LogInBtn);

export default LogInBtnContainer;
