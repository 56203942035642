import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Button from '../../Widget/Button';
import ClickOutside from '../../Widget/ClickOutside';
import firebaseClient from '../../../utils/FirebaseClient';
import fanoutClient from '../../../utils/FanoutClient';
import * as roomActions from "../../../actions/room_actions";

class AddGuestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            processing: false,
            error: '',
            tooltipText: ""
        }

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleCloseForm = this.handleCloseForm.bind(this);
        this.buttonRef = React.createRef();
    }

    handleChangeName(e) {
        this.setState({
            name: e.target.value
        })
    }

    handleCreateGuestLink(ref) {
        const { name } = this.state;
        const { eventItem } = this.props;

        if (name && eventItem && eventItem.id) {
            this.setState({
                processing: true
            }, () => {
                fanoutClient.createGuestTicket(name).then((ticketId) => {
                    if (ticketId) {
                        const link = `https://${firebaseClient.getDomain()}/event/${eventItem.id}?ticketId=${ticketId}`;

                        let onCopyOk = () => {
                            console.log('Copy link success', link, ref);
                            if (ref) {
                                this.setState({
                                    tooltipText: "Copied!"
                                }, () => {
                                    ReactTooltip.show(ref);
                                    this.setState({ processing: false, name: '' });
                                    setTimeout(() => {
                                        ReactTooltip.hide(ref);
                                    }, 2000);
                                });
                            }
                        }
                        let onCopyNOk = (e) => {
                            console.error(`Could not write link [${link}] to clipboard: ${e.message}`)
                            if (ref) {
                                this.setState({
                                    tooltipText: "Could not copy"
                                }, () => {
                                    ReactTooltip.show(ref);
                                    this.setState({ processing: false, name: '' });
                                    setTimeout(() => {
                                        ReactTooltip.hide(ref);
                                    }, 2000);
                                });
                            }
                        }

                        navigator.clipboard.writeText(link)
                            .then(onCopyOk)
                            .catch(onCopyNOk);
                    }
                }).catch((e) => {
                    console.error('ERROR', e.message);
                    this.setState({
                        error: "Error copying link",
                        processing: false
                    });
                });
            });
        }
    }

    handleCloseForm() {
        const { processing } = this.state;
        const { showAddGuestForm } = this.props;

        if (showAddGuestForm && !processing) {
            showAddGuestForm(false);
        }
    }

    render() {
        const { name, processing, error, tooltipText } = this.state;

        return (
            <ClickOutside
                className='guest-form-wrapper'
                clickOutside={this.handleCloseForm}
            >
                <p className='guest-form-title'>Add Guest</p>
                <input
                    className='guest-form-input'
                    onChange={this.handleChangeName}
                    disabled={processing}
                    placeholder="Name"
                    value={name}
                    autoFocus
                />
                <div className='guest-buttons'>
                    <div
                        data-tip
                        ref={ref => this.firstTooltip = ref}
                        data-for="guest-form-button"
                        className='button-wrapper'
                    >
                        <Button
                            type="default"
                            className='guest-form-button'
                            onClick={() => this.handleCreateGuestLink(this.firstTooltip)}
                            disabled={processing || ((!name) || (name && name.length < 2))}
                            loading={processing}
                        >
                            Copy guest link
                        </Button>
                        <ReactTooltip
                            id="guest-form-button"
                            isCapture={true}
                            className=""
                            place="top"
                            type="dark"
                            effect="solid"
                            event="none"
                            eventOff="click"
                            delayHide={2000}
                        >
                            {tooltipText}
                        </ReactTooltip>
                    </div>
                    <div
                        className='button-wrapper'
                    >
                        <Button
                            type="border"
                            className='guest-form-button'
                            onClick={this.handleCloseForm}
                            disabled={processing}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                {error && <p className='guest-form-error'>{error}</p>}
            </ClickOutside>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        streaming: state.room.streaming
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showAddGuestForm: (value) => {
            dispatch(roomActions.showAddGuest(value));
        }
    };
};

const AddGuestFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddGuestForm);

export default AddGuestFormContainer;
