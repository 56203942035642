import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from 'classnames';
import { push as nativePush } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import firebaseClient from '../../utils/FirebaseClient';
// import SubscripionPaymentWrapperContainer from '../Payment/SubscriptionPaymentWrapper';
import monetize1 from '../../../resources/images/monetize1.png';
import monetize2 from '../../../resources/images/monetize2.png';
import monetize3 from '../../../resources/images/monetize3.png';
import monetize4 from '../../../resources/images/monetize4.png';
import monetize5 from '../../../resources/images/monetize5.png';
import solution1 from '../../../resources/icons/solution1.svg';
import solution2 from '../../../resources/icons/solution2.svg';
import solution3 from '../../../resources/icons/solution3.svg';
import solution4 from '../../../resources/icons/solution4.svg';
import solution5 from '../../../resources/icons/solution5.svg';
import check from '../../../resources/icons/check-y.svg';
import close from '../../../resources/icons/close-x.svg';
import Button from '../Widget/Button';
import { stringToBoolean, getQueryVariable, logo } from '../../utils/text';
import { strings } from '../../translations/localizedStrings';
import * as appActions from "../../actions/app_actions";
import { APP_STAGE_LOADED } from '../../actions/app_actions';

class MemberSignup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: props.user && props.user.first_name ? props.user.first_name : "",
            last_name: props.user && props.user.last_name ? props.user.last_name : "",
            username: props.user && props.user.username ? props.user.username : "",
            image: props.user ? props.user.profile_picture2 ? props.user.profile_picture2 : props.user.profile_picture ? props.user.profile_picture : "" : "",
            email: props.user && props.user.email ? props.user.email : "",
            phone_number: props.user && props.user.phone_number ? props.user.phone_number : "",
            plan: null,
            formChange: false,
            processing: false,
            processingBtn: false,
            showButtons: false,
            privileges: null,
            loadingBtn: true
        }

        this.buttonRef = React.createRef();
        this.addFileRef = React.createRef();
        this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
        this.handleResetDefaultValue = this.handleResetDefaultValue.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleClickAddFile = this.handleClickAddFile.bind(this);
        this.setHostPrivileges = this.setHostPrivileges.bind(this);
    }

    componentDidMount() {
        const { user, appStage } = this.props;

        if (user) {
            this.loadingPrivileges();
        } else if (appStage && appStage === APP_STAGE_LOADED) {
            this.setState({
                loadingBtn: false
            }, () => {
                let showSignup = stringToBoolean(getQueryVariable('checkout'));

                if (showSignup)
                    this.handleSetPlan('content_creator_package');
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { formChange } = this.state;
        const { user, appStage } = this.props;

        if (prevProps.user !== user && user && !formChange) {
            this.setState({
                first_name: user.first_name || "",
                last_name: user.last_name || "",
                username: user.username || "",
                image: user ? user.profile_picture2 ? user.profile_picture2 : user.profile_picture ? user.profile_picture : "" : "",
                email: user.email || "",
                phone_number: user.phone_number || ""
            }, () => {
                this.loadingPrivileges();
            });

            this.props.showLogIn(false);
        }

        if (appStage && prevProps.appStage !== appStage && appStage === APP_STAGE_LOADED && !user) {
            this.setState({
                loadingBtn: false
            }, () => {
                let showSignup = stringToBoolean(getQueryVariable('checkout'));

                if (showSignup)
                    this.handleSetPlan('content_creator_package');
            });
        }
    }

    loadingPrivileges() {
        firebaseClient.getPrivileges().then((res) => {
            let resVal = res;

            if (!resVal || resVal && !Object.keys(resVal).length) {
                resVal = false;
            }

            this.setState({
                privileges: resVal,
                loadingBtn: false
            }, () => {
                let showSignup = stringToBoolean(getQueryVariable('checkout'));

                if (showSignup)
                    this.handleSetPlan('content_creator_package');
            });
        });
    }

    handleSetPlan(plan) {
        const { user } = this.props;
        if (!user)
            this.props.showLogIn(true);
        this.setState({ plan: 'content_creator_package' });
    }

    handleUpdateProfile() {
        const {
            first_name,
            last_name,
            username,
            image,
            phone_number
        } = this.state;

        const {
            user
        } = this.props;

        let data = {
            first_name: first_name,
            last_name: last_name,
            username: username,
            username_lc: username.toLowerCase(),
            profile_picture2: image,
            phone_number: phone_number
        }

        if (user && user.uid) {
            return firebaseClient.updateUser(user.uid, data).then(() => {
                this.setState({
                    processing: false,
                    showButtons: true
                }, () => {
                    this.successTimeout = setTimeout(() => {
                        this.pushToPage('home');
                    }, 30000);
                });

                return user.uid;
            }).catch((error) => {
                console.error('There was an error when calling the Cloud Function', error);
            });
        }
    }

    pushToPage(path) {
        const { user, pushToHome, pushToEdit, pushToCalendar } = this.props;

        if (user && path) {
            this.setState({ processingBtn: true });
            firebaseClient.getUserData(user).then(() => {
                this.setState({ processingBtn: false });
                switch (path) {
                    case 'home':
                        if (pushToHome) {
                            pushToHome();
                        }
                        break;
                    case 'edit':
                        if (pushToEdit) {
                            pushToEdit();
                        }
                        break;
                    case 'calendar':
                        if (pushToCalendar) {
                            pushToCalendar();
                        }
                        break;
                    default:
                        console.error('error path', path);
                        break;
                }
            });
        }
    }

    handleResetDefaultValue() {
        this.setState({
            plan: null,
            processing: false
        });
    }

    componentWillUnmount() {
        if (this.successTimeout) {
            clearTimeout(this.successTimeout);
            this.successTimeout = null;
        }
    }

    handleClickAddFile() {
        if (this.addFileRef && this.addFileRef.current && this.addFileRef.current.click) {
            this.addFileRef.current.click();
        }
    }

    handleImageChange(event) {
        const { user } = this.props;

        if (event.target.files && event.target.files[0] && user) {
            let img = event.target.files[0];
            let id = `${user.uid}/${uuidv4()}`;
            firebaseClient.addImageToStorage(img, null, id).then((res) => {
                this.setState({
                    image: res,
                    formChange: true
                });
            })
        }
    }

    validatePhoneNumber(number) {
        const re = /^\+[1]\d{10}$/;
        return re.test(String(number).toLowerCase());
    }

    setHostPrivileges() {
        const {
            first_name,
            last_name,
            username,
            image,
            phone_number
        } = this.state;

        const {
            user
        } = this.props;

        let data = {
            first_name: first_name,
            last_name: last_name,
            username: username,
            username_lc: username.toLowerCase(),
            profile_picture2: image,
            phone_number: phone_number
        }

        if (user && user.uid) {
            this.setState({
                processing: true
            }, () => {
                firebaseClient.updateUser(user.uid, data).then(() => {
                    firebaseClient.setHostPrivileges(user.uid).then((res) => {
                        if (res) {
                            this.setState({
                                processing: false,
                                showButtons: true
                            }, () => {
                                this.successTimeout = setTimeout(() => {
                                    this.pushToPage('home');
                                }, 30000);
                            });
                        }
                    });
                }).catch((error) => {
                    console.error('There was an error when calling the Cloud Function', error);
                    this.setState({
                        processing: false
                    });
                });
            });
        }
    }

    render() {

        const {
            first_name,
            last_name,
            username,
            email,
            plan,
            processing,
            showButtons,
            processingBtn,
            loadingBtn,
            privileges,
            image,
            phone_number
        } = this.state;

        const { user, succeeded, error } = this.props;

        return (
            <div>
                {(!plan || !user) && (!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) ?
                    <div>
                        <div className='member-page-wrapper'>
                            <div className='monetize-wrapper'>
                                <div className='monetize-text'>
                                    <p className='monetize-title'>The easiest way <span>to monetize</span></p>
                                    <p className='monetize-p'>
                                        Live streaming events and recorded content
                                    </p>
                                    <div className='two-line-button-wrapper'>
                                        <Button
                                            type='default'
                                            className='btn-monetize two-line'
                                            onClick={() => { this.handleSetPlan('content_creator_package') }}
                                            disabled={loadingBtn}
                                        >
                                            {`Click here to verify your email and create your ${strings.appName} Host account`}
                                        </Button>
                                    </div>
                                </div>
                                <img src={monetize1} loading='lazy' className='monetize-img' />
                            </div>
                        </div>
                        <div className='tips-for-hosts-wrapper'>
                            <p className='tips-top-title'>monetize live</p>
                            <p className='tips-top-txt'>and on Recorded Content</p>
                            <div className='tfh-wrapper four'>
                                {((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile')) &&
                                    <div className='tfh-item'>
                                        <div className='tfh-img-wrapper'>
                                            <p className='tfh-img-text'>💵</p>
                                        </div>
                                        <p className='tfh-title monetize'>create paid<br />subscriptions</p>
                                    </div>}
                                <div className='tfh-item'>
                                    <div className='tfh-img-wrapper'>
                                        <p className='tfh-img-text'>🤑</p>
                                    </div>
                                    <p className='tfh-title monetize'>charge for ticketed<br />events</p>
                                </div>
                                <div className='tfh-item'>
                                    <div className='tfh-img-wrapper'>
                                        <p className='tfh-img-text'>🎲</p>
                                    </div>
                                    <p className='tfh-title monetize'>collect tips live your<br />streams</p>
                                    <p className='tfh-text'>(and in pre-recorded shows)</p>
                                </div>
                                <div className='tfh-item'>
                                    <div className='tfh-img-wrapper'>
                                        <p className='tfh-img-text'>🔴</p>
                                    </div>
                                    <p className='tfh-title monetize'>monetize pre-recorded<br />streams</p>
                                </div>
                            </div>
                        </div>
                        <div className='member-page-wrapper'>
                            <div className='monetize-text-center'>
                                <div className='two-line-button-wrapper'>
                                    <Button
                                        type='default'
                                        className='btn-monetize two-line'
                                        onClick={() => { this.handleSetPlan('content_creator_package') }}
                                        disabled={loadingBtn}
                                    >
                                        {`Click here to verify your email and create your ${strings.appName} Host account`}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='tips-for-hosts-wrapper'>
                            <p className='tips-top-title monetize'>everything you need</p>
                            <p className='tips-top-txt'>To  Schedule, Market, and Stream Events</p>
                            <div className='monetize-page-img-wrapper'>
                                <div className='monetize-text-wrapper left'>
                                    <div className='monetize-text-item'>
                                        <div className='monetize-img-wrapper'>
                                            <p>📆</p>
                                        </div>
                                        <p className='monetize-text-p'>Easily Schedule Your Events in minutes</p>
                                    </div>
                                    <div className='monetize-text-item'>
                                        <div className='monetize-img-wrapper'>
                                            <p>✉️</p>
                                        </div>
                                        <p className='monetize-text-p'>Send Confirmation Emails to Viewers</p>
                                    </div>
                                    <div className='monetize-text-item'>
                                        <div className='monetize-img-wrapper'>
                                            <p>🔧</p>
                                        </div>
                                        <p className='monetize-text-p'>Custom Event Registration Pages</p>
                                    </div>
                                    <div className='monetize-text-item'>
                                        <div className='monetize-img-wrapper'>
                                            <p>⤴️</p>
                                        </div>
                                        <p className='monetize-text-p'>Easy Share Links for Events</p>
                                    </div>
                                    <div className='two-line-button-wrapper'>
                                        <Button
                                            type='default'
                                            className='btn-monetize two-line'
                                            onClick={() => { this.handleSetPlan('content_creator_package') }}
                                            disabled={loadingBtn}
                                        >
                                            {`Click here to verify your email and create your ${strings.appName} Host account`}
                                        </Button>
                                    </div>
                                </div>
                                <img src={monetize2} loading='lazy' />
                            </div>
                        </div>
                        <div className='tips-for-hosts-wrapper'>
                            <p className='tips-top-title monetize'>unparalleled streaming</p>
                            <p className='tips-top-txt'>The highest quality interactive experience</p>
                            <div className='monetize-page-img-wrapper'>
                                <img src={monetize3} loading='lazy' />
                                <div className='monetize-text-wrapper right'>
                                    <div className='monetize-text-item'>
                                        <div className='monetize-img-wrapper'>
                                            <p>⏳</p>
                                        </div>
                                        <p className='monetize-text-p'>ZERO DELAY video between host and viewer</p>
                                    </div>
                                    <div className='monetize-text-item'>
                                        <div className='monetize-img-wrapper'>
                                            <p>🎙</p>
                                        </div>
                                        <p className='monetize-text-p'>Professional Video and Audio Mixingfor up to 180 minutes per stream</p>
                                    </div>
                                    <div className='monetize-text-item'>
                                        <div className='monetize-img-wrapper'>
                                            <p>🙂</p>
                                        </div>
                                        <p className='monetize-text-p'>Viewers can KNOCK to join the stream directly on video</p>
                                    </div>
                                    <div className='monetize-text-item'>
                                        <div className='monetize-img-wrapper'>
                                            <p>😎️</p>
                                        </div>
                                        <p className='monetize-text-p'>Easily Add CoHosts to Events</p>
                                    </div>
                                    <div className='monetize-text-item'>
                                        <div className='monetize-img-wrapper'>
                                            <p>🤠️</p>
                                        </div>
                                        <p className='monetize-text-p'>Up to 24 on-screen participants (and up to 50,000 viewers)</p>
                                    </div>
                                    <div className='two-line-button-wrapper'>
                                        <Button
                                            type='default'
                                            className='btn-monetize two-line'
                                            onClick={() => { this.handleSetPlan('content_creator_package') }}
                                            disabled={loadingBtn}
                                        >
                                            {`Click here to verify your email and create your ${strings.appName} Host account`}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile')) &&
                            <div className='tips-for-hosts-wrapper blackBg'>
                                <p className='tips-top-title monetize'>a solution to using many apps</p>
                                <p className='tips-top-txt'>Have everything in one place for less money</p>
                                <div className='solution-wrapper'>
                                    <div className='solution-item-wrapper'>
                                        <div className='solution-item-1'>
                                            <div className='solution-img-wrapper'>
                                                <img src={solution1} loading='lazy' />
                                            </div>
                                            <p className='solution-text'>
                                                Patreon Pro Account: takes 8% + Processing Fees
                                            <br />
                                                {`(${strings.appName} takes 7% + Processing Fees)`}
                                        </p>
                                        </div>
                                        <div className='solution-item-2'>
                                            <img src={close} loading='lazy' />
                                            <p className='solution-text'>No creator/streaming tools</p>
                                        </div>
                                    </div>
                                    <div className='solution-item-wrapper'>
                                        <div className='solution-item-1'>
                                            <div className='solution-img-wrapper'>
                                                <img src={solution2} loading='lazy' />
                                            </div>
                                            <p className='solution-text'>
                                                StreamYard costs $49/month for custom streams and recordings... without any event scheduling, subscriptions, and limited to 10 onscreen participants
                                        </p>
                                        </div>
                                        <div className='solution-item-2'>
                                            <img src={close} loading='lazy' />
                                            <p className='solution-text'>Limited features for scheduling and monetization</p>
                                        </div>
                                    </div>
                                    <div className='solution-item-wrapper'>
                                        <div className='solution-item-1'>
                                            <div className='solution-img-wrapper'>
                                                <img src={solution3} loading='lazy' />
                                            </div>
                                            <p className='solution-text'>
                                                GoToWebinar costs $134/month for just their starter option for digital events and email reminders...
                                        </p>
                                        </div>
                                        <div className='solution-item-2'>
                                            <img src={close} loading='lazy' />
                                            <p className='solution-text'>Only to 100 participants<br />Only webinars/events</p>
                                        </div>
                                    </div>
                                    <div className='solution-item-wrapper'>
                                        <div className='solution-item-1'>
                                            <div className='solution-img-wrapper'>
                                                <img src={solution4} loading='lazy' />
                                            </div>
                                            <p className='solution-text'>
                                                Vimeo charges over $900/year up front for their premium event  hosting – and you have to contact a sales team if you want to create a paid subscription channel
                                        </p>
                                        </div>
                                        <div className='solution-item-2'>
                                            <img src={close} loading='lazy' />
                                            <p className='solution-text'>Extremely pricy; more tailored for corporations than for content creators</p>
                                        </div>
                                    </div>
                                    <div className='solution-item-wrapper'>
                                        <div className='solution-item-1'>
                                            <div className='solution-img-wrapper'>
                                                <img src={solution5} loading='lazy' />
                                            </div>
                                            <p className='solution-text'>
                                                Digital event licenses on Zoom start at $79/month for only 500 attendees... and then scales to over $2k/month for up to 5000 attendees!!!
                                        </p>
                                        </div>
                                        <div className='solution-item-2'>
                                            <img src={close} loading='lazy' />
                                            <p className='solution-text'>Price scales too high for more viewers;<br />can’t create a channel or subscriptions</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='tips-for-hosts-wrapper blackBg'>
                            <p className='tips-top-title monetize'>{`${strings.appName}`}</p>
                            <div className='member-nfb'>
                                <div className='member-nfb-item-1'>
                                    {/* {((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile')) &&
                                        <p className='member-nfb-title'>
                                            {${strings.appName} is only $${price}/month + 7% commision* for:`}
                                        </p>
                                    }
                                    {((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile')) &&
                                        <p className='member-nfb-txt'>
                                            *and Stripe processing free
                                        </p>
                                    } */}
                                    <div className='two-line-button-wrapper'>
                                        <Button
                                            type='default'
                                            className='btn-monetize two-line'
                                            onClick={() => { this.handleSetPlan('content_creator_package') }}
                                            disabled={loadingBtn}
                                        >
                                            {`Click here to verify your email and create your ${strings.appName} Host account`}
                                        </Button>
                                    </div>
                                    <div className='member-nfb-img-wrapper'>
                                        <img src={logo} loading='lazy' />
                                    </div>
                                </div>
                                <div className='member-nfb-item-2'>
                                    <div className='member-check-item'>
                                        <img src={check} loading='lazy' />
                                        <p className='member-text'>Free or paid ticketed digital events</p>
                                    </div>
                                    <div className='member-check-item'>
                                        <img src={check} loading='lazy' />
                                        <p className='member-text'>Up to 24 on-screen stream guests, and 50,000 live viewers</p>
                                    </div>
                                    <div className='member-check-item'>
                                        <img src={check} loading='lazy' />
                                        <p className='member-text'>{`Collect tips ${((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile')) ? 'and create paid subscriptions on ' : ''}a custom channel`}</p>
                                    </div>
                                    <div className='member-check-item'>
                                        <img src={check} loading='lazy' />
                                        <p className='member-text'>Record, archive, and download all your streams for easy redistribution</p>
                                    </div>
                                    <div className='member-check-item'>
                                        <img src={check} loading='lazy' />
                                        <p className='member-text'>No limit to the number or streams or events you put on</p>
                                    </div>
                                    <div className='member-check-item'>
                                        <img src={check} loading='lazy' />
                                        <p className='member-text'>Email and SMS reminders to viewers for your events </p>
                                    </div>
                                    <div className='member-check-item'>
                                        <img src={check} loading='lazy' />
                                        <p className='member-text'>No scaling cost per viewer, with up to 50,000 viewers per stream</p>
                                    </div>
                                    <div className='member-check-item'>
                                        <img src={check} loading='lazy' />
                                        <p className='member-text'>Zero delay regardless of how many stream attendees</p>
                                    </div>
                                    <div className='member-check-item'>
                                        <img src={check} loading='lazy' />
                                        <p className='member-text'>Schedule, host, stream, and redistribute all in the same place for a fraction of the costs of other apps</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='tips-for-hosts-wrapper'>
                            <p className='tips-top-title monetize'>designed for content creators</p>
                            <p className='tips-top-txt'>Ready to monetize with live streaming</p>
                            <div className='designer-wrapper'>
                                <div className='designer-img-wrapper'>
                                    <div className='designer-img-div des-1'>
                                        <p className='designer-p'>💻</p>
                                    </div>
                                    <div className='designer-img-div des-2'>
                                        <p className='designer-p'>✉️</p>
                                    </div>
                                    <div className='designer-img-div des-3'>
                                        <p className='designer-p'>🎧</p>
                                    </div>
                                    <div className='designer-img-div des-4'>
                                        <p className='designer-p'>💬</p>
                                    </div>
                                    <img src={monetize4} loading='lazy' />
                                </div>
                                <div className='designer-text'>
                                    <p className='designer-text-line'>💰Paid events</p>
                                    {((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile')) && <p className='designer-text-line'>💰Subscriptions</p>}
                                    <p className='designer-text-line'>💰Tips from viewers</p>
                                    <p className='designer-text-line'>💰Sell pre-recorded content</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='member-page-wrapper'>
                            <div className='member-list-wrapper'>
                                <div className='member-list'>
                                    <div className='list-item'>Podcasters</div>
                                    <div className='list-item'>coaches</div>
                                    <div className='list-item'>workshop hosts</div>
                                    <div className='list-item'>religious leaders</div>
                                    <div className='list-item'>streamers</div>
                                    <div className='list-item'>chefs</div>
                                    <div className='list-item'>comedians</div>
                                    <div className='list-item'>sport broadcasters</div>
                                    <div className='list-item'>fitness & yoga teachers</div>
                                    <div className='list-item'>Podcasters</div>
                                    <div className='list-item'>coaches</div>
                                </div>
                            </div>
                        </div> */}
                        <div className='member-page-wrapper'>
                            <div className='member-ad-wrapper'>
                                <div className='member-text'>
                                    <p className='member-title'>host your <span>own show</span> today</p>
                                    {((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile')) &&
                                        <p className='member-text-upper'>The Content Creator Package</p>
                                    }
                                    <p className='member-text-p'>
                                        ** Currently Available to Residents of USA and Canada **
                                    </p>
                                    <div className='two-line-button-wrapper'>
                                        <Button
                                            type='default'
                                            className='btn-monetize two-line'
                                            onClick={() => { this.handleSetPlan('content_creator_package') }}
                                            disabled={loadingBtn}
                                        >
                                            {`Click here to verify your email and create your ${strings.appName} Host account`}
                                        </Button>
                                    </div>
                                    {/* <span className='span-text'>** Currently Available to Residents of USA and Canada **</span> */}
                                </div>
                                <img src={monetize5} loading='lazy' />
                            </div>
                        </div>
                    </div>
                    : showButtons && (!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) ?
                        <div className='form-wrapper'>
                            <div className='signup-div-window sm'>
                                <div className='form-div'>
                                    <div className='signup-info-line'>
                                        <div className='signup-info-title'>Congratulations</div>
                                        {plan && <div className="signup-info-text">
                                            {`You are now a host on ${strings.fullName}!`}
                                            <br />
                                            <br />
                                            To schedule your first LIVE stream, click on your account logo and select Event Scheduler.
                                        </div>}
                                        <div className='form-submit'>
                                            <Button
                                                type='default'
                                                className='btn-member-success'
                                                disabled={processingBtn}
                                                loading={processingBtn}
                                                onClick={() => this.pushToPage('edit')}
                                            >
                                                Ok
                                        </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={classNames('subscription-form-wrapper', { 'header-taller': process.env.build && process.env.build === 'nfl-aa' })}>
                            <div className='subscription-form'>
                                {!succeeded && !error ?
                                    <div>
                                        <p className='subscription-title-p createAccount'>Create Your Host Account:</p>
                                        <div className='subscription-input-wrapper'>
                                            <div className='subscription-edit-picture'>
                                                <img src={image} className='change-avatar-img' loading='lazy' />
                                                <input
                                                    ref={this.addFileRef}
                                                    className='add-file'
                                                    type="file"
                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                    disabled={processing}
                                                    onChange={this.handleImageChange}
                                                />
                                                <Button type='text-white' className='btn-edit-change-img' disabled={processing} onClick={this.handleClickAddFile}>Change picture</Button>
                                            </div>
                                        </div>
                                        <div className='subscription-input-wrapper'>
                                            <p>First Name *</p>
                                            <input
                                                type="text"
                                                placeholder="First Name"
                                                value={first_name}
                                                onChange={(e) =>
                                                    this.setState({
                                                        first_name: e.target.value,
                                                        formChange: true
                                                    })
                                                }
                                                disabled={processing}
                                            />
                                        </div>
                                        <div className='subscription-input-wrapper'>
                                            <p>Last Name *</p>
                                            <input
                                                type="text"
                                                placeholder="Last Name"
                                                value={last_name}
                                                onChange={(e) =>
                                                    this.setState({
                                                        last_name: e.target.value,
                                                        formChange: true
                                                    })
                                                }
                                                disabled={processing}
                                            />
                                        </div>
                                        <div className='subscription-input-wrapper'>
                                            <p>Username / Alias *</p>
                                            <input
                                                type="text"
                                                placeholder="Username / Alias"
                                                value={username}
                                                onChange={(e) =>
                                                    this.setState({
                                                        username: e.target.value,
                                                        formChange: true
                                                    })
                                                }
                                                disabled={processing}
                                            />
                                        </div>
                                        <div className='subscription-input-wrapper'>
                                            <p>Email *</p>
                                            <input
                                                type="text"
                                                placeholder="Email"
                                                value={email}
                                                readOnly
                                                disabled
                                            />
                                        </div>
                                        <div className='subscription-input-wrapper'>
                                            <p>Phone Number (+1, followed by 10 digits) *</p>
                                            <input
                                                type="text"
                                                placeholder="Phone Number"
                                                value={phone_number}
                                                onChange={(e) =>
                                                    this.setState({
                                                        phone_number: e.target.value,
                                                        formChange: true
                                                    })
                                                }
                                                disabled={processing}
                                            />
                                        </div>
                                        <Button
                                            type='default'
                                            className='btn-subscription-btn no-transform'
                                            onClick={this.setHostPrivileges}
                                            disabled={!first_name || !last_name || !username || !phone_number || (phone_number && !this.validatePhoneNumber(phone_number)) || processing}
                                            loading={processing}
                                        >
                                            Become a host
                                        </Button>
                                    </div>
                                    : null}
                                {/* {user && (!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) ?
                                    <SubscripionPaymentWrapperContainer
                                        subscriptionType="hosting"
                                        subscriptionPlan={plan}
                                        resetValue={this.handleResetDefaultValue}
                                        successFunction={() => this.pushToPage('edit')}
                                        item={user}
                                        privileges={privileges}
                                        disabledBtn={!first_name || !last_name || !username}
                                        updateProfile={this.handleUpdateProfile}
                                    />
                                    : null} */}
                            </div>
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appStage: state.app.stage,
        user: state.firebase.user,
        categories: state.firebase.categories,
        calendarList: state.firebase.calendarList,
        succeeded: state.payment.state === "PAYMENT_STATE_SUCCESS",
        error: state.payment.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushToEdit: () => {
            dispatch(nativePush('/edit-profile'));
        },
        pushToCalendar: () => {
            dispatch(nativePush('/calendar'));
        },
        pushToHome: () => {
            dispatch(nativePush('/'));
        },
        showLogIn: (value) => {
            dispatch(appActions.showLogIn(value, true));
        },
    };
};

const MemberSignupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberSignup);

export default MemberSignupContainer;
