import React, { Component } from "react";
import cameraIcon from "../../../../resources/assets/Camera.svg";
import activeCameraIcon from "../../../../resources/assets/Camera-Active.svg";

class InboundVideoButtonBottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inboundVideo: true
        }

        this.handleInboundVideo = this.handleInboundVideo.bind(this);
    }

    handleInboundVideo() {
        const { inboundVideo } = this.state;

        this.setState({
            inboundVideo: !inboundVideo
        },() => {
            const { muteRemoteVideosForMe } = this.props;

            if(muteRemoteVideosForMe) {
                muteRemoteVideosForMe(inboundVideo);
            }
        });
    }

    render() {
        const { inboundVideo } = this.state;

        return (
            <li>
                <a
                    data-tip
                    data-for="toggle-video"
                    title="Inbound Video"
                    onClick={() => this.handleInboundVideo()}
                >
                    <img src={inboundVideo ? activeCameraIcon : cameraIcon} alt='inbound' />
                    <div>
                        <span>Inbound Video</span>
                    </div>
                </a>
            </li>
        );
    }
}

export default InboundVideoButtonBottomBar;