import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import classNames from "classnames";
import Cards from './Widget/Cards';
import firebaseClient from './../utils/FirebaseClient';
import Button from './Widget/Button';
import rssIcon from '../../resources/assets/rss-icon.svg';
import { validShowVaultEvent } from '../utils/text';

class MyStuff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav: 'public'
        }
    }

    componentDidMount() {
        firebaseClient.refreshData();
    }

    renderVideos(videos, type = null) {
        const { nav } = this.state;

        if (videos && videos.length && nav) {
            let videoArray = videos.filter((item) => {
                if (item && item.visibility && item.visibility === nav && ((!type) || (type && type === 'vault' && item.archiveEnabled))) {
                    return item;
                }
            });
            return videoArray;
        } else if (videos) {
            return [];
        } else return null;
    }

    render() {
        const { nav } = this.state;
        const { user, mystuff, broadcasterUser, videos } = this.props;

        let avatar = user ?
            (user.profile_picture2 ?
                user.profile_picture2 :
                (user.profile_picture ? user.profile_picture : null)) :
            null;

        let name = user ?
            (user.username ?
                user.username
                : (`${user.first_name} ${user.last_name}`))
            : null;

        let uvideos = videos && videos.filter ? videos.filter((item) => {
            if (validShowVaultEvent(item, user)) {
                return item;
            }
        }) : null;

        return (
            <div>
                <div className='profile-div-wrapper'>
                    <div className='profile-div-details my-stuff'>
                        <div className='profile-left'>
                            <img src={avatar} className='profile-img' loading='lazy' />
                            <div className='profile-username my-stuff'>
                                <p>
                                    {name}
                                    {user && ((user.ambassador) || (user.premium) || (user.administrator)) ?
                                        <a href={`https://${firebaseClient.getDomain()}/mrss/author/${user && user.uid ? user.uid : ''}`} target="_blank">
                                            <Button type='text' className='btn-social-icon rss'>
                                                <img title='RSS' src={rssIcon} />
                                            </Button>
                                        </a>
                                        : null
                                    }
                                </p>
                            </div>
                        </div>
                        <div className='profile-right'>
                            {broadcasterUser ?
                                <div className='profile-buttons'>
                                    <Link to='/calendar'>
                                        <Button type="default" className='btn-subscribe'>
                                            schedule new stream
                                    </Button>
                                    </Link>
                                    <Link to={`/profile/${user && user.uid ? user.uid : null}`}>
                                        <Button type="border" className='btn-subscribe'>
                                            view my public page
                                    </Button>
                                    </Link>
                                </div> : null
                            }
                        </div>
                    </div>
                    <div className='profile-nav-wrapper'>
                        <p className='profile-nav-title'>my STUFF</p>
                        <div className='profile-nav'>
                            <Button type='text-white' className={classNames('btn-profile-nav', { 'active': nav === 'public' })} onClick={() => this.setState({ nav: 'public' })}><span className='profile-nav-span'>public</span></Button>
                            <Button type='text-white' className={classNames('btn-profile-nav', { 'active': nav === 'private' })} onClick={() => this.setState({ nav: 'private' })}><span className='profile-nav-span'>private</span></Button>
                        </div>
                    </div>
                </div>
                <Cards
                    title='upcoming streams'
                    videos={this.renderVideos(mystuff && mystuff.hostingEvents ? mystuff.hostingEvents : null)}
                />
                <Cards
                    title={`${name}'s Vault`}
                    videos={this.renderVideos(uvideos ? uvideos : null, 'vault')}
                />
                <Cards
                    title='past streams'
                    videos={this.renderVideos(mystuff && mystuff.hostedEvents ? mystuff.hostedEvents : null)}
                />
                <Cards
                    title='tickets'
                    videos={this.renderVideos(mystuff && mystuff.upcomingEvents ? mystuff.upcomingEvents : null)}
                />
                <Cards
                    title='live streams'
                    videos={this.renderVideos(mystuff && mystuff.live ? mystuff.live : null)}
                />
                <Cards
                    title='past purchased events'
                    videos={this.renderVideos(mystuff && mystuff.pastEvents ? mystuff.pastEvents : null)}
                />
                {nav && nav === 'public' ?
                    <Cards
                        title='followed streams'
                        videos={this.renderVideos(mystuff && mystuff.followingEvents ? mystuff.followingEvents : null)}
                    />
                    : null
                }
                {nav && nav === 'public' ?
                    <Cards
                        title='my subscriptions'
                        videos={mystuff && mystuff.subscribedToHosts ? mystuff.subscribedToHosts : null}
                        hosts={true}
                    />
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mystuff: state.mystuff,
        user: state.firebase.user,
        broadcasterUser: state.firebase.roles && state.firebase.roles.broadcaster ? true : false,
        videos: state.firebase.videos,
    };
};

const MyStuffContainer = connect(
    mapStateToProps,
    null
)(MyStuff);

export default MyStuffContainer;
