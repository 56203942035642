import React, { Component } from 'react';

class FloatingEmoji extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emojis: <div />
        }
    }

    componentDidMount() {
        this.setState({
            emojis: this.renderEmojis()
        });
    }

    renderEmojis() {
        const { emoji } = this.props;
        let emojiItems = [];
        for (var i = 1; i <= 15; i++) {
            emojiItems.push(emoji);
        }
        return emojiItems.map((item, index) => {
            return (
                <span key={index} style={{ left: `${Math.floor(Math.random() * (80 - 20 + 1)) + 20}%`}} className={`emoji x${Math.floor(Math.random() * (3 - 1 + 1)) + 1}`}>{item}</span>
            )
        });
    }
    
    render() {
        const { emojis } = this.state;
        return (
            <div className='emoji-wrapper'>
                {emojis}
            </div>
        )
    }
}

export default FloatingEmoji;