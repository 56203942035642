import React, { Component } from 'react';
import playIcon from '../../../resources/assets/play-nfb.svg';

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        }

        this.videoRef = React.createRef();
        this.playVideo = this.playVideo.bind(this);
    }

    componentDidMount() {
        if (this.videoRef && this.videoRef.current) {
            this.videoRef.current.addEventListener('play', () => {
                this.setState({
                    active: true
                });
            });

            this.videoRef.current.addEventListener('pause', () => {
                this.setState({
                    active: false
                });
            });

            this.videoRef.current.addEventListener('ended', () => {
                this.setState({
                    active: false
                });
            });
        }
    }

    componentWillUnmount() {
        if (this.videoRef && this.videoRef.current) {

            if (this.videoRef.current.currentTime > 0 && !this.videoRef.current.paused && !this.videoRef.current.ended && this.videoRef.current.readyState > 2) {
                this.videoRef.current.pause();
                this.videoRef.current.removeAttribute('src'); // empty source
                this.videoRef.current.load();
            }

            this.videoRef.current.removeEventListener('play', () => {
                // console.log('Remove video event: play');
            });

            this.videoRef.current.removeEventListener('pause', () => {
                // console.log('Remove video event: pause');
            });

            this.videoRef.current.removeEventListener('ended', () => {
                // console.log('Remove video event: ended');
            });
        }
    }

    playVideo() {
        if (this.videoRef && this.videoRef.current && this.videoRef.current.play && !(this.videoRef.current.currentTime > 0 && !this.videoRef.current.paused && !this.videoRef.current.ended && this.videoRef.current.readyState > 2)) {
            // console.log('play');
            this.videoRef.current.play();
        } else if (this.videoRef && this.videoRef.current && this.videoRef.current.pause) {
            // console.log('pause');
            this.videoRef.current.pause();
        }
    }

    render() {
        const {
            active
        } = this.state;
        const {
            id,
            className,
            src,
            poster,
            controls,
            playsInline
        } = this.props;

        return (
            <div className='video-wrap'>
                <video
                    ref={this.videoRef}
                    onClick={this.playVideo}
                    id={id}
                    className={className}
                    src={src}
                    poster={poster}
                    controls={controls ? true : false}
                    playsInline={playsInline ? true : false}
                />
                {!active && <img src={playIcon} onClick={this.playVideo} className='video-play-icon' loading='lazy' />}
            </div>
        )
    }
}

export default Video;