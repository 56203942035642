import React, { Component } from 'react';

class ClickOutside extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        const { clickOutside } = this.props;

        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && clickOutside) {
            clickOutside();
        }
    }

    render() {
        const { children, className } = this.props;

        return (
            <div
                className={className}
                ref={this.setWrapperRef}
            >
                {children}
            </div>
        )
    }
}

export default ClickOutside;