import Cookies from "js-cookie";

var expireTime = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
const default_cookies_param = {
    path: "/",
    expires: expireTime,
    secure: true,
    sameSite: 'none',
};
let api, hasDefaults = false;

hasDefaults = Cookies.withAttributes;
api = hasDefaults ? Cookies.withAttributes(default_cookies_param) : Cookies;

class Storage {

    get(key) {
        return api.get(key);
    }

    remove(key) {
        return api.remove(key);
    }

    set(key, value, ignore) {
        if (hasDefaults)
            return api.set(key, value);
        else
            return api.set(key, value, ignore ? ignore : default_cookies_param);
    }

    getDevice(key) {
        const str = this.get(key);
        if (str) {
            try {
                return JSON.parse(str);
            } catch (e) {
                return null;
            }
        }
        return null;
    }

    setDevice(key, value, ignore) {
        this.set(key, JSON.stringify(value, ignore));
    }
}

export default new Storage();