import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Footer from '../Home/Footer';
import menu from '../../../resources/assets/menu.svg';
import gridIcon from "../../../resources/assets/grid.png";
import homeIcon from "../../../resources/assets/home.png";
import starIcon from "../../../resources/assets/star.png";
import usersIcon from "../../../resources/assets/users.png";
import {
    sideBar,
    signupCampaignPageLink,
    dudaLink,
    homeLink,
    showBecomeAHost
} from '../../utils/text';
import * as firebaseActions from "../../actions/firebase_actions";
import * as appActions from "../../actions/app_actions";

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            browseDiv: true,
            list: false
        };

        this.hideNavBar = this.hideNavBar.bind(this);
    }


    handleShowMobileMenu(value) {
        const { showSideBar, sideBar } = this.props;

        if (showSideBar && value !== sideBar) {
            showSideBar(!sideBar);
        }
    }

    hideNavBar() {
        const { showSideBar } = this.props;

        if (((window.innerWidth && window.innerWidth <= sideBar.overlayMaxWidth) || (process.env.platform && process.env.platform === 'mobile')) && showSideBar) {
            showSideBar(false);
        }
    }

    checkBrowseLength(nav) {
        const { events, videos } = this.props;

        let allItems = [];

        if (events && events.upcoming && videos) {
            allItems = events.upcoming.concat(videos)
        }

        let navValue = nav;

        if (navValue && navValue === 'food') {
            navValue = 'food&drink';
        }

        let filterArray = navValue && (navValue !== 'all' && navValue !== 'upcoming-streams' && navValue !== 'live') && allItems && allItems.length ? allItems.filter(item => item.cat && item.cat.toLowerCase() == navValue && navValue.toLowerCase()) : null;

        let array = filterArray;

        if (!array || (array && !array.length))
            return false;

        let newArray = [];


        if (array && array.length) {
            array.map((item) => {
                if (item && item.visibility && item.visibility === "public" && newArray.length < 50) {
                    newArray.push(item);
                    return item;
                }
            });
        }

        return newArray && newArray.length;
    }

    render() {
        const { browseDiv } = this.state;
        const { activeTab, auth, roles, categories, sideBar } = this.props;

        return (
            <div className='header-menu'>
                <img
                    src={menu}
                    className='menu'
                    loading='lazy'
                    onClick={() => this.handleShowMobileMenu(!sideBar)}
                />
                <div className={classNames('nav-bar', { 'sidebar': sideBar }, { 'header-taller': process.env.build && process.env.build === 'nfl-aa' })}>
                    <div className='bar-items header-btns'>
                        <Link
                            to='/search'
                            className={classNames('bar-item', { 'active': activeTab === '/search' })}
                            onClick={this.hideNavBar}
                        >
                            search
                        </Link>
                        {showBecomeAHost && (!auth || (roles && (!roles.broadcaster && !roles.administrator))) ?
                            <a
                                href={signupCampaignPageLink}
                                className={classNames('bar-item', { 'active': activeTab === '/signup' })}
                                onClick={this.hideNavBar}
                            >
                                become a host
                            </a> : null
                        }
                        {auth && roles && roles.broadcaster && activeTab && activeTab === '/calendar' &&
                            <a
                                href={`${dudaLink}/schedule-an-event`}
                                className='bar-item'
                            >
                                How To Create Event
                            </a>
                        }
                    </div>
                    <div className='bar-items'>
                        {process.env.build && process.env.build === 'nfl-aa' && homeLink ?
                            <a
                                href={homeLink}
                                className='bar-item'
                            >
                                <img src={homeIcon} loading='lazy' />
                                Home
                            </a>
                            :
                            <Link
                                to='/'
                                className={classNames('bar-item', { 'active': activeTab === '/' })}
                                onClick={this.hideNavBar}
                            >
                                <img src={homeIcon} loading='lazy' />
                                Home
                            </Link>
                        }
                        {process.env.build && process.env.build === 'nfl-aa' ?
                            <a
                                href={`${dudaLink}/raw-soundbites`}
                                className='bar-item'
                                onClick={this.hideNavBar}
                            >
                                <img src={starIcon} loading='lazy' />
                                Soundbites
                            </a>
                            : null
                        }
                        <Link
                            to='/browse?b=all'
                            className={classNames('bar-item', { 'active': activeTab === '/browse' })}
                            onClick={this.hideNavBar}
                        >
                            <img src={gridIcon} loading='lazy' />
                            Browse
                        </Link>
                        {browseDiv && categories && categories.length ?
                            <div>
                                {categories.sort((a, b) => {
                                    if (a.priority < b.priority) {
                                        return -1;
                                    }
                                    if (a.priority > b.priority) {
                                        return 1;
                                    }
                                    return 0;
                                }).map((category) => {
                                    let id = category && category.id && category.id.toLowerCase ? category.id.toLowerCase().trim() : null;
                                    id = id && id === 'food&drink' ? 'food' : id;
                                    if (this.checkBrowseLength(id)) {
                                        return (
                                            <Link
                                                to={`/browse?b=${id}`}
                                                key={category.id}
                                                onClick={this.hideNavBar}
                                            >
                                                <div className='bar-item browse'>{category.title}</div>
                                            </Link>
                                        );
                                    }
                                })}
                            </div>
                            : null}
                        <Link
                            to='/hosts'
                            className={classNames('bar-item', { 'active': activeTab === '/hosts' })}
                            onClick={this.hideNavBar}
                        >
                            <img src={usersIcon} loading='lazy' />
                            The Hosts
                        </Link>
                        {/* <Link
                            to='/features'
                            className={classNames('bar-item', { 'active': activeTab === '/features' })}
                            onClick={this.hideNavBar}
                        >
                            <img src={starIcon} loading='lazy' />
                            Features
                        </Link> */}
                    </div>
                    <Footer
                        hideNav={this.hideNavBar}
                    />
                </div >
                <div
                    className={classNames('nav-bar-overlay', { 'sidebar': sideBar })}
                    onClick={() => this.handleShowMobileMenu(false)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        auth: state.firebase.auth,
        roles: state.firebase.roles,
        sideBar: state.app.sideBar,
        categories: state.firebase.categories,
        events: state.firebase.events,
        videos: state.firebase.videos,
        mobile: state.app.user_agent_info.platform.type === 'mobile',
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logOut: () => {
            dispatch(firebaseActions.logOut());
        },
        showSideBar: (value) => {
            dispatch(appActions.showSideBar(value))
        }
    };
};

const NavBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBar);

export default NavBarContainer;