import React, { useEffect } from 'react';
import Loader from "../Widget/Loader";

function NotFound() {

    useEffect(() => {
        window.location.href = process.env.domain;
    });

    return <Loader />;
}

export default NotFound;