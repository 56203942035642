import {
    UPDATE_BANNED_LIST_ITEM_ACTION,
    UPDATE_BANNED_LIST_ACTION,
    CLEAR_BANNED_LIST_ACTION
} from '../actions/banned_actions';

const initialState = {};

const bannedUsers = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_BANNED_LIST_ITEM_ACTION: {
            if(action.payload) {
                const next = { ...state };
                
                if(action.payload.banned && action.payload.user && action.payload.user.user_id) {
                    next[action.payload.user.user_id] = action.payload.user;
                }
                else if(!action.payload.banned && action.payload.user && action.payload.user.user_id) {
                    delete next[action.payload.user.user_id];
                }

                return next;
            }
            else {
                return Object.assign({}, initialState);
            }
        }
        case UPDATE_BANNED_LIST_ACTION: {
            if(action.payload) {
                // Remove it
                const next = { ...state };
                for (const [key, value] of Object.entries(action.payload)) {
                    // console.log(`${key}: ${value}`);
                    if(value)
                        next[key] = value;
                    else
                        delete next[key];
                }

                return next;
            }
            return state;
        }
        case CLEAR_BANNED_LIST_ACTION: {
            return Object.assign({}, initialState);
        }
        default:
            return state;
    }
};

export default bannedUsers;