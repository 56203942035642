import React from "react";
import { connect as reduxConect } from 'react-redux';

let context = null;
let store = null;

// export default React.createContext();
export function getAppContext() {
    if(!context)
        context = React.createContext();
    return context;
}
// export default React.createContext();
export function getAppStore() {
    return store;
}
// export default React.createContext();
export function setAppStore(appstore) {
    if(appstore)
        store = appstore;
    return store;
}

export function connect(
    mapStateToProps=null,
    mapDispatchToProps=null) {
    return reduxConect(
        mapStateToProps,
        mapDispatchToProps,
        null,
        {context:getAppContext()});
}