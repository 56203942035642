import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import moment from 'moment';
import fanoutClient from "../../../utils/FanoutClient";
import { Defines } from '../../../utils/FanoutDefines';
import {
    checkApprovedEntry
} from '../../../utils/text';
import Preview from '../../Widget/Preview';
import zoomIn from '../../../../resources/assets/mock-zoom-in.png';
import zoomOut from '../../../../resources/assets/mock-zoom-out.png';
import speaker from '../../../../resources/assets/mock-speaker.png';
import adParticipants from '../../../../resources/assets/mock-ad+participant.png';
import linkIcon from '../../../../resources/assets/link5.svg';
import CallManager from "../../../utils/CallManager";
import { parseSpeakerBanner } from '../../../utils/text';
import * as roomActions from "../../../actions/room_actions";

class CustomSettings extends Component {

    #callManager = null;

    constructor(props) {
        super(props);
        this.state = {
            prevLayout: null,
            layout: Defines.RecordingLayout.NoCrop,
            prevLabels: null,
            labels: Defines.Labels.Default,
            processing: false,
            errorMessage: '',
            previewImage: null,
            previewTitle: '',
            prevBanners: null,
            banners: [],
            layoutSettings: false,
            audienceView: false,
            check: false
        }

        this.handleChangeLayout = this.handleChangeLayout.bind(this);
        this.handleChangeLabels = this.handleChangeLabels.bind(this);
        this.handleMessageChangeLayout = this.handleMessageChangeLayout.bind(this);
        this.handleChangeDefaultBanner = this.handleChangeDefaultBanner.bind(this);
        this.handleChangeLayoutResponse = this.handleChangeLayoutResponse.bind(this);
        this.handleChangeAudienceView = this.handleChangeAudienceView.bind(this);
        this.checkStreamingAvailable = this.checkStreamingAvailable.bind(this);
    }

    componentDidMount() {
        const { audienceView } = this.props;

        this.setDefaultValue();
        this.initCallManager();

        this.setState({ audienceView: audienceView });
    }

    componentDidUpdate(prevProps) {
        const { eventItem, audienceView } = this.props;

        if (eventItem && eventItem !== prevProps.eventItem) {
            this.setDefaultValue();
        }

        if (eventItem && !prevProps.eventItem && eventItem !== prevProps.eventItem) {
            this.initCallManager();
        }

        if (audienceView !== prevProps.audienceView) {
            this.setState({
                audienceView: audienceView
            });
        }
    }

    componentWillUnmount() {
        if (this.#callManager) {
            this.#callManager.removeListener('changeLayout', this.handleMessageChangeLayout);
        }

        if (this.checkStreamingTimeout) {
            clearTimeout(this.checkStreamingTimeout);
        }
    }

    checkStreamingAvailable() {
        const { eventItem } = this.props;

        if (eventItem && eventItem.startDate) {
            let diffMinutes = moment(new Date(eventItem.startDate)).subtract(30, 'minutes').diff(moment(new Date()), 'minutes');
            let diffSeconds = moment(new Date(eventItem.startDate)).subtract(30, 'minutes').diff(moment(new Date()), 'seconds');

            if (diffMinutes > 0 || diffSeconds > 0) {
                this.setState({
                    check: true
                }, () => {
                    this.checkStreamingTimeout = setTimeout(() => {
                        this.setState({
                            check: false
                        });
                    }, diffSeconds * 1000);
                });
            }
        }
    }

    setDefaultValue() {
        const { eventItem } = this.props;

        this.setState({
            prevLayout: eventItem && eventItem.callParams && eventItem.callParams.layout ? eventItem.callParams.layout : eventItem && eventItem.callParams && eventItem.callParams.layoutParams && eventItem.callParams.layoutParams.defaultLayout ? eventItem.callParams.layoutParams.defaultLayout : Defines.RecordingLayout.NoCrop,
            layout: eventItem && eventItem.callParams && eventItem.callParams.layout ? eventItem.callParams.layout : eventItem && eventItem.callParams && eventItem.callParams.layoutParams && eventItem.callParams.layoutParams.defaultLayout ? eventItem.callParams.layoutParams.defaultLayout : Defines.RecordingLayout.NoCrop,
            prevLabels: eventItem && eventItem.callParams && eventItem.callParams.layoutParams && eventItem.callParams.layoutParams.labels ? eventItem.callParams.layoutParams.labels : Defines.Labels.Default,
            labels: eventItem && eventItem.callParams && eventItem.callParams.layoutParams && eventItem.callParams.layoutParams.labels ? eventItem.callParams.layoutParams.labels : Defines.Labels.Default,
            prevBanners: eventItem && eventItem.callParams && eventItem.callParams.layoutParams && eventItem.callParams.layoutParams.banners && eventItem.callParams.layoutParams.banners.length ? eventItem.callParams.layoutParams.banners : eventItem && eventItem.callParams && eventItem.callParams.layoutParams && eventItem.callParams.layoutParams.speakerBanner ? parseSpeakerBanner(eventItem.callParams.layoutParams.speakerBanner) : [],
            banners: eventItem && eventItem.callParams && eventItem.callParams.layoutParams && eventItem.callParams.layoutParams.banners && eventItem.callParams.layoutParams.banners.length ? eventItem.callParams.layoutParams.banners : eventItem && eventItem.callParams && eventItem.callParams.layoutParams && eventItem.callParams.layoutParams.speakerBanner ? parseSpeakerBanner(eventItem.callParams.layoutParams.speakerBanner) : [],
            layoutSettings: eventItem && eventItem.callParams && eventItem.callParams.layoutParams && eventItem.callParams.layoutParams.layoutSettings ? true : false
        });
    }

    initCallManager() {
        const { eventItem } = this.props;

        if (eventItem && eventItem.id) {
            this.#callManager = CallManager.get(eventItem.id);
            this.#callManager.on('changeLayout', this.handleMessageChangeLayout);
        }
    }

    handleChangeLayout(event) {
        const { labels, layout, banners } = this.state;
        const { eventItem } = this.props;

        const value = event.target.value;
        let oldValue = eventItem && eventItem.callParams && eventItem.callParams.layoutParams ? eventItem.callParams.layoutParams : {}

        if (value) {
            this.setState({
                prevLayout: layout,
                layout: value,
                processing: true,
                errorMessage: ''
            }, () => {
                if (this.#callManager) {
                    this.#callManager.changeLayout({ layout: value, layoutParams: { ...oldValue, labels, banners }, eventId: eventItem.id })
                        .then(this.handleChangeLayoutResponse).catch(e => {
                            this.setState({
                                //prevLayout: layout,
                                layout: layout,
                                processing: false,
                                errorMessage: 'Something went wrong'
                            });
                        });
                }
            });
        }
    }

    handleChangeLabels(event) {
        const { layout, labels, banners } = this.state;
        const { eventItem } = this.props;

        const value = event.target.value;
        let oldValue = eventItem && eventItem.callParams && eventItem.callParams.layoutParams ? eventItem.callParams.layoutParams : {}

        if (value) {
            this.setState({
                prevLabels: labels,
                labels: value,
                processing: true,
                errorMessage: ''
            }, () => {
                fanoutClient.changeLayout(layout, { ...oldValue, labels: value, banners });
            });
        }
    }

    handleMessageChangeLayout(data) {
        console.log('handleMessageChangeLayout', data);
        const { prevLayout, prevLabels, prevBanners } = this.state;
        const { status, payload } = data;
        const { eventItem, layoutChanged } = this.props;

        switch (status) {
            case Defines.Response.Forbidden:
                this.setState({
                    errorMessage: 'You do not have privileges to change layout',
                    processing: true,
                    layout: prevLayout,
                    labels: prevLabels,
                    banners: prevBanners
                });
                break;
            case Defines.Response.NotFound:
                this.setState({
                    errorMessage: 'Could not change layout',
                    processing: false,
                    layout: prevLayout,
                    labels: prevLabels,
                    banners: prevBanners
                });
                break;
            case Defines.Response.OK:
            default:
                this.setState({
                    processing: false
                }, () => {
                    if (payload) {
                        this.setState({
                            prevLayout: payload.layout ? payload.layout : payload.layoutParams && payload.layoutParams.defaultLayout ? payload.layoutParams.defaultLayout : Defines.RecordingLayout.NoCrop,
                            layout: payload.layout ? payload.layout : payload.layoutParams && payload.layoutParams.defaultLayout ? payload.layoutParams.defaultLayout : Defines.RecordingLayout.NoCrop,
                            prevLabels: payload.layoutParams && payload.layoutParams.labels ? payload.layoutParams.labels : Defines.Labels.Default,
                            labels: payload.layoutParams && payload.layoutParams.labels ? payload.layoutParams.labels : Defines.Labels.Default,
                            prevBanners: payload.layoutParams && payload.layoutParams.banners && payload.layoutParams.banners.length ? payload.layoutParams.banners : [],
                            banners: payload.layoutParams && payload.layoutParams.banners && payload.layoutParams.banners.length ? payload.layoutParams.banners : []
                        });
                    }
                    if (eventItem) {
                        let parseCallParams = Object.assign({}, eventItem.callParams, data.payload);
                        const parseEvent = Object.assign({}, eventItem, { callParams: parseCallParams });
                        layoutChanged(parseEvent);
                    }
                });
                break;
        }
    }

    handleChangeLayoutResponse(payload) {
        console.log('handleChangeLayoutResponse', payload);
        const { eventItem, layoutChanged, changeLayout } = this.props;

        if (payload) {
            this.setState({
                processing: false,
                prevLayout: payload.layout ? payload.layout : payload.layoutParams && payload.layoutParams.defaultLayout ? payload.layoutParams.defaultLayout : Defines.RecordingLayout.NoCrop,
                layout: payload.layout ? payload.layout : payload.layoutParams && payload.layoutParams.defaultLayout ? payload.layoutParams.defaultLayout : Defines.RecordingLayout.NoCrop,
                prevLabels: payload.layoutParams && payload.layoutParams.labels ? payload.layoutParams.labels : Defines.Labels.Default,
                labels: payload.layoutParams && payload.layoutParams.labels ? payload.layoutParams.labels : Defines.Labels.Default,
                prevBanners: payload.layoutParams && payload.layoutParams.banners && payload.layoutParams.banners.length ? payload.layoutParams.banners : [],
                banners: payload.layoutParams && payload.layoutParams.banners && payload.layoutParams.banners.length ? payload.layoutParams.banners : []
            }, () => {
                const { layout } = this.state;

                if (changeLayout) {
                    changeLayout(layout);
                }
            });
        }
        if (eventItem) {
            let parseCallParams = Object.assign({}, eventItem.callParams, payload);
            const parseEvent = Object.assign({}, eventItem, { callParams: parseCallParams });
            layoutChanged(parseEvent);
        }
    }

    handleChangeDefaultBanner(event) {
        // const { layout, labels, banners } = this.state;
        // const { eventItem } = this.props;

        // const id = event.target.value;
        // let oldValue = eventItem && eventItem.callParams && eventItem.callParams.layoutParams ? eventItem.callParams.layoutParams : {}

        // if (id) {
        //     this.setState({
        //         prevLabels: labels,
        //         labels: labels,
        //         banners: setDefaultBanner(banners, id),
        //         processing: true,
        //         errorMessage: ''
        //     }, () => {
        //         fanoutClient.changeLayout(layout, { ...oldValue, labels: labels, banners: setDefaultBanner(banners, id) });
        //     });
        // }
    }

    handleChangeAudienceView(event) {
        const { setAudienceView } = this.props;

        this.setState({
            audienceView: event.target.checked,
        }, () => {
            const { audienceView } = this.state;

            if (setAudienceView) {
                setAudienceView(audienceView);
            }
        });
    }

    render() {
        const { layout, labels, processing, errorMessage, previewImage, previewTitle, banners, layoutSettings, audienceView, check } = this.state;
        const { isAdmin, conferenceEnded, approvedEntry, eventItem } = this.props;

        return (
            <div>
                {isAdmin ?
                    <div className='customSettings'>
                        <div className={classNames("form-group switch-enable audienceView", { "disabled": check || conferenceEnded || !checkApprovedEntry(approvedEntry, eventItem) })}>
                            <div className="switch-mode">
                                <input
                                    id="audienceView"
                                    name="audienceView"
                                    type="checkbox"
                                    onChange={this.handleChangeAudienceView}
                                    checked={audienceView}
                                />
                                <label htmlFor="audienceView">
                                    Check audience view
                                </label>
                            </div>
                        </div>
                        {layoutSettings ?
                            <div>
                                <div className='attendees-settings-header'>
                                    <h1>Event Settings</h1>
                                </div>
                                {errorMessage ? <p className='custom-setting-error-message'>{errorMessage}</p> : null}
                                <div className='settings-line'>
                                    <p className='settings-line-text'>Stream Layout</p>
                                    <div className='settings-window'>
                                        <div className='radio-groups radio-wrap'>
                                            <div className='radio-item'>
                                                <img src={adParticipants} onClick={() => this.setState({ previewImage: adParticipants, previewTitle: 'Stream Layout - Ad+Participants' })} alt='' className='radio-image' loading='lazy' />
                                                <input
                                                    type="radio"
                                                    id="radio4"
                                                    value={Defines.RecordingLayout.AdParticipants}
                                                    onChange={this.handleChangeLayout}
                                                    checked={layout && layout === Defines.RecordingLayout.AdParticipants}
                                                    disabled={processing}
                                                />
                                                <label className='label-text' htmlFor="radio4" />
                                                <p className='radio-text'>Ad+Participants</p>
                                            </div>
                                            <div className='radio-item'>
                                                <img src={speaker} onClick={() => this.setState({ previewImage: speaker, previewTitle: 'Stream Layout - Ad+Active Speaker' })} alt='' className='radio-image' loading='lazy' />
                                                <input
                                                    type="radio"
                                                    id="radio3"
                                                    value={Defines.RecordingLayout.Speaker}
                                                    onChange={this.handleChangeLayout}
                                                    checked={layout && layout === Defines.RecordingLayout.Speaker}
                                                    disabled={processing}
                                                />
                                                <label className='label-text' htmlFor="radio3" />
                                                <p className='radio-text'>Ad+Active Speaker</p>
                                            </div>
                                            <div className='radio-item'>
                                                <img src={zoomOut} onClick={() => this.setState({ previewImage: zoomOut, previewTitle: 'Stream Layout - Zoom out' })} alt='' className='radio-image' loading='lazy' />
                                                <input
                                                    type="radio"
                                                    id="radio1"
                                                    value={Defines.RecordingLayout.NoCrop}
                                                    onChange={this.handleChangeLayout}
                                                    checked={layout && layout === Defines.RecordingLayout.NoCrop}
                                                    disabled={processing}
                                                />
                                                <label className='label-text' htmlFor="radio1" />
                                                <p className='radio-text'>Zoom out</p>
                                            </div>
                                            <div className='radio-item'>
                                                <img src={zoomIn} onClick={() => this.setState({ previewImage: zoomIn, previewTitle: 'Stream Layout - Zoom in' })} alt='' className='radio-image' loading='lazy' />
                                                <input
                                                    type="radio"
                                                    id="radio2"
                                                    value={Defines.RecordingLayout.Crop}
                                                    onChange={this.handleChangeLayout}
                                                    checked={layout && layout === Defines.RecordingLayout.Crop}
                                                    disabled={processing}
                                                />
                                                <label className='label-text' htmlFor="radio2" />
                                                <p className='radio-text'>Zoom in</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='settings-line'>
                            <p className='settings-line-text'>Participant Name Labels</p>
                            <div className='settings-window'>
                                <div className='radio-groups'>
                                    <div className='radio-item'>
                                        <input
                                            type="radio"
                                            id="label1"
                                            value={Defines.Labels.Default}
                                            onChange={this.handleChangeLabels}
                                            checked={labels && labels === Defines.Labels.Default}
                                            disabled={processing}
                                        />
                                        <label htmlFor="label1">
                                            <span className='label-span default'>Default</span>
                                        </label>
                                    </div>
                                    <div className='radio-item'>
                                        <input
                                            type="radio"
                                            id="label2"
                                            value={Defines.Labels.Outlined}
                                            onChange={this.handleChangeLabels}
                                            checked={labels && labels === Defines.Labels.Outlined}
                                            disabled={processing}
                                        />
                                        <label htmlFor="label2">
                                            <span className='label-span outlined'>Outlined</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                {banners && banners.length ?
                                    <div className='settings-line'>
                                        <p className='settings-line-text'>Ad Banner</p>
                                        <div className='settings-window'>
                                            {banners.map((item) => {
                                                return (
                                                    <div key={item.id} className='banner-item'>
                                                        <input
                                                            type="radio"
                                                            id={item.id}
                                                            value={item.id}
                                                            onChange={this.handleChangeDefaultBanner}
                                                            checked={item.default}
                                                            disabled={processing}
                                                        />
                                                        <label className='banner-text' htmlFor={item.id} />
                                                        <img
                                                            onClick={() => this.setState({ previewImage: item.img })}
                                                            src={item.img}
                                                            loading='lazy'
                                                            alt=''
                                                            className='banner-img'
                                                        />
                                                        {item.link ?
                                                            <a href={item.link} target="_blank" title={item.link}>
                                                                <img src={linkIcon} loading='lazy' alt='' className='banner-link-img' />
                                                            </a>
                                                            : null
                                                        }
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div> : null}
                            </div>
                            : null
                        }
                    </div> : null}
                {previewImage ?
                    <Preview
                        previewImage={previewImage}
                        previewTitle={previewTitle}
                        closePreview={() => this.setState({ previewImage: null, previewTitle: '' })}
                    />
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        eventItem: state.room.eventItem,
        audienceView: state.room.audienceView,
        approvedEntry: state.room.approvedEntry,
        conferenceEnded: (state.room.conference && (state.room.conference === 'ended_for_audience' || state.room.conference === 'ended')) || (state.room.status && (state.room.status === 'ended_for_audience' || state.room.status === 'ended')),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        layoutChanged: (eventItem) => {
            dispatch(roomActions.setEventItem({ eventItem }));
        },
        setAudienceView: (value) => {
            dispatch(roomActions.setAudienceView({ audienceView: value }));
        }
    };
};

const CustomSettingsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomSettings);

export default CustomSettingsContainer;