import * as chatActions from "../actions/chat_actions";

class ChatClient {
    constructor(store) {
        this._store = store;
    }

    setStore(store) {
        this._store = store;
    }

    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            let state = getState();
            let res = next(action);
            switch (action.type) {
                case chatActions.ADD_MESSAGE_CHAT: {
                    const data = action.payload;

                    let message = data.chat.content;
                    if (message) {
                        try {
                            message = message.replace(/[0-9@#!$%^&*(){}|":<>?/.,';=-=+_]/g, '');
                            let emoji = message.match(/[\p{Emoji}\u200d]+/gu);
                            if (emoji && emoji.length) {
                                emoji = emoji.join('');

                                if(this.emojiTimeout) {
                                    clearTimeout(this.emojiTimeout);
                                }
                                this._store.dispatch(chatActions.hideEmojiForm());
                                this._store.dispatch(chatActions.showEmojiForm(emoji));
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    }

                    break;
                }
                case chatActions.SHOW_EMOJI_FORM_CHAT: {
                    this.emojiTimeout = setTimeout(() => {
                        this._store.dispatch(chatActions.hideEmojiForm());
                    }, 5000);
                    break;
                }
            }
            return res;
        };
    }
}

export default new ChatClient();