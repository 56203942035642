import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import browser from "bowser";
import { connect } from 'react-redux';
import ShareScreenOn from "../../../../../resources/assets/ShareScreen-Active.svg";
import ShareScreenOff from "../../../../../resources/assets/ShareScreen.svg";
import EntireScreenShareOn from "../../../../../resources/assets/icon-entire-screen.svg";
import EntireScreenShareOff from "../../../../../resources/assets/icon-entire-screen-hover.svg";
import FileShareOn from "../../../../../resources/assets/icon-file.svg";
import FileShareOff from "../../../../../resources/assets/icon-file-hover.svg";
import VideoShareOn from "../../../../../resources/assets/icon-video.svg";
import VideoShareOff from "../../../../../resources/assets/icon-video-hover.svg";
import WindowShareOn from "../../../../../resources/assets/icon-window.svg";
import WindowShareOff from "../../../../../resources/assets/icon-window-hover.svg";
import { isMobile, logo } from "../../../../utils/text";

class ScreenShareButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            contentUrlVideoPresentation: "",
            openedVideoPresentation: false,
            isMobile: isMobile(),
            hover_screen: false,
            hover_window: false,
            hover_file: false,
            hover_video: false
        };
        this.togglePopUp = this.togglePopUp.bind(this);
        this.handleClickFilePresentation = this.handleClickFilePresentation.bind(
            this
        );
        this.handleChange = this.handleChange.bind(this);
        this.toggleScreenShare = this.toggleScreenShare.bind(this);
        this.handleChangeInputVideoPresentation = this.handleChangeInputVideoPresentation.bind(
            this
        );
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.toggleVideoPresentation();
        }
    }

    toggleScreenShare() {
        this.props.toggle("screenshare");
        this.setState({
            opened: !this.state.opened,
            openedVideoPresentation: false
        });
    }

    toggleBubbleVideoPresentation() {
        this.setState({ opened: false, openedVideoPresentation: true });
    }

    toggleVideoPresentation() {
        this.props.toggleVideoPresentation(this.state.contentUrlVideoPresentation);
        this.setState({ opened: false, openedVideoPresentation: false });
    }

    togglePopUp() {
        const {
            screenShareEnabled,
            filePresentationEnabled,
            videoPresentationEnabled
        } = this.props;
        if (
            !screenShareEnabled &&
            !filePresentationEnabled &&
            !videoPresentationEnabled
        ) {
            if (this.state.openedVideoPresentation) {
                this.setState({ opened: false, openedVideoPresentation: false });
            } else {
                this.setState({
                    opened: !this.state.opened,
                    openedVideoPresentation: false
                });
            }
        } else {
            if (!this.state.opened) {
                // this.props.dispatch(
                //     OnBoardingMessageActions.onBoardingDisplay(
                //         'Someone is already sharing. Please stop it before start a new one.',
                //         2000
                //     )
                // );
            }
            this.setState({
                opened: false,
                openedVideoPresentation: false
            });
        }
    }

    handleClickFilePresentation(e) {
        document.getElementById("filePresentationUpload").click();
    }

    handleChange(e) {
        this.props.convertFilePresentation(e.target.files[0]);
        this.setState({ opened: !this.state.opened });
    }

    handleChangeInputVideoPresentation(e) {
        let content = e.target.value;
        this.setState({ contentUrlVideoPresentation: content });
    }

    render() {
        const {
            screenShareEnabled,
            toggle,
            toggleVideoPresentation,
            isBottomBar,
            filePresentationEnabled,
            currentUserFilePresentation,
            currentUserVideoPresentation,
            currentUserScreenShare,
            shareActions,
            videoPresentationEnabled,
            filePresentation
        } = this.props;
        const {
            opened,
            openedVideoPresentation,
            contentUrlVideoPresentation,
            isMobile,
            hover_screen,
            hover_window,
            hover_file,
            hover_video
        } = this.state;
        return (
            <li
                id="screenshare-container"
                className={
                    filePresentationEnabled ||
                        screenShareEnabled ||
                        videoPresentationEnabled ||
                        opened ||
                        openedVideoPresentation
                        ? "active"
                        : filePresentation && filePresentation.fileConverted
                            ? "conversion-running"
                            : ""
                }
            >
                {filePresentation && filePresentation.fileConverted ? (
                    <Fragment>
                        <div id="loader-container-file-presentation">
                            <img src={logo} />
                            {/* <div className="loader-file-presentation"></div> */}
                        </div>
                        {isBottomBar && (
                            <a>
                                <div>
                                    <span>Share</span>
                                </div>
                            </a>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        <a
                            data-tip
                            data-for="toggle-screenshare"
                            className={
                                "" + (opened || openedVideoPresentation ? "on" : "off")
                            }
                            title={currentUserScreenShare || currentUserFilePresentation || currentUserVideoPresentation ? 'Stop sharing' : 'Share screen'}
                            onClick={() => {
                                currentUserScreenShare ||
                                    currentUserFilePresentation ||
                                    currentUserVideoPresentation
                                    ? toggle()
                                    : this.togglePopUp();
                            }}
                        >
                            <img
                                src={
                                    filePresentationEnabled ||
                                        screenShareEnabled ||
                                        videoPresentationEnabled
                                        ? ShareScreenOn
                                        : ShareScreenOff
                                }
                            />
                            {isBottomBar && (
                                <div>
                                    <span>Share Screen</span>
                                </div>
                            )}
                        </a>
                    </Fragment>
                )}
                {openedVideoPresentation && (
                    <div className="bubble-tip bubble-video-presentation">
                        <a
                            className="icon-close"
                            title="Close"
                            currentitem="false"
                            onClick={() => this.togglePopUp()}
                        ></a>
                        <span className="title">Share a Video</span>
                        <input
                            placeholder='Video URL'
                            value={contentUrlVideoPresentation}
                            onChange={this.handleChangeInputVideoPresentation}
                            onKeyDown={this.handleKeyDown}
                            type="text"
                            id="video-presentation-input"
                        ></input>
                        <button onClick={() => this.toggleVideoPresentation()}>
                            Start
                        </button>
                        <div className="anchor-popup"></div>
                    </div>
                )}
                {opened && (
                    <div className="bubble-tip">
                        <a
                            className="icon-close"
                            title="Close"
                            currentitem="false"
                            onClick={() => this.togglePopUp()}
                        ></a>
                        <span className="title">Share Options</span>
                        {shareActions.indexOf("screenshare") > -1 &&
                            !browser.msie && (
                                <div>
                                    <Fragment>
                                        <a
                                            onClick={() =>
                                                this.toggleScreenShare()
                                            }
                                            onMouseEnter={() => {
                                                !isMobile && this.setState({ hover_screen: true });
                                            }}
                                            onMouseLeave={() => {
                                                !isMobile && this.setState({ hover_screen: false });
                                            }}
                                        >
                                            <img src={hover_screen ? EntireScreenShareOff : EntireScreenShareOn} />
                                            Share a Screen
                                        </a>
                                    </Fragment>
                                </div>
                            )}
                        {shareActions.indexOf("windowpresentation") > -1 && (
                            <Fragment>
                                <a
                                    // onClick={this.handleClickFilePresentation}
                                    onMouseEnter={() => {
                                        !isMobile && this.setState({ hover_window: true });
                                    }}
                                    onMouseLeave={() => {
                                        !isMobile && this.setState({ hover_window: false });
                                    }}
                                >
                                    <img src={hover_window ? WindowShareOff : WindowShareOn} />
                                    Share a Window
                                </a>
                                <input
                                    type="window"
                                    id="windowPresentationUpload"
                                    accept="application/pdf,
                  application/vnd.ms-powerpoint,
                  application/vnd.openxmlformats-officedocument.presentationml.slideshow,
                  application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                    onChange={this.handleChange}
                                    style={{ display: "none" }}
                                />
                            </Fragment>
                        )}
                        {shareActions.indexOf("filepresentation") > -1 && (
                            <Fragment>
                                <a
                                    onClick={this.handleClickFilePresentation}
                                    onMouseEnter={() => {
                                        !isMobile && this.setState({ hover_file: true });
                                    }}
                                    onMouseLeave={() => {
                                        !isMobile && this.setState({ hover_file: false });
                                    }}
                                >
                                    <img src={hover_file ? FileShareOff : FileShareOn} />
                                    Share a File
                                </a>
                                <input
                                    type="file"
                                    id="filePresentationUpload"
                                    accept="application/pdf,
                  application/vnd.ms-powerpoint,
                  application/vnd.openxmlformats-officedocument.presentationml.slideshow,
                  application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                    onChange={this.handleChange}
                                    style={{ display: "none" }}
                                />
                            </Fragment>
                        )}

                        {shareActions.indexOf("videopresentation") > -1 && (
                            <Fragment>
                                <a
                                    onClick={() => this.toggleBubbleVideoPresentation()}
                                    onMouseEnter={() => {
                                        !isMobile && this.setState({ hover_video: true });
                                    }}
                                    onMouseLeave={() => {
                                        !isMobile && this.setState({ hover_video: false });
                                    }}
                                >
                                    <img src={hover_video ? VideoShareOff : VideoShareOn} />
                                    Share a Video
                                </a>
                            </Fragment>
                        )}

                        <div className="anchor-popup"></div>
                    </div>
                )}
            </li>
        );
    }
}

ScreenShareButton.propTypes = {
    shareActions: PropTypes.array.isRequired,
    screenShareEnabled: PropTypes.bool.isRequired,
    filePresentationEnabled: PropTypes.bool.isRequired,
    videoPresentationEnabled: PropTypes.bool.isRequired,
    currentUserScreenShare: PropTypes.bool.isRequired,
    currentUserFilePresentation: PropTypes.bool.isRequired,
    currentUserVideoPresentation: PropTypes.bool.isRequired,
    convertFilePresentation: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    toggleVideoPresentation: PropTypes.func.isRequired,
    isBottomBar: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        filePresentation: state.room.filePresentation
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //   setBroadcastMessage: (message) => {
        //     dispatch(broadcastActions.setBroadcastMessage(message));
        //   }
    };
};

const ScreenShareButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ScreenShareButton);

export default ScreenShareButtonContainer;