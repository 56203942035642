import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './app';
import firebase from './firebase';
import broadcast from './broadcast';
import payment from './payment';
import event from './event';
import chat from './chat';
import mystuff from './mystuff';
import audience from './audience';
import hosts from './hosts';
import bannedUsers from './bannedUsers';
import knocks from './knocks';
import room from './room';
import devices from './devices';

const reducers = (history) => combineReducers({
    router: connectRouter(history),
    app,
    firebase,
    broadcast,
    payment,
    event,
    chat,
    mystuff,
    audience,
    hosts,
    bannedUsers,
    knocks,
    room,
    devices
});

export default reducers;