import { EnhancedEventEmitter } from "mediasoup-client/lib/EnhancedEventEmitter";
import { Defines } from './FanoutDefines';
import * as MediasoupClient from "./../components/Streaming/Fanout/mediasoup-client.js";
import fanoutClient from './FanoutClient';
import { v1 as uuidv1 } from "uuid";

class CallManager extends EnhancedEventEmitter {

    #mediasoupDevice = null;
    #sessionId = null;
    #audioConsumer = null;
    #videoConsumer = null;
    #recvTransport = null;
    #sendTransport = null;
    #remoteVideoId = null;
    #remoteAudioId = null;
    #audioProducer = null;
    #videoProducer = null;
    #localVideo = null;
    #consumerData = {};
    #producerData = {};
    #audioConsumers = {};
    #videoConsumers = {};
    #previousFanoutState = Defines.Fanout.Status.Starting;
    #username = null;
    #name = null;
    #uid = null;
    #eventId = null;
    #remoteVideoEnabled = true;
    #audioInputDevice = null;
    #videoInputDevice = null;
    #screenDevices = [];
    #ended = false;
    #callParams = null;
    #myVideoTracks = {};
    #myAudioTracks = {};
    #myScreenshareVideoTracks = {};
    #myScreenshareAudioTracks = {};
    #myScreenshareVideoProducers = {};
    #myScreenshareAudioProducers = {};
    #isFanoutConnection = false;
    #routerId = null;
    #streams = [];
    #reconnectTimer = null;

    static #events = {};

    static set(params) {
        let { eventId } = params;
        if (!CallManager.#events[eventId]) {
            CallManager.#events[eventId] = new CallManager();
        }
    }
    static get({ eventId }) {
        if (!CallManager.#events[eventId]) {
            CallManager.#events[eventId] = new CallManager();
        }
        return CallManager.#events[eventId];
    }

    /**
     * Constructor
     */
    constructor(params) {
        super(params);
        this.#mediasoupDevice = new MediasoupClient.Device();
        // Bind functions
        this.handleStreamingJoin = this.handleStreamingJoin.bind(this);
        this.handleChangeLayout = this.handleChangeLayout.bind(this);
        this.handleVideoPresentation = this.handleVideoPresentation.bind(this);
        this.handleSetActiveSpeaker = this.handleSetActiveSpeaker.bind(this);
        this.sendChatMessage = this.sendChatMessage.bind(this);
        this.handleCreateRoomResponse = this.handleCreateRoomResponse.bind(this);
        this.handleCreateTransportResponse = this.handleCreateTransportResponse.bind(this);
        this.handleConsumeResponse = this.handleConsumeResponse.bind(this);
        this.doConsume = this.doConsume.bind(this);
        this.doConsumeResume = this.doConsumeResume.bind(this);
        this.handleNewProducerResponse = this.handleNewProducerResponse.bind(this);
        this.createSendTransport = this.createSendTransport.bind(this);
        this.createProducers = this.createProducers.bind(this);
        this.handleTransportProduce = this.handleTransportProduce.bind(this);
        this.handleDeletedProducer = this.handleDeletedProducer.bind(this);
        this.handlePauseProducer = this.handlePauseProducer.bind(this);
        this.handleResumeProducer = this.handleResumeProducer.bind(this);
        this.deleteProducer = this.deleteProducer.bind(this);
        this.handleConsumeResume = this.handleConsumeResume.bind(this);
        this.sendChatMessage = this.sendChatMessage.bind(this);
        this.getCallState = this.getCallState.bind(this);
        this.destroy = this.destroy.bind(this);
        this.muteAudio = this.muteAudio.bind(this);
        this.muteVideo = this.muteVideo.bind(this);
        this.handleRejoin = this.handleRejoin.bind(this);
        this.handleReconnect = this.handleReconnect.bind(this);

        fanoutClient.on('streamingJoin', this.handleStreamingJoin);
        fanoutClient.on('changeLayout', this.handleChangeLayout);
        fanoutClient.on('activeSpeaker', this.handleSetActiveSpeaker);
        fanoutClient.on('videoPresentation', this.handleVideoPresentation);
        fanoutClient.on('rejoin', this.handleRejoin);
        fanoutClient.on('reconnect', this.handleReconnect);
    }

    destroy() {
        this.resetConference();
    }

    /**
     * Joins user to conference
     * @param params
     * @return {Promise<unknown>}
     */
    joinConference(params) {
        this.resetConference();
        this.#isFanoutConnection = false;
        this.#sessionId = uuidv1();
        let { uid, username, name, eventId, remoteVideoEnabled, callParams,
            audioInputDevice, videoInputDevice, screenDevices
        } = params;
        if (uid !== undefined)
            this.#uid = uid;
        if (username !== undefined)
            this.#username = username;
        if (name !== undefined)
            this.#name = name;
        if (eventId !== undefined)
            this.#eventId = eventId;
        if (remoteVideoEnabled !== undefined)
            this.#remoteVideoEnabled = remoteVideoEnabled;
        if (audioInputDevice !== undefined)
            this.#audioInputDevice = audioInputDevice;
        if (videoInputDevice !== undefined)
            this.#videoInputDevice = videoInputDevice;
        if (screenDevices !== undefined)
            this.#screenDevices = screenDevices;
        if (callParams !== undefined)
            this.#callParams = callParams;

        // Attach to FanoutClient
        fanoutClient.on('newProducerResponse', this.handleNewProducerResponse);
        fanoutClient.on('deletedProducer', this.handleDeletedProducer);
        fanoutClient.on('pauseProducer', this.handlePauseProducer);
        fanoutClient.on('resumeProducer', this.handleResumeProducer);

        // Initiate call
        return new Promise((resolve, reject) => {
            console.log('this.getCallState()', this.getCallState())
            let payload = { videoCodec: Defines.VideoCodec.VP8 };
            fanoutClient.joinConference({ ...payload, ...this.getCallState() }, this.getCallState())
                .then(this.handleCreateRoomResponse)
            resolve(eventId);
        });
    }

    /**
     * Joins user to conference
     * @param params
     * @return {Promise<unknown>}
     */
    joinRoom(params) {
        this.resetConference();
        this.#sessionId = uuidv1();
        this.#isFanoutConnection = true;
        let { uid, username, name, eventId, remoteVideoEnabled, callParams,
            audioInputDevice, videoInputDevice, screenDevices
        } = params;
        if (uid !== undefined)
            this.#uid = uid;
        if (username !== undefined)
            this.#username = username;
        if (name !== undefined)
            this.#name = name;
        if (eventId !== undefined)
            this.#eventId = eventId;
        if (remoteVideoEnabled !== undefined)
            this.#remoteVideoEnabled = remoteVideoEnabled;
        if (audioInputDevice !== undefined)
            this.#audioInputDevice = audioInputDevice;
        if (videoInputDevice !== undefined)
            this.#videoInputDevice = videoInputDevice;
        if (screenDevices !== undefined)
            this.#screenDevices = screenDevices;
        if (callParams !== undefined)
            this.#callParams = callParams;
        // Attach to FanoutClient
        fanoutClient.on('newProducerResponse', this.handleNewProducerResponse);
        fanoutClient.on('deletedProducer', this.handleDeletedProducer);
        fanoutClient.on('pauseProducer', this.handlePauseProducer);
        fanoutClient.on('resumeProducer', this.handleResumeProducer);
        // Initiate call
        return new Promise((resolve, reject) => {
            console.log('this.getCallState()', this.getCallState())
            let payload = { videoCodec: Defines.VideoCodec.VP9 };
            fanoutClient.joinRoom({ ...payload, ...this.getCallState() }, this.getCallState())
                .then(this.handleCreateRoomResponse)
            resolve(eventId);
        });
    }

    /**
     * Joins user to conference
     * @param params
     * @return {Promise<unknown>}
     */
    peekRoom(params) {
        this.resetConference();
        this.#sessionId = uuidv1();
        this.#isFanoutConnection = true;
        let { uid, username, name, eventId, remoteVideoEnabled, callParams,
            audioInputDevice, videoInputDevice, screenDevices
        } = params;
        if (uid !== undefined)
            this.#uid = uid;
        if (username !== undefined)
            this.#username = username;
        if (name !== undefined)
            this.#name = name;
        if (eventId !== undefined)
            this.#eventId = eventId;
        if (remoteVideoEnabled !== undefined)
            this.#remoteVideoEnabled = remoteVideoEnabled;
        if (audioInputDevice !== undefined)
            this.#audioInputDevice = audioInputDevice;
        if (videoInputDevice !== undefined)
            this.#videoInputDevice = videoInputDevice;
        if (screenDevices !== undefined)
            this.#screenDevices = screenDevices;
        if (callParams !== undefined)
            this.#callParams = callParams;
        // Initiate call
        return new Promise((resolve, reject) => {
            console.log('this.getCallState()', this.getCallState())
            let payload = { videoCodec: Defines.VideoCodec.VP9 };
            fanoutClient.peekRoom({ ...payload, ...this.getCallState() }, this.getCallState())
                .then(this.handleCreateRoomResponse)
            resolve(eventId);
        });
    }

    /**
     * End conference
     */
    async endConference(params) {
        try {
            let cleanup = async () => {
                try {
                    await this.destroyMediasoup();
                    this.resetConference();
                    return true;
                } catch (e) {
                    console.error('endConference::cleanup()  Failed to cleanup mediasoup connection');
                    // If we're here it's already ended
                    return true;
                }
            }

            return fanoutClient.sendEndCall(this.getCallState()).then(() => {
                return fanoutClient.sendChatDisconnect(this.getCallState()).then(cleanup).catch(async (e) => {
                    console.error('endConference::sendChatDisconnect()  Failed to disconnect from chat', e);
                    await cleanup();
                    return true;
                });
            }).catch((e) => {
                // TODO: Log error
                console.error('endConference::sendEndCall() Failed to end call', e);
                return false;
            });
        } catch (e) {
            // TODO: Loge error
            console.error('endConference()  Could not end conference');
            return false;
        }
    }

    /**
     * Exit conference
     */
    async exitConference(params) {
        try {
            let cleanup = async () => {
                try {
                    await this.destroyMediasoup();
                    this.resetConference();
                    return true;
                } catch (e) {
                    console.error('exitConference::cleanup()  Failed to cleanup mediasoup connection');
                    // If we're here it's already ended
                    return true;
                }
            }

            return fanoutClient.sendStopCall(this.getCallState()).then(() => {
                return fanoutClient.sendChatDisconnect(this.getCallState()).then(cleanup).catch(async (e) => {
                    console.error('exitConference::sendChatDisconnect()  Failed to disconnect from chat', e);
                    await cleanup();
                    return true;
                });
            }).catch((e) => {
                // TODO: Log error
                console.error('exitConference::sendStopCall() Failed to exit call', e);
                return false;
            });
        } catch (e) {
            // TODO: Loge error
            console.error('exitConference()  Could not exit conference');
            return false;
        }
    }

    /**
     * Exit conference
     */
    async handleStreamingJoin(params) {
        console.log('handleStreamingJoin()  About to emit joinConference');
        this.emit('joinConference');
    }

    /**
     * Change layout
     */
    async handleChangeLayout(data) {
        console.log('handleChangeLayout()  About to emit changeLayout', data);
        this.emit('changeLayout', data);
    }

    /**
    * Set active speaker
    */
    async handleSetActiveSpeaker(data) {
        console.log('handleSetActiveSpeaker()  About to emit activeSpeaker', data);
        this.emit('activeSpeaker', data);
    }

    /**
     * Joins user to conference
     */
    resetConference() {
        if (this.#myVideoTracks) {
            console.log('destroy()  About to stop my video tracks');
            Object.values(this.#myVideoTracks).forEach?.(tdata => {
                tdata.track?.stop?.()
            });
            this.#myVideoTracks = {};
        }
        if (this.#myAudioTracks) {
            console.log('destroy()  About to stop my audio tracks');
            Object.values(this.#myAudioTracks).forEach?.(tdata => {
                tdata.track?.stop?.()
            });
            this.#myAudioTracks = {};
        }

        this.#recvTransport?.close?.();
        this.#sendTransport?.close?.();
        this.#sessionId = null;
        this.#audioConsumer = null;
        this.#videoConsumer = null;
        this.#recvTransport = null;
        this.#sendTransport = null;
        this.#remoteVideoId = null;
        this.#remoteAudioId = null;
        this.#audioProducer = null;
        this.#videoProducer = null;
        this.#localVideo = null;
        this.#consumerData = {};
        this.#producerData = {};
        this.#previousFanoutState = Defines.Fanout.Status.Starting;
        this.#username = null;
        this.#name = null;
        this.#uid = null;
        this.#eventId = null;
        this.#remoteVideoEnabled = true;
        this.#ended = false;
        this.#callParams = null;
        fanoutClient.removeListener('newProducerResponse', this.handleNewProducerResponse);
        fanoutClient.removeListener('deletedProducer', this.handleDeletedProducer);
        fanoutClient.removeListener('pauseProducer', this.handlePauseProducer);
        fanoutClient.removeListener('resumeProducer', this.handleResumeProducer);
        if (this.#reconnectTimer)
            clearTimeout(this.#reconnectTimer);
        this.#reconnectTimer = null;

    }

    // --- Mediasoup ---------------------------------------------------------------------------------------------------

    async handleCreateRoomResponse(rpayload) {
        // console.log('handleCreateRoomResponse() RoomId:%s RouterId:%s', this.#eventId, payload.routerId);
        console.log('handleCreateRoomResponse()', this.#eventId, rpayload);
        if (!this.#mediasoupDevice.loaded) {
            await this.#mediasoupDevice.load({ routerRtpCapabilities: rpayload && rpayload.roomRtpCapabilities ? rpayload.roomRtpCapabilities : null });
            console.log("Mediasoup Client Device Loaded");
        } else {
            this.#mediasoupDevice = null;
            console.log("Mediasoup Client Device Already Loaded");
            this.#mediasoupDevice = new MediasoupClient.Device();
            await this.#mediasoupDevice.load({ routerRtpCapabilities: rpayload && rpayload.roomRtpCapabilities ? rpayload.roomRtpCapabilities : null });
            console.log("Mediasoup Created new Client Device ");
        }
        // Set the ID of the router to which we are connected on the Fanout Server
        // this.routerId = data.routerId;

        // Get ID's of any present Audio/Video Producers on the Fanout Server
        if (rpayload && rpayload.video)
            this.#remoteVideoId = rpayload.video;
        if (rpayload && rpayload.audio)
            this.#remoteAudioId = rpayload.audio;
        if (rpayload && rpayload.routerId)
            this.#routerId = rpayload.routerId;

        // Create transport
        let { eventId, sessionId, uid, username: name } = this.getCallState()
        let payload = {
            eventId: this.#eventId,
            sessionId: this.#sessionId,
            uid: this.#uid,
            name: this.myName,
        };
        if (rpayload && rpayload.routerId)
            payload.routerId = rpayload.routerId;
        await fanoutClient.sendRequest({
            request: Defines.Fanout.Signalling.Transport.Create,
            alias: eventId,
            payload: { ...payload, type: "recv" },
        }, this.handleCreateTransportResponse.bind(this));
        await fanoutClient.sendRequest({
            request: Defines.Fanout.Signalling.Transport.Create,
            alias: eventId,
            payload: { ...payload, type: "send" },
        }, this.handleCreateTransportResponse.bind(this));
    }

    async handleCreateTransportResponse(data) {
        try {
            let { transportData, type } = data;
            console.log('handleCreateTransportResponse() transportData:', transportData, "type:", type);
            let transport = null;

            if (type == "recv")
                transport = await this.createRecvTransport(transportData);
            else {
                transport = await this.createSendTransport(transportData);
            }
            // console.log(transport);
            transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                console.log('Transport::connect [direction:%s]', transport.direction);
                try {
                    callback();
                    let { eventId, sessionId, uid, username: name } = this.getCallState()
                    let payload = {
                        dtlsParameters,
                        transportId: transport.id,
                        routerId: this.#routerId,
                        eventId,
                        sessionId,
                        uid,
                        name,
                    };
                    return fanoutClient.sendRequest({
                        request: Defines.Fanout.Signalling.Transport.Connect,
                        alias: eventId,
                        payload
                    }, callback)
                        .catch(errback);

                } catch (error) {
                    console.log("handleCreateTransportResponse()  Got error %s", error && error.message, error);
                    errback(error);
                }
            });

            transport.on('connectionstatechange', (state) => {
                console.log("Transport::OnConnectionStateChange", state, transport.direction);
                if (state === "closed") {
                    console.warn("Transport", transport.id, state, "Shutting Down Mediasoup for", transport.direction);
                    const type = (transport.direction === "recv") ? Defines.Fanout.Signalling.Transport.Recv : Defines.Fanout.Signalling.Transport.Send;
                    if (!this.#ended) {
                        fanoutClient.deleteSignallingClientEntry(this.getCallState());
                        this.destroyMediasoup(type);
                        setTimeout(() => {
                            try {
                                this.handleReconnect();
                            } catch (error) {
                                console.error("Error restarting the PeerConnection on DTLS close", error);
                            }
                        }, 500);
                    } else
                        this.destroyMediasoup(type);
                } else if (state === "connected" || state === "new") {
                    if (this.#reconnectTimer) {
                        clearTimeout(this.#reconnectTimer);
                        this.#reconnectTimer = null;
                        console.warn("Transport %s [%s] changed state to %s. Reconnect timer cleared", transport.id, transport.direction, state);
                    }
                } else if (state === "disconnected") {
                    console.warn("Transport %s [%s] changed state to %s", transport.id, transport.direction, state);
                    fanoutClient.logToServer('warn', `Transport ${transport.id} [${transport.direction}] changed state to ${state}`);
                    if (!this.#reconnectTimer) {
                        this.#reconnectTimer = setTimeout(() => {
                            console.warn("Transport %s [%s] inactive for 2min. About to reconnect", transport.id, transport.direction);
                            fanoutClient.deleteSignallingClientEntry(this.getCallState());
                            this.destroyMediasoup(type);
                            try {
                                this.handleReconnect();
                            } catch (error) {
                                console.error("Error restarting the PeerConnection on DTLS close", error);
                            }
                        }, 2 * 60 * 1000); // Reconnect after 2min
                    }
                    if (false) {
                        console.warn("Transport", transport.id, state, "Shutting Down Mediasoup for", transport.direction);
                        // TODO: Double check if we should disconnect or leavi it to recover
                        const type = (transport.direction === "recv") ? Defines.Fanout.Signalling.Transport.Recv : Defines.Fanout.Signalling.Transport.Send;
                        this.destroyMediasoup(type);
                    }
                }
            });

            if (data.type == 'send' && !this.#isFanoutConnection) {
                this.createProducers();
            }
        } catch (e) {
            console.error('Could not handle create recv transport response', e)
        }
    };


    /********** Create WebRTC Transport for Incoming Data + Media **************/
    async doConsume(payload) {
        console.log('About to run doConsume');
        try {
            let { eventId, uid, username: name, routerId, sessionId } = this.getCallState();
            let data = {
                request: Defines.Fanout.Signalling.Media.Consume,
                fanoutId: process.env.fanoutId,
                name: name || '',
                routerId: routerId || null,
                sessionId: sessionId || null,
                uid: uid || null,
                alias: eventId,
                eventId: eventId,
                payload,
            }
            return fanoutClient.sendRequest(data, this.handleConsumeResponse);
        } catch (e) {
            console.error('Could not handle create recv transport response', e)
        }
    };


    /********** Create WebRTC Transport for Incoming Data + Media **************/
    async createRecvTransport(transportData) {
        try {

            console.log('createRecvTransport()', transportData);
            this.#recvTransport = await this.#mediasoupDevice.createRecvTransport(transportData);

            // Sync producers with backend
            setTimeout(() => {
                let { eventId, uid, username: name } = this.getCallState();
                if (!this.#isFanoutConnection) {
                    fanoutClient.sendRequest({
                        request: Defines.Fanout.Signalling.Media.SyncProducers,
                        payload: {
                            eventId, uid,
                        }
                    }, (payload) => {
                        let { producers } = payload;
                        console.log('createRecvTransport() Got producers', producers, uid, this.#remoteVideoEnabled);
                        if (producers) {
                            Object.values(producers).forEach(producer => {
                                this.#producerData[producer.id] = producer;
                                if (producer.kind === 'video' && this.#remoteVideoEnabled) {
                                    let payload = {
                                        uid, eventId, name,
                                        consumerPeerId: uid,
                                        producerPeerId: producer.peerId,
                                        producerId: producer.id,
                                        rtpCapabilities: this.#mediasoupDevice.rtpCapabilities,
                                        transportId: this.#recvTransport.id,
                                        kind: "Video"
                                    }
                                    this.doConsume(payload);
                                }
                                if (producer.kind === 'audio') {
                                    let payload = {
                                        uid, eventId, name,
                                        consumerPeerId: uid,
                                        producerPeerId: producer.uid,
                                        producerId: producer.id,
                                        rtpCapabilities: this.#mediasoupDevice.rtpCapabilities,
                                        transportId: this.#recvTransport.id,
                                        kind: "Audio"
                                    }
                                    this.doConsume(payload);
                                }
                            })
                        }
                    });
                } else {
                    // Consume fanout audio/video
                    if (this.#remoteVideoId) {
                        let payload = {
                            uid, eventId, name,
                            consumerPeerId: uid,
                            producerPeerId: 1,
                            producerId: this.#remoteVideoId,
                            rtpCapabilities: this.#mediasoupDevice.rtpCapabilities,
                            transportId: this.#recvTransport.id,
                            kind: "Video"
                        }
                        this.doConsume(payload);
                    }
                    if (this.#remoteAudioId) {
                        let payload = {
                            uid, eventId, name,
                            consumerPeerId: uid,
                            producerPeerId: 1,
                            producerId: this.#remoteAudioId,
                            rtpCapabilities: this.#mediasoupDevice.rtpCapabilities,
                            transportId: this.#recvTransport.id,
                            kind: "Audio"
                        }
                        this.doConsume(payload);
                    }
                }
            }, 200);

            return this.#recvTransport;

        } catch (e) {
            console.error('Could not create recv transport', e)
        }
    };

    async createSendTransport(transportData) {
        console.log('About to create Send Transport', transportData);
        this.#sendTransport = await this.#mediasoupDevice.createSendTransport(transportData);
        this.#sendTransport.on("produce", this.handleTransportProduce);
        return this.#sendTransport;
    };

    async handleTransportProduce({ kind, rtpParameters, appData }, callback, errback) {
        console.log("handleTransportProduce()  Transport Type:%s RtpParameters:", kind, rtpParameters, appData);

        const handleTransportProduceResponse = payload => {
            console.log("handleTransportProduceResponse()  Transport Type:%s RtpParameters:", kind, payload);
            try {
                console.log("handleTransportProduce()  ProducerId:%s", payload.producerData.id);
                callback({ id: payload.producerData.id });
            } catch (error) {
                console.error("handleTransportProduce()  Failed to handle TransportProduce Error:%s", error.message, error);
                errback(error);
            }
        };

        console.log("handleTransportProduce()  Sending Produce %s Message to Server", kind);
        let { eventId, sessionId, routerId, uid, username: name } = this.getCallState();

        return fanoutClient.sendRequest({
            request: Defines.Fanout.Signalling.Media.Produce,
            alias: eventId,
            payload: {
                eventId,
                sessionId,
                routerId,
                uid,
                name,
                transportId: this.#sendTransport.id,
                kind,
                rtpParameters,
                appData
            }
        }, handleTransportProduceResponse);
    }

    /********** Handle Consumption for Incoming Media ************/
    createProducers() {

        let audioConstraints = false;
        if (this.#audioInputDevice && this.#audioInputDevice.deviceId) {
            audioConstraints = {
                deviceId: { exact: this.#audioInputDevice.deviceId },
            };
        }

        let videoConstraints = false;
        if (this.#videoInputDevice && this.#videoInputDevice.deviceId) {
            videoConstraints = {
                deviceId: { exact: this.#videoInputDevice.deviceId },
                // width: {min:600, ideal: 1920, max: 1920},
                // height: {min:300, ideal: 1080},
                aspectRatio: { ideal: 16 / 9 },
                frameRate: { ideal: 30 },
            };
        }

        console.log('About to create producers', audioConstraints, videoConstraints);
        if (true) {
            // Create audio producer
            navigator.mediaDevices.getUserMedia({ audio: audioConstraints })
                .then(async (stream) => {
                    this.#streams.push(stream);
                    let track = stream.getAudioTracks()[0];
                    this.#myAudioTracks[track.id] = track;
                    this.#audioProducer = await this.#sendTransport.produce({
                        track,
                        codecOptions: {
                            opusStereo: true, // Customize audio codec options as needed
                        },
                        appData: { srcType: 'mic' }
                    });
                    this.#audioProducer.resume();
                })
                .catch((error) => {
                    console.error('Error accessing microphone:', error);
                });

            // Create video producer
            navigator.mediaDevices.getUserMedia({ video: videoConstraints })
                .then(async (stream) => {
                    this.#streams.push(stream);
                    let track = stream.getVideoTracks()[0];
                    this.#myVideoTracks[track.id] = track;
                    this.#videoProducer = await this.#sendTransport.produce({
                        track,
                        appData: { srcType: 'camera' }
                    });
                    this.#videoProducer.resume();
                })
                .catch((error) => {
                    console.error('Error accessing camera:', error);
                });
        }
    }

    /********** Handle Consumption for Incoming Media ************/
    handleNewProducerResponse(data) {
        try {
            console.log('handleNewProducerResponse() Got new producer', data)

            if (this.#recvTransport) {
                let { eventId, sessionId, uid, username: name } = this.getCallState();
                let { producerData, appData } = data?.payload || {};

                this.#producerData[producerData.id] = producerData;
                console.log('handleNewProducerResponse() exist recvTransport [producerData:%o]', producerData);
                let payload = {
                    uid, eventId, name,
                    consumerPeerId: uid,
                    producerPeerId: producerData.peerId,
                    producerId: producerData.id,
                    rtpCapabilities: this.#mediasoupDevice.rtpCapabilities,
                    transportId: this.#recvTransport.id
                }
                this.doConsume(payload);
            } else {
                console.log("handleNewProducerResponse() Recv transport doesn't exist [producerData:%o]", data?.payload?.producerData);
            }
        } catch (e) {
            console.error('handleNewProducerResponse() Error accessing camera:', e);
        }

    }

    handleDeletedProducer(data) {

        console.log('handleDeletedProducer() Got deleted producer', data);
        let found = Object.values(this.#consumerData).find(c => c.producerId === data.producerId);

        if (found) {
            console.log('handleDeletedProducer()  About to delete consumer', found);
            let { uid, eventId, name, kind, producerId, consumerId } = found;
            this.emit('trackRemoved', {
                uid, eventId, name, kind, producerId,
                track: found.consumer.track
            });
            this.#consumerData[consumerId].consumer = null;
            delete this.#consumerData[consumerId];
            delete this.#producerData[producerId];
        } else {
            console.warn('handleDeletedProducer()  Could not find consumer for producer %s', data.producerId);
        }
    }

    handlePauseProducer(data) {
        let { producerId } = data || {};
        console.log('handlePauseProducer() Got pause producer', producerId || data);
        let found = producerId ? Object.values(this.#consumerData).find(c => c.producerId === producerId) : false;

        if (found) {
            console.log('handlePauseProducer()  About to set pause', found);
            found.consumer.track.pause = true;
            this.#producerData[producerId].paused = true;
        } else {
            console.warn('handlePauseProducer()  Could not find consumer for producer %s', producerId);
        }
    }

    handleResumeProducer(data) {
        let { producerId } = data || {};
        console.log('handleResumeProducer() Got resume producer', producerId || data);
        let found = producerId ? Object.values(this.#consumerData).find(c => c.producerId === producerId) : false;

        if (found) {
            console.log('handleResumeProducer()  About to set resume', found);
            found.consumer.track.pause = false;
            this.#producerData[producerId].paused = false;
        } else {
            console.warn('handleResumeProducer()  Could not find consumer for producer %s', producerId);
        }
    }

    /********** Create WebRTC Transport for Incoming Data + Media **************/
    async doConsumeResume(payload) {
        console.log('About to run doConsumeResume');
        try {
            let { eventId, uid, username: name, routerId, sessionId } = this.getCallState();
            let data = {
                request: Defines.Fanout.Signalling.Media.ConsumeResume,
                fanoutId: process.env.fanoutId,
                name: name || '',
                routerId: routerId || null,
                sessionId: sessionId || null,
                uid: uid || null,
                alias: eventId,
                eventId: eventId,
                payload,
            }
            return fanoutClient.sendRequest(data, this.handleConsumeResume);
        } catch (e) {
            console.error('Could not handle create recv transport response', e)
        }
    };
    async handleConsumeResponse(payload) {
        console.log('handleConsumeResponse() consumerData:', payload.consumerData);
        const { uid, eventId, name, audienceView } = payload;
        const { consumerId, kind, producerId, rtpParameters, peerId, peerName, peerAvatar, peerRole, appData } = payload.consumerData;
        let { srcType } = appData || {};
        srcType ??= (kind === 'audio') ? 'mic' : 'camera';
        const isPaused = (payload.paused === 'true');
        if (!this.#consumerData[consumerId])
            this.#consumerData[consumerId] = {};
        this.#consumerData[consumerId] = { consumerId, producerId, rtpParameters, srcType };

        try {
            this.#consumerData[consumerId].consumer = await this.#recvTransport.consume({
                id: consumerId,
                kind, producerId, rtpParameters, appData
            });
            this.#consumerData[consumerId].consumer.on("transportclose", () => {
                console.log("handleConsumeResponse() %s Consumer %s transport is closed:", kind, consumerId, this.#consumerData[consumerId].consumer.closed);
                this.emit('trackRemoved', {
                    uid,
                    eventId,
                    name,
                    audienceView,
                    kind,
                    producerId,
                    track: this.#consumerData[consumerId].consumer.track,
                    srcType,
                    role: peerRole,
                    avatar: peerAvatar
                });
                this.#consumerData[consumerId].consumer = null;
                delete this.#consumerData[consumerId];
            });
            this.#consumerData[consumerId].consumer.on("producerclose", () => {
                console.log("handleConsumeResponse() %s Consumer %s transport is closed:", kind, consumerId, this.#consumerData[consumerId].consumer.closed);
                this.emit('trackRemoved', {
                    uid,
                    eventId,
                    name,
                    audienceView,
                    kind,
                    producerId,
                    track: this.#consumerData[consumerId].consumer.track,
                    srcType,
                    role: peerRole,
                    avatar: peerAvatar
                });
                this.#consumerData[consumerId].consumer = null;
                delete this.#consumerData[consumerId];
            });
            this.#consumerData[consumerId].consumer.observer.on("close", () => {
                console.log("handleConsumeResponse() %s Consumer %s  is closed:", kind, consumerId, this.#consumerData[consumerId].consumer.closed);
                this.emit('trackRemoved', {
                    uid: peerId,
                    eventId,
                    name: peerName,
                    audienceView,
                    kind,
                    producerId,
                    track: this.#consumerData[consumerId].consumer.track,
                    srcType,
                    role: peerRole,
                    avatar: peerAvatar
                });
                this.#consumerData[consumerId].consumer = null;
                delete this.#consumerData[consumerId];
            });
            let videoTrack = this.#consumerData[consumerId].consumer.track;
            if (srcType && srcType === 'camera' && peerId && this.#uid && peerId === this.#uid) {
                videoTrack = await this.getLocalVideo();
            } else {
                this.fixVideoTrack(videoTrack);
            }
            this.emit('trackAdded', {
                uid: peerId,
                eventId,
                name: peerName,
                audienceView,
                kind,
                producerId,
                track: videoTrack,
                isMe: peerId && this.#uid && peerId === this.#uid,
                srcType,
                role: peerRole,
                avatar: peerAvatar
            });
            console.log('handleConsumeResponse() %s track added', kind, this.#consumerData[consumerId].consumer.track)
        } catch (error) {
            console.error('failed to consume [kind:%s, error:%o]', kind, error);
            throw error;
        }

        if (isPaused) {
            let { eventId, uid, username: name } = this.getCallState();
            let payload = { consumerId, uid, name, eventId };
            if (this.#producerData[producerId] && this.#producerData[producerId].paused) {
                this.handlePauseProducer(this.#consumerData[consumerId]);
                return payload;
            } else {
                return this.doConsumeResume(payload);
            }
        }
    };

    async handleConsumeResume(data) {
        let { consumerId, lowBandwidthMode } = data;

        if (this.#consumerData[consumerId] && this.#consumerData[consumerId].consumer && ((!lowBandwidthMode) || (lowBandwidthMode && this.#consumerData[consumerId].srcType && this.#consumerData[consumerId].srcType !== 'camera'))) {
            console.log("handleConsumeResume()  About to resume consumer %s lowBandbidthMode %s", consumerId, lowBandwidthMode);
            this.#consumerData[consumerId].consumer?.resume();
        } else {
            console.log("handleConsumeResume()  Could not resume consumer %s lowBandbidthMode %s", consumerId, lowBandwidthMode);
            if (lowBandwidthMode && consumerId) {
                if (this.#consumerData[consumerId].consumer?.track) {
                    let { eventId, uid, username: name, routerId, sessionId } = this.getCallState();
                    let payload = { uid, name, eventId };
                    let request = Defines.Fanout.Signalling.Media.ConsumePause;
                    let onResponse = () => {
                        this.#consumerData[consumerId].consumer?.pause();
                        console.log(`handleConsumeResume()  Video consumer ${consumerId} should be muted`)
                    };
                    fanoutClient.sendRequest({
                        request,
                        alias: eventId,
                        routerId,
                        sessionId,
                        payload: { ...payload, consumerId },
                    }, onResponse);
                }
            }
            return;
        }
    }



    /********** Handle Consumption for Incoming Media ************/
    async createScreenshareProducers(stream, callback) {
        console.log('About to create sc producer', stream);

        if (!stream) {
            console.log('Stream is missing, nothing to produce');
            callback(false);
            return;
        }

        let audioTracks = stream.getAudioTracks();
        audioTracks.forEach(async (track) => {
            console.log('About to create sc producer for track', track);
            this.#myScreenshareAudioTracks[track.id] = track;
            let audioProducer = await this.#sendTransport.produce({
                track,
                stopTracks: true,
                codecOptions: {
                    opusStereo: true, // Customize audio codec options as needed
                },
                appData: { srcType: 'screenshare' }
            });
            audioProducer.resume();
            this.#myScreenshareAudioProducers[audioProducer.id] = audioProducer;
            callback(true)
            track.addEventListener('ended', () => {
                audioProducer.close();
                callback(false)
                this.deleteProducer(audioProducer.id);
                console.log('The user has ended sharing the screen');
            });
        })
        let videoTracks = stream.getVideoTracks();
        videoTracks.forEach(async (track) => {
            console.log('About to create sc producer for track', track);
            this.#myScreenshareVideoTracks[track.id] = track;
            let videoProducer = await this.#sendTransport.produce({
                track,
                stopTracks: true,
                appData: { srcType: 'screenshare' }
            });
            this.#videoProducer.resume();
            this.#myScreenshareVideoProducers[videoProducer.id] = videoProducer;
            callback(true)
            track.addEventListener('ended', () => {
                videoProducer.close();
                callback(false)
                this.deleteProducer(videoProducer.id);
                console.log('The user has ended sharing the screen');
            });
        });
    }

    async stopAllScreenshareProducers() {
        console.log('About to stop sc producers');

        try {
            let audioTracks = await Object.values(this.#myScreenshareAudioTracks);
            await audioTracks.forEach(async (track) => {
                console.log('About to stop sc producer for track', track);
                await track.stop();
            });
        } catch (error) {
            console.error('Error to stop sc producer for track', error);
            throw error;
        }

        try {
            let audioProducers = await Object.values(this.#myScreenshareAudioProducers);
            await audioProducers.forEach(async (producer) => {
                console.log('About to stop sc producer', producer);
                producer.close();
                this.deleteProducer(producer.id);
            });
        } catch (error) {
            console.error('Error to stop sc producer', error);
            throw error;
        }

        try {
            let videoTracks = await Object.values(this.#myScreenshareVideoTracks);
            await videoTracks.forEach(async (track) => {
                console.log('About to stop sc producer for track', track);
                await track.stop();
            });
        } catch (error) {
            console.error('Error to stop sc producer for track', error);
            throw error;
        }

        try {
            let videoProducers = await Object.values(this.#myScreenshareVideoProducers);
            await videoProducers.forEach(async (producer) => {
                console.log('About to stop sc producer', producer);
                producer.close();
                this.deleteProducer(producer.id);
            });
        } catch (error) {
            console.error('Error to stop sc producer', error);
            throw error;
        }

        console.log('The user has ended sharing the screen');

        return false;
    }

    /**
     * Shut down all the active mediasoup entities
     * @param type {bit} indicates whether shutting down for incoming/outgoing data/media
     * @return {Promise<void>}
     */
    async deleteProducer(producerId) {
        console.log("destroyMediasoup()  About to destroy producer [%s]", producerId);
        if (!producerId)
            return;

        delete this.#myScreenshareAudioProducers[producerId];
        delete this.#myScreenshareVideoProducers[producerId];

        let rid = uuidv1();
        let { eventId, sessionId, uid, username: name } = this.getCallState();
        let payload = { producerId, uid, name, eventId, sessionId };

        return new Promise((resolve, reject) => {
            let to = setTimeout(reject, 5000);
            fanoutClient.sendRequest({
                request: Defines.Fanout.Signalling.Media.DeleteProducer,
                alias: eventId,
                payload
            }, (data) => {
                clearTimeout(to);
                resolve(data);
            });
        });
    }

    stopAllStreams() {
        if (this.#streams && this.#streams.length) {
            return this.#streams.filter((stream) => stream.active).map(async (stream, i) => {
                if (stream && stream.active && stream.getTracks) {
                    await stream.getTracks().forEach(async (track) => {
                        await track.stop();
                    });
                }
                delete this.#streams[i];
                return i;
            });
        }
    }

    /**
     * Shut down all the active mediasoup entities
     * @param type {bit} indicates whether shutting down for incoming/outgoing data/media
     * @return {Promise<void>}
     */
    async destroyMediasoup(type = Defines.Fanout.Signalling.Transport.Send | Defines.Fanout.Signalling.Transport.Recv) {
        console.log("destroyMediasoup()  About to destroy medisaoup transports [%s]", type);
        if (!type)
            return;

        if (type & Defines.Fanout.Signalling.Transport.Recv) {
            if (this.#recvTransport && !this.#recvTransport.closed) {
                try {
                    await this.#recvTransport.close();
                } catch (e) {
                }
                this.#recvTransport = null;
            }
        }
        if (type & Defines.Fanout.Signalling.Transport.Send) {
            if (this.#sendTransport && !this.#sendTransport.closed) {
                try {
                    await this.#sendTransport.close();
                } catch (e) {
                }
                this.#sendTransport = null;
            }
        }
    };

    /**
     * Mute my audio producer
     * @param shouldMute {boolean} Should mute or unmute
     * @return {Promise<boolean>}
     */
    async muteAudio(shouldMute) {
        if (!this.#audioProducer) {
            console.warn('Could not mute audio - missing audio producer');
            return;
        }
        try {
            if (shouldMute)
                this.#audioProducer.pause();
            else
                this.#audioProducer.resume();
            return true;
        } catch (e) {
            console.error(`muteAudio() Failed to ${shouldMute ? 'mute' : 'unmute'} audio`, e);
        }
    }

    /**
     * Mute my video
     * @param shouldMute {boolean} Should mute or unmute video
     * @return {Promise<boolean>}
     */
    async muteVideo(shouldMute) {
        if (!this.#videoProducer) {
            console.warn('Could not mute video - missing video producer');
            return;
        }
        try {
            console.log(`muteVideo()  Video producer ${this.#videoProducer}`)
            if (shouldMute) {
                this.#videoProducer.pause();
                if (this.#localVideo && this.#localVideo.track) {
                    this.#localVideo.track.enable = false;
                }
                let { eventId, uid, username: name, routerId, sessionId } = this.getCallState();
                let payload = { uid, name, eventId };
                let request = Defines.Fanout.Signalling.Media.ProducePause;
                let onResponse = () => {
                    this.#videoProducer.pause();
                    console.log(`muteVideo()  Video producer ${this.#videoProducer.id} should be paused`);
                    if (this.#localVideo && this.#localVideo.track) {
                        this.#localVideo.track.enable = false;
                        console.log(`muteVideo()  local video ${this.#localVideo.track.id} should be paused`, this.#localVideo.track);
                    }
                };
                fanoutClient.sendRequest({
                    request,
                    alias: eventId,
                    routerId,
                    sessionId,
                    payload: { ...payload, producerId: this.#videoProducer.id },
                }, onResponse);
            }
            else {
                this.#videoProducer.resume();
                if (this.#localVideo && this.#localVideo.track) {
                    this.#localVideo.track.enable = true;
                }
                let { eventId, uid, username: name, routerId, sessionId } = this.getCallState();
                let payload = { uid, name, eventId };
                let request = Defines.Fanout.Signalling.Media.ProduceResume;
                let onResponse = () => {
                    this.#videoProducer.resume();
                    console.log(`muteVideo()  Video producer ${this.#videoProducer.id} should be resumed`);
                    if (this.#localVideo && this.#localVideo.track) {
                        this.#localVideo.track.enable = true;
                        console.log(`muteVideo()  local video ${this.#localVideo.track.id} should be resumed`, this.#localVideo.track);
                    }
                };
                fanoutClient.sendRequest({
                    request,
                    alias: eventId,
                    routerId,
                    sessionId,
                    payload: { ...payload, producerId: this.#videoProducer.id },
                }, onResponse);
            }
            return true;
        } catch (e) {
            console.error(`muteVideo() Failed to ${shouldMute ? 'mute' : 'unmute'} video`, e);
        }
    }

    async muteRemoteVideosForMe(shouldMute, trackIds) {
        if (!this.#consumerData) {
            console.warn('Could not mute video - missing consumerData');
            return;
        }
        console.log('About to mute consumers', this.#consumerData)
        try {
            let { eventId, uid, username: name, routerId, sessionId } = this.getCallState();

            Object.values(this.#consumerData).filter(c => c.consumer.kind.toLowerCase() === 'video' && ((!c.srcType) || (c.srcType && c.srcType !== 'screenshare'))).forEach(c => {
                if (!c || !c.consumer)
                    return;
                let payload = { uid, name, eventId };
                let consumerId = c.consumer.id;
                let request = (shouldMute) ? Defines.Fanout.Signalling.Media.ConsumePause : Defines.Fanout.Signalling.Media.ConsumeResume;
                let onResponse = () => {
                    if (shouldMute)
                        c.consumer.pause();
                    else
                        c.consumer.resume();
                    console.log(`muteRemoteVideosForMe()  Video consumer ${consumerId} should be ${shouldMute ? 'muted' : 'unmuted'}`)
                };

                if (!trackIds || (c.consumer.track && trackIds.indexOf(c.consumer.track.id) !== -1)) {
                    if (c && c.consumer.track) {
                        fanoutClient.sendRequest({
                            request,
                            alias: eventId,
                            routerId,
                            sessionId,
                            payload: { ...payload, consumerId },
                        }, onResponse);
                    }
                }
            });
        } catch (e) {
            console.error(`muteRemoteVideosForMe() Failed to ${shouldMute ? 'mute' : 'unmute'} video`, e);
        }
    }

    async muteRemoteAudiosForMe(shouldMute, trackIds) {
        if (!this.#consumerData) {
            console.warn('Could not mute audio - missing consumerData');
            return;
        }
        console.log('About to mute consumers', this.#consumerData)
        try {
            let { eventId, uid, username: name, routerId, sessionId } = this.getCallState();

            Object.values(this.#consumerData).filter(c => c.consumer.kind.toLowerCase() === 'audio').forEach(c => {
                if (!c || !c.consumer)
                    return;
                let payload = { uid, name, eventId };
                let consumerId = c.consumer.id;
                let request = (shouldMute) ? Defines.Fanout.Signalling.Media.ConsumePause : Defines.Fanout.Signalling.Media.ConsumeResume;
                let onResponse = () => {
                    if (shouldMute)
                        c.consumer.pause();
                    else
                        c.consumer.resume();
                    console.log(`muteRemoteAudiosForMe()  Audio consumer ${consumerId} should be ${shouldMute ? 'muted' : 'unmuted'}`)
                };

                if (!trackIds || (c.consumer.track && trackIds.indexOf(c.consumer.track.id) !== -1)) {
                    if (c && c.consumer.track) {
                        fanoutClient.sendRequest({
                            request,
                            alias: eventId,
                            routerId,
                            sessionId,
                            payload: { ...payload, consumerId },
                        }, onResponse);
                    }
                }
            });
        } catch (e) {
            console.error(`muteRemoteAudiosForMe() Failed to ${shouldMute ? 'mute' : 'unmute'} audio`, e);
        }
    }

    async replaceAudioInput(audioInputDevice) {
        if (!audioInputDevice)
            return;
        this.#audioInputDevice = audioInputDevice;
        let audioConstraints = false;
        if (this.#audioInputDevice && this.#audioInputDevice.deviceId) {
            let { deviceId } = audioInputDevice;
            audioConstraints = {
                deviceId: { exact: deviceId },
            };
            if (this.#audioProducer) {
                let oldTrack = this.#audioProducer.track;
                if (oldTrack) {
                    oldTrack.stop();
                    delete this.#myAudioTracks[oldTrack.id];
                }
                const stream = await navigator.mediaDevices.getUserMedia({ audio: audioConstraints });
                if (stream) {
                    this.#streams.push(stream);
                    const newTrack = stream.getAudioTracks()[0];
                    if (newTrack) {
                        this.#myAudioTracks[newTrack.id] = newTrack;
                        await this.#audioProducer.replaceTrack({ track: newTrack });
                    }
                }
            }
        }
    }

    async replaceVideoInput(videoInputDevice) {
        if (!videoInputDevice)
            return;
        this.#videoInputDevice = videoInputDevice;
        let videoConstraints = false;
        if (this.#videoInputDevice && this.#videoInputDevice.deviceId) {
            let { deviceId } = videoInputDevice;
            videoConstraints = {
                deviceId: { exact: deviceId },
                // width: {min:600, ideal: 1920, max: 1920},
                // height: {min:300, ideal: 1080},
                aspectRatio: { ideal: 16 / 9 },
                frameRate: { ideal: 30 },
            };
            if (this.#videoProducer) {
                let oldStream = this.#videoProducer.stream;
                if (oldStream && oldStream.active && oldStream.getTracks) {
                    await oldStream.getTracks().forEach(async (track) => {
                        await track.stop();
                    });
                }

                let oldTrack = this.#videoProducer.track;
                if (oldTrack) {
                    oldTrack.stop();
                    delete this.#myVideoTracks[oldTrack.id];
                }

                if (this.#localVideo) {
                    let oldLocalStream = this.#localVideo.stream;
                    if (oldLocalStream && oldLocalStream.active && oldLocalStream.getTracks) {
                        await oldLocalStream.getTracks().forEach(async (track) => {
                            await track.stop();
                        });
                    }

                    let oldLocalTrack = this.#localVideo.track;
                    if (oldLocalTrack) {
                        oldLocalTrack.stop();
                        delete this.#myVideoTracks[oldLocalTrack.id];
                    }
                }

                const stream = await navigator.mediaDevices.getUserMedia({ video: videoConstraints });
                if (stream) {
                    this.#streams.push(stream);
                    const newTrack = stream.getVideoTracks()[0];
                    if (newTrack) {
                        this.fixLocalVideoTrack(newTrack);
                        this.#myVideoTracks[newTrack.id] = newTrack;
                        let localVideoData = {
                            stream,
                            track: newTrack
                        }
                        this.#localVideo = localVideoData;
                        this.emit('updateProducerVideo', { ...localVideoData, producerId: this.#videoProducer.id });
                        await this.#videoProducer.replaceTrack({ track: newTrack });
                    }
                }
            }
        }
    }

    async getLocalVideo() {
        console.log('call getLocalVideo', this.#videoInputDevice);
        let videoConstraints = false;
        if (this.#videoInputDevice && this.#videoInputDevice.deviceId) {
            let { deviceId } = this.#videoInputDevice;
            videoConstraints = {
                deviceId: { exact: deviceId },
                aspectRatio: { ideal: 16 / 9 },
                frameRate: { ideal: 30 },
            };

            if (this.#localVideo) {
                let oldLocalStream = this.#localVideo.stream;
                if (oldLocalStream && oldLocalStream.active && oldLocalStream.getTracks) {
                    await oldLocalStream.getTracks().forEach(async (track) => {
                        await track.stop();
                    });
                }

                let oldLocalTrack = this.#localVideo.track;
                if (oldLocalTrack) {
                    oldLocalTrack.stop();
                    delete this.#myVideoTracks[oldLocalTrack.id];
                }
            }

            const stream = await navigator.mediaDevices.getUserMedia({ video: videoConstraints });
            if (stream) {
                this.#streams.push(stream);
                const newTrack = stream.getVideoTracks()[0];
                this.fixLocalVideoTrack(newTrack);
                this.#localVideo = {
                    stream,
                    track: newTrack
                }
                if (newTrack) {
                    this.#myVideoTracks[newTrack.id] = newTrack;
                    return newTrack;
                }
            }
        } else return;
    }

    /**
     * Change layout
     * @param layout
     * @param layoutParams
     * @return {Promise<unknown>}
     */
    async changeLayout({ layout, layoutParams, eventId }) {
        let rid = uuidv1();

        return new Promise((resolve, reject) => {
            let to = setTimeout(reject, 5000);
            fanoutClient.sendRequest({
                request: Defines.Fanout.Signalling.ChangeLayout,
                alias: eventId,
                payload: { layout, layoutParams }
            }, (data) => {
                clearTimeout(to);
                resolve(data);
            });
        });
    }

    stream(shouldStream) {

    }

    // --- Video presentation ------------------------------------------------------------------------------------------
    /**
     * Start video
     * @param url {string} URL of the video
     * @param eventId {string} Event Id
     * @return {Promise<unknown>}
     */
    async startVideo(params) {
        let rid = uuidv1();
        let { eventId } = params || {}

        return new Promise((resolve, reject) => {
            let to = setTimeout(reject, 5000);
            fanoutClient.sendRequest({
                request: Defines.VideoPresentation.Started,
                alias: eventId,
                payload: params,
                rid
            }, (data) => {
                clearTimeout(to);
                resolve(data);
            });
        });
    }

    /**
     * Stop video
     * @param eventId {string} Event Id
     * @return {Promise<unknown>}
     */
    async stopVideo() {
        let rid = uuidv1();

        return new Promise((resolve, reject) => {
            let to = setTimeout(reject, 5000);
            fanoutClient.sendRequest({
                request: Defines.VideoPresentation.Stopped,
                rid
            }, (data) => {
                clearTimeout(to);
                resolve(data);
            });
        });
    }

    /**
     * Play video
     * @param eventId {string} Event Id
     * @return {Promise<unknown>}
     */
    async playVideo(params) {
        let rid = uuidv1();
        let { eventId } = params || {}

        return new Promise((resolve, reject) => {
            let to = setTimeout(reject, 5000);
            fanoutClient.sendRequest({
                request: Defines.VideoPresentation.Played,
                alias: eventId,
                payload: params,
                rid
            }, (data) => {
                clearTimeout(to);
                resolve(data);
            });
        });
    }

    /**
     * Pause video
     * @param eventId {string} Event Id
     * @return {Promise<unknown>}
     */
    async pauseVideo(params) {
        let rid = uuidv1();
        let { eventId } = params || {}

        return new Promise((resolve, reject) => {
            let to = setTimeout(reject, 5000);
            fanoutClient.sendRequest({
                request: Defines.VideoPresentation.Paused,
                alias: eventId,
                payload: params,
                rid
            }, (data) => {
                clearTimeout(to);
                resolve(data);
            });
        });
    }

    /**
     * Seek video
     * @param eventId {string} Event Id
     * @return {Promise<unknown>}
     */
    async seekVideo(params) {
        let rid = uuidv1();
        let { eventId } = params || {}

        return new Promise((resolve, reject) => {
            let to = setTimeout(reject, 5000);
            fanoutClient.sendRequest({
                request: Defines.VideoPresentation.Sought,
                alias: eventId,
                payload: params,
                rid
            }, (data) => {
                clearTimeout(to);
                resolve(data);
            });
        });
    }

    /**
     * Handle video events triggerd by some other user
     * @param data data received
     */
    handleVideoPresentation(data) {
        console.log('handleVideoPresentation() Got videoPresentation', data);

        switch (data.request) {
            case Defines.VideoPresentation.Started:
            case Defines.VideoPresentation.Stopped:
            case Defines.VideoPresentation.Played:
            case Defines.VideoPresentation.Paused:
            case Defines.VideoPresentation.Sought: try {
                this.emit('videoPresentation', data);
            } catch (e) {
                console.error('handleVideoPresentation() Got videoPresentation %s error %s', data, e.message, e);
            } break;
            default:
                console.warn('handleVideoPresentation() Got unknwn videoPresentation %s error %s', data);
        }

    }

    /**
     * Add new hetter/setter for enabled
     * @param track {MediaStreamTrack} track to be fixed
     */
    async fixLocalVideoTrack(track) {
        // Define a custom setter for the 'enabled' property
        Object.defineProperty(track, 'enable', {
            set: function (value) {
                // Emit your custom event here
                const event = new CustomEvent('enabled', { detail: { enabled: value } });
                this.dispatchEvent(event);

                // Set the 'enabled' property as usual
                this.enabled = value;
            },
            get: function () {
                return this.enabled;
            }
        });
    }

    /**
     * Add new hetter/setter for paused
     * @param track {MediaStreamTrack} track to be fixed
     */
    async fixVideoTrack(track) {
        // Define a custom setter for the 'paused' property
        Object.defineProperty(track, 'pause', {
            set: function (value) {
                // Emit your custom event here
                const event = new CustomEvent('paused', { detail: { paused: value } });
                this.dispatchEvent(event);

                // Set the 'paused' property as usual
                this.paused = value;
            },
            get: function () {
                return this.paused;
            }
        });

    }

    // --- General --------------------------------------------------------------------------------------------------------

    /**
     * Get user name in call
     * @return {*|string}
     */
    get myName() {
        return this.#username ? this.#username : (this.#name ? this.#name : 'Unknown');
    }

    /**
     * Return common call state params for wss request
     * @return {{uid: (*|null), eventId: *, sessionId: null, username: (*|string)}|null}
     */
    getCallState() {

        if (this.#eventId) {
            console.log('getCallState', {
                eventId: this.#eventId,
                alias: this.#eventId,
                username: this.myName,
                name: this.myName,
                uid: this.#uid ? this.#uid : null,
                routerId: this.#routerId || null,
                sessionId: this.#sessionId
            });
            return {
                username: this.myName,
                name: this.myName,
                uid: this.#uid ? this.#uid : null,
                routerId: this.#routerId || null,
                sessionId: this.#sessionId,
                alias: this.#eventId,
                eventId: this.#eventId
            }
        } else {
            return null;
        }
    }

    // --- Chat --------------------------------------------------------------------------------------------------------

    sendChatMessage(message) {

        if (message) {
            const chat = {
                title: Defines.Fanout.DataChannel.Chat,
                content: message,
                time: Date.now(),
                type: "text",
                name: this.#username ? this.#username : (this.#name ? this.#name : 'Unknown'),
                ownerId: this.#uid ? this.#uid : '',
                avatarUrl: null
            };
            fanoutClient.sendChatMessage(JSON.stringify(chat), this.getCallState());
        }
    }


    // --- Connection --------------------------------------------------------------------------------------------------

    handleRejoin(data) {
        console.log('handleRejoin()  About to forward rejoin');

        this.emit('rejoin', data);
    }

    handleReconnect(data) {
        console.log('handleReconnect()  About to forward reconnect');

        this.emit('reconnect', data);
    }

}

export default CallManager;
