import React, { Component } from 'react';
import { connect } from "react-redux";
import ComponentHeader from './Widget/ComponentHeader';
import Cards from './Widget/Cards';
import firebaseClient from '../utils/FirebaseClient';
import { stringToBytes } from '../utils/text';
import path from '../../resources/assets/path.svg';

class Ambassadors extends Component {

    constructor(props) {
        super(props);
        this.state = {
            input: '',
            hosts: props.ambassadors
        }

        this.handleChangeInput = this.handleChangeInput.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.ambassadors !== prevProps.ambassadors) {
            this.setState({
                hosts: this.props.ambassadors
            });
        }
    }

    handleChangeInput(e) {
        this.setState({
            input: e.target.value
        }, () => {
            const { input } = this.state;
            const { ambassadors } = this.props;

            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }

            this.searchTimeout = setTimeout(() => {
                if (input && input.trim && input.trim()) {
                    let byteString = '1', text = input, start = 0, end = 0;
                    text = text.substring(0, 4);
                    text = text.toLowerCase();
                    let byteArray = stringToBytes(text);
                    byteArray.map((item) => {
                        if (item < 100) {
                            byteString = byteString.concat(`0${item}`);
                        } else {
                            byteString = byteString.concat(item);
                        }
                        return null;
                    })
                    if (byteArray && byteArray.length === 3) {
                        start = byteString.concat('0000');
                        end = byteString.concat('9999');
                    } else {
                        start = byteString.concat('0');
                        end = byteString.concat('9');
                    }
                    start = parseInt(start);
                    end = parseInt(end);

                    firebaseClient.getUsersFS(start, end).then((result) => {
                        if (result && result.data) {
                            let users = result.data;
                            let newArray = users.filter((item) => {
                                let username = item.username.toLowerCase();
                                let searchValue = input.toLowerCase();
                                if (username.indexOf(searchValue) !== -1) {
                                    return item;
                                } else return null;
                            })
                            this.setState({
                                hosts: newArray
                            });
                        }
                    });
                } else {
                    this.setState({
                        hosts: ambassadors
                    });
                }
            }, 500);
        });
    }

    render() {
        const { input, hosts } = this.state;

        return (
            <div>
                <ComponentHeader
                    text='the hosts'
                />
                <div className='hosts-search-wrapper'>
                    <div className='hosts-search'>
                        <img src={path} className='path' loading='lazy' />
                        <input
                            type="text"
                            className="search-input"
                            placeholder='Who are you looking for?'
                            value={input}
                            onChange={this.handleChangeInput}
                        />
                    </div>
                </div>
                <Cards
                    title={`${input && input.trim && input.trim() ? "Searched" : "Featured"} Hosts`}
                    videos={hosts}
                    hosts={true}
                    grid={true}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        firebaseInit: state.firebase.init,
        ambassadors: state.firebase.ambassadors,
        hosts: state.firebase.hosts
    };
};

const AmbassadorsContainer = connect(
    mapStateToProps,
    null
)(Ambassadors);

export default AmbassadorsContainer;
