import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import fanoutClient from '../../utils/FanoutClient';
import deviceManager from '../../utils/DeviceManager';
import { v1 as uuidv1 } from 'uuid';
import * as roomActions from "../../actions/room_actions";
import { Defines } from '../../utils/FanoutDefines';
import StreamingAudience from "../Streaming/StreamingAudience";

class AudienceRoom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: null,
            userPhoto: null,
            name: null
        }

        this.sessionId = uuidv1();
        this.handleRoomClosed = this.handleRoomClosed.bind(this);
        this.handleRoomDisconnected = this.handleRoomDisconnected.bind(this);
        this.handleRoomStopped = this.handleRoomStopped.bind(this);
        this.handleExit = this.handleExit.bind(this);

        fanoutClient.on('roomClosed', this.handleRoomClosed);
        fanoutClient.on('roomDisconnected', this.handleRoomDisconnected);
        fanoutClient.on('roomStopped', this.handleRoomStopped);
    }

    componentWillUnmount() {
        fanoutClient.removeListener('roomClosed', this.handleRoomClosed);
        fanoutClient.removeListener('roomDisconnected', this.handleRoomDisconnected);
        fanoutClient.removeListener('roomStopped', this.handleRoomStopped);

        if (this.exitTimeout) {
            clearTimeout(this.exitTimeout);
        }
        if (this.messageTimeout) {
            clearTimeout(this.messageTimeout);
        }
    }

    handleRoomClosed(data) {
        const { displayMessage, hideMessage } = this.props;

        try {
            console.log("handleRoomClosed response received from server", data);
            if (displayMessage && hideMessage) {
                hideMessage();
                this.messageTimeout = setTimeout(() => {
                    displayMessage('The event is over', 2000);
                }, 500);
                this.exitTimeout = setTimeout(() => {
                    this.handleExit(true);
                }, 3000);
            } else {
                this.handleExit(true);
            }
        }
        catch (error) {
            console.error("Failed handleRoomClosed", error, data);
        }
    }

    handleRoomDisconnected(data) {
        const { displayMessage, hideMessage } = this.props;

        try {
            console.log("handleRoomDisconnected response received from server", data);
            if (displayMessage && hideMessage) {
                hideMessage();
                let message = 'Access not granted';

                if (data && data.status) {
                    switch (data.status) {
                        case Defines.Response.Conflict:
                            message = data && data.message ? data.message : 'Multiple joins not allowsed';
                            break;
                        default:
                            console.warn('handleRoomDisconnected default case', data);
                            break;
                    }
                }

                this.messageTimeout = setTimeout(() => {
                    displayMessage(message, 4000);
                }, 500);
                this.exitTimeout = setTimeout(() => {
                    this.handleExit(true);
                }, 5000);
            } else {
                this.handleExit(true);
            }
        }
        catch (error) {
            console.error("Failed handleRoomDisconnected", error, data);
        }
    }

    handleRoomStopped(data) {
        const { displayMessage, hideMessage, role } = this.props;

        try {
            console.log("handleRoomStopped response received from server", data);
            if (displayMessage && hideMessage && role && role !== 'presenter') {
                hideMessage();
                this.messageTimeout = setTimeout(() => {
                    displayMessage('The event is over', 4000);
                }, 595000);
                this.exitTimeout = setTimeout(() => {
                    this.handleExit(true);
                }, 600000);
            } else {
                this.handleExit(true);
            }
        }
        catch (error) {
            console.error("Failed handleRoomStopped", error, data);
        }
    }

    async handleExit(end) {
        const { handleExit } = this.props;

        await deviceManager.leaveConference();

        fanoutClient.cleanTheRoom();

        if (handleExit)
            handleExit(end);
    }



    render() {
        const { role, user, eventItem, userPhoto, eventId, username, name } = this.props;
        console.log('Audience render', user, role, eventItem, eventId);

        return (
            <Fragment>
                <StreamingAudience
                    name={(name ? name : null)}
                    uid={user ? user.uid : null}
                    username={username ? username : (name ? name : '')}
                    role={role}
                    email={this.props.user ? this.props.user.email : null}
                    userPhoto={userPhoto}
                    eventId={eventId}
                    eventItem={eventItem}
                    handleExit={this.handleExit}
                    isListener={true}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        displayMessage: (message, timer) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer }));
        },
        hideMessage: () => {
            dispatch(roomActions.hideMessage());
        }
    };
};

const AudienceRoomContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AudienceRoom);

export default AudienceRoomContainer;