import React, { Component } from "react";

class Message extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.props.handleShowMessage) {
                this.props.handleShowMessage();
            }
        }
    }

    render() {
        const { messageText } = this.props;
        return (
            <div className='message-modal-wrapper'>
                <div ref={this.setWrapperRef} className='message-modal'>
                    <div className='message-text'>{messageText}</div>
                </div>
            </div>
        );
    }
}

export default Message;