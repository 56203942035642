import {
    // Actions
    EVENT_SET_EVENTS_ACTION,
    EVENT_SET_STATUS_ACTION,
    EVENT_UPDATE_EVENTS_ACTION,
} from "../actions/event_actions";
import * as firebaseActions from "../actions/firebase_actions";

const initialState = {
    my: null,
    upcoming: null,
    scheduled: null,
    following: null,
    recommended: null,
    categorized: null,
    isStreaming: null,
    fanoutId: null,
    eventAuthor: null,
    eventId: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENT_SET_EVENTS_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, data);
        }
        case EVENT_UPDATE_EVENTS_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, data);
        }
        case EVENT_SET_STATUS_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, data);
        }
        default:
            return state;
    }
};

export default reducer;
