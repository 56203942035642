import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { push as nativePush } from 'connected-react-router';
import * as paymentActions from "../../actions/payment_actions";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import firebaseClient from '../../utils/FirebaseClient';
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';
import arrowIcon from '../../../resources/assets/ArrowLeft.svg';
import heartIcon from '../../../resources/assets/heart_fill.svg';
import { returnStartDate, prices, isIOSPrice, isIOS, renderCategory } from "../../utils/text";

let stripe_pk = process.env.build == 'prod' ? process.env.stripe_pk_prod : process.env.build == 'test' ? process.env.stripe_pk_test : process.env.build == 'nfl-aa' ? null : null;
let stripePK = null;

if (stripe_pk) {
    stripePK = loadStripe(stripe_pk);
}

async function confirmCard(stripe, elements, clientSecret, processingPayment, successPayment, failedConfirmPayment, successFunction, resetPaymentValue) {

    let cardElement = elements.getElement(CardElement);

    if (cardElement && clientSecret) {

        processingPayment();

        const confirmedCardPayment = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement
            }
        });

        const { paymentIntent, error } = confirmedCardPayment;

        if (error) {
            if (failedConfirmPayment)
                failedConfirmPayment(error);
        } else if (paymentIntent && paymentIntent.status && paymentIntent.status === 'succeeded') {
            cardElement.clear();
            successPayment();
            setTimeout(() => {
                successFunction(paymentIntent.id);
                resetPaymentValue();
            }, 5000);
        }
    }
};

class TipWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardComplete: false,
            customTip: props.item && props.item.tipsValue && isIOSPrice(props.item.tipsValue) ? parseFloat(props.item.tipsValue) : 1.99,
            paymentType: ''
        }
        this.handlePaymentError = this.handlePaymentError.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        if (isIOS() && process.env.platform && process.env.platform === 'mobile') {
            this.setState({
                paymentType: 'apple'
            });
        }
    }

    handleChangeCardInput(e) {
        this.setState({
            cardComplete: e.complete
        });
    }

    handlePay(stripe, elements) {
        const { customTip } = this.state;
        const { processingPayment, successPayment, item, createPaymentIntents, resetPaymentValue, successFunction } = this.props;

        let ticketType = '';

        if (item && item.type && item.type === "event" && item.status !== 'ended') {
            ticketType = 'event-ticket';
        } else if (item && item.type && ((item.type === "stream") || (item.status && item.status === 'ended'))) {
            ticketType = 'stream-ticket';
        } else {
            ticketType = 'event-ticket';
        }
        if (createPaymentIntents && item && item.id, ticketType) {
            processingPayment();
            firebaseClient.createPaymentIntentsOrThrowError({ type: 'tip', streamId: item.id, ticketType: ticketType, customTip: parseFloat(customTip) }).then((res) => {
                if (res) {
                    confirmCard(stripe, elements, res, processingPayment, successPayment, this.handlePaymentError, successFunction, resetPaymentValue);
                }
            })
        }
    };


    handleBack() {
        const { resetValue, resetPaymentValue } = this.props;

        if (resetValue && resetPaymentValue) {
            resetPaymentValue();
            resetValue();
            this.setState({
                cardComplete: false
            });
        }
    }

    handlePaymentError(error) {
        console.error('Payment error', error);
        const { failedConfirmPayment, failedPayment } = this.props;
        if (failedConfirmPayment)
            failedConfirmPayment(error);
        if (failedPayment)
            failedPayment(error);
        this.setState({
            error: error,
            processing: false
        });
    }

    render() {
        const { cardComplete, customTip, paymentType } = this.state;
        const { processing, succeeded, item } = this.props;
        let error = this.props.error || this.state.error;

        return (
            <div className='payment-page-overlay'>
                {item ?
                    <div className='payment-page-wrapper'>
                        <div className='payment-page-details' style={{ backgroundImage: `url(${firebaseClient.getEventHorizontalPicture(item)})` }}>
                            <img
                                src={arrowIcon}
                                loading='lazy'
                                className='payment-back-icon'
                                onClick={this.handleBack}
                            />
                            <div className='payment-details'>
                                <p className='payment-title'>{item.title}</p>
                                <Link
                                    to={`/browse?b=${item.cat.toLowerCase()}`}
                                    className='payment-category'
                                >
                                    {renderCategory(item.cat)}
                                </Link>
                                <Link to={`/profile/${item.uid}`} className='payment-author-details'>
                                    <img className='author-img' src={item.authorPic} loading='lazy' />
                                    <div className='author-name'>{item.author}</div>
                                </Link>
                                {((item.type && item.type === 'stream') || (item.status && item.status === 'ended')) ?
                                    <p className='payment-date'>{item && item.startDate ? `${moment(new Date(item.startDate)).format('LL')}` : ''}</p>
                                    :
                                    <p className='payment-date'>{item && item.startDate ? `Start ${moment(new Date(item.startDate)).format('LL')} | ${moment(new Date(item.startDate)).format('LT')}` : ''}</p>
                                }
                            </div>
                        </div>
                        <div className='payment-form-wrapper'>
                            <div className='payment-form-div-wrapper'>
                                <div className='payment-form-div'>
                                    <div className='payment-form-item'>
                                        <p className='payment-form-title'>Date</p>
                                        <p className='payment-form-value'>{item ? moment(new Date(returnStartDate(item))).format('LL') : null}</p>
                                    </div>
                                    <div className='payment-form-item'>
                                        <p className='payment-form-title'>Begins</p>
                                        <p className='payment-form-value'>{item ? moment(new Date(returnStartDate(item))).format('LT') : null}</p>
                                    </div>
                                </div>
                                <div className='payment-form-div'>
                                    <div className='payment-form-item'>
                                        <p className='payment-form-title'>Title</p>
                                        <p className='payment-form-value'>{item.title}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='payment-form-stripe'>
                                {succeeded ?
                                    <div className='stripe-wrapper'>
                                        <p className='stripe-text'>Send Tip</p>
                                        <p className='stripe-text'>Success</p>
                                        <p className='stripe-text'><img className='heart' src={heartIcon} loading='lazy' /></p>
                                    </div>
                                    : <div className='stripe-wrapper'>
                                        <div className='input-div'>
                                            <select
                                                className="tip-control"
                                                value={customTip}
                                                disabled={processing}
                                                onChange={(e) => this.setState({ customTip: e.target.value })}
                                            >
                                                {prices.map((price, index) => {
                                                    if (item && item.tipsValue && price && price >= (isIOSPrice(item.tipsValue) ? item.tipsValue : 1.99)) {
                                                        return (
                                                            <option key={index} value={price}>{`$${price}`}</option>
                                                        );
                                                    }
                                                })}
                                            </select>
                                        </div>
                                        {stripePK ?
                                            <Elements stripe={stripePK}>
                                                <ElementsConsumer>
                                                    {({ stripe, elements }) => (
                                                        <div>
                                                            <div className='input-div'>
                                                                {/* <p>Card details</p> */}
                                                                <CardElement
                                                                    id='card-element'
                                                                    options={{
                                                                        hidePostalCode: true,
                                                                        style: {
                                                                            base: {
                                                                                color: '#ffffff',
                                                                                fontSize: '18px',
                                                                                fontWeight: 300,
                                                                                '::placeholder': {
                                                                                    color: '#9fa5b5',
                                                                                },
                                                                                ':disabled': {
                                                                                    color: '#343a40',
                                                                                },
                                                                            }
                                                                        },
                                                                        disabled: processing
                                                                    }}
                                                                    onChange={(e) => this.handleChangeCardInput(e)}
                                                                />
                                                            </div>
                                                            <Button
                                                                type='default'
                                                                className='btn-pay-ticket'
                                                                onClick={() => this.handlePay(stripe, elements)}
                                                                disabled={(!cardComplete && !paymentType) || processing}
                                                                loading={processing}
                                                            >
                                                                Send Tip
                                                        </Button>
                                                            <div className='stripe-message-wrapper'>
                                                                {error && error.message ?
                                                                    <p className='stripe-message error-text'>{error.message}</p>
                                                                    : error ?
                                                                        <p className='stripe-message error-text'>Send Tip Failed</p>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </ElementsConsumer>
                                            </Elements>
                                            : null
                                        }
                                        <p className='stripe-text'>
                                            {`Like the show? Subscribe to `}
                                            <Link
                                                to={item.uid ? `/profile/${item.uid}` : '/'}
                                                onClick={this.handleBack}
                                            >
                                                {item.author ? item.author : 'host'}
                                            </Link>.
                                                    </p>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    :
                    <Loader />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        processing: state.payment.state === "PAYMENT_STATE_PROCESSING",
        succeeded: state.payment.state === "PAYMENT_STATE_SUCCESS",
        error: state.payment.error,
        clientSecret: state.payment.clientSecret
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createPaymentIntents: (type, streamId, ticketType, customTip) => {
            dispatch(paymentActions.createPaymentIntents({ type, streamId, ticketType, customTip }));
        },
        processingPayment: () => {
            dispatch(paymentActions.processingConfirmPayment());
        },
        successPayment: () => {
            dispatch(paymentActions.successConfirmPayment());
        },
        failedPayment: (error) => {
            dispatch(paymentActions.failedConfirmPayment(error));
        },
        resetPaymentValue: () => {
            dispatch(paymentActions.resetPaymentIntents());
        }
    };
};

const TipWrapperContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TipWrapper);

export default TipWrapperContainer;
