import React, { Component } from 'react';
import { strings } from '../../translations/localizedStrings';

class ComponentHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, big } = this.props;
        return (
            <div className='component-header'>
                {big ? 
                <div className='component-header-big'>
                    <p className='component-header-p-start'>You can</p>
                   <p className='component-header-p center-p'>officially <span>start hosting</span></p> 
                   <p className='component-header-p-end'>{`Live Streaming Events on ${strings.fullName}.`}</p>
                </div>
                :
                <p className='component-header-p'>{text}</p>
                }
            </div>
        )
    }
}

export default ComponentHeader;
