import React, { Component } from 'react';
import { connect } from "react-redux";
import ComponentHeader from '../Widget/ComponentHeader';
import Button from '../Widget/Button';
import monetize5 from '../../../resources/images/monetize5.png';
import features1 from '../../../resources/images/features1.png';
import features2 from '../../../resources/images/features2.png';
import features3 from '../../../resources/images/features3.png';
import features4 from '../../../resources/images/features4.png';
import { Link } from "react-router-dom";
import { 
    signupCampaignPageLink, 
    showBecomeAHost 
} from '../../utils/text';

class Features extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { auth, user } = this.props;

        return (
            <div>
                <ComponentHeader
                    text="features"
                />
                <div className='features-page-wrapper'>
                    <div className='features-flex-wrapper features1'>
                        <div className='features-flex-item'>
                            <div className='features-flex-img-wrapper'>
                                <p>🏈</p>
                            </div>
                            <p className='features-flex-title'>No Delay from Host to Viewer</p>
                            <p className='features-flex-text'>The only way to get an interactive online experience.</p>
                        </div>
                        <div className='features-flex-item'>
                            <div className='features-flex-img-wrapper'>
                                <p>🎧</p>
                            </div>
                            <p className='features-flex-title'>No Compromises</p>
                            <p className='features-flex-text'>High-quality audio and video makes it feel like you are right next to your viewers.</p>
                        </div>
                        <div className='features-flex-item'>
                            <div className='features-flex-img-wrapper'>
                                <p>🖤</p>
                            </div>
                            <p className='features-flex-title'>NO FILTER</p>
                            <p className='features-flex-text'>You write your own story on No Filter Network.<br />Be yourself with no executive pressure.</p>
                        </div>
                    </div>
                </div>
                <div className='features-page-wrapper'>
                    <div className='features-ad-wrapper'>
                        <div className='features-text'>
                            <p className='features-title-sm'>
                                <span>Zero</span>
                                <br />
                                Software
                                <br />
                                Downloads
                            </p>
                            <p className='features-text-p'>
                                Our cloud-based application means no need to download software, making it easy for anyone to get started.
                            </p>
                        </div>
                        <img src={features1} loading='lazy' />
                    </div>
                </div>
                <div className='features-page-wrapper schedule'>
                    <p className='features-page-title schedule'>Schedule your show</p>
                    <p className='features-page-text schedule'>Easy to use event scheduler lets you schedule,<br />promote, and sell your event all in one place.</p>
                    <img className='features-schedule-img' src={features2} loading='lazy' />
                </div>
                <div className='features-page-wrapper'>
                    <div className='features-part-wrapper'>
                        <div className='features-part-item'>
                            <img className='features-part-img1' src={features3} loading='lazy' />
                            <p className='features-part-item-title'>knock knock knock<span>in</span></p>
                            <p className='features-part-item-text'>Want to join in on the action? Click the knock button to ask to enter the stream!</p>
                        </div>
                        <div className='features-part-item'>
                            <img className='features-part-img2' src={features4} loading='lazy' />
                            <p className='features-part-item-title'>add a <span>co-host</span></p>
                            <p className='features-part-item-text'>Start a show with a Co-Host, grow your audience together!</p>
                        </div>
                    </div>
                </div>
                <div className='features-page-wrapper'>
                    <p className='features-page-title'>
                        <span>Monetizing</span> on No Filter
                    </p>
                    <div className='features-flex-wrapper features4'>
                        <div className='features-flex-item'>
                            <div className='features-flex-img-wrapper'>
                                <p>💵</p>
                            </div>
                            <p className='features-flex-title'>Set the price</p>
                            <p className='features-flex-text'>Take full control of your earning potential, you set the price on No Filter.</p>
                        </div>
                        <div className='features-flex-item'>
                            <div className='features-flex-img-wrapper'>
                                <p>💰</p>
                            </div>
                            <p className='features-flex-title'>TIPs!</p>
                            <p className='features-flex-text'>Generate extra revenue through tips before, during, and after your show.</p>
                        </div>
                        <div className='features-flex-item'>
                            <div className='features-flex-img-wrapper'>
                                <p>🤑</p>
                            </div>
                            <p className='features-flex-title'>NO FILTER NETWORK</p>
                            <p className='features-flex-text'>is committed to developing more ways for you to earn real coin.</p>
                        </div>
                    </div>
                </div>
                <div className='features-page-wrapper'>
                    <p className='features-page-title'>requirements</p>
                    <p className='features-page-text'>Theses are minimal requirements for views.</p>
                    <p className='features-page-text-blue'>recommendations for hosting a stream</p>
                    <div className='features-flex-wrapper features5'>
                        <div className='features-flex-item'>
                            <p className='features-flex-p'>Internet speeds</p>
                            <p className='features-flex-text'>
                                10 mb/s download
                                <br />
                                5 mb/s upload
                            </p>
                        </div>
                        <div className='features-flex-item'>
                            <p className='features-flex-p'>Memory</p>
                            <p className='features-flex-text'>4 GB RAM</p>
                        </div>
                        <div className='features-flex-item'>
                            <p className='features-flex-p'>cpu</p>
                            <p className='features-flex-text'>Intel Core i5-4670 processor or AMD equivalent</p>
                        </div>
                    </div>
                </div>
                {user && auth && <div className='features-page-wrapper'>
                    <div className='features-ad-wrapper'>
                        <div className='features-text'>
                            <p className='features-title'>Ready <span>to host</span> your<br />own stream?</p>
                            {user.broadcaster ?
                                <Link to='/calendar'>
                                    <Button
                                        type='default'
                                        className='btn-features-host-app'
                                    >
                                        create event
                                    </Button>
                                </Link>
                                : showBecomeAHost ?
                                    <a href={signupCampaignPageLink}>
                                        <Button
                                            type='default'
                                            className='btn-features-host-app'
                                        >
                                            become a host
                                    </Button>
                                    </a>
                                    : null
                            }
                        </div>
                        <img src={monetize5} loading='lazy' />
                    </div>
                </div>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        user: state.firebase.user
    };
};

const FeaturesContainer = connect(
    mapStateToProps,
    null
)(Features);

export default FeaturesContainer;