import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import fanoutClient from '../../utils/FanoutClient';
import deviceManager from '../../utils/DeviceManager';
import { v1 as uuidv1 } from 'uuid';
import * as roomActions from "../../actions/room_actions";
import * as devicesActions from "../../actions/devices_actions";
import { Defines } from '../../utils/FanoutDefines';
import StreamingConference from "../Streaming/StreamingConference";
import Loader from "../Widget/Loader";

class Room extends Component {

    constructor(props) {
        super(props);
        this.state = {
            linkId: null,
            isError: false,
            isLoading: false,
            loadingMessage: '',
            knockGranted: false,
            show: false,
            username: null,
            userPhoto: null,
            name: null,
            errorWhere: null,
            errorTitle: null,
            errorMessage: null
        }

        this.sessionId = uuidv1();
        this.handleRoomClosed = this.handleRoomClosed.bind(this);
        this.handleRoomDisconnected = this.handleRoomDisconnected.bind(this);
        this.handleRoomStopped = this.handleRoomStopped.bind(this);
        this.handleFanoutReqestExpired = this.handleFanoutReqestExpired.bind(this);
        this.getCallState = this.getCallState.bind(this);
        this.handleKnockGranted = this.handleKnockGranted.bind(this);
        this.handleKnockRevoked = this.handleKnockRevoked.bind(this);
        this.handleExit = this.handleExit.bind(this);
        this.handleUpdateIdToken = this.handleUpdateIdToken.bind(this);

        // TODO change this after to get rid of hardcoding and instead request ip from server);
        fanoutClient.on('roomClosed', this.handleRoomClosed);
        fanoutClient.on('roomDisconnected', this.handleRoomDisconnected);
        fanoutClient.on('roomStopped', this.handleRoomStopped);
        fanoutClient.on('fanoutReqestExpired', this.handleFanoutReqestExpired);
        fanoutClient.on('updateIdToken', this.handleUpdateIdToken);
    }


    getCallState() {
        return {};
    }

    async componentDidMount() {
        console.log('Room componentDidMount', this.props)

        const { role } = this.props;

        if (role && role === 'audience') {
            fanoutClient.on('knockGranted', this.handleKnockGranted);
            fanoutClient.on('knockRevoked', this.handleKnockRevoked);
        }
    }

    componentDidUpdate(prevProps) {
        const { user, role } = this.props;
        console.log('Room has been updated', user, role)

        if (prevProps.role !== role && role === 'audience') {

            if (prevProps.role && prevProps.role === 'audience') {
                fanoutClient.removeListener('knockGranted', this.handleKnockGranted);
                fanoutClient.removeListener('knockRevoked', this.handleKnockRevoked);
            }
            if (role && role === 'audience') {
                fanoutClient.on('knockGranted', this.handleKnockGranted);
                fanoutClient.on('knockRevoked', this.handleKnockRevoked);
            }
        }
    }

    componentWillUnmount() {
        const { role } = this.props;

        fanoutClient.removeListener('roomClosed', this.handleRoomClosed);
        fanoutClient.removeListener('roomDisconnected', this.handleRoomDisconnected);
        fanoutClient.removeListener('roomStopped', this.handleRoomStopped);
        fanoutClient.removeListener('fanoutReqestExpired', this.handleFanoutReqestExpired);
        fanoutClient.removeListener('updateIdToken', this.handleUpdateIdToken);

        if (role && role === 'audience') {
            fanoutClient.removeListener('knockGranted', this.handleKnockGranted);
            fanoutClient.removeListener('knockRevoked', this.handleKnockRevoked);
        }

        // fanoutClient.disconnectFromFanout(this.getCallState());

        if (this.exitTimeout) {
            clearTimeout(this.exitTimeout);
        }
        if (this.messageTimeout) {
            clearTimeout(this.messageTimeout);
        }
        if (this.revokeTimeout) {
            clearTimeout(this.revokeTimeout);
        }
        if (this.grantedTimeout) {
            clearTimeout(this.grantedTimeout);
        }
        if (this.updateIdTimeout) {
            clearTimeout(this.updateIdTimeout);
        }
    }

    handleKnockRevoked(data) {
        const { knockGranted } = this.state;
        const { role } = this.props;

        if (role === 'presenter' || role === 'moderator' || role === 'guest_speaker')
            return;

        try {
            console.log("handleKnockRevoked response received from server", data);
            if (knockGranted) {
                this.setState({
                    isLoading: true,
                    loadingMessage: 'Moving to audience'
                });
                this.revokeTimeout = setTimeout(() => {
                    this.setState({
                        knockGranted: false,
                        token: null,
                        isLoading: false,
                        loadingMessage: ''
                    }, () => {
                        const { setAnonymousGuest } = this.props;

                        if (setAnonymousGuest) {
                            setAnonymousGuest(false);
                        }
                    });
                }, 2000);
            } else {
                this.setState({
                    knockGranted: false,
                    token: null
                });
            }
        }
        catch (error) {
            console.error("Failed handleKnockRevoked", error, data);
        }
    }

    handleKnockGranted(data) {
        const { role } = this.props;
        if (role === 'presenter' || role === 'moderator' || role === 'guest_speaker')
            return;

        try {
            console.log("handleKnockGranted response received from server", data);
            this.setState({
                isLoading: true,
                loadingMessage: 'Moving into live stream'
            });
            this.grantedTimeout = setTimeout(() => {
                this.setState({
                    knockGranted: true,
                    token: (data && data.payload ? data.payload.token : null),
                    isLoading: false,
                    loadingMessage: ''
                }, () => {
                    const { setPreConfiguration } = this.props;

                    if (setPreConfiguration) {
                        setPreConfiguration(true);
                    }
                });
            }, 2000);
        }
        catch (error) {
            console.error("Failed handleKnockGranted", error, data);
        }
    }

    handleRoomClosed(data) {
        const { displayMessage, hideMessage } = this.props;

        try {
            console.log("handleRoomClosed response received from server", data);
            if (displayMessage && hideMessage) {
                hideMessage();
                this.messageTimeout = setTimeout(() => {
                    displayMessage('The event is over', 2000);
                }, 500);
                this.exitTimeout = setTimeout(() => {
                    this.handleExit(true);
                }, 3000);
            } else {
                this.handleExit(true);
            }
        }
        catch (error) {
            console.error("Failed handleRoomClosed", error, data);
        }
    }

    handleRoomDisconnected(data) {
        const { displayMessage, hideMessage } = this.props;

        try {
            console.log("handleRoomDisconnected response received from server", data);
            if (displayMessage && hideMessage) {
                hideMessage();
                let message = 'Access not granted';

                if (data && data.status) {
                    switch (data.status) {
                        case Defines.Response.Conflict:
                            message = data && data.message ? data.message : 'Multiple joins not allowsed';
                            break;
                        default:
                            console.warn('handleRoomDisconnected default case', data);
                            break;
                    }
                }

                this.messageTimeout = setTimeout(() => {
                    displayMessage(message, 4000);
                }, 500);
                this.exitTimeout = setTimeout(() => {
                    this.handleExit(true);
                }, 5000);
            } else {
                this.handleExit(true);
            }
        }
        catch (error) {
            console.error("Failed handleRoomDisconnected", error, data);
        }
    }

    handleRoomStopped(data) {
        const { displayMessage, hideMessage, role } = this.props;

        try {
            console.log("handleRoomStopped response received from server", data);
            if (displayMessage && hideMessage && role && role !== 'presenter') {
                hideMessage();
                this.messageTimeout = setTimeout(() => {
                    displayMessage('The event is over', 4000);
                }, 595000);
                this.exitTimeout = setTimeout(() => {
                    this.handleExit(true);
                }, 600000);
            } else {
                this.handleExit(true);
            }
        }
        catch (error) {
            console.error("Failed handleRoomStopped", error, data);
        }
    }

    handleUpdateIdToken(data) {
        const { role, updateIdToken } = this.props;

        try {
            console.log("handleUpdateIdToken response received from server", data);
            if (updateIdToken) {
                updateIdToken(data);
            }
            if (role && data && data.payload && data.payload.role && data.payload.role !== role) {
                this.setState({
                    isLoading: true,
                    loadingMessage: data.payload.role !== 'audience' ? 'Moving into live stream' : 'Moving to audience'
                });
                this.updateIdTimeout = setTimeout(() => {
                    this.setState({
                        isLoading: false,
                        loadingMessage: ''
                    }, () => {
                        const { setPreConfiguration } = this.props;

                        if (data.payload.role !== 'audience' && setPreConfiguration) {
                            setPreConfiguration(true);
                        }
                    });
                }, 2000);
            }
        }
        catch (error) {
            console.error("Failed handleRoomStopped", error, data);
        }
    }

    handleFanoutReqestExpired(data) {

        try {
            console.log("handleFanoutReqestExpired response received from server", data);
            if (data && data.message) {
                this.setState({
                    isError: true,
                    errorWhere: data.where || '',
                    errorTitle: data.title || '',
                    errorMessage: data.message
                });
            }
        }
        catch (error) {
            console.error("Failed handleFanoutReqestExpired", error, data);
        }
    }

    async handleExit(end) {
        const { handleExit } = this.props;

        await deviceManager.leaveConference();

        fanoutClient.cleanTheRoom();

        if (handleExit)
            handleExit(end);
    }

    renderError() {
        const { errorMessage, errorTitle, errorWhere } = this.state;

        return (
            <div className='error-wrapper'>
                <div className='error-div'>
                    <p className='error-title'>{errorTitle ? errorTitle : 'Network Problem'}</p>
                    <p className='error-message'>
                        {errorMessage ? errorMessage : null}
                        {errorMessage ? <br /> : null}
                        {!errorWhere ? 'Please check your internet connection or try reload the page.' : ''}
                    </p>
                    <button
                        className='btn-reload'
                        onClick={() => window.location.reload()}
                    >
                        Reload
                    </button>
                </div>
            </div>
        );
    }



    render() {
        const { knockGranted, token, isError, isLoading, loadingMessage } = this.state;
        const { role, user, eventItem, userPhoto, eventId, username, name, isAnonymous, isAnonymousGuest, joinUser, uid } = this.props;
        console.log('Room render', user, role, eventItem, eventId);

        return (
            <Fragment>
                {(isError) ?
                    this.renderError()
                    : isLoading ?
                        <Loader
                            text={loadingMessage}
                            dots={loadingMessage ? true : false}
                            type="page"
                        /> :
                        (role) ?
                            <StreamingConference
                                token={token}
                                name={(name ? name : null)}
                                uid={uid ? uid : null}
                                isOwner={user ? user.isOwner : false}
                                isAdmin={(role && ((role === 'presenter') || (role === 'moderator')))}
                                username={username ? username : (name ? name : '')}
                                role={role}
                                preConfig={true}
                                email={this.props.user ? this.props.user.email : null}
                                userPhoto={userPhoto}
                                eventId={eventId}
                                eventItem={eventItem}
                                handleExit={this.handleExit}
                                knockGranted={knockGranted}
                                isAnonymous={isAnonymous}
                                isAnonymousGuest={isAnonymousGuest}
                                isListener={!role || (role && role === 'audience' && !knockGranted)}
                                joinUser={joinUser}
                            />
                            : <div />
                }

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        displayMessage: (message, timer) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer }));
        },
        hideMessage: () => {
            dispatch(roomActions.hideMessage());
        },
        setPreConfiguration: (value) => {
            dispatch(devicesActions.setDevicesData({ preConfiguration: value }));
        }
    };
};

const RoomContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Room);

export default RoomContainer;