import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Rnd } from "react-rnd";
import AudienceRoom from '../../Room/AudienceRoom';
import * as roomActions from "../../../actions/room_actions";
import closeIcon from '../../../../resources/assets/close-icon.svg';

class AudienceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rndWidth: 320,
            rndHeight: 180,
            rndX: 0,
            rndY: 70
        }

        this.handleResize = this.handleResize.bind(this);
        this.handleExit = this.handleExit.bind(this);
        this.dragStop = this.dragStop.bind(this);
        this.rndResize = this.rndResize.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps) {
        const { orientation } = this.props;

        if (orientation && orientation !== prevProps.orientation) {
            this.handleResize();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        if(this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = null;
        }
    }

    handleResize() {
        let wrapper = document.getElementById('rnd-parent');

        if (wrapper) {

            if (this.wrapperTimeout) {
                clearTimeout(this.wrapperTimeout);
            }

            if (wrapper && wrapper.clientWidth && wrapper.clientHeight) {
                this.setState({
                    clientWidth: wrapper.clientWidth,
                    clientHeight: wrapper.clientHeight
                }, () => {
                    const { rndHeight, rndWidth, clientWidth, clientHeight, rndX, rndY } = this.state;

                    if ((rndWidth && clientWidth && clientWidth < rndWidth) || (rndHeight && clientHeight && clientHeight < rndHeight) || ((rndX + rndWidth) > clientWidth) || ((rndY + rndHeight) > clientHeight)) {
                        this.setState({
                            rndWidth: 320,
                            rndHeight: 180,
                            rndX: 0,
                            rndY: 70
                        });
                    }
                });
            }
        } else {
            this.wrapperTimeout = setTimeout(() => {
                this.handleResize();
            }, 3000);
        }
    }

    dragStop(e, d) {
        if(this.resizeTimeout)
            return;

        this.setState({
            rndX: d.x && d.x > 0 ? d.x : 0,
            rndY: d.y && d.y > 0 ? d.y : 0
        });
    }

    rndResize(e, direction, ref, delta, position) {
        this.setState({
            rndWidth: ref.offsetWidth,
            rndHeight: ref.offsetHeight,
            rndX: position.x && position.x > 0 ? position.x : 0,
            rndY: position.y && position.y > 0 ? position.y : 0
        },() => {
            if(this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
                this.resizeTimeout = null;
            }

            this.resizeTimeout = setTimeout(() => {
                console.log('expire resizeTimeout');
                this.resizeTimeout = null;
            }, 200);
        });
    }

    handleExit() {
        const { hideMessage, setAudienceView } = this.props;

        if (hideMessage)
            hideMessage();

        if (setAudienceView)
            setAudienceView(false);
    }

    render() {
        const { rndHeight, rndWidth, rndX, rndY } = this.state;
        const { audienceView, setAudienceView, user, role, name, username, userPhoto, eventId, eventItem } = this.props;

        return (
            <div>
                {audienceView ?
                    <div className='rnd-parent' id='rnd-parent'>
                        <Rnd
                            className="rnd-audience-view-wrapper"
                            bounds='parent'
                            cancel='.close-img, .play'
                            size={{ width: rndWidth, height: rndHeight }}
                            position={{ x: rndX, y: rndY }}
                            onDragStop={this.dragStop}
                            onResize={this.rndResize}
                            minWidth={320}
                            minHeight={180}
                            lockAspectRatio="16/9"
                            resizeHandleComponent={{
                                bottomRight: <span className='drag-img' />
                            }}
                            resizeHandleClasses={{
                                bottom: 'bottom-resize',
                                bottomLeft: 'bottomLeft-resize',
                                bottomRight: 'bottomRight-resize',
                                left: 'left-resize',
                                right: 'right-resize',
                                top: 'top-resize',
                                topLeft: 'topLeft-resize',
                                topRight: 'topRight-resize'
                            }}
                        >
                            <div className='audience-view-wrapper'>
                                <img
                                    src={closeIcon}
                                    alt='x'
                                    loading='lazy'
                                    onClick={() => setAudienceView(false)}
                                    className='close-img'
                                />
                                <div className='audience-view-video-wrapper'>
                                    <AudienceRoom
                                        role={role}
                                        user={user}
                                        name={name ? name : null}
                                        username={username ? username : (name ? name : '')}
                                        userPhoto={userPhoto || null}
                                        eventId={eventId}
                                        eventItem={eventItem}
                                        handleExit={this.handleExit}
                                    />
                                </div>
                            </div>
                        </Rnd>
                    </div>
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        audienceView: state.room.audienceView,
        orientation: state.app.orientation
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAudienceView: (value) => {
            dispatch(roomActions.setAudienceView({ audienceView: value }));
        },
        hideMessage: () => {
            dispatch(roomActions.hideMessage());
        }
    };
};

const AudienceViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AudienceView);

export default AudienceViewContainer;