import React, { Component } from 'react';
import classNames from "classnames";

export default class Loader extends Component {

    constructor(props) {
        super(props);
        this.counterRef = React.createRef();
    }
    componentDidMount() {
        const { seconds } = this.props;
        const counterDiv = this.counterRef.current;
        if (seconds) {
            var timeleft = seconds;
            counterDiv.innerHTML = timeleft;
            timeleft -= 1;
            let downloadTimer = setInterval(function () {
                if (timeleft < 0) {
                    clearInterval(downloadTimer);
                    counterDiv.innerHTML = "";
                } else {
                    counterDiv.innerHTML = timeleft;
                }
                timeleft -= 1;
            }, 1000);
        }
    }

    render() {
        const { overlay, payment, text, play, blackBg, type, login, dots, clickFunction } = this.props;
        return (
            <div
                onClick={clickFunction}
                className={classNames('loader-wrapper',
                    { 'overlay': overlay },
                    { 'payment': payment },
                    { 'login': login },
                    { 'remove-header': type && type === 'page' },
                    { 'blackBg': blackBg })}
            >
                <div className={classNames('loader', { 'no-spinner': play })}>
                    {play ? <i className="fa fa-play" /> : null}
                </div>
                {text ? <p className='loader-text'>{text}{dots ? <span className='dot-flashing' /> : null}</p> : null}
                <div className='loader-countdown' id="countdown" ref={this.counterRef} />
            </div>
        )
    }
}
