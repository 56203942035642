import * as firebaseActions from "./firebase_actions";

export const EVENT_SET_EVENTS_ACTION = 'EVENT_SET_EVENTS_ACTION';
export const EVENT_UPDATE_EVENTS_ACTION = 'EVENT_UPDATE_EVENTS_ACTION';
export const EVENT_SET_STATUS_ACTION = 'EVENT_SET_STATUS_ACTION';


let sortCalendarEvents = (user, events) => {
    let uid = null;
    if (user && user.uid) {
        uid = user.uid;
    }

    if (events && events.length) {
        let eventCollections = {
            upcoming: [],
            my: [],
            scheduled: []
        }
        let now = Date.now();

        events.map((item) => {
            if (item.startDate > now && item.visibility && item.visibility === "public" || item.startDate > now && item.visibility && uid && item.uid === uid && item.visibility === "private") {
                eventCollections.upcoming.push(item);
            }
            if (item.uid === uid) {
                eventCollections.my.push(item);
            }
            if (item.visibility && item.visibility === "public" || item.visibility && uid && item.uid === uid && item.visibility === "private") {
                eventCollections.scheduled.push(item);
            }
        });
        eventCollections.upcoming.sort((a, b) => {
            if (a.startDate < b.startDate) {
                return -1;
            }
            if (a.startDate > b.startDate) {
                return 1;
            }
            return 0;
        });
        
        return eventCollections;
    }
}


export const setEvents = (user, events) => {

    return {
        type: EVENT_SET_EVENTS_ACTION,
        payload: sortCalendarEvents(user,events)
    };
}

export const updateEvents = (data) => {
    return {
        type: EVENT_UPDATE_EVENTS_ACTION,
        payload: {
            ...data,
        }
    };
}

export const setEventStatus = (status) => {
    return {
        type: EVENT_SET_STATUS_ACTION,
        payload: {
            ...status,
        }
    };
}

