import React, { Component } from 'react';
import { connect } from 'react-redux';
import Draggable from "react-draggable";
import deviceManager from '../../../../utils/DeviceManager';

class SelfView extends Component {
    constructor(props) {
        super(props);

        this.selfVideoStream = null;
    }

    componentDidMount() {
        this.checkSelfView()
    }

    componentDidUpdate(prevProps) {
        const { selfView, videoInputDevice } = this.props;

        if ((selfView !== prevProps.selfView) || ((videoInputDevice !== prevProps.videoInputDevice) || (videoInputDevice && prevProps.videoInputDevice && JSON.stringify(videoInputDevice) !== JSON.stringify(prevProps.videoInputDevice)))) {
            this.checkSelfView();
        }
    }

    componentWillUnmount() {
        if (this.selfVideoStream) {
            deviceManager.stopStreamTracks(this.selfVideoStream);
            this.selfVideoStream = null;
        }
    }

    async checkSelfView() {
        const { selfView } = this.props;

        if (this.selfVideoStream) {
            deviceManager.stopStreamTracks(this.selfVideoStream);
            this.selfVideoStream = null;
        }

        if (selfView) {
            this.selfVideoStream = await deviceManager.getVideoStream(this.selfVideoRef);
        }
    }

    render() {
        const { selfView } = this.props;

        if (selfView) {
            return (
                <Draggable bounds="parent">
                    <div className='self-view-tile'>
                        <video
                            ref={(ref) => (this.selfVideoRef = ref)}
                            className='self-view-video'
                            playsInline
                            autoPlay
                            muted
                            onContextMenu={e => e.preventDefault()}
                        />
                    </div>
                </Draggable>
            )
        } else return <div />
    }
}

const mapStateToProps = (state) => {
    return {
        selfView: state.room.selfView,
        videoInputDevice: state.devices.videoInputDevice
    };
};

const SelfViewContainer = connect(
    mapStateToProps
)(SelfView);

export default SelfViewContainer;
