import React, { Component } from 'react';
import classNames from 'classnames';
import { Helmet } from "react-helmet";
import ComponentHeader from '../Widget/ComponentHeader';
import QuestionEmailComponent from '../Footer/QuestionEmailComponent';
import { strings } from '../../translations/localizedStrings';

class Contact extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={classNames('bg-100', { 'header-taller': process.env.build && process.env.build === 'nfl-aa' })}>
                <Helmet>
                    {/*Primary Meta Tags*/}
                    <meta charSet="utf-8" />
                    <title>{`${strings.title} - Contact Us`}</title>
                    <meta name="title" content={`${strings.title} - Contact Us`} />
                    <link rel="canonical" href={`${process.env.domain}/contact`} />
                    {/*Open Graph / Facebook*/}
                    <meta property="og:url" content={`${process.env.domain}/contact`} />
                    <meta property="og:title" content={`${strings.title} - Contact Us`} />
                    {/*Twitter*/}
                    <meta property="twitter:url" content={`${process.env.domain}/contact`} />
                    <meta property="twitter:title" content={`${strings.title} - Contact Us`} />
                </Helmet>
                <ComponentHeader text="contact us" />
                <QuestionEmailComponent />
            </div>
        )
    }
}

export default Contact;