export const JOIN_ROOM_ACTION = 'JOIN_ROOM_ACTION';
export const EXIT_ROOM_ACTION = 'EXIT_ROOM_ACTION';
export const SET_DEVICES_DATA_ACTION = 'SET_DEVICES_DATA_ACTION';
export const SET_DEVICES_ACTION = 'SET_DEVICES_ACTION';
export const SET_VIDEO_ENABLED_ACTION = 'SET_VIDEO_ENABLED_ACTION';
export const SET_AUDIO_ENABLED_ACTION = 'SET_AUDIO_ENABLED_ACTION';
export const SET_AUDIO_INPUT_DEVICE_ACTION = 'SET_AUDIO_INPUT_DEVICE_ACTION';
export const SET_AUDIO_OUTPUT_DEVICE_ACTION = 'SET_AUDIO_OUTPUT_DEVICE_ACTION';
export const SET_VIDEO_INPUT_DEVICE_ACTION = 'SET_VIDEO_INPUT_DEVICE_ACTION';

export const joinRoom = (payload) => {
    return {
        type: JOIN_ROOM_ACTION,
        payload: payload
    };
}

export const exitRoom = () => {
    return {
        type: EXIT_ROOM_ACTION
    };
}

export const setDevicesData = (payload) => {
    return {
        type: SET_DEVICES_DATA_ACTION,
        payload: payload
    };
}

export const setDevices = (devices) => {
    return {
        type: SET_DEVICES_ACTION,
        payload: devices
    };
}

export const setVideoEnabled = (value) => {
    return {
        type: SET_VIDEO_ENABLED_ACTION,
        payload: {
            videoEnabled: value
        }
    };
}

export const setAudioEnabled = (value) => {
    return {
        type: SET_AUDIO_ENABLED_ACTION,
        payload: {
            audioEnabled: value
        }
    };
}

export const setAudioInputDevice = (device) => {
    return {
        type: SET_AUDIO_INPUT_DEVICE_ACTION,
        payload: {
            audioInputDevice: device
        }
    };
}

export const setAudioOutputDevice = (device) => {
    return {
        type: SET_AUDIO_OUTPUT_DEVICE_ACTION,
        payload: {
            audioOutputDevice: device
        }
    };
}

export const setVideoInputDevice = (device) => {
    return {
        type: SET_VIDEO_INPUT_DEVICE_ACTION,
        payload: {
            videoInputDevice: device
        }
    };
}