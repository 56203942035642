import { EnhancedEventEmitter } from "mediasoup-client/lib/EnhancedEventEmitter";


class StreamManager extends EnhancedEventEmitter {
    #callMngr = null;
    #isStreaming = false;
    #isSharingScreen = false;
    #isSharingVideo = false;
    #callState = null;
    #layoutState = null;
    #videoTracks = {};
    #audioTracks = {};

    /**
     * Constructor
     */
    constructor({ callManager }) {
        super();
        this.#callMngr = callManager;

        this.onTrackAdded = this.onTrackAdded.bind(this);
        this.onTrackRemoved = this.onTrackRemoved.bind(this);
        this.onShowBackground = this.onShowBackground.bind(this);
        this.onOrientationChanged = this.onOrientationChanged.bind(this);
        this.onChangeLayout = this.onChangeLayout.bind(this);
        this.onActiveSpeaker = this.onActiveSpeaker.bind(this);
        this.onVideoPresentation = this.onVideoPresentation.bind(this);
        this.onUpdateProducerVideo = this.onUpdateProducerVideo.bind(this);

        this.#callMngr.on('trackAdded', this.onTrackAdded);
        this.#callMngr.on('trackRemoved', this.onTrackRemoved);
        this.#callMngr.on('showBackground', this.onShowBackground);
        this.#callMngr.on('videoPresentation', this.onVideoPresentation);
        this.#callMngr.on('updateProducerVideo', this.onUpdateProducerVideo);
        this.#callMngr.on('orientationChanged', this.onOrientationChanged);
        this.#callMngr.on('changeLayout', this.onChangeLayout);
        this.#callMngr.on('activeSpeaker', this.onActiveSpeaker);
    }

    destroy() {
        if (this.#videoTracks) {
            console.log('destroy()  About to stop video tracks');
            Object.values(this.#videoTracks).forEach?.(tdata => {
                tdata.track?.stop?.()
            });
            this.#videoTracks = {};
        }
        if (this.#audioTracks) {
            console.log('destroy()  About to stop audio tracks');
            Object.values(this.#audioTracks).forEach?.(tdata => {
                tdata.track?.stop?.()
            });
            this.#audioTracks = {};
        }

        this.#callMngr.removeListener('trackAdded', this.onTrackAdded);
        this.#callMngr.removeListener('trackRemoved', this.onTrackRemoved);
        this.#callMngr.removeListener('showBackground', this.onShowBackground);
        this.#callMngr.removeListener('videoPresentation', this.onVideoPresentation);
        this.#callMngr.removeListener('updateProducerVideo', this.onUpdateProducerVideo);
        this.#callMngr.removeListener('orientationChanged', this.onOrientationChanged);
        this.#callMngr.removeListener('changeLayout', this.onChangeLayout);
        this.#callMngr.removeListener('activeSpeaker', this.onActiveSpeaker);
        this.#callMngr = null;
        this.#isStreaming = false;
        this.#isSharingScreen = false;
        this.#isSharingVideo = false;
        this.#callState = null;
        this.#layoutState = null;
    }

    onTrackAdded(data) {
        console.log('onTrackAdded', data);
        try {
            let { kind, producerId, track, eventId, role, audienceView } = data;
            let update = data, isPeekRoom = !eventId && !role;
            update.type = 'horizontal';
            if (kind === 'audio') {
                if (this.#audioTracks[producerId]) {
                    update = { ...this.#audioTracks[producerId], ...update };
                }
                this.#audioTracks[producerId] = update;
            } else {
                if (this.#videoTracks[producerId]) {
                    update = { ...this.#videoTracks[producerId], ...update };
                }
                this.#videoTracks[producerId] = update;
            }
            if (!update.stream) {
                update.stream = new MediaStream([track]);
            } else {
                let tracks = update.stream.getTracks();
                if (tracks && tracks[0] && tracks[0].id !== track.id) {
                    update.stream.removeTrack(tracks[0])
                    update.stream.addTrack(track);
                }
            }
            if (kind === 'audio') {
                // TODO: Add to mixer
            } else {
                if (isPeekRoom && audienceView) {
                    console.log('About to emit onAVAdded', update);
                    this.emit('onAVAdded', update);
                } else {
                    console.log('About to emit onVideoAdded', update);
                    this.emit('onVideoAdded', update);
                }
            }
        } catch (e) {
            console.error('Error while adding track', e && e.message);
        }

    }

    onTrackRemoved(data) {
        try {
            console.log('onTrackRemoved', data)
            let { uid, eventId, name, kind, producerId, audienceView, role } = data;
            let isPeekRoom = !eventId && !role;
            if (kind === 'audio') {
                delete this.#audioTracks[producerId];
            } else {
                delete this.#videoTracks[producerId];

                if (isPeekRoom && audienceView) {
                    console.log('About to emit onAVRemoved', data);
                    this.emit('onAVRemoved', data);
                } else {
                    console.log('About to emit onVideoRemoved', data);
                    this.emit('onVideoRemoved', data);
                }
            }
        } catch (e) {
            console.error('Error while removing track', e && e.message);
        }
    }

    resyncTracks() {
        try {
            console.log('resyncTracks')
            let streams = Object.values(this.#videoTracks)//.map(o => o.stream);
            console.log('About to emit showActiveSpeakers', { streams })
            this.emit('showActiveSpeakers', { streams })
        } catch (e) {
            console.error('Error while synchronizing tracks', e && e.message);
        }
    }

    onShowBackground(data) {
        try {
            console.log('onShowBackground')
            this.emit('showBackground', { data })
        } catch (e) {
            console.error('Error show background', e && e.message);
        }
    }

    onVideoPresentation(data) {
        try {
            console.log('onVideoPresentation', data)
            this.emit('videoPresentation', data);
        } catch (e) {
            console.error('Error while onVideoPresentation', e && e.message);
        }
    }

    onUpdateProducerVideo(data) {
        try {
            console.log('onUpdateProducerVideo', data)
            this.emit('updateProducerVideo', data);
        } catch (e) {
            console.error('Error while onUpdateProducerVideo', e && e.message);
        }
    }

    onOrientationChanged(data) {
        try {
            console.log('onOrientationChanged', data)
            this.emit('orientationChanged', data)
        } catch (e) {
            console.error('Error while onOrientationChanged', e && e.message);
        }
    }

    onChangeLayout(data) {
        try {
            console.log('onChangeLayout', data)
            this.emit('changeLayout', data)
        } catch (e) {
            console.error('Error while onChangeLayout', e && e.message);
        }
    }

    onActiveSpeaker(data) {
        try {
            console.log('onActiveSpeaker', data)
            this.emit('activeSpeaker', data)
        } catch (e) {
            console.error('Error while onActiveSpeaker', e && e.message);
        }
    }
}

export default StreamManager;