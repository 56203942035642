import React, { Component } from 'react';
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import ComponentHeader from '../Widget/ComponentHeader';
import QuestionEmailComponent from '../Footer/QuestionEmailComponent';
import path from '../../../resources/assets/path.svg';
import vector from '../../../resources/icons/vector.png';
import { faq } from '../../utils/text';
import { strings } from '../../translations/localizedStrings';

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'about',
            input: '',
            faqList: faq
        }

        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleChangeFaqSelect = this.handleChangeFaqSelect.bind(this);
        this.handleChangeFaq = this.handleChangeFaq.bind(this);
    }

    componentDidMount() {
        this.setFocus();
    }

    componentWillUnmount() {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    handleChangeFaqSelect(e) {
        let value = e.target.value;
        this.setState({
            tab: value
        });
    }

    handleChangeFaq(value) {
        this.setState({
            tab: value
        }, () => {
            this.setFocus();
        });
    }

    setFocus() {
        let el = document.getElementsByClassName('button-focus')[0];

        if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            this.timeout = setTimeout(() => {
                el.focus();
            }, 500);
        }
    }

    renderFaq() {
        const { tab, faqList } = this.state;

        const styles = {
            bgColor: 'transparent',
            titleTextColor: "white",
            rowTitleColor: "white",
            rowContentColor: 'rgba(251, 251, 251, 0.7)',
            arrowColor: "#17B0EF"
        };

        const config = {
            animate: true,
            arrowIcon: <img src={vector} loading='lazy' />,
            tabFocus: false
        };

        switch (tab) {
            case 'about': {
                return (
                    <Faq data={faqList.aboutData} styles={styles} config={config} />
                )
            }
            case 'technical': {
                return (
                    <Faq data={faqList.techData} styles={styles} config={config} />
                )
            }
            case 'viewers': {
                return (
                    <Faq data={faqList.viewersData} styles={styles} config={config} />
                )
            }
            case 'hosts': {
                return (
                    <Faq data={faqList.hostsData} styles={styles} config={config} />
                )
            }
            case 'monetization': {
                return (
                    <Faq data={faqList.monetizationData} styles={styles} config={config} />
                )
            }
            default: {
                return null;
            }
        }
    }

    handleChangeInput(e) {
        this.setState({
            input: e.target.value
        }, () => {
            const { input } = this.state;

            let inputValue = input.toLowerCase();

            if (inputValue && inputValue.length) {
                let newFaqList = {};
                newFaqList.aboutData = { rows: this.searchArray(faq.aboutData, inputValue) };
                newFaqList.hostsData = { rows: this.searchArray(faq.hostsData, inputValue) };
                newFaqList.monetizationData = { rows: this.searchArray(faq.monetizationData, inputValue) };
                newFaqList.techData = { rows: this.searchArray(faq.techData, inputValue) };
                newFaqList.viewersData = { rows: this.searchArray(faq.viewersData, inputValue) };
                this.setState({
                    faqList: newFaqList
                });
            } else {
                this.setState({
                    faqList: faq
                });
            }
        });
    }

    searchArray(array, inputValue) {
        let searchRows = []
        array.rows.map((item) => {
            let exist = false
            if (item) {
                if (item.title && item.title.props && item.title.props.children) {
                    let children = item.title.props.children;
                    if (Array.isArray(children)) {
                        children.map((item) => {
                            if (item.props && item.props.children && item.props.children.toLowerCase().indexOf(inputValue) !== -1) {
                                exist = true;
                            }
                        })
                    } else if (children.toLowerCase().indexOf(inputValue) !== -1) {
                        exist = true;
                    }
                }
                if (item.content && item.content.props && item.content.props.children) {
                    let children = item.content.props.children;
                    if (children.toLowerCase && children.toLowerCase().indexOf(inputValue) !== -1) {
                        exist = true;
                    }
                }
            }
            if (exist) {
                searchRows.push(item);
            }
        });
        return searchRows;
    }

    render() {
        const { tab, input, faqList } = this.state;

        return (
            <div>
                <Helmet>
                    {/*Primary Meta Tags*/}
                    <meta charSet="utf-8" />
                    <title>{`${strings.title} - FAQ`}</title>
                    <meta name="title" content={`${strings.title} - FAQ`} />
                    <link rel="canonical" href={`${process.env.domain}/faq`}  />
                    {/*Open Graph / Facebook*/}
                    <meta property="og:url" content={`${process.env.domain}/faq`} />
                    <meta property="og:title" content={`${strings.title} - FAQ`} />
                    {/*Twitter*/}
                    <meta property="twitter:url" content={`${process.env.domain}/faq`} />
                    <meta property="twitter:title" content={`${strings.title} - FAQ`} />
                </Helmet>
                <ComponentHeader text='faq' />
                <div className='faq-search-wrapper'>
                    <div className='faq-search'>
                        <img src={path} className='path' loading='lazy' />
                        <input
                            type="text"
                            className="search-input"
                            placeholder='Type your question here'
                            value={input}
                            onChange={this.handleChangeInput}
                        />
                    </div>
                </div>
                {!faqList || (
                    (!faqList.aboutData || !faqList.aboutData.rows || !faqList.aboutData.rows.length)
                    && (!faqList.techData || !faqList.techData.rows || !faqList.techData.rows.length)
                    && (!faqList.viewersData || !faqList.viewersData.rows || !faqList.viewersData.rows.length)
                    && (!faqList.hostsData || !faqList.hostsData.rows || !faqList.hostsData.rows.length)
                    && (!faqList.monetizationData || !faqList.monetizationData.rows || !faqList.monetizationData.rows.length)) ?
                    <div className='faq-wrapper'>
                        <p className='faq-search-message'>We found nothing</p>
                    </div>
                    :
                    <div className='faq-wrapper'>
                        <select
                            className="faq-select"
                            value={tab}
                            onChange={this.handleChangeFaqSelect}
                        >
                            {faqList && faqList.aboutData && faqList.aboutData.rows && faqList.aboutData.rows.length ?
                                <option value='about'>About No Filter Network</option>
                                : null
                            }
                            {faqList && faqList.techData && faqList.techData.rows && faqList.techData.rows.length ?
                                <option value='technical'>Technical/Support for No Filter Network</option>
                                : null
                            }
                            {faqList && faqList.viewersData && faqList.viewersData.rows && faqList.viewersData.rows.length ?
                                <option value='viewers'>No Filter Network Viewers/Accounts</option>
                                : null
                            }
                            {faqList && faqList.hostsData && faqList.hostsData.rows && faqList.hostsData.rows.length ?
                                <option value='hosts'>No Filter Network Hosts</option>
                                : null
                            }
                            {faqList && faqList.monetizationData && faqList.monetizationData.rows && faqList.monetizationData.rows.length ?
                                <option value='monetization'>Monetization as a Host on No Filter Network</option>
                                : null
                            }
                        </select>
                        <div className='faq-items faq'>
                            {faqList && faqList.aboutData && faqList.aboutData.rows && faqList.aboutData.rows.length ?
                                <div className='faq-item'>
                                    <span
                                        onClick={() => this.handleChangeFaq('about')}
                                        className={classNames({ 'active': tab && tab === 'about' })}
                                    >
                                        About No Filter Network
                                </span>
                                </div>
                                :
                                null
                            }
                            {faqList && faqList.techData && faqList.techData.rows && faqList.techData.rows.length ?
                                <div className='faq-item'>
                                    <span
                                        onClick={() => this.handleChangeFaq('technical')}
                                        className={classNames({ 'active': tab && tab === 'technical' })}
                                    >
                                        Technical/Support for No Filter Network
                            </span>
                                </div>
                                :
                                null
                            }
                            {faqList && faqList.viewersData && faqList.viewersData.rows && faqList.viewersData.rows.length ?
                                <div className='faq-item'>
                                    <span
                                        onClick={() => this.handleChangeFaq('viewers')}
                                        className={classNames({ 'active': tab && tab === 'viewers' })}
                                    >
                                        No Filter Network Viewers/Accounts
                            </span>
                                </div>
                                :
                                null
                            }
                            {faqList && faqList.hostsData && faqList.hostsData.rows && faqList.hostsData.rows.length ?
                                <div className='faq-item'>
                                    <span
                                        onClick={() => this.handleChangeFaq('hosts')}
                                        className={classNames({ 'active': tab && tab === 'hosts' })}
                                    >
                                        No Filter Network Hosts
                            </span>
                                </div>
                                :
                                null
                            }
                            {faqList && faqList.monetizationData && faqList.monetizationData.rows && faqList.monetizationData.rows.length ?
                                <div className='faq-item'>
                                    <span
                                        onClick={() => this.handleChangeFaq('monetization')}
                                        className={classNames({ 'active': tab && tab === 'monetization' })}
                                    >
                                        Monetization as a Host on No Filter Network
                            </span>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className='faq-content faq'>
                            {this.renderFaq()}
                        </div>
                    </div>}
                <QuestionEmailComponent
                    blackBg={true}
                />
            </div>
        )
    }
}

export default FAQ;