import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebaseClient from '../../utils/FirebaseClient';
import Button from '../Widget/Button';
import closeIcon from '../../../resources/assets/x.svg';

class InquiryWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            daytime_contact: "",
            message: "",
            processing: false,
        }
    }

    handleBack() {
        const { closeInquiryWrapper } = this.props;

        closeInquiryWrapper()
    }

    handleInquireClick() {
        const {
            message,
            first_name,
            last_name,
            email,
            daytime_contact,
        } = this.state;

        const {
            user
        } = this.props;

        let name = first_name + " " + last_name;

        // this.setState({inquireForm : true});

        console.log("Details ", this.props.broadcaster_username, this.props.broadcaster_email, message)

        this.setState({ processing: true });
        firebaseClient.sendInquireEmail(this.props.broadcaster_username, this.props.broadcaster_email, name, email, daytime_contact, message).then(() => {
            this.setState({ processing: false })
            this.handleBack()
        })
    }


    render() {
        const { first_name, last_name, email, daytime_contact, message, processing } = this.state;
        const { broadcaster_username } = this.props;

        return (
            <div className='inquiry-wrapper'>
                <div className='inquiry-form'>
                    <div className='inquiry-div'>
                        <img className='inquiry-close' onClick={() => this.handleBack()} src={closeIcon} loading='lazy' />
                        <div className='inquiry-title'>Inquiry</div>
                        <div className='inquiry-info-line'>
                            <div className='inquiry-input-line'>
                                <input
                                    type="text"
                                    placeholder="First Name*"
                                    value={first_name}
                                    onChange={(e) => this.setState({ first_name: e.target.value })}
                                />
                            </div>
                            <div className='inquiry-input-line'>
                                <input
                                    type="text"
                                    placeholder="Last Name*"
                                    value={last_name}
                                    onChange={(e) => this.setState({ last_name: e.target.value })}
                                />
                            </div>
                            <div className='inquiry-input-line'>
                                <input
                                    type="text"
                                    placeholder="Email Address*"
                                    value={email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                />
                            </div>
                            <div className='inquiry-input-line'>
                                <input
                                    type="text"
                                    placeholder="Daytime Contact*"
                                    value={daytime_contact}
                                    onChange={(e) => this.setState({ daytime_contact: e.target.value })}
                                />
                            </div>
                            <div className="inqury-input-text">
                                Type(s) of private event you seeking from {broadcaster_username}* (may use commas to separate request)
                            </div>
                            <div className='inquiry-input-line'>
                                <input
                                    type="text"
                                    placeholder="Enter request type(s)"
                                    value={message}
                                    onChange={(e) => this.setState({ message: e.target.value })}
                                />
                            </div>
                            <Button
                                type='default'
                                className='btn-inquiry'
                                onClick={() => this.handleInquireClick()}
                                disabled={processing}
                                loading={processing}
                            >
                                Submit
                            </Button>
                            <Button
                                type='text-white'
                                className='btn-inquiry'
                                onClick={() => this.handleBack()}
                                disabled={processing}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
    };
};


const InquiryWrapperContainer = connect(
    mapStateToProps
)(InquiryWrapper);

export default InquiryWrapperContainer;
