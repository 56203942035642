import React, { Component } from "react";
import { connect } from "react-redux";
import recordingActive from "../../../../resources/assets/record-active.svg";

class RecordingStatus extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { streaming } = this.props;

        return (
            <div>
                {streaming ?
                    <div className='recording-button'>
                        <img src={recordingActive} alt='recording' loading='lazy' />
                    </div>
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        streaming: state.room.streaming,
    };
};

const RecordingStatusContainer = connect(
    mapStateToProps
)(RecordingStatus);

export default RecordingStatusContainer;
