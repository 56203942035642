import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { push as nativePush } from 'connected-react-router';
import path from '../../resources/assets/path.svg';
import LogInBtn from './Header/LogInBtn';
import NavBar from './Header/NavBar';
import LogIn from './Header/LogIn';
import Button from './Widget/Button';
import * as appActions from "../actions/app_actions";
import {
    getQueryVariable,
    signupCampaignPageLink,
    dudaLink,
    homeLink,
    showBecomeAHost,
    logo,
    facebookLink,
    twitterLink,
    instagramLink,
    youtubeLink,
    linkedInLink,
    tikTokLink,
} from "../utils/text";
import tikTokIcon from "../../resources/assets/tiktok.svg";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            input: ''
        }

        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
        this.handlePushToSearch = this.handlePushToSearch.bind(this);
    }

    handleChangeInput(e) {
        this.setState({
            input: e.target.value
        });
    }

    enterPressed(event) {
        const { input } = this.state;
        var code = event.keyCode || event.which;
        if (code === 13 && input) {
            this.handlePushToSearch();
        }
    }

    handlePushToSearch() {
        const { input } = this.state;
        const { pushToSearch } = this.props;

        if (input && input.length && pushToSearch) {
            pushToSearch(input);
        }
    }

    componentDidMount() {
        let q = getQueryVariable('q');

        this.setState({
            input: q || ''
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { input } = this.state;
        const { location } = this.props;

        let q = getQueryVariable('q');

        if (location && location.search && location.search !== prevProps.location.search) {
            this.setState({
                input: q || ''
            });
        }

        if (location && location.pathname && location.pathname !== prevProps.location.pathname && location.pathname !== '/search') {
            if (input) {
                this.setState({
                    input: ''
                });
            }
        }
    }

    handleShowLogIn() {
        const { location, showLogIn, logIn, pushToHome, auth } = this.props;
        const pathname = location.pathname;

        if (showLogIn) {
            showLogIn(!logIn);
            if (logIn && pathname && pathname !== "/" && pathname.indexOf('/profile') === -1 && pushToHome && !auth) {
                pushToHome();
            }
        }
    }

    handleShowNavList() {
        const { showNavList, navList } = this.props;
        if (showNavList) {
            showNavList(!navList);
        }
    }

    render() {
        const { input } = this.state;
        const { history, auth, logIn, navList, roles, mobile } = this.props;

        return (
            <div>
                <div className={classNames('header', { 'header-taller': process.env.build && process.env.build === 'nfl-aa' })}>
                    <div className='header-left'>
                        <NavBar activeTab={history.location.pathname} />
                        <div className={classNames('header-logo', { 'header-taller': process.env.build && process.env.build === 'nfl-aa' })}>
                            {process.env.build && process.env.build === 'nfl-aa' && homeLink ?
                                <a href={homeLink}>
                                    <img src={logo} className='logo' loading='lazy' />
                                </a>
                                :
                                <Link to="/">
                                    <img src={logo} className='logo' loading='lazy' />
                                </Link>
                            }
                        </div>
                    </div>
                    <div className='search-wrapper'>
                        <img
                            src={path}
                            className='path'
                            loading='lazy'
                            onClick={this.handlePushToSearch}
                        />
                        <input
                            type="text"
                            className="search-input"
                            placeholder='Search events'
                            value={input}
                            onChange={this.handleChangeInput}
                            onKeyPress={this.enterPressed}
                        />
                    </div>
                    <div className='header-button'>
                        {showBecomeAHost && (!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                            <a href={signupCampaignPageLink}>
                                <Button
                                    type='border'
                                    className='btn-header-become'
                                >
                                    become a host
                                </Button>
                            </a> : null
                        }
                        {auth && roles && roles.broadcaster && history && history.location && history.location.pathname && history.location.pathname.indexOf('/calendar') !== -1 &&
                            <a href={`${dudaLink}/schedule-an-event`}>
                                <Button
                                    type='border'
                                    className='btn-header-become'
                                >
                                    How To Create Event
                                </Button>
                            </a>
                        }
                        {(process.env.build && process.env.build === 'nfl-aa') ?
                            <div className="social-media-links-header">
                                {facebookLink ?
                                    <a
                                        className="facebook-icon fa fa-facebook"
                                        href={facebookLink}
                                        target="_blank"
                                    />
                                    : null
                                }
                                {twitterLink ?
                                    <a
                                        className="twitter-icon fa fa-twitter"
                                        href={twitterLink}
                                        target="_blank"
                                    />
                                    : null
                                }
                                {instagramLink ?
                                    <a
                                        className="instagram-icon fa fa-instagram"
                                        href={instagramLink}
                                        target="_blank"
                                    />
                                    : null
                                }
                                {youtubeLink ?
                                    <a
                                        className="youtube-icon fa fa-youtube-play"
                                        href={youtubeLink}
                                        target="_blank"
                                    />
                                    : null
                                }
                                {linkedInLink ?
                                    <a
                                        className="linkedIn-icon fa fa-linkedin-square"
                                        href={linkedInLink}
                                        target="_blank"
                                    />
                                    : null
                                }
                                {tikTokLink ?
                                    <a
                                        className="tiktok-icon"
                                        href={tikTokLink}
                                        target="_blank"
                                    >
                                        <img src={tikTokIcon} loading='lazy' />
                                    </a>
                                    : null
                                }
                            </div>
                            : null
                        }
                        <LogInBtn
                            activeLogIn={logIn}
                            activeNavList={navList}
                            activeTab={history.location.pathname}
                            history={history}
                            mobile={mobile}
                            handleShowLogIn={() => this.handleShowLogIn()}
                        />
                    </div>
                    {logIn &&
                        <LogIn
                            handleShowLogIn={() => this.handleShowLogIn()}
                        />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        auth: state.firebase.auth,
        roles: state.firebase.roles,
        navList: state.app.navList,
        orientation: state.app.orientation,
        logIn: state.app.logIn,
        sideBar: state.app.sideBar,
        categories: state.firebase.categories,
        mobile: state.app.user_agent_info.platform.type === 'mobile'
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showNavList: (value) => {
            dispatch(appActions.showNavList(value));
        },
        showLogIn: (value) => {
            dispatch(appActions.showLogIn(value));
        },
        pushToSearch: (value) => {
            dispatch(nativePush(`/search?q=${value}`));
        },
        pushToHome: () => {
            dispatch(nativePush('/'));
        }
    };
};

const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default HeaderContainer;
