import React, { Component } from "react";
import { connect } from 'react-redux';
import { Defines } from '../../utils/FanoutDefines';
import { Device } from "mediasoup-client";
import * as MediasoupClient from "./Fanout/mediasoup-client.js";
import * as devicesActions from "../../actions/devices_actions";
import { v1 as uuidv1 } from 'uuid';
import canAutoPlay from 'can-autoplay';
import CallManager from "../../utils/CallManager";
import fanoutClient from "../../utils/FanoutClient";
import StreamManager from "../../utils/StreamManager";
import Loader from "../Widget/Loader";
import VideoBox from "./Conferencing/Common/VideoBox";

const initialState = {
    videos: [],
    background: null,
    videoBackground: null,
    activeSpeakers: false,
    layout: 'no-crop',
    labels: 'default',
    videoEnabled: true,
    isLoading: false,
    loadingMessage: '',
    canPlay: false,
    showPlayButton: false,
    maxVideoCount: 4,
}

class StreamingAudience extends Component {

    #CallManager = null;
    #StreamManager = null;

    constructor(props) {
        super(props);
        this.state = { ...initialState };
        this.sessionEnded = false;

        this.sessionId = uuidv1();

        this.audioConsumer = null;
        this.videoConsumer = null;
        this.remoteMediaStream = null;
        this.recvTransport = null;
        this.remoteVideoId = null;
        this.remoteAudioId = null;
        this.dataProducer = null;
        this.audioProducer = null;
        this.videoProducer = null;
        this.dataConsumers = new Map();
        this.initialDataProducers = null;
        this.dataProducerCallback = null;
        this.dataProducerErrback = null;

        this.previousFanoutState = Defines.Fanout.Status.Starting;

        this.MediasoupDevice = new MediasoupClient.Device();
        this.getCallState = this.getCallState.bind(this);
        this.setupRoom = this.setupRoom.bind(this);
        this.onAVAdded = this.onAVAdded.bind(this);
        this.onAVRemoved = this.onAVRemoved.bind(this);
        this.rejoinRoom = this.rejoinRoom.bind(this);
        this.reconnectRoom = this.reconnectRoom.bind(this);
        this.handlePeekRoom = this.handlePeekRoom.bind(this);
        this.getAudienceVideo = this.getAudienceVideo.bind(this);
    }

    getAudienceVideo() {
        let { videos } = this.state;

        if (videos && videos.length) {
            return videos[0];
        } else return null;
    }

    async setupRoom() {
        this.sessionEnded = false;
        this.sessionId = uuidv1();
        this.audioConsumer = null;
        this.videoConsumer = null;
        this.remoteMediaStream = null;
        this.recvTransport = null;
        this.remoteVideoId = null;
        this.remoteAudioId = null;
        this.dataProducer = null;
        this.dataConsumers = new Map();
        this.initialDataProducers = null;
        this.dataProducerCallback = null;
        this.dataProducerErrback = null;
        this.consumerData = {};
        this.previousFanoutState = Defines.Fanout.Status.Starting;
        this.MediasoupDevice = new Device();
        this.setState({ ...initialState });
    }

    handlePeekRoom() {
        console.log('Streaming Audience joined');
    }

    async handleJoin() {
        const { eventId, name, uid, username, eventItem, joinRoom } = this.props;

        let params = {
            name, uid, username,
            eventId: eventId || eventItem.id,
            audioInputDevice: null,
            videoInputDevice: null,
            remoteVideoEnabled: true,
            callParams: eventItem.callParams
        };

        let autoPlayResult = await this.handleCanAutoPlay();

        if (!autoPlayResult)
            return;

        this.#CallManager.peekRoom(params).then(this.handlePeekRoom);

        if (joinRoom)
            joinRoom();

        console.log("StreamingAudience handleJoin called, sending join-conference request sessionId:", params, this.props);
    }

    /**
    * @param params
    * @return {Promise<void>}
    */
    async onAVAdded(video) {
        console.log('onAVAdded', video);

        let newVideos = [...this.state.videos, video && video.stream && video.stream.id ? video : null];

        this.setState({
            videos: newVideos,
            activeSpeakers: true
        });
    }

    /**
    * @param params
    * @return {Promise<void>}
    */
    async onAVRemoved(video) {
        console.log('onAVRemoved', video);

        let { videos } = this.state;

        if (video && video.producerId) {
            videos = videos.filter((item) => item.producerId && item.producerId !== video.producerId);
        }

        this.setState({
            videos: videos,
            activeSpeakers: true
        });
    }

    async componentDidMount() {
        this.#CallManager = CallManager.get({ eventId: uuidv1() });
        this.#CallManager.on('rejoin', this.rejoinRoom);
        this.#CallManager.on('reconnect', this.reconnectRoom);
        this.#StreamManager = new StreamManager({ callManager: this.#CallManager });
        this.#StreamManager.on('onAVAdded', this.onAVAdded);
        this.#StreamManager.on('onAVRemoved', this.onAVRemoved);

        this.setupRoom();
        this.handleJoin();
    }

    async handleCanAutoPlay() {
        let APVideo = await canAutoPlay.video({ inline: true, muted: true }).then(({ result }) => result);
        let APAudio = await canAutoPlay.audio().then(({ result }) => result);

        console.log('handleCanAutoPlay result', APVideo, APAudio);

        if (APVideo && APAudio) {
            this.setState({
                canPlay: true,
                showPlayButton: false
            });
            return true;
        } else {
            this.setState({
                canPlay: false,
                showPlayButton: true
            });
            return false;
        }
    }

    getCallState() {
        const { eventId, name, uid, username } = this.props;

        if (eventId) {
            console.log('getCallState', {
                fanoutId: process.env.fanoutId,
                username: username ? username : (name ? name : 'Unknown'),
                uid: uid ? uid : null,
                routerId: this.routerId,
                sessionId: this.sessionId,
                conferenceAlias: eventId,
            });
            return {
                fanoutId: process.env.fanoutId,
                username: username ? username : (name ? name : 'Unknown'),
                uid: uid ? uid : null,
                routerId: this.routerId,
                sessionId: this.sessionId,
                conferenceAlias: eventId,
            }
        } else {
            return null;
        }
    }

    async rejoinRoom() {
        console.log('--- About to rejoin ----------')
        this.setState({
            isLoading: true,
            loadingMessage: 'Rejoining'
        });
        if (this.rejoinTimeout) {
            clearTimeout(this.rejoinTimeout);
        }
        this.rejoinTimeout = setTimeout(() => {
            this.setState({
                isLoading: false,
                loadingMessage: ''
            }, () => {
                this.handleJoin();
            })
        }, 2000);
        console.log('--- rejoin ----------')
    }

    async reconnectRoom() {
        console.log('--- About to reconnect ----------')
        this.setState({
            isLoading: true,
            loadingMessage: 'Reconnecting'
        });
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
        }
        this.reconnectTimeout = setTimeout(() => {
            this.setState({
                isLoading: false,
                loadingMessage: ''
            }, () => {
                this.handleJoin();
            })
        }, 2000);
        console.log('--- reconnect ----------')
    }

    componentWillUnmount() {
        this.#CallManager.removeListener('rejoin', this.rejoinRoom);
        this.#CallManager.removeListener('reconnect', this.reconnectRoom);
        this.#StreamManager.removeListener('onAVAdded', this.onAVAdded);
        this.#StreamManager.removeListener('onAVRemoved', this.onAVRemoved);
        this.#StreamManager.destroy();
        this.#CallManager.destroy();

        if (this.rejoinTimeout) {
            clearTimeout(this.rejoinTimeout);
            this.rejoinTimeout = null;
        }

        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }

        console.log("StreamingAudience ComponentWillUnmount called");
    }

    render() {
        const { isLoading, loadingMessage, canPlay, showPlayButton } = this.state;

        console.log('rerendering...peekRoom', this.state, this.props);
        console.log('videos...', this.state.videos);

        return (
            <div className='conference-wrapper voxeet-wrapper'>
                {isLoading ?
                    <Loader
                        text={loadingMessage}
                        dots={loadingMessage ? true : false}
                        type="page"
                    />
                    : !canPlay ?
                        showPlayButton ?
                            <Loader
                                clickFunction={() => this.handleJoin()}
                                text="Click to play"
                                play
                            />
                            :
                            <Loader
                                text='Connecting stream'
                            />
                        :
                        <div className='audience-view-wrapper'>
                            {this.getAudienceVideo && this.getAudienceVideo() ?
                                <VideoBox
                                    key={this.getAudienceVideo().id}
                                    className='audience-view-video'
                                    source={this.getAudienceVideo().stream}
                                    track={this.getAudienceVideo().track}
                                    picture={this.getAudienceVideo().avatar}
                                    autoPlay={true}
                                    muted={true}
                                    loop={true}
                                    name={this.getAudienceVideo().name}
                                    labels={null}
                                    loading
                                    speaking={this.getAudienceVideo().speaking}
                                    disableLBM
                                    disableTrackHandler
                                />
                                : null
                            }
                        </div>
                }
            </div>
        );
    }
}

StreamingAudience.defaultProps = {
    conferenceName: "conference_name",
    userName: "Guest " + Math.floor(Math.random() * 100 + 1),
};

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        knocks: state.knocks,
        streaming: state.room.streaming,
        roomMessage: state.room.roomMessage,
        mobile: state.app.user_agent_info.platform.type === 'mobile',
        conferenceEnded: (state.room.conference && (state.room.conference === 'ended_for_audience' || state.room.conference === 'ended')) || (state.room.status && (state.room.status === 'ended_for_audience' || state.room.status === 'ended'))
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        joinRoom: () => {
            dispatch(devicesActions.joinRoom({ preConfiguration: false }));
        }
    };
};

const StreamingAudienceContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamingAudience);

export default StreamingAudienceContainer;
