import { EnhancedEventEmitter } from "mediasoup-client/lib/EnhancedEventEmitter";

class AudioMixer extends EnhancedEventEmitter {
    #audioContext = null;
    #conferenceCallManager = null;
    #conferenceSinkId = null;
    #audioDestination = null;
    #audioElement = null;
    #tracks = {};

    static #events = {};

    static set(params) {
        let { eventId } = params;
        if (!AudioMixer.#events[eventId]) {
            AudioMixer.#events[eventId] = new AudioMixer(params);
        }
        return AudioMixer.#events[eventId];
    }
    static get(params) {
        let { eventId } = params;
        if (!AudioMixer.#events[eventId]) {
            AudioMixer.#events[eventId] = new AudioMixer(params);
        }
        return AudioMixer.#events[eventId];
    }
    static delete(eventId) {
        if (AudioMixer.#events[eventId]) {
            let am = AudioMixer.#events[eventId];
            am.destroy();
            delete AudioMixer.#events[eventId];
        }
    }

    constructor(params) {
        super(params);
        let { callManager, sinkId } = params;

        this.onTrackAdded = this.onTrackAdded.bind(this);
        this.onTrackRemoved = this.onTrackRemoved.bind(this);
        // Create audio context
        this.#audioContext = new AudioContext();
        this.#audioDestination = this.#audioContext.createMediaStreamDestination();
        // this.#audioDestination = this.#audioContext.destination;
        // Connect to call manager
        this.#conferenceCallManager = callManager || null;
        this.#conferenceCallManager?.on('trackAdded', this.onTrackAdded);
        this.#conferenceCallManager?.on('trackRemoved', this.onTrackRemoved);
        // Set audio sink
        this.#conferenceSinkId = sinkId || null;
        this.#conferenceSinkId = (sinkId && sinkId !== 'default') ? sinkId : "";
        this.#audioElement = document.createElement('audio');
        this.#audioElement.id = 'audio-player';
        this.#audioElement.autoplay = true;
        this.#audioElement.srcObject = this.#audioDestination.stream;

        if (this.#conferenceSinkId) {
            // this.#audioContext.setSinkId(this.#conferenceSinkId);
            // this.#audioElement.setSinkId(this.#conferenceSinkId);
            // this.#audioElement.play();
        } else {
            // this.#audioContext.setSinkId({ type : 'none' });
        }
    }

    /**
     * Set sink Id
     * @param id
     * @return {Promise<void>}
     */
    set sinkId(id) {
        this.#conferenceSinkId = (id && id !== 'default') ? id : "";
        if (this.#conferenceSinkId) {
            console.log('set sinkId()  About to set sink id to %s', this.#conferenceSinkId);
            // this.#audioContext.setSinkId(this.#conferenceSinkId);
            this.#audioElement.setSinkId(this.#conferenceSinkId);
            // this.#audioElement.play();
            if (this.#tracks) {
                console.log('destroy()  Set new sink to audio elements');
                Object.values(this.#tracks).forEach?.(tdata => {
                    tdata.audioElement.setSinkId(this.#conferenceSinkId);
                });
            }
        } else {
            console.log('set sinkId()  About to set sink id to none',);
            // this.#audioContext.setSinkId({ type : 'none' });
        }
    }

    /**
     * On track added callback
     * @param trackData
     */
    onTrackAdded(trackData) {
        // console.log('onTrackAdded', trackData);
        try {
            let { track, kind, isMe, audienceView, eventId, role } = trackData;
            let isPeekRoom = !eventId && !role;
            if (kind === 'audio' && !isMe && ((!audienceView) || (audienceView && !isPeekRoom))) {
                console.log('onTrackAdded() Got audio track', trackData, this.#audioDestination);
                this.#tracks[track.id] = trackData;
                // this.#tracks[track.id].source = this.#audioContext.createMediaStreamSource(new MediaStream([track]));
                // this.#tracks[track.id].source.connect(this.#audioDestination);
                // this.#tracks[track.id].source.start?.();
                // const oscillator = this.#audioContext.createOscillator();
                // oscillator.type = "square";
                // oscillator.frequency.setValueAtTime(440, this.#audioContext.currentTime); // value in hertz
                // oscillator.connect(this.#audioDestination);
                // oscillator.start();
                // this.#audioElement.srcObject = this.#audioDestination.stream
                this.#tracks[track.id].audioElement = document.createElement('audio');
                this.#tracks[track.id].audioElement.id = 'audio-player-' + track.id;
                this.#tracks[track.id].audioElement.autoplay = true;
                this.#tracks[track.id].audioElement.srcObject = new MediaStream([track]);
                this.#tracks[track.id].audioElement.setSinkId(this.#conferenceSinkId);
                // this.#audioElement.srcObject = new MediaStream([track]);
            }
        } catch (e) {
            console.error('onTrackAdded()  Error:', e && e.message, e);
        }
    }

    /**
     * On track removed callback
     * @param trackData
     */
    onTrackRemoved(trackData) {
        try {
            let { track } = trackData;
            if (this.#tracks[track.id]) {
                console.log('onTrackRemoved', trackData);
                this.#tracks[track.id].audioElement?.remove?.();
                this.#tracks[track.id].source?.disconnect();
                this.#tracks[track.id].track?.stop();
                delete this.#tracks[track.id];
            }
        } catch (e) {
            console.error('onTrackRemoved()  Error:', e && e.message, e);
        }
    }

    destroy() {
        if (this.#tracks) {
            console.log('destroy()  About to stop audio tracks');
            Object.values(this.#tracks).forEach?.(tdata => {
                tdata.source?.disconnect?.();
                tdata.track?.stop?.()
                tdata.audioElement?.remove?.();
            });
            this.#tracks = {};
        }
    }


}

export default AudioMixer;