import React, { Component } from 'react';
import Button from '../Widget/Button';
import ad1 from '../../../resources/images/ad-1.jpg';
import { signupCampaignPageLink } from '../../utils/text';

class BecomeHost extends Component {
    render() {
        return (
            <div className='ad-wrapper'>
                <div className='ad-text'>
                    <p className='ad-title'>be the star of your <span>show</span></p>
                    <p>
                        Want to be a Host on No Filter?
                        <br />
                        Apply for your chance to get early access.
                    </p>
                    <a href={signupCampaignPageLink}>
                        <Button
                            type='default'
                            className='btn-ad'
                        >
                            become a host
                        </Button>
                    </a>
                </div>
                <img src={ad1} loading='lazy' />
            </div>
        )
    }
}

export default BecomeHost;