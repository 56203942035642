import React, { Component } from 'react';

class StaticPage extends Component {
    constructor(props) {
        super(props);

        this.closeStaticPage = this.closeStaticPage.bind(this);
    }

    closeStaticPage() {
        const { close } = this.props;

        if (close) {
            close();
        }
    }

    render() {
        const { title, text } = this.props;

        return (
            <div className='static-page-wrapper'>
                <div className='static-page-div'>
                    <p className='static-page-title'>{title}</p>
                    <p className='static-page-text'>{text}</p>
                    <button
                        className='btn btn-static-cancel'
                        onClick={this.closeStaticPage}
                    >
                        Ok
                    </button>
                </div>
            </div>
        )
    }
}

export default StaticPage;