import React from 'react';

function Counter(props) {
    const [counter, setCounter] = React.useState(props.seconds);

    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        if (counter === 0 && props.zeroFunction) {
            props.zeroFunction();
        }
    }, [counter]);

    return (
        <span>
            {counter && props.endCounter && counter > props.endCounter ? counter : props.zeroText ? props.zeroText : counter}
        </span>
    )
}

export default Counter;