import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import firebaseClient from "../../../utils/FirebaseClient";
import { prices, isIOSPrice, isIOS } from "../../../utils/text";
import * as broadcastActions from "../../../actions/broadcast_actions";
import * as paymentActions from "../../../actions/payment_actions";
import Tip from "../../../../resources/assets/Tip-Bar.svg";
import tipHeader from "../../../../resources/assets/Tip-1.svg";
import heartIcon from '../../../../resources/assets/heart_fill.svg';

let stripe_pk = process.env.build == 'prod' ? process.env.stripe_pk_prod : process.env.build == 'test' ? process.env.stripe_pk_test : process.env.build == 'nfl-aa' ? null : null;
let stripePK = null;

if (stripe_pk) {
    stripePK = loadStripe(stripe_pk);
}

async function confirmCard(stripe, elements, clientSecret, processingPayment, successPayment, failedConfirmPayment, closeTipWrapper, resetPaymentValue) {

    let cardElement = elements.getElement(CardElement);

    if (cardElement && clientSecret) {

        // processingPayment();

        const confirmedCardPayment = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement
            }
        });

        const { paymentIntent, error } = confirmedCardPayment;

        if (error) {
            failedConfirmPayment(error);
            setTimeout(() => {
                resetPaymentValue();
                closeTipWrapper();
            }, 5000);
        } else if (paymentIntent && paymentIntent.status && paymentIntent.status === 'succeeded') {
            cardElement.clear();
            successPayment();
            setTimeout(() => {
                resetPaymentValue();
                closeTipWrapper();
            }, 5000);
        }
    }
};

class TipButtonBottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardComplete: false,
            customTip: props.item && props.item.tipsValue && isIOSPrice(props.item.tipsValue) ? parseFloat(props.item.tipsValue) : 1.99,
            paymentType: ''
        }
    }

    componentDidMount() {
        if (isIOS() && process.env.platform && process.env.platform === 'mobile') {
            this.setState({
                paymentType: 'apple'
            });
        }
    }

    handleShowTipMenu() {
        const { showMenu, showTip } = this.props;
        if (showMenu) {
            showMenu(!showTip);
        }
    }

    handleChangeCardInput(e) {
        this.setState({
            cardComplete: e.complete
        });
    }

    handlePay(stripe, elements) {
        const { customTip } = this.state;
        const { processingPayment, successPayment, failedConfirmPayment, item, createPaymentIntents, resetPaymentValue } = this.props;

        let ticketType = '';

        if (item && item.type && item.type === "event" && item.status !== 'ended') {
            ticketType = 'event-ticket';
        } else if (item && item.type && ((item.type === "stream") || (item.status && item.status === 'ended'))) {
            ticketType = 'stream-ticket';
        } else {
            ticketType = 'event-ticket';
        }
        if (createPaymentIntents && item && item.id, ticketType) {
            processingPayment();
            firebaseClient.createPaymentIntentsOrThrowError({ type: 'tip', streamId: item.id, ticketType: ticketType, customTip: parseFloat(customTip) }).then((res) => {
                if (res) {
                    confirmCard(stripe, elements, res, processingPayment, successPayment, (error) => failedConfirmPayment(error), () => this.handleBack(), resetPaymentValue);
                }
            })
        }
    };

    handleBack() {
        const { showMenu, resetPaymentValue, item } = this.props;

        if (showMenu && resetPaymentValue && item) {
            resetPaymentValue();
            showMenu(false);
            this.setState({
                cardComplete: false,
                customTip: item && item.tipsValue && isIOSPrice(item.tipsValue) ? parseFloat(item.tipsValue) : 1.99
            });
        }
    }

    render() {
        const { cardComplete, customTip, paymentType } = this.state;
        const { processing, succeeded, error, item, showTip, isAnonymous } = this.props;

        return (
            <li className={classNames("toggle-tip", { 'active': showTip }, { 'disabled': processing || isAnonymous })}>
                {showTip &&
                    <div className={classNames("bubble-tip tip-menu")}>
                        <img src={tipHeader} loading='lazy' />
                        <div className='tip-title'>SEND A TIP</div>
                        {succeeded ?
                            <div className='success-wrapper'>
                                <div className='text-line'>Send Tip</div>
                                <div className='text-line'>Success</div>
                                <div className='text-line'><img src={heartIcon} loading='lazy' /></div>
                            </div>
                            : error ?
                                <div className='error-message'>
                                    {error && error.message ?
                                        <div className='tip-detail error-text'>{error.message}</div>
                                        : error ?
                                            <div className='tip-detail error-text'>Send Tip Failed</div>
                                            :
                                            null
                                    }
                                </div>
                                :
                                <div className='tip-div'>
                                    <select
                                        className="form-control"
                                        value={customTip}
                                        disabled={processing}
                                        onChange={(e) => this.setState({ customTip: e.target.value })}
                                    >
                                        {prices.map((price, index) => {
                                            if (item && item.tipsValue && price && price >= (isIOSPrice(item.tipsValue) ? item.tipsValue : 1.99)) {
                                                return (
                                                    <option key={index} value={price}>{`$${price}`}</option>
                                                );
                                            }
                                        })}
                                    </select>
                                    {stripePK ?
                                        <Elements stripe={stripePK}>
                                            <ElementsConsumer>
                                                {({ stripe, elements }) => (
                                                    <div>
                                                        {!paymentType ?
                                                            <div className='tip-input'>
                                                                {/* <label>Card details</label> */}
                                                                <CardElement
                                                                    id='card-element'
                                                                    options={{
                                                                        hidePostalCode: true,
                                                                        style: {
                                                                            base: {
                                                                                color: '#fff',
                                                                                fontSize: '16px',
                                                                                lineHeight: '33px',
                                                                                backgroundColor: '#000',
                                                                                fontWeight: 300,
                                                                                '::placeholder': {
                                                                                    color: '#9fa5b5',
                                                                                },
                                                                                ':disabled': {
                                                                                    color: '#343a40',
                                                                                },
                                                                            }
                                                                        },
                                                                        disabled: processing
                                                                    }}
                                                                    onChange={(e) => this.handleChangeCardInput(e)}
                                                                />
                                                            </div> : null
                                                        }
                                                        <button
                                                            className='btn tip-btn'
                                                            onClick={() => this.handlePay(stripe, elements)}
                                                            disabled={(!cardComplete && !paymentType) || processing}
                                                        >
                                                            Send Tip
                                                    {processing ? <i className="fa fa-circle-o-notch fa-spin" /> : ''}
                                                        </button>
                                                        <button
                                                            className='btn cancel-btn'
                                                            onClick={() => this.handleBack()}
                                                            disabled={processing}
                                                        >
                                                            Cancel
                                                </button>
                                                    </div>
                                                )}
                                            </ElementsConsumer>
                                        </Elements>
                                        : null
                                    }
                                </div>}
                        <div className="anchor-popup" />
                    </div>}
                <a
                    data-tip
                    data-for="tip"
                    title="Tip"
                    onClick={this.handleShowTipMenu.bind(this)}
                >
                    <img src={Tip} />
                    <div>
                        <span>Tip</span>
                    </div>
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        processing: state.payment.state === "PAYMENT_STATE_PROCESSING",
        succeeded: state.payment.state === "PAYMENT_STATE_SUCCESS",
        error: state.payment.error,
        clientSecret: state.payment.clientSecret,
        showTip: state.broadcast.showTip
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showMenu: (value) => {
            dispatch(broadcastActions.showTipMenu(value));
        },
        createPaymentIntents: (type, streamId, ticketType, customTip) => {
            dispatch(paymentActions.createPaymentIntents({ type, streamId, ticketType, customTip }));
        },
        processingPayment: () => {
            dispatch(paymentActions.processingConfirmPayment());
        },
        successPayment: () => {
            dispatch(paymentActions.successConfirmPayment());
        },
        failedConfirmPayment: (error) => {
            dispatch(paymentActions.failedConfirmPayment(error));
        },
        resetPaymentValue: () => {
            dispatch(paymentActions.resetPaymentIntents());
        }
    };
};

const TipContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TipButtonBottomBar);

export default TipContainer;
