import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import Button from '../Widget/Button';
import firebaseClient from "../../utils/FirebaseClient";
import * as appActions from "../../actions/app_actions";

class QuestionEmailComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            subject: "General",
            processing: false,
            success: false
        }

        this.handleSendEmailClick = this.handleSendEmailClick.bind(this);
    }

    handleChangeSubjectTime(e) {
        let value = e.target.value;
        this.setState({
            subject: value,
            success: false
        });
    }

    componentDidUpdate(prevProps) {
        const { processing } = this.state;
        const { user } = this.props;

        if (prevProps.user != user && user && processing) {
            this.handleSendEmailClick();
        }
    }


    handleSendEmailClick() {
        const {
            message,
            subject,
        } = this.state;

        const {
            user, 
            showLogIn,
        } = this.props;

        this.setState({ processing: true });

        if (user) {
            firebaseClient.sendFaqQuestionEmail(subject, message).then(() => {
                this.setState({
                    message: "",
                    subject: "General",
                    processing: false,
                    success: true
                });
            });
        } else if(!user && showLogIn) {
            showLogIn();
        }
    }


    render() {

        const {
            message,
            subject,
            processing,
            success
        } = this.state;

        const { blackBg } = this.props;

        return (
            <div className={classNames('ask-more-wrapper', { 'black-wrapper': blackBg })}>
                <div className='contact-us-wrapper'>
                    <div className='faq-ask-more'>Have some questions?</div>
                    <div className='contact-us-group-input'>
                        <div className='input-wrapper'>
                            <p>Subject</p>
                            <select
                                className="form-control"
                                value={subject}
                                onChange={this.handleChangeSubjectTime.bind(this)}
                            >
                                <option value='Technical'>Technical</option>
                                <option value='Advertising'>Advertising</option>
                                <option value='Partnerships'>Partnerships</option>
                                <option value='Billing'>Billing</option>
                                <option value='General'>General</option>
                            </select>
                        </div>
                    </div>
                    <div className='contact-us-textarea'>
                        <p>Message</p>
                        <textarea
                            type="text"
                            className="form-control"
                            placeholder="Write message"
                            value={message}
                            onChange={(e) => this.setState({ message: e.target.value, success: false })}
                        />
                    </div>
                    <Button
                        type="default"
                        className="contact-us"
                        onClick={this.handleSendEmailClick}
                        disabled={!subject || !message || processing}
                        loading={processing}
                    >
                        Email Us
                    </Button>
                    {success ? <p className='faq-success'>FAQ mail has been sent</p> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showLogIn: () => {
            dispatch(appActions.showLogIn(true));
        }
    };
};

const QECContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(QuestionEmailComponent);

export default QECContainer;
