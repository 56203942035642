import {
    // Actions
    MYSTUFF_MY_TICKETS_ACTION,
    MYSTUFF_MY_STUFF_ACTION,
    MYSTUFF_ADD_PROCESSING_TICKET,
} from "../actions/mystuff_actions";

const initialState = {
    vault: null,
    private_vault: null,
    live: null,
    upcomingEvents: null,
    pastEvents: null,
    hostingEvents: null,
    hostedEvents: null,
    followingEvents: null,
    hostSubscriptions: null,
    subscribedToHosts: null,
    tickets: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MYSTUFF_MY_TICKETS_ACTION:
        case MYSTUFF_MY_STUFF_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, data);
        }
        case MYSTUFF_ADD_PROCESSING_TICKET: {
            const data = action.payload;

            state.tickets[data.ticket.eventId] = data.ticket;

            return state;
        }
        default:
            return state;
    }
};

export default reducer;
