import React, { Component } from 'react';
import { connect } from "react-redux";
import Loader from './Loader';
import * as appActions from "../../actions/app_actions";

class CheckingLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true
        }
    }

    componentDidMount() {
        const { showLogIn, appStage, user } = this.props;

        if (appStage && appStage === appActions.APP_STAGE_LOADED && !user && showLogIn) {
            this.setState({
                loader: false
            }, () => {
                showLogIn();
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { showLogIn, appStage, user } = this.props;

        if (appStage && prevProps.appStage !== appStage && appStage === appActions.APP_STAGE_LOADED && !user && showLogIn) {
            this.setState({
                loader: false
            }, () => {
                showLogIn();
            });
        }
    }

    render() {
        const { loader } = this.state;

        return (
            <div>
                {loader ?
                    <Loader />
                    : null
                }
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        appStage: state.app.stage,
        user: state.firebase.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showLogIn: () => {
            dispatch(appActions.showLogIn(true));
        }
    };
};

const CheckingLoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckingLogin);

export default CheckingLoginContainer;
