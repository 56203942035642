import {
    UPDATE_AUDIENCE_LIST_ACTION,
    CLEAR_AUDIENCE_LIST_ACTION
} from '../actions/audience_actions';

const initialState = {};

const audience = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_AUDIENCE_LIST_ACTION: {
            if(action.payload) {
                // Remove it
                const next = { ...state };
                for (const [key, value] of Object.entries(action.payload)) {
                    // console.log(`${key}: ${value}`);
                    if(value)
                        next[key] = value;
                    else
                        delete next[key];
                }

                return next;
            }
            return state;
        }
        case CLEAR_AUDIENCE_LIST_ACTION: {
            return Object.assign({}, initialState);
        }
        default:
            return state;
    }
};

export default audience;