export const FIREBASE_INIT_ACTION = 'FIREBASE_INIT_ACTION';
export const FIREBASE_LOG_IN_ACTION = 'FIREBASE_LOG_IN_ACTION';
export const FIREBASE_LOG_OUT_ACTION = 'FIREBASE_LOG_OUT_ACTION';
export const FIREBASE_SET_PROVIDER_ACTION = 'FIREBASE_SET_PROVIDER_ACTION';
export const FIREBASE_SET_USER_ACTION = 'FIREBASE_SET_USER_ACTION';
export const FIREBASE_SET_USER_LOADED_ACTION = 'FIREBASE_SET_USER_LOADED_ACTION';
export const FIREBASE_GET_USERS_ACTION = 'FIREBASE_GET_USERS_ACTION';
export const FIREBASE_SET_HOSTS_ACTION = 'FIREBASE_SET_HOSTS_ACTION';
export const FIREBASE_GET_VIDEOS_ACTION = 'FIREBASE_GET_VIDEOS_ACTION';
export const FIREBASE_UPDATED_VIDEOS_ACTION = 'FIREBASE_UPDATED_VIDEOS_ACTION';
export const FIREBASE_UPDATED_EVENTS_ACTION = 'FIREBASE_UPDATED_EVENTS_ACTION';
export const FIREBASE_SET_ROLES_ACTION = 'FIREBASE_SET_ROLES_ACTION';
export const FIREBASE_GET_CATEGORIES_ACTION = 'FIREBASE_GET_CATEGORIES_ACTION';
export const FIREBASE_GET_TOKEN_ACTION = 'FIREBASE_GET_TOKEN_ACTION';
export const FIREBASE_SET_CHANNEL_LIST_ACTION = 'FIREBASE_SET_CHANNEL_LIST_ACTION';
export const FIREBASE_SET_AUDIENCE_LIST_ACTION = 'FIREBASE_SET_AUDIENCE_LIST_ACTION';
export const FIREBASE_SET_KNOCK_LIST_ACTION = 'FIREBASE_SET_KNOCK_LIST_ACTION';
export const FIREBASE_SET_KNOCK_LIST_ITEM_ACTION = 'FIREBASE_SET_KNOCK_LIST_ITEM_ACTION';
export const FIREBASE_DELETE_KNOCK_LIST_ITEM_ACTION = 'FIREBASE_DELETE_KNOCK_LIST_ITEM_ACTION';
export const FIREBASE_CLEAR_KNOCK_LIST_ACTION = 'FIREBASE_CLEAR_KNOCK_LIST_ACTION';
export const FIREBASE_SET_ACTIVE_PARTICIPANTS_LIST_ACTION = 'FIREBASE_SET_ACTIVE_PARTICIPANTS_LIST_ACTION';
export const FIREBASE_SET_ACTIVE_PARTICIPANTS_ITEM_ACTION = 'FIREBASE_SET_ACTIVE_PARTICIPANTS_ITEM_ACTION';
export const FIREBASE_DELETE_ACTIVE_PARTICIPANTS_ITEM_ACTION = 'FIREBASE_DELETE_ACTIVE_PARTICIPANTS_ITEM_ACTION';
export const FIREBASE_CLEAR_ACTIVE_PARTICIPANTS_LIST_ACTION = 'FIREBASE_CLEAR_ACTIVE_PARTICIPANTS_LIST_ACTION';
export const FIREBASE_GET_CALENDAR_LIST_ACTION = 'FIREBASE_GET_CALENDAR_LIST_ACTION';
export const FIREBASE_SET_PRODUCTS_LIST_ACTION = 'FIREBASE_SET_PRODUCTS_LIST_ACTION';
export const FIREBASE_CHECK_STRIPE_CUSTOMERS_ACTION = 'FIREBASE_CHECK_STRIPE_CUSTOMERS_ACTION';
export const FIREBASE_CREATE_CARD_ACTION = 'FIREBASE_CREATE_CARD_ACTION';
export const FIREBASE_SET_CARD_ACTION = 'FIREBASE_SET_CARD_ACTION';
export const FIREBASE_DELETE_CARD_ACTION = 'FIREBASE_DELETE_CARD_ACTION';
export const FIREBASE_SET_STRIPE_DATA_ACTION = 'FIREBASE_SET_STRIPE_DATA_ACTION';
export const FIREBASE_CREATE_SUBSCRIPTION_ACTION = 'FIREBASE_CREATE_SUBSCRIPTION_ACTION';
export const FIREBASE_SET_SUBSCRIPTIONS_ACTION = 'FIREBASE_SET_SUBSCRIPTIONS_ACTION';
export const FIREBASE_CANCEL_SUBSCRIPTION_ACTION = 'FIREBASE_CANCEL_SUBSCRIPTION_ACTION';
export const FIREBASE_GET_FEATURED_ACTION = 'FIREBASE_GET_FEATURED_ACTION';
export const FIREBASE_GET_DEFAULTS_ACTION = 'FIREBASE_GET_DEFAULTS_ACTION';
export const FIREBASE_SET_TEASERS_ACTION = 'FIREBASE_SET_TEASERS_ACTION';

export const initFirebase = (value) => {
    return {
        type: FIREBASE_INIT_ACTION,
        payload: {
            init: value
        }
    };
}

export const setUser = (value) => {
    return {
        type: FIREBASE_SET_USER_ACTION,
        payload: {
            user: value
        }
    };
}

export const setUserLoaded = (value) => {
    return {
        type: FIREBASE_SET_USER_LOADED_ACTION,
        payload: {
            userLoaded: value
        }
    };
}

export const getUsers = (value) => {
    return {
        type: FIREBASE_GET_USERS_ACTION,
        payload: {
            users: value
        }
    };
}

export const setHosts = (ambassadors, hosts) => {
    return {
        type: FIREBASE_SET_HOSTS_ACTION,
        payload: {
            ambassadors: ambassadors,
            hosts: hosts
        }
    };
}

export const logIn = () => {
    return {
        type: FIREBASE_LOG_IN_ACTION,
        payload: {
            auth: true
        }
    };
}

export const setProvider = (value) => {
    return {
        type: FIREBASE_SET_PROVIDER_ACTION,
        payload: {
            provider: value
        }
    };
}

export const logOut = () => {
    return {
        type: FIREBASE_LOG_OUT_ACTION,
        payload: {
            auth: false
        }
    };
}

export const getVideos = (videos) => {
    return {
        type: FIREBASE_GET_VIDEOS_ACTION,
        payload: {
            videos: videos
        }
    };
}

export const updatedEvents = (events) => {
    return {
        type: FIREBASE_UPDATED_EVENTS_ACTION,
        payload: { events }
    };
}

export const updatedVideos = (videoCollections) => {
    if (!videoCollections)
        videoCollections = {};
    return {
        type: FIREBASE_UPDATED_VIDEOS_ACTION,
        payload: {
            ...videoCollections
        }
    };
}

export const getToken = () => {
    return {
        type: FIREBASE_GET_TOKEN_ACTION,
        payload: {
        }
    };
}

export const setRoles = (value) => {
    return {
        type: FIREBASE_SET_ROLES_ACTION,
        payload: {
            roles: value
        }
    };
}

export const getCategories = (value) => {
    return {
        type: FIREBASE_GET_CATEGORIES_ACTION,
        payload: {
            categories: value
        }
    };
}

export const setAudienceList = (value) => {
    return {
        type: FIREBASE_SET_AUDIENCE_LIST_ACTION,
        payload: {
            audienceList: value,
        }
    };
}

export const setKnockList = (value) => {
    return {
        type: FIREBASE_SET_KNOCK_LIST_ACTION,
        payload: {
            knockList: value,
        }
    };
}

export const setKnockListItem = (value) => {
    return {
        type: FIREBASE_SET_KNOCK_LIST_ITEM_ACTION,
        payload: {
            knockList: value,
        }
    };
}

export const deleteKnockListItem = (value) => {
    return {
        type: FIREBASE_DELETE_KNOCK_LIST_ITEM_ACTION,
        payload: {
            uid: value,
        }
    };
}

export const clearKnockList = () => {
    return {
        type: FIREBASE_CLEAR_KNOCK_LIST_ACTION,
        payload: {
            knockList: [],
        }
    };
}

export const setChannelList = (value) => {
    return {
        type: FIREBASE_SET_CHANNEL_LIST_ACTION,
        payload: {
            channelList: value
        }
    };
}

export const getCalendarList = (value) => {
    return {
        type: FIREBASE_GET_CALENDAR_LIST_ACTION,
        payload: {
            calendarList: value
        }
    };
}

export const setProducts = (value) => {
    return {
        type: FIREBASE_SET_PRODUCTS_LIST_ACTION,
        payload: {
            products: value
        }
    };
}

export const checkStripeCustomers = (value) => {
    return {
        type: FIREBASE_CHECK_STRIPE_CUSTOMERS_ACTION,
        payload: {
            user: value
        }
    };
}

export const createCard = (value, token) => {
    return {
        type: FIREBASE_CREATE_CARD_ACTION,
        payload: {
            stripeId: value,
            tokenId: token
        }
    };
}

export const setCard = (value) => {
    return {
        type: FIREBASE_SET_CARD_ACTION,
        payload: {
            card: value
        }
    };
}

export const deleteCard = (stripeId, cardId) => {
    return {
        type: FIREBASE_DELETE_CARD_ACTION,
        payload: {
            stripeId: stripeId,
            cardId: cardId
        }
    };
}

export const setStripeData = (value) => {
    return {
        type: FIREBASE_SET_STRIPE_DATA_ACTION,
        payload: {
            stripeData: value
        }
    };
}

export const createSubscription = (stripeId, priceId) => {
    return {
        type: FIREBASE_CREATE_SUBSCRIPTION_ACTION,
        payload: {
            stripeId: stripeId,
            priceId: priceId
        }
    };
}

export const setSubscriptionList = (value) => {
    return {
        type: FIREBASE_SET_SUBSCRIPTIONS_ACTION,
        payload: {
            subscriptions: value
        }
    };
}

export const cancelSubscription = (subscriptionId) => {
    return {
        type: FIREBASE_CANCEL_SUBSCRIPTION_ACTION,
        payload: {
            subscriptionId: subscriptionId,
        }
    };
}

export const setActiveParticipantsList = (value) => {
    return {
        type: FIREBASE_SET_ACTIVE_PARTICIPANTS_LIST_ACTION,
        payload: {
            activeParticipantsList: value,
        }
    };
}

export const setActiveParticipantsItem = (value) => {
    return {
        type: FIREBASE_SET_ACTIVE_PARTICIPANTS_ITEM_ACTION,
        payload: {
            activeParticipantsList: value,
        }
    };
}

export const deleteActiveParticipantsItem = (value) => {
    return {
        type: FIREBASE_DELETE_ACTIVE_PARTICIPANTS_ITEM_ACTION,
        payload: {
            uid: value,
        }
    };
}

export const clearActiveParticipantsList = () => {
    return {
        type: FIREBASE_CLEAR_ACTIVE_PARTICIPANTS_LIST_ACTION,
        payload: {
            activeParticipantsList: [],
        }
    };
}

export const getFeatured = (featured) => {
    return {
        type: FIREBASE_GET_FEATURED_ACTION,
        payload: {
            featured,
        }
    };
}

export const getDefaults = (defaults) => {
    return {
        type: FIREBASE_GET_DEFAULTS_ACTION,
        payload: {
            defaults,
        }
    };
}

export const setTeasers = (value) => {
    return {
        type: FIREBASE_SET_TEASERS_ACTION,
        payload: {
            teasers: value
        }
    };
}