import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import ClickOutside from '../../Widget/ClickOutside';
import fanoutClient from '../../../utils/FanoutClient';
import * as roomActions from "../../../actions/room_actions";
import KnockImg from "../../../../resources/assets/Knock.svg";
import KnockActiveImg from "../../../../resources/assets/Knock-Active.svg";
import { isMobile } from "../../../utils/text";

class KnockButtonBottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            knock: false,
            knockRid: null,
            isMobile: isMobile(),
            hover: false
        }
    }

    componentDidUpdate(prevProps) {
        const { knock } = this.state;
        const { knocks, getCallState, displayMessage, hideMenu, knockModal, showKnockModal } = this.props;

        if (Object.keys(knocks).length !== Object.keys(prevProps.knocks).length) {
            if (Object.keys(knocks).length) {
                let callState = getCallState();
                Object.keys(knocks).map((key) => {
                    if (knocks[key] && callState && knocks[key].uid === callState.uid && ((!knocks[key].payload) || (knocks[key].payload && !knocks[key].payload.isAnonymousGuest))) {
                        this.setState({
                            knock: true,
                            knockRid: knocks[key].rid
                        }, () => {
                            if (displayMessage) {
                                displayMessage('You knocked to join the live stream', 2000);
                            }

                            if (showKnockModal) {
                                showKnockModal(knockModal);
                            }
                        });
                    }
                    return key;
                });
            } else if (knock) {
                this.setState({
                    knock: false,
                    knockRid: null
                }, () => {
                    if (displayMessage) {
                        displayMessage('No longer asking to join the live stream', 1000);
                    }
                });
            }
        }

        if (hideMenu && !knockModal && prevProps.knockModal !== knockModal) {
            hideMenu();
        }
    }

    render() {
        const { knock, hover, isMobile } = this.state;
        const { streaming, isAnonymous, knockModal, showKnockModal, knockDisabled } = this.props;

        return (
            <li
                className={classNames("toggle-knock", { 'active': knock || knockModal }, { 'disable': knockDisabled || isAnonymous || (!streaming && !knock) })}
                onMouseEnter={() => {
                    !isMobile && this.setState({ hover: true });
                }}
                onMouseLeave={() => {
                    !isMobile && this.setState({ hover: false });
                }}
            >
                <a
                    data-tip
                    data-for="knock"
                    title="Knock"
                    onClick={() => {
                        if (showKnockModal) {
                            showKnockModal(!knockModal);
                        }
                    }}
                >
                    <img src={knock || knockModal || hover ? KnockActiveImg : KnockImg} />
                    <div>
                        <span>Knock</span>
                    </div>
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        knocks: state.knocks,
        streaming: state.room.streaming,
        knockModal: state.room.knockModal,
        knockDisabled: state.room.knockDisabled
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        displayMessage: (message, timer) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer }));
        },
        showKnockModal: (knockModal, knockDisabled = false) => {
            dispatch(roomActions.showKnockModal(knockModal, knockDisabled));
        }
    };
};

const KnockContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(KnockButtonBottomBar);

export default KnockContainer;
