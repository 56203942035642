import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { Defines } from '../../../utils/FanoutDefines';
import { checkApprovedEntry } from "../../../utils/text";

class NoStreamingText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooEarly: false
        }

        this.checkStreamingAvailable = this.checkStreamingAvailable.bind(this);
    }

    componentDidMount() {
        this.checkStreamingAvailable();
    }

    componentDidUpdate(prevProps) {
        const { eventItem } = this.props;

        if (eventItem && prevProps.eventItem !== eventItem) {
            this.checkStreamingAvailable();
        }
    }

    componentWillUnmount() {
        if (this.tooEarlyTimeout) {
            clearTimeout(this.tooEarlyTimeout);
        }
    }

    checkStreamingAvailable() {
        const { eventItem } = this.props;

        if (eventItem && eventItem.startDate) {
            let diffMinutes = moment(new Date(eventItem.startDate)).subtract(30, 'minutes').diff(moment(new Date()), 'minutes');
            let diffSeconds = moment(new Date(eventItem.startDate)).subtract(30, 'minutes').diff(moment(new Date()), 'seconds');

            if (diffMinutes > 0 || diffSeconds > 0) {
                this.setState({
                    tooEarly: true
                }, () => {
                    this.tooEarlyTimeout = setTimeout(() => {
                        this.setState({
                            tooEarly: false
                        });
                    }, diffSeconds * 1000);
                });
            }
        }
    }

    render() {
        const { tooEarly } = this.state;
        const { streaming, role, eventItem, recordingStatus, approvedEntry, conferenceEnded, streamed } = this.props;

        return (
            <div>
                {(role && ((role === 'presenter') || (role === 'moderator'))) && !streaming ?
                    tooEarly ?
                        <div className='streaming-message-wrapp no-animation'>
                            <p>
                                Start Stream is not available
                                <br />
                                <span>
                                    {`You're early, the earliest the event can start is 30 minutes before the scheduled start.`}
                                    <br />
                                    {eventItem && eventItem.startDate ? `(Start ${moment(new Date(eventItem.startDate)).format('LL')} | ${moment(new Date(eventItem.startDate)).format('LT')})` : ''}
                                </span>
                            </p>
                        </div>
                        : ((conferenceEnded) || (recordingStatus && ((recordingStatus === Defines.RecordingStatus.Disconnected) || (recordingStatus === Defines.RecordingStatus.Done)))) ?
                            <div className='streaming-message-wrapp no-animation blue-message'>
                                <p>
                                    The stream has ended
                                </p>
                            </div>
                            : checkApprovedEntry(approvedEntry, eventItem) ?
                                <div className='streaming-message-wrapp'>
                                    <p>
                                        When you are ready to start streaming, click on the Start Stream button below
                                        <br />
                                        <span>
                                            Your event will be recorded automatically when you start streaming and will be available in My Stuff after the event ends
                                        </span>
                                    </p>
                                </div>
                                : !streamed ?
                                    <div className='streaming-message-wrapp no-animation'>
                                        <p>
                                            Loading streaming service...
                                            <br />
                                            <span>
                                                Hang tight, the stream will be ready to go within 2 minutes!
                                            </span>
                                        </p>
                                    </div>
                                    : null
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        streaming: state.room.streaming,
        approvedEntry: state.room.approvedEntry,
        recordingStatus: state.room.recording,
        conferenceEnded: (state.room.conference && (state.room.conference === 'ended_for_audience' || state.room.conference === 'ended')) || (state.room.status && (state.room.status === 'ended_for_audience' || state.room.status === 'ended')),
        streamed: state.room.streamed,
    };
};

const NoStreamingTextContainer = connect(
    mapStateToProps
)(NoStreamingText);

export default NoStreamingTextContainer;
