import React, { Component } from 'react';
import ReactPlayer from "react-player";
import browser from "bowser";
import firebaseClient from '../../../../utils/FirebaseClient';
import callManager from '../../../../utils/CallManager';

class VideoPresentation extends Component {

    #CallManager = null;

    constructor(props) {
        super(props);
        this.state = {
            safariAutoplay: browser.safari
        };

        this.onSeek = this.onSeek.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.startVideoForSafari = this.startVideoForSafari.bind(this);
    }

    componentDidMount() {
        const { eventItem } = this.props;

        if (eventItem && eventItem.id) {
            this.#CallManager = callManager.get({ eventId: eventItem.id });
        }

        if (this.videoPresentation && this.props.videoDuration) {
            let parseTS = this.props.videoDuration || 0;
            if (parseTS) {
                parseTS++;
            }
            this.videoPresentation.seekTo(parseTS, 'seconds');
        }
    }

    componentDidUpdate(prevProps) {
        const { eventItem } = this.props;

        if (!this.#CallManager && eventItem && eventItem.id && prevProps.eventItem !== eventItem) {
            this.#CallManager = callManager.get({ eventId: eventItem.id });
        }

        if (prevProps.videoTS !== this.props.videoTS) {
            let parseTS = parseInt(this.props.videoTS / 1000) || 0;
            if (parseTS) {
                parseTS++;
            }
            this.videoPresentation.seekTo(parseTS, 'seconds');
        }
    }

    async onSeek(seconds) {
        const { isVideoPresentation, videoURL, eventItem  } = this.props;

        if (isVideoPresentation) {
            try {
                this.#CallManager.seekVideo({ timestamp: seconds * 1000, url: videoURL, eventId: eventItem.id });
            } catch (ex) {
                console.log("Error occurred", ex);
            }
        }
    }

    onPause() {
        const { isVideoPresentation, videoURL, eventItem } = this.props;

        if (isVideoPresentation) {
            try {
                this.#CallManager.pauseVideo({ timestamp: this.videoPresentation.getCurrentTime() * 1000, url: videoURL, eventId: eventItem.id });
            } catch (ex) {
                console.log("Error occurred", ex);
            }
        }
    }

    onPlay() {
        const { isVideoPresentation, eventItem, videoURL } = this.props;

        this.setState | { safariAutoplay: false };

        if (isVideoPresentation) {
            // Just for YouTube seek to current position before play (CC-1205)
            if (videoURL && (videoURL.indexOf('youtube.com') !== -1 || videoURL.indexOf('youtu.be') !== -1)) {
                try {
                    this.#CallManager.seekVideo({ timestamp: this.videoPresentation.getCurrentTime() * 1000, url: videoURL, eventId: eventItem.id });
                } catch (ex) {
                    console.log("Error occurred", ex);
                }
            }

            try {
                this.#CallManager.playVideo({ url: videoURL, timestamp: this.videoPresentation.getCurrentTime() * 1000, url: videoURL, eventId: eventItem.id });
            } catch (ex) {
                console.log("Error occurred", ex);
            }
        }
    }

    startVideoForSafari() {
        this.setState({ safariAutoplay: false });
        this.videoPresentation.getInternalPlayer().play();
    }

    render() {
        const { videoURL, videoPlaying, isVideoPresentation } = this.props;

        return (
            <div className='video-presentation-wrapper'>
                {browser && browser.safari && this.state.safariAutoplay && (
                    <div className="safari-autoplay-container">
                        <button
                            className="safari-autoplay"
                            onClick={this.startVideoForSafari}
                        >
                            Start video
                        </button>
                    </div>
                )}
                <ReactPlayer
                    className='video-participant'
                    id="fullscreen-video"
                    style={!isVideoPresentation ? { pointerEvents: 'none' } : {}}
                    url={videoURL}
                    playsinline
                    ref={ref => (this.videoPresentation = ref)}
                    playing={videoPlaying ? true : false}
                    controls={isVideoPresentation}
                    pip={true}
                    // onReady={() => this.props.sendRef(this.videoPresentation)}
                    config={{
                        file: {
                            attributes: {
                                autoPlay: true
                            }
                        },
                        youtube: {
                            playerVars: {
                                enablejsapi: 1,
                                origin: firebaseClient.getDomain()
                            }
                        }
                    }}
                    width="100%"
                    height="100%"
                    onSeek={this.onSeek}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                />
            </div>
        )
    }
}

export default VideoPresentation;