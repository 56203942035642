import React, { Component } from 'react';
import videojs from 'video.js';
import 'videojs-playlist';
import 'video.js/dist/video-js.css';
import Loader from '../Widget/Loader';
import firebaseClient from "../../utils/FirebaseClient";
import arrowIcon from '../../../resources/assets/ArrowLeft.svg';

class VideoWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPlaying: false,
            linksLoaded: false,
            videoLinks: [],
            loadingText: "Loading video",
            dots: true
        }
    }

    componentDidMount() {
        this.loadVideoData();
    }

    componentDidUpdate(prevProps) {
        const { loadVideo } = this.props; 

        if(loadVideo && prevProps.loadVideo !== loadVideo) {
            this.loadVideoData();
        }
    }

    loadVideoData() {
        const { item, loadVideo } = this.props;

        if (item && item.id && loadVideo) {
            firebaseClient.getVideoLinks(item, false, true).then((res) => {
                if (res && res.length && res !== 'processing' && res !== 'error') {
                    this.setState({
                        linksLoaded: true,
                        videoLinks: res
                    }, () => {
                        this.initVideoPlayer();
                    });
                } else {
                    this.setState({
                        loadingText: res && ((res === 'processing') || (res === 'error')) ? 'The recording is still being processed' : 'There is no recording',
                        dots: false
                    }, () => {
                        this.backTimeout = setTimeout(() => {
                            this.handleBack();
                        }, 3000);
                    })
                }
            });
        }
    }

    initVideoPlayer() {
        const { videoLinks } = this.state;
        const { item } = this.props;

        const videoJsOptions = {
            autoplay: true,
            controls: true,
            poster: firebaseClient.getEventHorizontalPicture(item),
            inactivityTimeout: 0,
            html5: {
                nativeTextTracks: false,
                featuresNativeTextTracks: true,
            },
            textTrackSettings: false,
            playsinline: true,
            controlBar: {
                children: [
                    'playToggle',
                    'volumePanel',
                    'currentTimeDisplay',
                    'timeDivider',
                    'durationDisplay',
                    'progressControl',
                    'liveDisplay',
                    'seekToLive',
                    'remainingTimeDisplay',
                    'customControlSpacer',
                    'playbackRateMenuButton',
                    'chaptersButton',
                    'descriptionsButton',
                    // 'subsCapsButton',
                    'audioTrackButton',
                    `${!process.env.platform || (process.env.platform && process.env.platform !== 'mobile') ? 'fullscreenToggle' : ''}`,
                    'buttonChildOption'
                ]
            },
            sources: [{
                src: videoLinks && videoLinks.length ? videoLinks[0].url : null
            }],
            // tracks: videoLinks && videoLinks.length && videoLinks[0] && videoLinks[0].captionsLink ? [{ src: videoLinks[0].captionsLink, kind: 'captions', srclang: 'en', label: 'English', default: true }] : null
        }

        // instantiate Video.js
        this.player = videojs(this.videoNode, videoJsOptions, () => {
            console.log('onPlayerReady', this)
            window.scrollTo(0, 0);

            if (videoLinks && videoLinks.length && videoLinks.length > 1) {
                let videoPlaylist = [];
                videoLinks.map((item) => {
                    let sourceItem = {
                        sources: [{
                            src: item.url
                        }],
                        // tracks: item && item.captionsLink ? [{ src: item.captionsLink, kind: 'captions', srclang: 'en', label: 'English', default: true }] : null,
                        poster: firebaseClient.getEventHorizontalPicture(item)
                    }
                    videoPlaylist.push(sourceItem);
                });

                this.player.playlist(videoPlaylist);

                // Play through the playlist automatically.
                this.player.playlist.autoadvance(0);
                // this.player.playlist.repeat(true);

                var playList = this.player.playlist;

                var buttonComponent = videojs.getComponent('Button');

                var prevButton = videojs.extend(buttonComponent, {
                    constructor: function () {
                        buttonComponent.apply(this, arguments);
                        this.addClass('vjs-icon-previous-item');
                        this.controlText('Previous');
                    },
                    handleClick: function (e) {
                        playList.previous();
                    }
                });

                var nextButton = videojs.extend(buttonComponent, {
                    constructor: function () {
                        buttonComponent.apply(this, arguments);
                        this.addClass('vjs-icon-next-item');
                        this.controlText('Next');
                    },
                    handleClick: function (e) {
                        playList.next();
                    }
                });

                videojs.registerComponent('prevButton', prevButton);
                videojs.registerComponent('nextButton', nextButton);

                this.player.getChild('controlBar').addChild('prevButton', {}, 0);
                this.player.getChild('controlBar').addChild('nextButton', {}, 2);
            }

        });

        this.player.on(['waiting', 'pause'], () => {
            this.setState({
                isPlaying: false
            });
        });

        this.player.on(['playerresize'], () => {
            let video = this.player.currentDimensions();
            this.player.dimensions(video.width, video.height);
        });

        this.player.on('playing', () => {
            this.setState({
                isPlaying: true
            });
        });

        this.player.on('loadeddata', () => {
            let value = this.player.duration();
            console.log('duration', parseInt(value));
        });

        this.player.on('ended', () => {
            // this.player.src({src: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm'});
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }

        if (this.backTimeout) {
            clearTimeout(this.backTimeout);
        }
    }

    handleBack() {
        const { closeVideoWrapper } = this.props;

        if (closeVideoWrapper) {
            closeVideoWrapper();
        }
    }

    render() {
        const { linksLoaded, loadingText, dots } = this.state;

        return (
            <div className='payment-wrapper'>
                {linksLoaded ?
                    <div className='payment-form video-form'>
                        <div className='payment-div'>
                            <div className='back-icon' onClick={() => this.handleBack()}>
                                <img src={arrowIcon} loading='lazy' />
                            </div>
                            <video ref={node => this.videoNode = node}
                                className="video-js vjs-big-play-centered"
                                playsInline={true}
                                // crossOrigin="anonymous"
                            />
                        </div>
                    </div>
                    :
                    <Loader
                        text={loadingText}
                        dots={dots}
                    />
                }
            </div>
        )
    }
}

export default VideoWrapper;