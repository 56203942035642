import {
    // Actions
    PAYMENT_SET_CLIENT_SECRET_ACTION,
    PAYMENT_CREATE_INTENTS_ACTION,
    PAYMENT_RESET_INTENTS_ACTION,
    PAYMENT_SUCCESS_CONFIRM_PAYMENT_ACTION,
    PAYMENT_FAILED_CONFIRM_PAYMENT_ACTION,
    PAYMENT_FAILED_INTENTS_ACTION,
    PAYMENT_PROCESSING_CONFIRM_PAYMENT_ACTION,
    // State
    PAYMENT_STATE_READY
} from "../actions/payment_actions";

const initialState = {
    clientSecret: null,
    state: PAYMENT_STATE_READY
};

const payment = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_SUCCESS_CONFIRM_PAYMENT_ACTION:
        case PAYMENT_FAILED_CONFIRM_PAYMENT_ACTION:
        case PAYMENT_PROCESSING_CONFIRM_PAYMENT_ACTION:
        case PAYMENT_SET_CLIENT_SECRET_ACTION: 
        case PAYMENT_CREATE_INTENTS_ACTION:
        case PAYMENT_FAILED_INTENTS_ACTION:
        case PAYMENT_RESET_INTENTS_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, data);
        }
        default:
            return state;
    }
};

export default payment;
